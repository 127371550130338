import { forwardRef, useMemo } from 'react';
import { Link, LinkProps, To, useLocation } from 'react-router-dom';

const PreserveSearchLink = forwardRef<HTMLAnchorElement, LinkProps>(({ to: receivedTo, ...props }, ref) => {
  const location = useLocation();

  const to = useMemo<To>(() => {
    if (typeof receivedTo === 'string') {
      return {
        pathname: receivedTo,
        search: location.search,
      };
    } else {
      return {
        ...receivedTo,
        search: location.search,
      };
    }
  }, [location.search, receivedTo]);

  return <Link ref={ref} to={to} {...props} />;
});

export default PreserveSearchLink;
