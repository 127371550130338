import React, { FC } from 'react';
import { Props } from 'recharts/types/component/DefaultLegendContent';
import { Box, Text } from '@mantine/core';

const CustomLegend: FC<Props> = ({ payload }) => {
  return (
    <Box component="ul" m={0} p={0}>
      {payload?.map((entry, index) => (
        <Box
          component="li"
          display="inline-flex"
          key={`item-${index}`}
          sx={theme => ({
            alignItems: 'center',
            listStyle: 'none',
            ':not(:first-child)': {
              marginLeft: 10,
            },
            ':before': {
              content: '""',
              display: 'inline-block',
              width: 22,
              height: 22,
              background: theme.colors.blue[4],
              borderRadius: 4,
              marginRight: 8,
            },
          })}
        >
          <Text size={12} color="dark.5" weight={600}>
            {entry.value}
          </Text>
        </Box>
      ))}
    </Box>
  );
};

export default CustomLegend;
