import { Measures } from '@shared/modules/measures/model';
import { Utils } from '@shared/utils/model';
import { Threshold } from '@modules/iot/model';
import { NumberUtils } from '@shared/utils/number';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { MantineColor } from '@mantine/core';
import { Icon, IconBattery4, IconCircuitResistor, IconDroplet, IconTemperature, IconWifi2 } from '@tabler/icons-react';
import * as O from 'fp-ts/Option';
import * as N from 'fp-ts/number';

export namespace LastMeasuresUtils {
  export type NumericMeasure = Exclude<Measures.Type, Measures.Type.Signal>;

  export const MaxMeasure: { [Key in NumericMeasure]: Measures.Value<Key> } = {
    [Measures.Type.Battery]: Utils.Percent.parse(1),
    [Measures.Type.Humidity]: Utils.Percent.parse(1),
    [Measures.Type.Temperature]: Utils.Temperature.parse(50),
    [Measures.Type.Nutrition]: 100,
  };

  export const MinMeasure: { [Key in NumericMeasure]: Measures.Value<Key> } = {
    [Measures.Type.Battery]: Utils.Percent.parse(0),
    [Measures.Type.Humidity]: Utils.Percent.parse(0),
    [Measures.Type.Temperature]: Utils.Temperature.parse(-20),
    [Measures.Type.Nutrition]: 0,
  };

  export const StepMeasure: { [Key in NumericMeasure]: Measures.Value<Key> } = {
    [Measures.Type.Battery]: Utils.Percent.parse(0.01),
    [Measures.Type.Humidity]: Utils.Percent.parse(0.01),
    [Measures.Type.Temperature]: Utils.Temperature.parse(1),
    [Measures.Type.Nutrition]: 1,
  };

  export interface MeasureValue<Type extends NumericMeasure> {
    measure: Measures.RealTimeMeasure.Impl<Type>;
    scale: Threshold.Scale<Measures.Value<Type>, Threshold.Level>;
  }

  export function formatter<Type extends Measures.Type>(
    value: Measures.Value<Type>,
    type: Type & Measures.Type,
  ): string {
    switch (type) {
      case Measures.Type.Battery:
        return NumberUtils.formatPercent(value as Measures.Value<typeof type>);
      case Measures.Type.Humidity:
        return NumberUtils.formatPercent(value as Measures.Value<typeof type>);
      case Measures.Type.Temperature:
        return NumberUtils.formatTemperature(value as Measures.Value<typeof type>);
      case Measures.Type.Nutrition:
        return N.Show.show(value as Measures.Value<typeof type>);
      case Measures.Type.Signal:
        return Measures.signalStrengthLabel[value as Measures.Value<typeof type>];
      default:
        return '';
    }
  }

  export function getCurrentScale<Type extends NumericMeasure>(
    scale: Threshold.Scale<Measures.Value<Type>, Threshold.Level>,
    value: Measures.Value<Type>,
    type: Type,
  ) {
    return scale.levels.reduceRight((acc, curr) => (value > curr.until ? acc : curr), {
      until: MaxMeasure[type],
      level: scale.last,
    });
  }

  export const levelColor: Record<Threshold.Level, MantineColor> = {
    [Threshold.Level.None]: 'green.7',
    [Threshold.Level.Alert]: 'yellow.5',
    [Threshold.Level.Critical]: 'primary',
  };

  export const measureIcon: Record<Measures.Type, Icon> = {
    [Measures.Type.Battery]: IconBattery4,
    [Measures.Type.Humidity]: IconDroplet,
    [Measures.Type.Signal]: IconWifi2,
    [Measures.Type.Nutrition]: IconCircuitResistor,
    [Measures.Type.Temperature]: IconTemperature,
  };

  export function filterMeasures<Type extends NumericMeasure>(
    measures: Array<Measures.RealTimeMeasure.Impl<Measures.Type>>,
    scale: Threshold.Scale<Measures.Value<Type>, Threshold.Level> | null | undefined,
    type: Type,
  ): O.Option<MeasureValue<Type>> {
    return pipe(
      measures,
      A.findFirst((measure): measure is Measures.RealTimeMeasure.Impl<Type> => measure.type === type),
      O.filterMap(measure =>
        pipe(
          O.fromNullable(scale),
          O.map(scale => ({ measure, scale })),
        ),
      ),
    );
  }
}
