import React, { ChangeEventHandler, FC } from 'react';
import { defineRoute } from '@core/router';
import { defineLoader, httpTaskToResponseTask } from '@core/router/loader';
import { AlertTriggersService } from '@modules/iot/alert-triggers/service';
import { getPageQuery } from '@shared/modules/range/utils';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import Page from '@layout/page/Page';
import { ActionIcon, Badge, Box, Button, Group, Switch, Tooltip } from '@mantine/core';
import { useRange, useVirtualizedTableColumns, VirtualizedList } from '@shared/modules/range';
import { AlertTrigger } from '@modules/iot/alert-triggers/model';
import { identity, pipe } from 'fp-ts/function';
import AlertTriggersPlaceholder from '@modules/iot/alert-triggers/components/list/placeholder/AlertTriggersPlaceholder';
import { Link, useNavigate, useOutlet } from 'react-router-dom';
import FullDrawer from '@shared/components/drawer/FullDrawer';
import { IconPencil } from '@tabler/icons-react';
import { Threshold } from '@modules/iot/model';
import * as T from 'fp-ts/Task';
import { useSendTask } from '@core/http/hooks';
import { Measures } from '@shared/modules/measures/model';

const loader = defineLoader({
  handler: ({ request }) => {
    const queries = parseQueriesFormUrl(request.url);

    return httpTaskToResponseTask(AlertTriggersService.getAlertTriggersRange(getPageQuery(queries)));
  },
});

const AlertTriggersListPage: FC = () => {
  const navigate = useNavigate();
  const outlet = useOutlet();

  const { range, handleLoadPage, setRange } = useRange<typeof loader, AlertTrigger, {}>(identity);

  const [, updateAlertTriggerState] = useSendTask(AlertTriggersService.updateAlertTriggerState);

  const handleChangeAlertTriggerState =
    (id: AlertTrigger.Id): ChangeEventHandler<HTMLInputElement> =>
    e => {
      const state = e.target.checked ? AlertTrigger.State.Enabled : AlertTrigger.State.Disabled;

      pipe(
        () => setRange(old => old.map(alert => (alert.id === id ? { ...alert, state } : alert))),
        T.fromIO,
        T.chain(() => updateAlertTriggerState(id, state)),
      )();
    };

  const { header, row } = useVirtualizedTableColumns<AlertTrigger>([
    {
      key: 'name',
      label: 'Nom',
    },
    {
      key: 'sensor',
      label: 'Sonde',
      renderer: ({ sensor }) => sensor.name,
    },
    {
      key: 'type',
      label: "Type d'alerte",
      renderer: ({ type }) => Measures.typeTitle[type],
    },
    {
      key: 'thresholdLevel',
      label: 'Niveau dalerte',
      width: '200px',
      renderer: ({ thresholdLevel }) => (
        <Badge color={Threshold.levelColor[thresholdLevel]}>{Threshold.levelTitle[thresholdLevel]}</Badge>
      ),
    },
    {
      key: 'actions',
      width: '250px',
      label: (
        <Button component={Link} to="new">
          Créer une alerte
        </Button>
      ),
      headerStyle: { textAlign: 'right' },
      renderer: ({ id, state }) => (
        <Group position="right" spacing="sm">
          <Switch
            size="sm"
            color="primary"
            label="Activer l'alerte"
            checked={state === AlertTrigger.State.Enabled}
            onChange={handleChangeAlertTriggerState(id)}
          />

          <Box sx={{ flex: '1 1 auto' }} />

          <Tooltip label="Modifier">
            <ActionIcon component={Link} to={id} c="tertiary" variant="light" size={36}>
              <IconPencil size={20} />
            </ActionIcon>
          </Tooltip>
        </Group>
      ),
    },
  ]);

  const handleCloseDrawer = () => navigate('.', { replace: true });

  return (
    <Page top="Gestion des alertes">
      <Box p={30}>
        {range.total > 0 ? (
          <VirtualizedList header={header} rowHeight={67} range={range} loadPage={handleLoadPage}>
            {row}
          </VirtualizedList>
        ) : (
          <AlertTriggersPlaceholder />
        )}
      </Box>

      <FullDrawer opened={outlet != null} onClose={handleCloseDrawer} width={530}>
        {outlet}
      </FullDrawer>
    </Page>
  );
};

const alertTriggersListRoute = defineRoute({
  component: AlertTriggersListPage,
  loader,
});

export default alertTriggersListRoute;
