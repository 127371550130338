import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import loginRoute from '@modules/auth/LoginPage';
import loginWithTokenRoute from '@modules/auth/LoginWithTokenPage';

export const authRoutes = (
  <Route path="/login">
    <Route index {...createRoute(loginRoute)} />
    <Route path="token/:token" {...createRoute(loginWithTokenRoute)} />
  </Route>
);

export default authRoutes;
