import { defineLoader, useParentLoader } from '@core/router/loader';
import { flow, pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as EI from 'fp-ts/Either';
import { HttpStatusCode } from '@core/http';
import { redirect } from 'react-router-dom';
import { defineRoute } from '@core/router';
import { stringifyQueries } from '@shared/utils/queries';
import { ProfileService } from '@modules/profile/service';
import * as TE from 'fp-ts/TaskEither';
import * as TO from 'fp-ts/TaskOption';
import { OAuthService } from '@core/oauth/service';

const LOADER_ID = 'profile-loader';

const profileLoader = defineLoader({
  id: LOADER_ID,
  handler: ({ request }) =>
    pipe(
      ProfileService.getProfile(),
      TE.orElseFirstTaskK(
        flow(
          TO.fromPredicate(({ status }) => [HttpStatusCode.UNAUTHORIZED, HttpStatusCode.FORBIDDEN].includes(status)),
          TO.chainTaskK(OAuthService.removeOAuthTokensInStorage),
        ),
      ),
      T.chainIOK(res => () => {
        if (EI.isLeft(res)) {
          if ([HttpStatusCode.UNAUTHORIZED, HttpStatusCode.FORBIDDEN].includes(res.left.status)) {
            throw redirect(`/login?${stringifyQueries({ referrer: new URL(request.url).pathname })}`);
          }

          throw res.left.toResponse();
        }

        return res.right;
      }),
    ),
});

const profileLoaderRoute = defineRoute({
  loader: profileLoader,
});

export function useProfile() {
  return useParentLoader<typeof profileLoaderRoute.loader>(LOADER_ID);
}

export default profileLoaderRoute;
