import React, { forwardRef, useDeferredValue, useEffect, useState } from 'react';
import { Loader, Select, SelectProps } from '@mantine/core';
import { IconMapPin } from '@tabler/icons-react';
import { useDebouncedState } from '@mantine/hooks';
import { GeoService } from '@shared/modules/geo/service';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import { constTrue, constVoid, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import { Geo } from '@shared/modules/geo/model';
import { Utils } from '@shared/utils/model';
import { filterEmptyStringToNullable } from '@shared/utils/string';

interface SearchGeoCityProps extends Partial<Omit<SelectProps, 'value' | 'onChange'>> {
  value?: Geo.City | null;
  onChange?: (city: Geo.City) => void;
}

type CityDataValue = `${Geo.City.INSEECode}-${Utils.PostalCode}`;

function geoCityToCityDataValue(geoCity?: Geo.City | null): CityDataValue | null {
  return geoCity ? `${geoCity.code}-${geoCity.postalCode}` : null;
}

function getCityToCityDataLabel(geoCity?: Geo.City | null): string | null {
  return geoCity ? `${geoCity.postalCode}, ${geoCity.city}` : null;
}

const SearchGeoCity = forwardRef<HTMLInputElement, SearchGeoCityProps>(
  ({ value, onChange = constVoid, ...props }, ref) => {
    const [search, setSearch] = useDebouncedState<string | null>(null, 400);

    const handleSetSearch = (search: string | null) => {
      // prevent initial search
      if (search !== getCityToCityDataLabel(value)) setSearch(search);
    };

    const [l, setLoading] = useState<boolean>(false);

    const loading = useDeferredValue(l);

    const [cities, setCities] = useState<Array<Geo.City>>(() =>
      pipe(
        O.fromNullable(value),
        O.fold(
          () => [],
          city => [city],
        ),
      ),
    );

    useEffect(() => {
      const filteredSearch = filterEmptyStringToNullable(search);

      if (filteredSearch) {
        setLoading(true);

        pipe(
          GeoService.getCities(filteredSearch),
          TE.chainIOK(cities => () => setCities(cities)),
          T.chainIOK(() => () => setLoading(false)),
        )();
      }
    }, [search]);

    const citiesData = pipe(
      cities,
      A.map(city => ({
        value: geoCityToCityDataValue(city) ?? '',
        label: getCityToCityDataLabel(city) ?? '',
      })),
    );

    const handleCityChange = (value: CityDataValue | null) => {
      const city = pipe(
        O.fromNullable(value),
        O.map(value => value.split('-') as [Geo.City.INSEECode, Utils.PostalCode]),
        O.chain(([code, postalCode]) =>
          pipe(
            cities,
            A.findFirst(city => city.code === code && city.postalCode === postalCode),
          ),
        ),
        O.toNullable,
      );

      if (city) {
        onChange(city);
      }
    };

    return (
      <Select
        ref={ref}
        placeholder="Sélectionner une ville"
        value={geoCityToCityDataValue(value)}
        data={citiesData}
        onSearchChange={handleSetSearch}
        onChange={handleCityChange}
        filter={constTrue} // prevent double filter
        nothingFound={search ? 'Aucun résultat trouvé' : 'Aucune recherche renseignée'}
        rightSection={loading ? <Loader pr="xs" size={26} /> : undefined}
        searchable
        required
        icon={<IconMapPin size={20} color="black" strokeWidth={1} />}
        label="Ville"
        {...props}
      />
    );
  },
);

export default SearchGeoCity;
