import styled from '@emotion/styled';
import { styledOptions } from '@styles/utils';

export const DiseasePrevisionsContainer = styled('div', styledOptions)<{ $vertical?: boolean }>`
  display: grid;
  grid-template-columns: ${({ $vertical }) =>
    $vertical ? 'repeat(2, 1fr)' : 'repeat(3, 11%) repeat(3, minmax(0, 230px))'};
  grid-gap: 20px 10px;
  margin-top: 5px;
`;
