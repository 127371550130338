import React, { Dispatch, FC } from 'react';

import { Dashboard } from '@modules/dashboard/model';
import { Group, SimpleGrid, Skeleton, Stack, Text, Title } from '@mantine/core';
import WeatherPrevisions from '@shared/modules/previsions/components/weather/WeatherPrevisions';
import { Zone } from '@modules/iot/zones/model';
import DiseasePrevisions from '@shared/modules/previsions/components/disease/DiseasePrevisions';
import { renderOptional } from '@shared/utils/render';
import * as O from 'fp-ts/Option';
import { sequenceS } from 'fp-ts/Apply';
import LocationFilter from '@shared/modules/previsions/components/filter/LocationFilter';
import DiseaseCaption from '@shared/modules/previsions/components/disease/DiseaseCaption';
import DiseasePlaceholder from '@shared/modules/previsions/components/disease/DiseasePlaceholder';
import WeatherPlaceholder from '@shared/modules/previsions/components/weather/WeatherPlaceholder';

interface PrevisionsProps {
  zones: Array<Zone>;
  context: O.Option<Dashboard.Context>;
  weather: O.Option<Dashboard.Weather>;
  disease: O.Option<Dashboard.Disease>;
  setFilter: Dispatch<React.SetStateAction<Dashboard.Filter | null>>;
  vertical?: boolean;
}

const Previsions: FC<PrevisionsProps> = ({ vertical, zones, context, weather, disease, setFilter }) => {
  return (
    <>
      <Stack spacing={20}>
        <SimpleGrid cols={vertical ? 1 : 2} sx={{ alignItems: 'center' }}>
          <Title order={2} size="h4">
            Prévision météo
          </Title>

          {renderOptional(
            context,
            ({ filter }) => (
              <LocationFilter filter={filter} setFilter={setFilter} zones={zones} />
            ),
            () => (
              <Skeleton h={36} />
            ),
          )}
        </SimpleGrid>

        {renderOptional(
          sequenceS(O.Apply)({ weather, context }),
          ({ weather, context }) => (
            <WeatherPrevisions
              main={weather.today}
              days={weather.forecast}
              location={context.location}
              vertical={vertical}
            />
          ),
          () => (
            <WeatherPlaceholder vertical={vertical} />
          ),
        )}
      </Stack>

      <Stack spacing={8} pt={28}>
        <Group position="apart">
          <Text color="dark.5" weight={700} size={18} lh={1.45}>
            Épidémiosurveillance
          </Text>

          <DiseaseCaption />
        </Group>
        {renderOptional(
          sequenceS(O.Apply)({ disease, context }),
          ({ context, disease }) => (
            <DiseasePrevisions
              main={disease.today}
              days={disease.forecast}
              location={context.location}
              vertical={vertical}
              formatStr="EEEE"
            />
          ),
          () => (
            <DiseasePlaceholder />
          ),
        )}
      </Stack>
    </>
  );
};

export default Previsions;
