import React, { FC, useState } from 'react';
import DiseaseLineChart from '@shared/modules/charts/line/DiseaseLineChart';
import { DashboardUtils } from '@modules/alert-reports/dashboard/utils';
import { Card, Checkbox, Group, Text } from '@mantine/core';
import { pipe } from 'fp-ts/function';
import { Disease } from '@shared/modules/disease/model';
import * as A from 'fp-ts/Array';
import { Charts } from '@shared/modules/charts/model';

interface DiseaseChartCardProps {
  days: Array<Disease.DayForecast>;
}

const DiseaseChartCard: FC<DiseaseChartCardProps> = ({ days }) => {
  const [selection, setSelection] = useState(Object.values(Disease.Model));

  const handleCheckboxChange = (selection: Array<Disease.Model>) => setSelection(selection);

  return (
    <>
      <DiseaseLineChart data={DashboardUtils.getForecastData(days, selection)} h={326} />
      <Card
        w="fit-content"
        mt={15}
        py={10}
        px={14}
        withBorder={false}
        sx={theme => ({ border: `1px solid ${theme.colors.tertiary[2]}` })}
      >
        <Text c="dark.1" fz={10} fw={600} pb={10}>
          Capteur Sol
        </Text>
        <Checkbox.Group value={selection} onChange={handleCheckboxChange}>
          <Group>
            {pipe(
              Object.values(Disease.Model),
              A.map(model => (
                <Checkbox
                  key={model}
                  value={model}
                  color="red"
                  styles={theme => ({
                    label: { paddingLeft: 8 },
                    input: {
                      borderColor: `${theme.fn.themeColor(Charts.Line.Disease.modelColor[model])} !important`,
                      ':checked': {
                        backgroundColor: theme.fn.themeColor(Charts.Line.Disease.modelColor[model]),
                      },
                    },
                  })}
                  label={
                    <Text c="dark.5" fz={12} fw={600}>
                      {Disease.modelLabel[model]}
                    </Text>
                  }
                />
              )),
            )}
          </Group>
        </Checkbox.Group>
      </Card>
    </>
  );
};

export default DiseaseChartCard;
