import React, { FC } from 'react';
import { SimpleGrid } from '@mantine/core';
import DashboardActiveSensorsCard from '@modules/iot/dashboard/components/DashboardActiveSensorsCard';
import DashboardPendingSensorsCard from '@modules/iot/dashboard/components/DashboardPendingSensorsCard';
import { useLoader } from '@core/router/loader';
import { loader } from '@modules/iot/dashboard/DashboardPage';

const DashboardPark: FC = () => {
  const { dashboard } = useLoader<typeof loader>();

  return (
    <SimpleGrid cols={2}>
      <DashboardActiveSensorsCard dashboard={dashboard} />
      <DashboardPendingSensorsCard dashboard={dashboard} />
    </SimpleGrid>
  );
};

export default DashboardPark;
