import { Utils } from '@shared/utils/model';
import { Geo } from '@shared/modules/geo/model';
import { Zone } from '@modules/iot/zones/model';
import { FunctionComponent, SVGProps } from 'react';
import { MantineColor } from '@mantine/core';
import { ReactComponent as IconCloudy } from '@assets/icons/weather/cloudy.svg';
import { ReactComponent as IconCloudyGusts } from '@assets/icons/weather/cloudy-gusts.svg';
import { ReactComponent as IconShowers } from '@assets/icons/weather/showers.svg';
import { ReactComponent as IconRainMix } from '@assets/icons/weather/rain-mix.svg';
import { ReactComponent as IconRain } from '@assets/icons/weather/rain.svg';
import { ReactComponent as IconSleet } from '@assets/icons/weather/sleet.svg';
import { ReactComponent as IconSnow } from '@assets/icons/weather/snow.svg';
import { ReactComponent as IconDayCloudy } from '@assets/icons/weather/day-cloudy.svg';
import { ReactComponent as IconDayCloudyGusts } from '@assets/icons/weather/day-cloudy-gusts.svg';
import { ReactComponent as IconDayRain } from '@assets/icons/weather/day-rain.svg';
import { ReactComponent as IconDayWindy } from '@assets/icons/weather/day-windy.svg';
import { ReactComponent as IconHot } from '@assets/icons/weather/hot.svg';
import { ReactComponent as IconDaySunny } from '@assets/icons/weather/day-sunny.svg';
import { Pest as PestModel } from '@modules/alert-reports/pests/model';
import { Disease as DiseaseModel } from '@shared/modules/disease/model';
import { Weather as WeatherModel } from '@shared/modules/weather/model';

export namespace Dashboard {
  import GPSCoordinates = Utils.GPSCoordinates;

  export interface LocationFilter {
    type: 'location';
    location: Geo.City;
  }

  export interface ZoneFilter {
    type: 'zone';
    zoneId: Zone.Id;
  }

  export type Filter = LocationFilter | ZoneFilter;
  export interface Context {
    filter: Filter;
    coordinates: GPSCoordinates;
    location: Geo.City;
  }

  export namespace Weather {
    export enum Type {
      Cloudy = 'f013',
      CloudyGusts = 'f011',
      Showers = 'f01a',
      RainMix = 'f017',
      Rain = 'f019',
      Sleet = 'f0b5',
      Snow = 'f01b',
      DayCloudy = 'f002',
      DayCloudyDusts = 'f000',
      DayRain = 'f008',
      DayWindy = 'f085',
      Hot = 'f072',
      DaySunny = 'f00d',
    }

    export interface TypeInfo {
      label: string;
      icon: FunctionComponent<SVGProps<SVGSVGElement>>;
      color: MantineColor;
    }

    export const typeInfos: Record<Type, TypeInfo> = {
      [Type.Cloudy]: {
        label: 'Nuageux',
        icon: IconCloudy,
        color: 'blue.3',
      },
      [Type.CloudyGusts]: {
        label: 'Nuageux et venteux',
        icon: IconCloudyGusts,
        color: 'blue.3',
      },
      [Type.Showers]: {
        label: 'Couvert, pluie occationnelle',
        icon: IconShowers,
        color: 'blue.3',
      },
      [Type.RainMix]: {
        label: 'Pluvieux',
        icon: IconRainMix,
        color: 'blue.3',
      },
      [Type.Rain]: {
        label: 'Orageux',
        icon: IconRain,
        color: 'blue.3',
      },
      [Type.Sleet]: {
        label: 'Bruine',
        icon: IconSleet,
        color: 'blue.3',
      },
      [Type.Snow]: {
        label: 'Neige',
        icon: IconSnow,
        color: 'blue.3',
      },
      [Type.DayCloudy]: {
        label: 'Partiellement nuageux',
        icon: IconDayCloudy,
        color: 'yellow.5',
      },
      [Type.DayCloudyDusts]: {
        label: 'Venteux avec quelques nuages',
        icon: IconDayCloudyGusts,
        color: 'blue.3',
      },
      [Type.DayRain]: {
        label: 'Partiellement nuageux avec quelques averses',
        icon: IconDayRain,
        color: 'blue.3',
      },
      [Type.DayWindy]: {
        label: 'Venteux / vent fort',
        icon: IconDayWindy,
        color: 'yellow.5',
      },
      [Type.Hot]: {
        label: 'Vague de chaleur',
        icon: IconHot,
        color: 'yellow.5',
      },
      [Type.DaySunny]: {
        label: 'Ensoleillé',
        icon: IconDaySunny,
        color: 'yellow.5',
      },
    };
  }

  export interface Weather {
    today: WeatherModel.ForecastValues | null;
    forecast: Array<WeatherModel.DayForecast>;
  }
  export interface Disease {
    today: DiseaseModel.Forecast;
    forecast: Array<DiseaseModel.DayForecast>;
  }

  export namespace Pest {
    export interface Risk {
      name: string;
      enum: PestModel.Name;
      stage: PestModel.Stage;
      level: PestModel.Risk.Level;
    }
  }

  export interface Pest {
    dateRange: PestModel.Description.DateRange;
    risks: Array<Pest.Risk>;
  }
}
