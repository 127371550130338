import React, { FC } from 'react';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import Seo from '@shared/modules/seo/Seo';
import { Box, Card } from '@mantine/core';
import { defineAction, useAction } from '@core/router/action';
import { defineRoute, preventActionLeave, usePreserveNavigate, withKeyObserver } from '@core/router';
import { z } from 'zod';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { sequenceS } from 'fp-ts/Apply';
import * as TE from 'fp-ts/TaskEither';
import { Outlet } from 'react-router-dom';
import { ZonesService } from '@modules/iot/zones/service';
import { SensorsService } from '@modules/iot/sensors/service';
import { Sinafis } from '@modules/iot/sensors/sinafis/model';
import { SinafisSchema } from '@modules/iot/sensors/sinafis/schema';
import { SinafisService } from '@modules/iot/sensors/sinafis/service';
import PendingHeader from '@modules/iot/sensors/list/components/pending/PendingHeader';
import PendingForm from '@modules/iot/sensors/sinafis/components/PendingForm';
import { Sensor } from '@modules/iot/sensors/model';
import { SinafisUtils } from '@modules/iot/sensors/sinafis/utils';
import { flow, pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import * as O from 'fp-ts/Option';
import configSchemaFromProbe = SinafisUtils.configSchemaFromProbe;
import { SensorUtils } from '@modules/iot/sensors/utils';
import ExtendedMeasureProbeMapping = Sinafis.Config.ExtendedMeasureProbeMapping;

const loader = defineLoader({
  id: Sensor.SensorsRouteId.SinafisPending,
  params: Sensor.sensorPendingParams,
  handler: ({ params }) => {
    return httpTaskToResponseTask(
      sequenceS(TE.ApplyPar)({
        sensor: SensorsService.getPendingSensor<Sensor.Type.Sinafis>(params.id),
        zones: ZonesService.getZones(),
      }),
    );
  },
});

const actions = {
  update: defineAction({
    type: 'update',
    payload: SinafisSchema.pendingSchema,
    handler: ({ payload }) => SinafisService.updatePending(payload),
    flashOptions: {
      success: () => 'Configuration réalisée',
    },
  }),
};

const PendingDetailPage: FC = () => {
  const [loading, send] = useAction(actions.update);
  const { sensor, zones } = useLoader<typeof loader>();
  const navigate = usePreserveNavigate();

  const filteredMappings: Record<keyof ExtendedMeasureProbeMapping, unknown> = pipe(
    { ...sensor.config.mappings, transmitter: -1 } satisfies ExtendedMeasureProbeMapping,
    R.filterMap(O.fromNullable),
  );

  const { formRef, form } = useEnhancedForm<Sinafis.PendingParams>({
    resolver: zodResolver(
      SinafisSchema.pendingSchema.and(z.object({ config: configSchemaFromProbe(filteredMappings) })),
    ),
    defaultValues: SinafisUtils.getPendingDefaultValues(sensor, filteredMappings),
  });

  const handleUpdate = flow(
    send,
    TE.chainIOK(
      ({ id }) =>
        () =>
          navigate(SensorUtils.sensorLinkBuilder({ type: Sensor.Type.Sinafis, id }), { replace: true }),
    ),
  );

  return (
    <>
      <Box h="100%">
        <Seo title="Configuration Sinafis" />
        <PendingHeader type={sensor.type} measures={sensor.measures} />
        <Card
          p={22}
          radius={10}
          shadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
          sx={theme => ({
            '&[data-with-border]': { border: `1px solid ${theme.colors.tertiary[2]}`, borderTop: 'none' },
          })}
        >
          <EnhancedForm ref={formRef} form={form} onSubmit={handleUpdate} preventLeave>
            <PendingForm
              serial={sensor.serial}
              loading={loading}
              zones={zones}
              location={sensor.location}
              mappings={filteredMappings}
            />
          </EnhancedForm>
        </Card>
      </Box>
      <Outlet />
    </>
  );
};

const component = withKeyObserver<typeof loader>(PendingDetailPage, 'id');

export const sinafisPendingRoute = defineRoute({
  component,
  actions,
  loader,
  shouldRevalidate: preventActionLeave<typeof actions>('update'),
});
