import z from 'zod';
import * as N from 'fp-ts/number';
import * as Ord from 'fp-ts/Ord';
import * as Show from 'fp-ts/Show';
import { NumberUtils } from '@shared/utils/number';

export namespace Utils {
  import formatPercent = NumberUtils.formatPercent;
  export const PostalCode = z.string().min(1).brand<'PostalCode'>();
  export type PostalCode = z.infer<typeof PostalCode>;

  export const Percent = z.number().brand<'Percent'>();
  export type Percent = z.infer<typeof Percent>;
  export const percentOrd: Ord.Ord<Percent> = N.Ord;
  export const percentShow: Show.Show<Percent> = { show: formatPercent };

  export const Temperature = z.number().brand<'Temperature'>();
  export type Temperature = z.infer<typeof Temperature>;
  export const temperatureOrd: Ord.Ord<Temperature> = N.Ord;

  export const Length = z.number().brand<'Length'>();
  export type Length = z.infer<typeof Length>;

  export const GPSCoordinatesSchema = z.object({
    latitude: z.number().min(-90).max(90),
    longitude: z.number().min(-180).max(180),
  });

  export type GPSCoordinates = z.infer<typeof GPSCoordinatesSchema>;

  export enum ChronoUnit {
    Nanos = 'NANOS',
    Micros = 'MICROS',
    Millis = 'MILLIS',
    Seconds = 'SECONDS',
    Minutes = 'MINUTES',
    Hours = 'HOURS',
    HalfDays = 'HALF_DAYS',
    Days = 'DAYS',
    Weeks = 'WEEKS',
    Months = 'MONTHS',
    Years = 'YEARS',
    Decades = 'DECADES',
    Centuries = 'CENTURIES',
    Millennia = 'MILLENNIA',
    Eras = 'ERAS',
    Forever = 'FOREVER',
  }
}
