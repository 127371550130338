import { httpService, HttpTask } from '@core/http';
import { Pest } from '@modules/alert-reports/pests/model';
import { Dashboard } from '@modules/dashboard/model';

export namespace PestService {
  const URI = '/pest';
  export function getPests(context: Dashboard.Context): HttpTask<Array<Pest.Description>> {
    return httpService.post(`${URI}/list`, context);
  }

  export function getPest(name: Pest.Name, context: Dashboard.Context): HttpTask<Pest.Detail> {
    return httpService.post(`${URI}/${name}`, context);
  }

  export function updateSubscription(name: Pest.Name, value: boolean): HttpTask {
    return httpService.post(`${URI}/${name}/subscription`, { value });
  }

  export function updateVisibility(name: Pest.Name, value: boolean): HttpTask {
    return httpService.post(`${URI}/${name}/visibility`, { value });
  }
}
