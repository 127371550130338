import React, { createElement, FC } from 'react';
import { Card, Group, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { Sensor } from '@modules/iot/sensors/model';
import { Measures } from '@shared/modules/measures/model';
import { renderOptional } from '@shared/utils/render';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as NEA from 'fp-ts/NonEmptyArray';
import { MeasuresUtils } from '@shared/modules/measures/utils';
import batteryLevel = MeasuresUtils.batteryLevel;

interface PendingHeaderProps {
  type: Sensor.Type;
  measures: Measures.Last | null;
}

const PendingHeader: FC<PendingHeaderProps> = ({ type, measures }) => {
  const battery = MeasuresUtils.getMeasureValue(Measures.Type.Battery, measures);
  const signal = MeasuresUtils.getMeasureValue(Measures.Type.Signal, measures);

  const measuresRender = pipe(NEA.fromArray(A.compact<Measures.Value>([battery, signal])));

  return (
    <Group py={15} mb={16} position="apart" sx={theme => ({ borderBottom: `1px solid ${theme.colors.gray[3]}` })}>
      <Group spacing={10}>
        {createElement(Sensor.typeIcon[type], { width: 48, height: 48 })}
        <Text color="primary" weight={700} size={22}>
          Sonde {Sensor.typeTitle[type]}
        </Text>
      </Group>
      {renderOptional(measuresRender, () => (
        <Card px={10} py={4} radius={8} withBorder={false} bg="tertiary.2">
          <Group>
            {renderOptional(signal, strength => (
              <Tooltip label={Measures.signalStrengthLabel[strength]} withinPortal>
                {createElement(Measures.signalStrengthIcon[strength], { strokeWidth: 1, size: 31 })}
              </Tooltip>
            ))}

            {renderOptional(battery, level => (
              <Tooltip label={Measures.batteryLabel[batteryLevel(level)]} withinPortal>
                <ThemeIcon size={31} variant="transparent" c="secondary">
                  {createElement(Measures.batteryIcon[batteryLevel(level)], { strokeWidth: 1, size: 31 })}
                </ThemeIcon>
              </Tooltip>
            ))}
          </Group>
        </Card>
      ))}
    </Group>
  );
};

export default PendingHeader;
