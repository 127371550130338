import { useCallback, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { Filter } from '@shared/modules/filter';
import { Range, RangeCursor, RangeResult } from './model';
import { useFetcher, useLocation, useNavigate } from 'react-router-dom';
import { stringifyQueries } from '@shared/utils/queries';
import { LoaderReturnType, useLoader } from '@core/router/loader';

/**
 * Hooks permettant d'utilisation un range à partir d'un loader
 * Il prend un mapper en paramètres pour mapper les données du loader en un RangeResult. La plupart du temps on pourra utiliser `identity` de fp-ts
 *
 * @param mapper - selecteur des données du range.
 */
export function useRange<
  Loader,
  R,
  F extends Filter = {},
  Mapper extends (data: LoaderReturnType<Loader>) => RangeResult<R, F> = (
    data: LoaderReturnType<Loader>,
  ) => RangeResult<R, F>,
>(mapper: Mapper) {
  const initialData = useLoader<Loader>();

  const mapperRef = useRef(mapper);

  mapperRef.current = mapper;

  const initialRange = useMemo(() => mapperRef.current(initialData), [initialData]);

  const location = useLocation();
  const navigate = useNavigate();

  const fetcher = useFetcher();

  const [range, setRange] = useState<Range<R, F>>(() => Range.fromRangeResult(initialRange));

  const [, startTransition] = useTransition();

  /**
   * Synchronisation avec les données du loader et écrase les anciennes données
   * Les données du loader changent quand les queries changes
   */
  useEffect(() => {
    startTransition(() => {
      setRange(Range.fromRangeResult(initialRange));
    });
  }, [initialRange]);

  /**
   * Synchronisation avec les données du fetcher. Merge avec les anciennes données.
   * Les données du fetcher changent quand on utilise la pagination virtuelle
   */
  useEffect(() => {
    const data = fetcher.data as LoaderReturnType<Loader> | undefined;

    if (data) {
      startTransition(() => {
        setRange(old => old.merge(Range.fromRangeResult(mapperRef.current(data))));
      });
    }
  }, [fetcher.data]);

  /**
   * Chargement d'une nouvelle page
   *
   * On utilise alors le fetcher avec en paramètres les nouvelles queries. La query page est alors cachée à l'utilisateur
   */
  const handleLoadPage = useCallback(
    (page: number) => {
      fetcher.submit(new URLSearchParams(stringifyQueries({ ...range.filter, page })));
    },
    [fetcher, range.filter],
  );

  /**
   * Rafraichi la page concerné par l'index
   */
  const handleRefreshIndex = useCallback(
    (index: number) => {
      handleLoadPage(RangeCursor.fromIndex(index).toPage());
    },
    [handleLoadPage],
  );

  /**
   * Modifie les queries de l'utilisateur pour relancer le fetcher
   */
  const handleFilter = useCallback(
    (filter: F) => navigate({ ...location, search: stringifyQueries(filter) }, { replace: true }),
    [location, navigate],
  );

  return {
    range,
    handleLoadPage,
    handleFilter,
    handleRefreshIndex,
    setRange,
  };
}
