import { useEffect, useState } from 'react';
import * as A from 'fp-ts/Array';
import Supercluster from 'supercluster';
import { Utils } from '@shared/utils/model';

export type MarkerWithLocation<Marker> = Marker & { location: Utils.GPSCoordinates };

export interface CustomPointFeature<Marker> {
  marker: MarkerWithLocation<Marker>;
  cluster: false;
}

export const MAX_ZOOM = 16;

export function useMapCluster<Marker>(
  map: google.maps.Map,
  markers: Array<MarkerWithLocation<Marker>>,
  supercluster: Supercluster<CustomPointFeature<Marker>>,
) {
  const [clusters, setClusters] = useState<ReturnType<Supercluster<CustomPointFeature<Marker>>['getClusters']>>([]);

  useEffect(() => {
    const computeCluster = () => {
      const zoom = map.getZoom();
      const bounds = map.getBounds();
      if (bounds && zoom) {
        setClusters(
          supercluster.getClusters(
            [
              bounds.getSouthWest().lng(),
              bounds.getSouthWest().lat(),
              bounds.getNorthEast().lng(),
              bounds.getNorthEast().lat(),
            ],
            zoom,
          ),
        );
      }
    };

    const listener = map.addListener('bounds_changed', computeCluster);

    computeCluster();

    if (A.isNonEmpty(markers)) {
      const bounds = new google.maps.LatLngBounds();

      markers.forEach(({ location }) =>
        bounds.extend({
          lat: location.latitude,
          lng: location.longitude,
        }),
      );
      map.fitBounds(bounds, 20);

      google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
        if ((map.getZoom() ?? 0) > MAX_ZOOM) map.setZoom(MAX_ZOOM);
      });
    }

    return () => {
      listener.remove();
    };
  }, [markers, map, supercluster]);

  return clusters;
}
