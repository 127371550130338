import { ActionIcon, ActionIconProps, createPolymorphicComponent } from '@mantine/core';
import styled from '@emotion/styled';
import { styledOptions } from '@styles/utils';

export const LayoutContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
`;

export const LayoutContent = styled.div`
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
`;

export const LayoutNavButton = createPolymorphicComponent<'button', ActionIconProps & { $navOpen: boolean }>(styled(
  ActionIcon,
  styledOptions,
)<{ $navOpen: boolean }>`
  position: absolute;
  left: ${props => (props.$navOpen ? '246px' : '60px')};
  top: 14px;
  border-color: ${props => props.theme.colors.tertiary[3]};
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: left 0.25s ease-in-out;

  &:hover {
    background: ${props => props.theme.white};
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: none;
  }

  svg {
    transform: rotate(${props => (props.$navOpen ? '0' : '180')}deg);
    transition: transform 0.25s ease-in-out;
  }
`);
