import React, { FC } from 'react';
import { Skeleton } from '@mantine/core';
import * as A from 'fp-ts/Array';
import * as Styled from '@shared/modules/previsions/components/weather/WeatherPrevisions.styles';

interface WeatherPlaceholderProps {
  vertical?: boolean;
}

const WeatherPlaceholder: FC<WeatherPlaceholderProps> = ({ vertical }) => {
  return (
    <Styled.WeatherPrevisionsContainer $vertical={vertical}>
      <Skeleton radius={8} h={vertical ? 314 : 325} style={{ gridColumn: 'span 3' }} />
      {A.makeBy(vertical ? 6 : 5, index => (
        <Skeleton key={index} radius={8} h={325} />
      ))}
    </Styled.WeatherPrevisionsContainer>
  );
};

export default WeatherPlaceholder;
