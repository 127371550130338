import React, { FC, useCallback, useLayoutEffect, useState } from 'react';
import { defineLoader, httpTaskToResponseTask, useLoader, useParentLoader } from '@core/router/loader';
import { SensorsService } from '@modules/iot/sensors/service';
import { defineRoute, usePreserveNavigate } from '@core/router';
import { Accordion, Box, Button, Stack, Title } from '@mantine/core';
import { Outlet, useMatches } from 'react-router-dom';
import { renderOptional } from '@shared/utils/render';
import * as NEA from 'fp-ts/NonEmptyArray';
import { IconSquarePlus } from '@tabler/icons-react';
import ActiveSensorZone from '@modules/iot/sensors/list/components/ActiveSensorZone';
import PendingSensors from '@modules/iot/sensors/list/components/pending/PendingSensors';
import { iotLayoutLoader } from '@modules/iot/sensors/layout/IotLayout';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';
import * as S from 'fp-ts/string';
import { ActiveSensor, Sensor } from '@modules/iot/sensors/model';
import { defineAction, useAction } from '@core/router/action';
import { SensorSchema } from '@modules/iot/sensors/schema';
import { Zone } from '@modules/iot/zones/model';
import { pipe } from 'fp-ts/function';
import PreserveSearchLink from '@core/router/components/PreserveSearchLink';
import { PAGE_TOP_HEIGHT } from '@layout/page/Page.styles';
import SensorsPlaceholder from '@modules/iot/sensors/list/components/SensorsPlaceholder';
import { modals } from '@mantine/modals';
import { SensorAction } from '@modules/iot/sensors/action';
import deleteSensor = SensorAction.deleteSensor;

export const iotSensorsListLoader = defineLoader({
  id: 'iot-sensors-list',
  handler: () => httpTaskToResponseTask(SensorsService.getPendingSensors()),
});

const actions = {
  updateZone: defineAction({
    type: 'updateZone',
    payload: SensorSchema.updateZoneSchema,
    handler: ({ payload }) => SensorsService.updateZone(payload),
    flashOptions: {
      success: () => 'Modification réalisée',
    },
  }),
  deleteSensor,
};

export const ACCORDION_ITEM_PANEL = 300;

const ListPage: FC = () => {
  const pending = useLoader<typeof iotSensorsListLoader>();
  const { active, filters } = useParentLoader<typeof iotLayoutLoader>('iot-layout');

  const [, updateZone] = useAction(actions.updateZone);
  const [, deleteSensor] = useAction(actions.deleteSensor);

  const navigate = usePreserveNavigate();

  const matches = useMatches();

  const getAccordionValue = useCallback(() => {
    if (!!filters.search) {
      return pipe(
        active.sensors,
        A.filterMap(({ zone }) => O.fromNullable(zone?.id)),
        A.uniq(S.Eq),
        NEA.fromArray,
        O.toNullable,
      );
    }

    const zone = pipe(
      matches,
      A.findFirst(
        match =>
          match.id === Sensor.SensorsRouteId.SensoterraDetail ||
          match.id === Sensor.SensorsRouteId.SinafisDetail ||
          match.id === Sensor.SensorsRouteId.HorteeDetail,
      ),
      O.chain(match => O.tryCatch(() => Sensor.sensorDetailParams.parse(match.params))),
      O.chain(({ id }) =>
        pipe(
          active.sensors,
          A.findFirst(sensor => sensor.id === id),
        ),
      ),
      O.chainNullableK(sensor => sensor.zone),
      O.toNullable,
    );

    if (zone) {
      return zone.id;
    }

    return 'pending';
  }, [active.sensors, filters.search, matches]);

  const [accordionValue, setAccordionValue] = useState<string | Array<string> | null>(getAccordionValue);

  useLayoutEffect(() => {
    setAccordionValue(getAccordionValue);
  }, [getAccordionValue]);

  const handleDrop = (zone: Zone) => (sensor: ActiveSensor) => {
    if (zone.id !== sensor.zone?.id) updateZone({ zoneId: zone.id, id: sensor.id });
  };

  const onConfirmDelete = (sensor: ActiveSensor) => () =>
    pipe(
      deleteSensor(sensor.id),
      TE.chainIOK(() => () => navigate('/iot/sensors/list', { replace: true })),
    )();

  const handleDelete = (sensor: ActiveSensor) => {
    modals.openConfirmModal({
      title: <Title size="h3">Êtes vous sûr ?</Title>,
      size: 400,
      centered: true,
      children: <>Supprimer {sensor.name}.</>,
      labels: { confirm: 'Supprimer la sonde', cancel: 'Annuler' },
      cancelProps: { variant: 'subtle', color: 'gray' },
      onConfirm: onConfirmDelete(sensor),
    });
  };

  const isEmpty = A.isEmpty(pending) && A.isEmpty(active.sensors);

  const dndAccept = active.sensors.map(({ id }) => id);

  return isEmpty ? (
    <SensorsPlaceholder />
  ) : (
    <>
      <Box display="grid" sx={{ gridTemplateColumns: '346px 1fr' }}>
        <Box
          px={42}
          py={14}
          mah={`calc(100vh - ${PAGE_TOP_HEIGHT}px)`}
          pos="sticky"
          top={0}
          style={{ overflow: 'auto' }}
        >
          <Stack spacing={10}>
            <Accordion
              color="complementary.2"
              radius={8}
              variant="separated"
              value={accordionValue}
              multiple={!!filters.search}
              onChange={setAccordionValue}
              styles={theme => ({
                control: {
                  borderRadius: '8px 8px 0 0',
                },
                panel: {
                  borderRadius: '0 0 8px 8px',
                  background: theme.colors.complementary[4],
                },
                item: {
                  border: '2px solid transparent',
                  backgroundColor: theme.colors.tertiary[2],
                  '&[data-active]': {
                    backgroundColor: theme.colors.complementary[4],
                    borderColor: 'transparent',

                    ':hover': {
                      backgroundColor: theme.colors.complementary[4],
                    },
                  },

                  ':hover': {
                    backgroundColor: theme.colors.tertiary[3],
                  },
                },
              })}
            >
              {renderOptional(NEA.fromArray(pending), sensors => (
                <PendingSensors
                  accept={dndAccept}
                  onDrop={handleDelete}
                  sensors={sensors}
                  {...(A.isNonEmpty(active.zones) && {
                    pb: 10,
                    mb: 10,
                    sx: theme => ({ borderBottom: `1px solid ${theme.colors.tertiary[3]}` }),
                    color: 'tertiary.5',
                  })}
                />
              ))}
              <Stack spacing={10}>
                {active.zones.map(zone => (
                  <ActiveSensorZone
                    key={zone.id}
                    zone={zone}
                    sensors={active.sensors.filter(sensor => sensor.zone?.id === zone.id)}
                    accept={dndAccept}
                    onDrop={handleDrop(zone)}
                    isOpen={zone.id === accordionValue}
                  />
                ))}
              </Stack>
            </Accordion>
            <Button
              component={PreserveSearchLink}
              w="fit-content"
              to="zones/new"
              relative="path"
              leftIcon={<IconSquarePlus size={14} />}
            >
              Créer un groupe
            </Button>
          </Stack>
        </Box>

        <Box px={45} pt={0} sx={theme => ({ borderLeft: `1px solid ${theme.colors.gray[1]}` })}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

const sensorsListRoute = defineRoute({
  component: ListPage,
  loader: iotSensorsListLoader,
  actions,
});

export default sensorsListRoute;
