import React, { FC } from 'react';
import { Skeleton } from '@mantine/core';
import * as A from 'fp-ts/Array';
import * as Styled from '@shared/modules/previsions/components/disease/DiseasePrevisions.styles';

interface DiseasePlaceholderProps {
  vertical?: boolean;
}

const DiseasePlaceholder: FC<DiseasePlaceholderProps> = ({ vertical }) => {
  return (
    <Styled.DiseasePrevisionsContainer>
      <Skeleton radius={8} h={vertical ? 203 : 364} style={{ gridColumn: 'span 3' }} />
      {A.makeBy(4, index => (
        <Skeleton key={index} radius={8} h={364} />
      ))}
    </Styled.DiseasePrevisionsContainer>
  );
};

export default DiseasePlaceholder;
