import { Product } from '@modules/alert-reports/products/model';
import { httpService, HttpTask } from '@core/http';

export namespace ProductService {
  const URI = 'pest/products';

  export function getProduct(id: Product.Id): HttpTask<Product> {
    return httpService.get(`${URI}/${id}`);
  }
}
