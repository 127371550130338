import styled from '@emotion/styled';

export const DayWeatherContainer = styled.div`
  padding: 14px 8px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
  border: 1px solid ${props => props.theme.colors.tertiary[2]};
  border-radius: 8px;
`;

export const DayWeatherStats = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-row-gap: 10px;
  padding-top: 20px;
  align-items: center;

  > div {
    &:nth-of-type(odd) {
      font-size: 12px;
      font-weight: 600;
      color: ${props => props.theme.colors.dark[1]};
    }

    &:nth-of-type(even) {
      font-size: 14px;
      color: ${props => props.theme.colors.dark[3]};
      text-align: right;
    }
  }
`;
