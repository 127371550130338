import { constFalse, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as EI from 'fp-ts/Either';
import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import AuthLayout from '@layout/auth/AuthLayout';
import PasswordsForm from '@modules/passwords/components/PasswordsForm';
import Seo from '@shared/modules/seo/Seo';
import { defineLoader, httpTaskToResponseTask } from '@core/router/loader';
import { z } from 'zod';
import { defineAction, useAction } from '@core/router/action';
import { Passwords } from '@modules/passwords/model';
import { PasswordsService } from '@modules/passwords/service';
import { Text } from '@mantine/core';

const params = z.object({ token: Passwords.ResetPasswordToken });

const loader = defineLoader({
  params,
  handler: ({ params }) => httpTaskToResponseTask(PasswordsService.getPasswordResetDetail(params.token)),
});

const actions = {
  reset: defineAction({
    type: 'reset',
    params,
    payload: Passwords.PasswordParams,
    handler: ({ params, payload }) => PasswordsService.resetPassword(params.token, payload),
    flashOptions: {
      error: constFalse,
      success: () => 'Modification réalisée',
    },
    redirect: ({ result }) => (EI.isRight(result) ? '/login' : null),
  }),
};

const ResetPasswordPage: FC = () => {
  const [loading, send, error] = useAction(actions.reset);

  const handleResetPassword = ({ password }: Passwords.PasswordsBody) => send({ password });

  const errorMessage = pipe(
    error,
    O.map(() => 'Une erreur technique est survenue.'),
  );

  return (
    <AuthLayout title="Créer un nouveau mot de passe">
      <Seo title="Créer un nouveau mot de passe" />

      <Text size="sm" c="gray.6" mt={5} mb={24}>
        Saisissez votre nouveau mot de passe
      </Text>

      <PasswordsForm loading={loading} error={errorMessage} onSubmit={handleResetPassword} />
    </AuthLayout>
  );
};

const resetPasswordRoute = defineRoute({
  component: ResetPasswordPage,
  loader,
  actions,
});

export default resetPasswordRoute;
