import React, { FC, useMemo } from 'react';
import * as Styled from './DayWeather.styles';
import { Box, Group, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { PrevisionsUtils } from '@shared/modules/previsions/utils';
import { renderOptional } from '@shared/utils/render';
import WeatherTemperature from '@shared/modules/previsions/components/weather/temperature/WeatherTemperature';
import { NumberUtils } from '@shared/utils/number';
import { Weather } from '@shared/modules/weather/model';
import { IconInfoCircle, IconQuestionMark } from '@tabler/icons-react';
import WeatherForecastParamTooltip from '@shared/modules/previsions/components/weather/WeatherForecastParamTooltip';

interface DayWeatherProps {
  day: Weather.DayForecast;
}

const DayWeather: FC<DayWeatherProps> = ({ day }) => {
  const typeInfo = useMemo(() => PrevisionsUtils.getWeatherTypeInfos(day.values), [day.values]);

  return (
    <Styled.DayWeatherContainer>
      <Group position="apart" spacing={5}>
        <Text size={10} tt="uppercase" c="dark.1" fw={600}>
          {day.date}
        </Text>
        <WeatherForecastParamTooltip values={day.values}>
          <ThemeIcon c="dark.1" variant="white" w="fit-content" miw="fit-content" h="fit-content" mih="fit-content">
            <IconInfoCircle size={16} />
          </ThemeIcon>
        </WeatherForecastParamTooltip>
      </Group>
      {renderOptional(
        typeInfo,
        ({ label, icon: Icon, color }) => (
          <Tooltip label={label} position="bottom" offset={-10}>
            <Box mt={6} c={color} ta="center">
              <Icon width={72} height={72} />
            </Box>
          </Tooltip>
        ),
        () => (
          <Box mt={6} c="gray.4" ta="center">
            <IconQuestionMark size={72} />
          </Box>
        ),
      )}

      <Text size={34} fw={700} c="dark.3" ta="center" mb={5}>
        {NumberUtils.formatTemperature(day.values[Weather.DayForecast.Param.AverageTemp])}
      </Text>

      <WeatherTemperature values={day.values} />

      <Styled.DayWeatherStats>
        <Text>PR</Text>
        <Text>{NumberUtils.formatTemperature(day.values[Weather.DayForecast.Param.AverageDewPoint])}</Text>

        <Text>Précip.</Text>
        <Text>
          {NumberUtils.formatNumber(day.values[Weather.DayForecast.Param.Precipitation], {
            style: 'unit',
            unit: 'millimeter',
            maximumFractionDigits: 1,
          })}
        </Text>

        <Text>ETP</Text>
        <Text>
          {NumberUtils.formatNumber(day.values[Weather.DayForecast.Param.ETP], {
            style: 'unit',
            unit: 'millimeter',
            maximumFractionDigits: 1,
          })}
        </Text>
      </Styled.DayWeatherStats>
    </Styled.DayWeatherContainer>
  );
};

export default DayWeather;
