import { HttpTask } from '@core/http';
import { LayoutContext } from '@layout/model';
import { pipe } from 'fp-ts/function';
import { SensorsService } from '@modules/iot/sensors/service';
import * as TE from 'fp-ts/TaskEither';

export namespace LayoutService {
  export function getLayoutContext(): HttpTask<LayoutContext> {
    return pipe(
      SensorsService.getPendingSensorsCount(),
      TE.map(pendingSensorCount => ({ pendingSensorCount })),
    );
  }
}
