import React, { FC } from 'react';
import { Line, LineChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { DateFormat, formatDate } from '@shared/modules/dates';
import CustomTooltip from '@shared/modules/charts/line/CustomTooltip';
import { Charts } from '@shared/modules/charts/model';
import { useTheme } from '@emotion/react';
import * as A from 'fp-ts/Array';
import * as S from 'fp-ts/string';
import { NumberUtils } from '@shared/utils/number';
import formatPercent = NumberUtils.formatPercent;
import { pipe } from 'fp-ts/function';
import * as Eq from 'fp-ts/Eq';
import SensorDataValues = Charts.Line.SensorDataValues;
import roundWithDigits = NumberUtils.roundWithDigits;
import { LastMeasuresUtils } from '@shared/modules/measures/last/utils';
import MinMeasure = LastMeasuresUtils.MinMeasure;
import MaxMeasure = LastMeasuresUtils.MaxMeasure;
import SensorData = Charts.Line.SensorData;

interface ReferenceAreaProps {
  min: number;
  max: number;
  key: keyof SensorDataValues;
}

interface SimpleLineChartProps {
  data: Array<SensorData>;
  referenceArea: Array<ReferenceAreaProps>;
  dateFormatter(date: Date): string;
}

const defaultMargin = { bottom: 0, left: 0, right: 0, top: 20 };
const defaultYAxisWidth = 25;

const DataKeyEq: Eq.Eq<keyof SensorData> = S.Eq;

const SimpleLineChart: FC<SimpleLineChartProps> = ({ data, referenceArea, dateFormatter }) => {
  const theme = useTheme();

  const dataKeys = pipe(
    data.flatMap(values => Object.keys(values) as unknown as keyof typeof values),
    A.uniq(DataKeyEq),
    A.filter((key): key is Exclude<typeof key, 'date'> => key !== 'date'),
  );

  return (
    <ResponsiveContainer height={244}>
      <LineChart data={data} margin={defaultMargin}>
        <XAxis
          fontSize={8}
          color={theme.colors.dark[0]}
          dataKey="date"
          type="category"
          tickLine={false}
          tickFormatter={dateFormatter}
        />
        <YAxis
          fontSize={8}
          color={theme.colors.dark[0]}
          width={defaultYAxisWidth}
          tickLine={false}
          axisLine={false}
          interval="preserveStartEnd"
          domain={[MinMeasure.humidity, MaxMeasure.humidity]}
          tickFormatter={value => `${roundWithDigits(value * 100, 0)}`}
        />

        <Tooltip
          formatter={value => formatPercent(value as number)}
          labelFormatter={date => formatDate(date, DateFormat.LocalDateTime)}
          separator=" "
          content={CustomTooltip}
        />

        {dataKeys.map(key => (
          <Line
            key={key}
            type="step"
            dataKey={key}
            name={Charts.Line.sensorDataLabel[key]}
            stroke={Charts.Line.sensorDataColor[key]}
            strokeWidth={2}
            dot={false}
          />
        ))}

        {referenceArea.map(({ min, max, key }) => (
          <ReferenceArea
            key={key}
            y1={min}
            y2={max}
            fill={Charts.Line.sensorDataColor[key]}
            fillOpacity={0.3}
            stroke={theme.colors.gray[2]}
            strokeDasharray={3}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SimpleLineChart;
