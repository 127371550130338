import React, { FC } from 'react';
import { SubmitOnEnter, useEnhancedFormContext } from '@shared/modules/form';
import { User } from '@modules/users/model';
import { Anchor, Box, Text, TextInput } from '@mantine/core';

interface UserFormProps {
  isEdit: boolean;
}

const UserForm: FC<UserFormProps> = ({ isEdit }) => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useEnhancedFormContext<User.CreateParams>();

  const email = watch('email');

  return (
    <form onSubmit={handleSubmit} noValidate>
      <SubmitOnEnter />

      <TextInput
        id="lastname"
        label="Nom"
        placeholder="Nom du technicien"
        required
        error={!!errors.lastname}
        {...register('lastname')}
      />

      <TextInput
        mt={30}
        id="firstname"
        label="Prénom"
        placeholder="Prénom du technicien"
        error={!!errors.firstname}
        required
        {...register('firstname')}
      />

      {isEdit ? (
        <Box mt={30}>
          <Text size="sm" fw={500} c="dark.9">
            Adresse mail
          </Text>
          <Anchor size="sm" href={`mailto:${email}`}>
            {email}
          </Anchor>
        </Box>
      ) : (
        <TextInput
          mt={30}
          type="email"
          label="Adresse mail"
          placeholder="Saisir l’adresse mail"
          required
          error={!!errors.email}
          disabled={isEdit}
          {...register('email')}
        />
      )}
    </form>
  );
};

export default UserForm;
