import { OAuth } from '@core/oauth/model';
import { HttpError, httpService } from '@core/http';
import { constant, constVoid, pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as TO from 'fp-ts/TaskOption';
import * as T from 'fp-ts/Task';
import * as O from 'fp-ts/Option';
import * as IOR from 'fp-ts/IORef';
import * as IO from 'fp-ts/IO';

import * as localforage from 'localforage';
import { stringifyQueries } from '@shared/utils/queries';
import config from '@root/config';
import queryString from 'query-string';

const refreshTokenRef = IOR.newIORef<ReturnType<OAuth.OAuthHttpTask> | null>(null)();

const tokensStorage = localforage.createInstance({
  name: 'pg-pro',
  storeName: 'oauth',
});

const TOKENS_KEY = 'tokens';

export namespace OAuthService {
  import OAuthHttpError = OAuth.OAuthHttpError;

  export function getOAuthTokensFromStorage(): TO.TaskOption<OAuth.SavedOAuthTokens> {
    return pipe(() => tokensStorage.getItem<OAuth.SavedOAuthTokens>(TOKENS_KEY), T.map(O.fromNullable));
  }

  export function saveOAuthTokensInStorage({
    refresh_token,
    access_token,
  }: OAuth.OAuthTokens): T.Task<OAuth.SavedOAuthTokens> {
    return () => tokensStorage.setItem<OAuth.SavedOAuthTokens>(TOKENS_KEY, { refresh_token, access_token });
  }

  export function removeOAuthTokensInStorage(): T.Task<void> {
    return () => tokensStorage.removeItem(TOKENS_KEY);
  }

  function sendOAuthRequest(request: OAuth.OAuthRequest): OAuth.OAuthHttpTask {
    return pipe(
      httpService.post<OAuth.OAuthTokens, OAuth.OAuthError>(
        `${config.VITE_API_OAUTH_PREFIX}/tokens`,
        queryString.stringify(request),
      ),
      TE.chainFirstTaskK(saveOAuthTokensInStorage),
      TE.map(constVoid),
    );
  }

  export function passwordStrategyRequest(email: string, password: string): OAuth.OAuthHttpTask {
    return sendOAuthRequest({ grant_type: 'password', username: email, password });
  }

  export function refreshToken(): OAuth.OAuthHttpTask {
    return pipe(
      getOAuthTokensFromStorage(),
      TO.chainNullableK(({ refresh_token }) => refresh_token),
      TE.fromTaskOption(() => HttpError.notFound as OAuthHttpError),
      TE.chain(refresh_token => sendOAuthRequest({ grant_type: 'refresh_token', refresh_token })),
      TE.orElseFirstTaskK(removeOAuthTokensInStorage),
      TE.orElseFirstIOK(() => () => {
        window.location.replace(`/login?${stringifyQueries({ referrer: window.location.pathname })}`);
      }),
    );
  }

  export function refreshTokenWithSharedState(): OAuth.OAuthHttpTask {
    return pipe(
      TO.fromNullable(refreshTokenRef.read()),
      TO.fold(
        () =>
          pipe(
            refreshToken(),
            IO.chainFirst<ReturnType<OAuth.OAuthHttpTask>, void>(refreshTokenRef.write),
            T.chainFirstIOK(() => refreshTokenRef.write(null)),
          ),
        constant,
      ),
    );
  }
}
