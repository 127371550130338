import React, { FC } from 'react';
import { Box, Button, Group, NumberInput, Select, SimpleGrid, Stack, Text, Textarea, TextInput } from '@mantine/core';
import { Controller } from 'react-hook-form';
import { useEnhancedFormContext } from '@shared/modules/form';
import GoogleMaps from '@shared/modules/maps/components/GoogleMaps';
import { Maps } from '@shared/modules/maps/model';
import SensorPin from '@shared/modules/maps/components/SensorPin';
import { Sensor } from '@modules/iot/sensors/model';
import Type = Sensor.Type;
import { Zone } from '@modules/iot/zones/model';
import GoogleMapsOverlay from '@shared/modules/maps/components/GoogleMapsOverlay';
import { Sinafis } from '@modules/iot/sensors/sinafis/model';
import { Utils } from '@shared/utils/model';
import GPSCoordinates = Utils.GPSCoordinates;
import { renderNullable } from '@shared/utils/render';
import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import * as A from 'fp-ts/Array';
import { Measures } from '@shared/modules/measures/model';
import ThresholdScaleCard from '@shared/modules/threshold/ThresholdScaleCard';
import ThresholdScaleController from '@shared/modules/threshold/ThresholdScaleController';
import { Threshold } from '@modules/iot/model';

interface PendingFormProps {
  serial: Sensor.Serial;
  location: GPSCoordinates | null;
  zones: Array<Zone>;
  mappings: Sinafis.Config.ExtendedMeasureProbeMapping;
  loading: boolean;
  level?: Threshold.Level;
}

const configIdentifierDistanceLabel: Record<Sinafis.Config.Identifier, string> = {
  transmitter: 'Hauteur Transmetteur (cm)',
  ground1: 'Profondeur Capteur Sol 1 (cm)',
  ground2: 'Profondeur Capteur Sol 2 (cm)',
  leaf: 'Hauteur Capteur Foliaire (cm)',
};

const thresholdIdentifierLabel: Record<Sinafis.Config.Identifier, string> = {
  transmitter: 'Transmetteur',
  ground1: 'Capteur Sol 1',
  ground2: 'Capteur Sol 2',
  leaf: 'Capteur Foliaire',
};

const type = Type.Sinafis;

const PendingForm: FC<PendingFormProps> = ({
  serial,
  zones,
  loading,
  location,
  mappings,
  level = Threshold.Level.None,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useEnhancedFormContext<Sinafis.PendingParams>();

  const measuresDistances = pipe(
    R.keys(mappings),
    A.sort(Sinafis.Config.configOrd),
    A.map(key => {
      switch (key) {
        case 'ground1':
        case 'ground2':
          return (
            <Controller
              control={control}
              key={key}
              name={`config.${key}.depth`}
              render={({ field }) => (
                <NumberInput
                  {...field}
                  label={configIdentifierDistanceLabel[key]}
                  placeholder={configIdentifierDistanceLabel[key]}
                  error={!!errors.config?.[key]?.depth}
                  required
                />
              )}
            />
          );
        case 'transmitter':
        case 'leaf':
          return (
            <Controller
              control={control}
              key={key}
              name={`config.${key}.height`}
              render={({ field }) => (
                <NumberInput
                  {...field}
                  label={configIdentifierDistanceLabel[key]}
                  placeholder={configIdentifierDistanceLabel[key]}
                  error={!!errors.config?.[key]?.height}
                  required
                />
              )}
            />
          );
        default:
          return null;
      }
    }),
  );

  const thresholdScales = pipe(
    R.keys(mappings),
    A.sort(Sinafis.Config.configOrd),
    A.map(key => (
      <ThresholdScaleCard key={key} title={thresholdIdentifierLabel[key]} icon={Sensor.typeIcon[Sensor.Type.Sinafis]}>
        <ThresholdScaleController
          name={`config.${key}.humidityThreshold`}
          control={control}
          type={Measures.Type.Humidity}
        />
        {key !== 'leaf' && (
          <ThresholdScaleController
            name={`config.${key}.temperatureThreshold`}
            control={control}
            type={Measures.Type.Temperature}
          />
        )}
      </ThresholdScaleCard>
    )),
  );

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Stack>
        <Group position="apart">
          <Text size={18} weight={700}>
            Configuration
          </Text>
          <Button type="submit" loading={loading}>
            Enregistrer
          </Button>
        </Group>

        {renderNullable(location, ({ longitude, latitude }) => (
          <Stack spacing={10}>
            <Box h={175} pos="relative">
              <GoogleMaps options={{ ...Maps.defaultOptions, center: { lat: latitude, lng: longitude } }}>
                {map => (
                  <GoogleMapsOverlay position={{ lat: latitude, lng: longitude }} map={map}>
                    <SensorPin type={type} level={level} />
                  </GoogleMapsOverlay>
                )}
              </GoogleMaps>
            </Box>
            <Text color="dark.3" weight={600} size={10}>
              {latitude}, {longitude}
            </Text>
          </Stack>
        ))}

        <SimpleGrid cols={2} pt={20}>
          <Stack>
            <Text size={14}>
              <Text weight={700} component="span">
                Numéro de la sonde :
              </Text>{' '}
              {serial}
            </Text>
            <Controller
              name="zoneId"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  error={!!errors.zoneId}
                  label="Groupe"
                  data={zones.map(({ id, name }) => ({ value: id, label: name }))}
                  placeholder="Sélectionner"
                  required
                />
              )}
            />
            <TextInput
              {...register('name')}
              error={!!errors.name}
              label="Nom de la sonde"
              placeholder="Nom de la sonde"
              required
            />
            {measuresDistances}
          </Stack>
          <Textarea
            {...register('comment')}
            styles={{ wrapper: { height: '100%' }, input: { height: '100%' } }}
            error={!!errors.comment}
            placeholder="Commentaire"
          />
        </SimpleGrid>
        <Stack>
          <Text size={14} weight={700}>
            Seuils d'alertes
          </Text>
          <SimpleGrid cols={thresholdScales.length > 3 ? 2 : 3}>{thresholdScales}</SimpleGrid>
        </Stack>
      </Stack>
    </form>
  );
};

export default PendingForm;
