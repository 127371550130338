import React, { FC } from 'react';
import { Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useTheme } from '@emotion/react';
import { NumberUtils } from '@shared/utils/number';
import formatPercent = NumberUtils.formatPercent;
import * as A from 'fp-ts/Array';
import * as S from 'fp-ts/string';
import CustomTooltip from '@shared/modules/charts/line/CustomTooltip';
import { DateFormat, formatDate } from '@shared/modules/dates';
import { Measures } from '@shared/modules/measures/model';
import { Sensor } from '@modules/iot/sensors/model';
import Identifier = Sensor.Probe.Identifier;
import identifierLabel = Sensor.Probe.identifierLabel;
import { pipe } from 'fp-ts/function';
import { Charts } from '@shared/modules/charts/model';
import { LastMeasuresUtils } from '@shared/modules/measures/last/utils';
import NumericMeasure = LastMeasuresUtils.NumericMeasure;
import SensorData = Charts.Line.SensorData;

const typeYAxis: Record<Measures.History.Type, 'left' | 'right' | 'hidden'> = {
  battery: 'right',
  humidity: 'right',
  temperature: 'left',
  nutrition: 'hidden',
};

interface SensorLineChartProps {
  data: Array<SensorData>;
  dateFormatter(date: Date): string;
}

const defaultMargin = { bottom: 0, left: 0, right: 0, top: 22 };
const percentageTicks = A.makeBy(11, i => i * 0.1);
const temperatureTicks = A.makeBy(11, i => i * 5 - 10);

const SensorLineChart: FC<SensorLineChartProps> = ({ data, dateFormatter }) => {
  const theme = useTheme();

  const keys = pipe(
    data,
    A.chain(({ date, ...keys }) => Object.keys(keys)),
    A.uniq(S.Eq),
    A.map(s => s.split('.') as [Identifier, Measures.History.Type]),
  );

  return (
    <ResponsiveContainer height={418}>
      <LineChart data={data} margin={defaultMargin}>
        <XAxis
          fontSize={8}
          color={theme.colors.dark[0]}
          dataKey="date"
          type="category"
          tickLine={false}
          tickFormatter={dateFormatter}
        />
        <YAxis
          yAxisId="left"
          fontSize={8}
          color={theme.colors.dark[0]}
          width={30}
          tickLine={false}
          axisLine={false}
          interval="preserveStartEnd"
          ticks={temperatureTicks}
          tickFormatter={value => `${value}°C`}
        >
          <Label value="Température en C°" fontSize={8} offset={15} position="top" dx={25} />
        </YAxis>
        <YAxis
          yAxisId="right"
          fontSize={8}
          color={theme.colors.dark[0]}
          width={30}
          tickLine={false}
          axisLine={false}
          interval="preserveStartEnd"
          ticks={percentageTicks}
          orientation="right"
          tickFormatter={formatPercent}
        >
          <Label value="En %" fontSize={8} offset={15} position="top" />
        </YAxis>
        <YAxis yAxisId="hidden" hide />
        <Tooltip
          formatter={(value, name, item, index, payload) => {
            const [, type] = (payload[index].dataKey ?? '').toString().split('.') as [
              Identifier,
              Measures.History.Type,
            ];
            return LastMeasuresUtils.formatter(value as Measures.Value<NumericMeasure>, type);
          }}
          labelFormatter={date => formatDate(date, DateFormat.LocalDateTime)}
          separator=" "
          content={CustomTooltip}
        />
        {keys.map(([identifier, type]) => (
          <Line
            key={`${identifier}.${type}`}
            dataKey={`${identifier}.${type}`}
            yAxisId={typeYAxis[type]}
            name={`${identifierLabel[identifier]} - ${Measures.typeTitle[type]}`}
            stroke={Charts.Line.sensorDataColor[`${identifier}.${type}`]}
            dot={{ fill: Charts.Line.sensorDataColor[`${identifier}.${type}`], r: 4 }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SensorLineChart;
