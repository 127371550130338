import React, { FC } from 'react';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Charts } from '@shared/modules/charts/model';
import { useTheme } from '@emotion/react';
import { formatDate } from '@shared/modules/dates';
import * as A from 'fp-ts/Array';
import CustomTooltip from '@shared/modules/charts/line/CustomTooltip';
import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as O from 'fp-ts/Option';
import * as S from 'fp-ts/string';
import { Disease } from '@shared/modules/disease/model';
import { NumberUtils } from '@shared/utils/number';
import formatPercent = NumberUtils.formatPercent;
import { Box, Group, Text } from '@mantine/core';

const defaultMargin = { bottom: -15, left: 5, right: 5, top: 10 };
const dateFormatter = (date: Date) => formatDate(date, 'dd/MM/yyyy');
const percentageTicks = A.makeBy(11, i => i * 0.1);
const riskTicks = NEA.range(0, 24);

enum RiskValue {
  Number = 'number',
  Percentage = 'percentage',
}

const modelRiskValue: Record<Disease.Model, RiskValue> = {
  [Disease.Model.LWD]: RiskValue.Number,
  [Disease.Model.DollarSpot]: RiskValue.Percentage,
  [Disease.Model.ColdFusarium]: RiskValue.Percentage,
};

const riskValueWidth: Record<RiskValue, number> = {
  [RiskValue.Number]: 20,
  [RiskValue.Percentage]: 30,
};

const riskValueTicks: Record<RiskValue, Array<number>> = {
  [RiskValue.Number]: riskTicks,
  [RiskValue.Percentage]: percentageTicks,
};

const riskValueTickFormatter: Record<RiskValue, (value: number) => string> = {
  [RiskValue.Number]: String,
  [RiskValue.Percentage]: formatPercent,
};

const riskValueLabel: Record<RiskValue, any> = {
  [RiskValue.Number]: 'Valeur du risque (indice de 0 à 24)',
  [RiskValue.Percentage]: 'Valeur du risque en %',
};
interface DiseaseLineChartProps {
  data: Array<Charts.Line.Disease.Data>;
  h: number;
}

const DiseaseLineChart: FC<DiseaseLineChartProps> = ({ data, h = 326 }) => {
  const theme = useTheme();

  const models = pipe(
    data,
    A.chain(({ date, ...models }) => R.keys<Disease.Model>(R.filterMap(O.fromNullable)(models))),
    A.uniq(Disease.modelEq),
  );

  const axis = pipe(
    models,
    A.map(model => modelRiskValue[model]),
    A.uniq<RiskValue>(S.Eq),
  );

  return (
    <Box>
      <Group fz={8} position="apart" c="dark.3">
        {axis.map(axis => (
          <Text key={axis}>{riskValueLabel[axis]}</Text>
        ))}
      </Group>

      <ResponsiveContainer height={h}>
        <LineChart data={data} margin={defaultMargin}>
          <XAxis
            fontSize={8}
            color={theme.colors.dark[3]}
            dataKey="date"
            tickLine={false}
            tickFormatter={dateFormatter}
          />
          {axis.map((type, index) => (
            <YAxis
              key={type}
              yAxisId={type}
              ticks={riskValueTicks[type]}
              width={riskValueWidth[type]}
              tickFormatter={riskValueTickFormatter[type]}
              orientation={index === 0 ? 'left' : 'right'}
              fontSize={8}
              color={theme.colors.dark[0]}
              tickLine={false}
              axisLine={false}
              interval="preserveStartEnd"
            />
          ))}
          <Tooltip
            formatter={(value, name, item, index, payload) => {
              const model = payload[index].dataKey as Disease.Model;
              return Disease.modelShow[model].show(value as Disease.Forecast.Score);
            }}
            labelFormatter={dateFormatter}
            separator=" "
            content={CustomTooltip}
          />

          {models.map(model => (
            <Line
              key={model}
              dataKey={model}
              yAxisId={modelRiskValue[model]}
              name={Disease.modelLabel[model]}
              stroke={theme.fn.themeColor(Charts.Line.Disease.modelColor[model])}
              dot={{ fill: theme.fn.themeColor(Charts.Line.Disease.modelColor[model]), r: 4 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default DiseaseLineChart;
