import React, { FC, useMemo } from 'react';
import { Box, Card, Group, SimpleGrid, Skeleton, Stack } from '@mantine/core';
import GoogleMaps from '@shared/modules/maps/components/GoogleMaps';
import { Maps } from '@shared/modules/maps/model';
import IotMapClustering from '@modules/iot/sensors/map/components/IotMapClustering';
import DashboardAlertsTable from '@modules/iot/dashboard/components/DashboardAlertsTable';
import { useLoader } from '@core/router/loader';
import { loader } from '@modules/iot/dashboard/DashboardPage';
import { Threshold } from '@modules/iot/model';
import DashboardStatsCard from '@modules/iot/dashboard/components/DashboardStatsCard';
import Level = Threshold.Level;
import DashboardPieChart from '@modules/dashboard/components/DashboardPieChart';
import { flow, pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as R from 'fp-ts/Record';
import * as T from 'fp-ts/Task';
import { renderOptional } from '@shared/utils/render';
import DashboardAlertsTablePlaceholder from '@modules/iot/dashboard/components/placeholder/DashboardAlertsTablePlaceholder';
import { SensorsMap } from '@modules/iot/sensors/map/model';
import Supercluster from 'supercluster';
import { CustomPointFeature, MAX_ZOOM } from '@shared/hooks/supercluster';
import DashboardPieChartPlaceholder from '@modules/iot/dashboard/components/placeholder/DashboardPieChartPlaceholder';
import { sequenceS } from 'fp-ts/Apply';
import Await from '@core/router/components/Await';

const DashboardMonitoring: FC = () => {
  const { dashboard, zoneAlerts } = useLoader<typeof loader>();

  const sensors = useMemo(
    () =>
      pipe(
        () => dashboard,
        T.map(({ alerts }) =>
          alerts.sensors.filter((marker): marker is SensorsMap.Marker.WithLocation => !!marker.location),
        ),
      ),
    [dashboard],
  );

  const supercluster = useMemo(
    () =>
      pipe(
        sensors,
        T.map(sensors => {
          const cluster = new Supercluster<CustomPointFeature<SensorsMap.Marker>>({ radius: 75, maxZoom: MAX_ZOOM });

          const points: Array<Supercluster.PointFeature<CustomPointFeature<SensorsMap.Marker>>> = sensors.map(
            marker => ({
              type: 'Feature' as const,
              properties: { cluster: false, marker },
              geometry: {
                type: 'Point' as const,
                coordinates: [marker.location.longitude, marker.location.latitude],
              },
            }),
          );

          cluster.load(points);

          return cluster;
        }),
      ),
    [sensors],
  );

  const filteredZoneAlerts = pipe(
    () => zoneAlerts,
    T.map(
      flow(
        A.filter(({ sensors, name }) =>
          pipe(
            sensors,
            A.filter(({ measures }) =>
              pipe(
                R.toEntries(measures),
                A.chain(([, measures]) => measures),
                A.some(({ alert }) => alert === Threshold.Level.Alert || alert === Threshold.Level.Critical),
              ),
            ),
            A.isNonEmpty,
          ),
        ),
        NEA.fromArray,
      ),
    ),
  )();

  return (
    <Card p={18} shadow="0px 4px 10px rgba(0, 0, 0, 0.1)" withBorder={false} radius={10}>
      <SimpleGrid cols={2}>
        <Group>
          <Await resolve={dashboard} fallback={<DashboardPieChartPlaceholder mah={165} />}>
            {({ alerts }) => <DashboardPieChart mah={165} alerts={alerts} mt="auto" sx={{ flex: 2 }} />}
          </Await>
          <Stack style={{ flex: 1, height: '100%' }}>
            <Await
              resolve={dashboard}
              fallback={
                <>
                  <Skeleton radius={8} style={{ flex: 1 }} />
                  <Skeleton radius={8} style={{ flex: 1 }} />
                </>
              }
            >
              {({ alerts }) => (
                <>
                  <DashboardStatsCard count={alerts.medium} level={Level.Alert} />
                  <DashboardStatsCard count={alerts.high} level={Level.Critical} />
                </>
              )}
            </Await>
          </Stack>
        </Group>
        <Await resolve={sequenceS(T.ApplyPar)({ sensors, supercluster })()} fallback={<Skeleton height={194} />}>
          {({ sensors, supercluster }) => (
            <Box h={194} pos="relative">
              <GoogleMaps options={{ ...Maps.defaultOptions, fullscreenControl: true, zoomControl: true }}>
                {map => <IotMapClustering map={map} markers={sensors} supercluster={supercluster} />}
              </GoogleMaps>
            </Box>
          )}
        </Await>
      </SimpleGrid>
      <Await resolve={filteredZoneAlerts} fallback={<DashboardAlertsTablePlaceholder />}>
        {zoneAlerts =>
          renderOptional(zoneAlerts, alerts => (
            <Box pt={30}>
              {alerts.map((alerts, index) => (
                <DashboardAlertsTable key={index} alerts={alerts} showFilter={index === 0} />
              ))}
            </Box>
          ))
        }
      </Await>
    </Card>
  );
};

export default DashboardMonitoring;
