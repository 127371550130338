import React, { FC } from 'react';
import AuthLayout from '@layout/auth/AuthLayout';
import ForgotPasswordForm from '@modules/passwords/components/forgot/ForgotPasswordForm';
import Seo from '@shared/modules/seo/Seo';
import { defineRoute } from '@core/router';
import { defineAction, useAction } from '@core/router/action';
import { constFalse } from 'fp-ts/function';
import { Passwords } from '@modules/passwords/model';
import { PasswordsService } from '@modules/passwords/service';
import { Anchor, Text } from '@mantine/core';
import { Link } from 'react-router-dom';

const actions = {
  forgotPassword: defineAction({
    type: 'forgot',
    payload: Passwords.ForgotPasswordParams,
    handler: ({ payload }) => PasswordsService.forgotPassword(payload),
    flashOptions: {
      error: constFalse,
    },
  }),
};

const ForgotPasswordPage: FC = () => {
  const [loading, send, error, success] = useAction(actions.forgotPassword);

  if (success) {
    return (
      <AuthLayout title="Mail envoyé !">
        <Text c="gray.6" mt={20} fw={700}>
          Regardez votre boîte mail
        </Text>

        <Text size="sm" c="gray.6" my={10}>
          Nous vous avons envoyé un mail avec un lien de réinitialisation de votre mot de passe.
        </Text>

        <Anchor size="sm" component={Link} to="/login" c="dark.3" fw={600} replace underline>
          Retour à la connexion
        </Anchor>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout title="Mot de passe oublié">
      <Seo title="Mot de passe oublié" />

      <Text size="sm" c="gray.6" mt={5} mb={24}>
        Indiquer nous votre adresse mail.
      </Text>

      <ForgotPasswordForm loading={loading} error={error} onSubmit={send} />
    </AuthLayout>
  );
};

const forgotPasswordRoute = defineRoute({
  component: ForgotPasswordPage,
  actions,
});

export default forgotPasswordRoute;
