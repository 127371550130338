import React, { FC } from 'react';
import { Editorial } from '@shared/modules/editorial/model';
import Block = Editorial.Block;
import Template = Block.Template;
import { Box } from '@mantine/core';

interface TextRendererProps {
  block: Block<Template.Text>;
}

const TextRenderer: FC<TextRendererProps> = ({ block }) => {
  return <Box dangerouslySetInnerHTML={{ __html: block.content }} />;
};

export default TextRenderer;
