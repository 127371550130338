import { z } from 'zod';
import { emailSchema, NonEmptyString } from '@shared/schemas';

export namespace Passwords {
  export const ResetPasswordToken = z.string().brand<'ResetPasswordToken'>();
  export type ResetPasswordToken = z.infer<typeof ResetPasswordToken>;

  export const ActivationToken = z.string().brand<'ActivationToken'>();
  export type ActivationToken = z.infer<typeof ActivationToken>;

  export const ForgotPasswordParams = z.object({
    email: emailSchema,
  });

  export type ForgotPasswordParams = z.infer<typeof ForgotPasswordParams>;

  export interface PasswordsDetail {
    email: string;
  }

  export const PasswordsBody = z
    .object({
      password: NonEmptyString.regex(
        /^.*(?=.{8,})(?=.*[a-z])(?=.*?[0-9])(?=.*[A-Z])(?=.*\W).*$/,
        'Votre mot de passe doit contenir au moins 8 caractères, dont une majuscule, une minuscule, un chiffre et un caractère special.',
      ),
      confirmPassword: NonEmptyString,
    })
    .refine(value => value?.password && value.confirmPassword && value.password === value.confirmPassword, {
      path: ['confirmPassword'],
      message: 'Les mots de passe ne correspondent pas',
    });

  export type PasswordsBody = z.infer<typeof PasswordsBody>;

  export const PasswordParams = z.object({ password: NonEmptyString });

  export type PasswordsParams = z.infer<typeof PasswordParams>;
}
