import React, { FC } from 'react';
import { SubmitOnEnter, useEnhancedFormContext } from '@shared/modules/form';
import { Zone } from '@modules/iot/zones/model';
import { Stack, Textarea, TextInput } from '@mantine/core';
import SearchGeoCity from '@shared/modules/geo/SearchGeoCity';
import { Controller } from 'react-hook-form';

const SaveZoneForm: FC = () => {
  const {
    register,
    formState: { errors },
    control,
  } = useEnhancedFormContext<Zone.SaveParams>();

  return (
    <form>
      <SubmitOnEnter />

      <Stack spacing={30}>
        <TextInput
          {...register('name')}
          label="Nom du groupe"
          placeholder="Nom du groupe"
          error={!!errors.name}
          required
        />

        <Controller
          control={control}
          name="location"
          render={({ field }) => <SearchGeoCity {...field} required error={!!errors.location} />}
        />

        <Textarea
          {...register('description')}
          label="Description"
          placeholder="Votre description"
          error={!!errors.description}
          minRows={8}
          required
        />
      </Stack>
    </form>
  );
};

export default SaveZoneForm;
