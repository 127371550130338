import React, { FC } from 'react';
import { Editorial as EditorialModel } from '@shared/modules/editorial/model';
import BlockRenderer from '@shared/modules/editorial/components/BlockRenderer';
import { Box, MantineProvider, MantineThemeOverride, rem } from '@mantine/core';
import { constant } from 'fp-ts/function';

interface EditorialProps {
  editorial: EditorialModel;
}

const theme: MantineThemeOverride = {
  headings: {
    sizes: {
      h1: {
        fontSize: rem(22),
      },
      h2: {
        fontSize: rem(18),
      },
      h3: {
        fontSize: rem(16),
      },
    },
  },
  globalStyles: constant({
    'li, p': {
      color: 'var(--mantine-color-dark-5)',
      fontSize: rem(14),
      lineHeight: 1.55,
      fontWeight: 400,
    },
  }),
};

const Editorial: FC<EditorialProps> = ({ editorial }) => {
  return (
    <MantineProvider theme={theme} inherit withCSSVariables>
      <Box h="100%" style={{ overflow: 'auto' }}>
        {editorial.map(block => (
          <BlockRenderer key={block.id} block={block} />
        ))}
      </Box>
    </MantineProvider>
  );
};

export default Editorial;
