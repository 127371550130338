import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { defineAction, useAction } from '@core/router/action';
import { pipe } from 'fp-ts/function';
import UserForm from '@modules/users/components/form/UserForm';
import { HttpStatusCode } from '@core/http';
import { User } from '@modules/users/model';
import * as TE from 'fp-ts/TaskEither';
import { UsersService } from '@modules/users/service';
import DrawerContent from '@shared/components/drawer/DrawerContent';
import { Anchor, Button, Group, Title } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import Seo from '@shared/modules/seo/Seo';

const actions = {
  create: defineAction({
    type: 'create',
    payload: User.CreateParams,
    handler: ({ payload }) => UsersService.createUser(payload),
    flashOptions: {
      success: () => 'Création réalisée',
      error: ({ error }) => {
        switch (error.status) {
          case HttpStatusCode.CONFLICT:
            return 'Cet e-mail est déjà rattaché à un autre utilisateur';
          case HttpStatusCode.FORBIDDEN:
            return "Vous n'êtes pas autorisé à créer un autre technicien";
          default:
            return true;
        }
      },
    },
  }),
};

const CreateUserPage: FC = () => {
  const navigate = useNavigate();

  const { formRef, form, handleFormSubmit } = useEnhancedForm<User.CreateParams>({
    resolver: zodResolver(User.CreateParams),
  });

  const [loading, create] = useAction(actions.create);

  const handleCreateUser = (params: User.CreateParams) =>
    pipe(
      create(params),
      TE.chainIOK(() => () => navigate('..', { replace: true })),
    );

  return (
    <DrawerContent
      top={<Title size="h3">Inviter un technicien</Title>}
      bottom={
        <Group position="right">
          <Anchor component={Link} to=".." replace size="sm" fw={600} c="dark.3">
            Annuler
          </Anchor>
          <Button loading={loading} disabled={loading} onClick={handleFormSubmit}>
            Envoyer une invitation
          </Button>
        </Group>
      }
    >
      <Seo title="Inviter un technicien" />

      <EnhancedForm ref={formRef} form={form} onSubmit={handleCreateUser} preventLeave>
        <UserForm isEdit={false} />
      </EnhancedForm>
    </DrawerContent>
  );
};

const createUserRoute = defineRoute({
  component: CreateUserPage,
  actions,
});

export default createUserRoute;
