import { User } from '@modules/users/model';

export namespace UsersUtils {
  type UserNames = Pick<User, 'firstname' | 'lastname'>;

  export function getUserName({ firstname, lastname }: UserNames) {
    return `${firstname} ${lastname}`;
  }

  export function getUserInitial({ firstname, lastname }: UserNames) {
    return `${firstname.charAt(0)}${lastname.charAt(0)}`;
  }
}
