import { defineLoader, httpTaskToResponseTask } from '@core/router/loader';
import { z } from 'zod';
import { Auth } from '@modules/auth/model';
import { AuthService } from '@modules/auth/service';
import { defineRoute } from '@core/router';
import React, { FC } from 'react';
import AuthLayout from '@layout/auth/AuthLayout';
import Seo from '@shared/modules/seo/Seo';
import { Text } from '@mantine/core';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as O from 'fp-ts/Option';
import { HttpStatusCode } from '@core/http';

const params = z.object({ id: Auth.CompanyADFSId });

const loader = defineLoader({
  params,
  handler: ({ params }) =>
    pipe(
      AuthService.exchangeSubscription(params.id),
      TE.map(O.some),
      TE.orElse(error => (error.status === HttpStatusCode.NOT_FOUND ? TE.right(O.none) : TE.left(error))),
      httpTaskToResponseTask,
    ),
  redirect: ({ result }) =>
    pipe(
      result,
      O.map(({ token }) => `/activation/${token}`),
      O.toNullable,
    ),
});

const SubscriptionErrorPage: FC = () => {
  return (
    <AuthLayout title="Abonnement">
      <Seo title="Abonnement" />
      <Text pt="xs">Votre lien d'activation peut avoir expiré ou est déjà utilisé</Text>
    </AuthLayout>
  );
};

const subscriptionRoute = defineRoute({
  loader,
  component: SubscriptionErrorPage,
});

export default subscriptionRoute;
