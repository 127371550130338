import z from 'zod';
import { Utils } from '@shared/utils/model';
import { Threshold } from '@modules/iot/model';

export namespace ThresholdSchema {
  const limitSchema = <Value extends z.ZodTypeAny, Level extends z.ZodTypeAny>(value: Value, level: Level) =>
    z.object({
      until: value,
      level,
    });

  const scaleSchema = <Value extends z.ZodTypeAny, Level extends z.ZodTypeAny>(value: Value, level: Level) =>
    z.object({
      levels: z.array(limitSchema(value, level)),
      last: level,
    });

  export const humidityScaleSchema = scaleSchema(Utils.Percent, z.nativeEnum(Threshold.Level));
  export const temperatureScaleSchema = scaleSchema(Utils.Temperature, z.nativeEnum(Threshold.Level));
}
