import React, { FC } from 'react';
import * as NEA from 'fp-ts/NonEmptyArray';
import { Disease } from '@shared/modules/disease/model';
import { Card, Text } from '@mantine/core';

interface AnalysisListingDiseasesProps {
  diseases: NEA.NonEmptyArray<[Disease.Model, Disease.Forecast.Value]>;
}

const AnalysisListingDiseases: FC<AnalysisListingDiseasesProps> = ({ diseases }) => {
  return (
    <Card key="diseases-forecast" px={8} py={10} bg="gray.0" radius={4} withBorder={false} w="fit-content">
      {diseases.map(([model, { score }]) => (
        <Text key={model} c="dark.5" fz={14} fw={400} lh={1.55}>
          {Disease.modelName[model]} : {Disease.modelShow[model].show(score)}
        </Text>
      ))}
    </Card>
  );
};

export default AnalysisListingDiseases;
