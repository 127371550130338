import React, { FC } from 'react';
import { ActiveSensor, Sensor } from '@modules/iot/sensors/model';
import MeasureCard from '@shared/modules/measures/last/MeasureCard';
import * as A from 'fp-ts/Array';
import * as R from 'fp-ts/Record';
import { identity, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { Measures } from '@shared/modules/measures/model';
import { SimpleGrid } from '@mantine/core';
import { LastMeasuresUtils } from '@shared/modules/measures/last/utils';
import filterMeasures = LastMeasuresUtils.filterMeasures;
import MeasureValue = LastMeasuresUtils.MeasureValue;
import Type = Measures.Type;
import { Sinafis } from '@modules/iot/sensors/sinafis/model';

interface MeasureCardsProps {
  sensor: ActiveSensor;
  formatDistance?(distance: string): string | null;
  withoutDistance?: true;
  withoutDate?: true;
}

const MeasureCards: FC<MeasureCardsProps> = ({ sensor, formatDistance = identity, withoutDistance, withoutDate }) => {
  if (sensor.measures === null) return null;

  switch (sensor.type) {
    case Sensor.Type.Sensoterra: {
      const humidityMeasures = filterMeasures(sensor.measures.ground, sensor.config.humidityThreshold, Type.Humidity);

      return (
        <MeasureCard
          title={{ icon: Sensor.typeIcon[sensor.type], label: 'Capteur sol' }}
          measures={A.compact([humidityMeasures])}
          distance={pipe(
            O.fromNullable(sensor.config.depth),
            O.chainNullableK(formatDistance),
            O.map(value => ({ label: 'Profondeur', value })),
            O.toNullable,
          )}
          withoutDistance={withoutDistance}
          withoutDate={withoutDate}
        />
      );
    }
    case Sensor.Type.Hortee: {
      const humidityMeasures = filterMeasures(sensor.measures.ground, sensor.config.humidityThreshold, Type.Humidity);

      const temperatureMeasures = filterMeasures(
        sensor.measures.ground,
        sensor.config.temperatureThreshold,
        Type.Temperature,
      );

      return (
        <MeasureCard
          title={{ icon: Sensor.typeIcon[sensor.type], label: 'Capteur sol' }}
          measures={A.compact<MeasureValue<Measures.Type.Humidity | Measures.Type.Temperature>>([
            humidityMeasures,
            temperatureMeasures,
          ])}
          distance={null}
          withoutDistance={withoutDistance}
          withoutDate={withoutDate}
        />
      );
    }
    case Sensor.Type.Sinafis: {
      const sinafisMeasures = sensor.measures; // help typescript

      const cards = pipe(
        R.keys(sensor.measures),
        A.sort(Sinafis.Probe.identifierOrd),
        A.map(identifier => {
          const identifierConfig = Sinafis.Probe.identifierConfig[identifier];
          const lengthField = Sinafis.Config.configLengthField[identifierConfig];

          const humidityMeasures = filterMeasures(
            sinafisMeasures[identifier],
            sensor.config[identifierConfig]?.humidityThreshold,
            Type.Humidity,
          );

          const measures = (function () {
            switch (identifier) {
              case 'transmitter':
              case 'ground-1':
              case 'ground-2':
                const identiferConfig = Sinafis.Probe.identifierConfig[identifier];

                const temperatureMeasures = filterMeasures(
                  sinafisMeasures[identifier],
                  sensor.config[identiferConfig]?.temperatureThreshold,
                  Type.Temperature,
                );

                return A.compact<MeasureValue<Measures.Type.Humidity | Measures.Type.Temperature>>([
                  humidityMeasures,
                  temperatureMeasures,
                ]);

              case 'leaf':
                return A.compact([humidityMeasures]);
              default:
                return [];
            }
          })();

          const length = (function () {
            switch (identifierConfig) {
              case 'transmitter':
              case 'leaf':
                return sensor.config[identifierConfig]?.[Sinafis.Config.configLengthField[identifierConfig]];
              case 'ground1':
              case 'ground2':
                return sensor.config[identifierConfig]?.[Sinafis.Config.configLengthField[identifierConfig]];
            }
          })();

          const distance = pipe(
            O.fromNullable(length),
            O.map(length => ({
              label: Sinafis.Config.configLengthTitle[lengthField],
              value: `${length} cm`,
            })),
            O.toNullable,
          );

          return (
            <MeasureCard
              key={identifier}
              title={{ icon: Sensor.typeIcon[sensor.type], label: Sinafis.Probe.identifierLabel[identifier] }}
              measures={measures}
              distance={distance}
              withoutDistance={withoutDistance}
              withoutDate={withoutDate}
            />
          );
        }),
      );
      return <SimpleGrid cols={cards.length > 1 ? 2 : 1}>{cards}</SimpleGrid>;
    }
  }
};

export default MeasureCards;
