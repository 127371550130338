import { Auth } from '@modules/auth/model';
import { OAuthService } from '@core/oauth/service';
import { OAuth } from '@core/oauth/model';
import { constVoid, pipe } from 'fp-ts/function';
import { httpService, HttpTask } from '@core/http';
import * as TE from 'fp-ts/TaskEither';

export namespace AuthService {
  export function authenticate({ email, password }: Auth.AuthenticateParams): OAuth.OAuthHttpTask {
    return OAuthService.passwordStrategyRequest(email, password);
  }

  export function exchangeToken(token: Auth.Token): OAuth.OAuthHttpTask {
    return pipe(
      httpService.post<OAuth.OAuthTokens, OAuth.OAuthError>('/portal/authenticate/as/exchange', {
        access_token: token,
      }),
      TE.chainFirstTaskK(OAuthService.saveOAuthTokensInStorage),
      TE.map(constVoid),
    );
  }

  export function exchangeSubscription(id: Auth.CompanyADFSId): HttpTask<Auth.ActivateBySubscriptionOut> {
    return httpService.get(`/portal/activation/subscription/${id}`);
  }

  export function logout() {
    return OAuthService.removeOAuthTokensInStorage();
  }
}
