import { z } from 'zod';
import { Pest } from '@modules/alert-reports/pests/model';

export namespace PestSchema {
  export const updateSubscription = z.object({
    name: z.nativeEnum(Pest.Name),
    value: z.boolean(),
  });

  export const updateVisibility = z.object({
    name: z.nativeEnum(Pest.Name),
    value: z.boolean(),
  });
}
