import { HttpStatusCode } from '@core/http';

export function getErrorTitle(status: number) {
  switch (status) {
    case HttpStatusCode.NOT_FOUND:
      return 'La page que vous cherchez est introuvable';
    case HttpStatusCode.UNAUTHORIZED:
      return "Vous n'êtes pas autorisés à accéder à cette section";
    default:
      return `Une erreur ${status} est survenue`;
  }
}
