import { Sinafis } from '@modules/iot/sensors/sinafis/model';
import { ActiveSensor, PendingSensor, Sensor } from '@modules/iot/sensors/model';
import { DeepPartial } from 'react-hook-form';
import { Threshold } from '@modules/iot/model';
import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import { SinafisSchema } from '@modules/iot/sensors/sinafis/schema';
import { z } from 'zod';

export namespace SinafisUtils {
  import Type = Sensor.Type.Sinafis;
  import Level = Threshold.Level;
  import ExtendedMeasureProbeMapping = Sinafis.Config.ExtendedMeasureProbeMapping;

  export function getPendingDefaultValues(
    sensor: PendingSensor<Type>,
    mappings: ExtendedMeasureProbeMapping,
  ): DeepPartial<Sinafis.PendingParams> {
    const humidityThreshold = {
      last: Level.None,
      levels: [
        { level: Level.Critical, until: 0.33 },
        { level: Level.Alert, until: 0.66 },
      ],
    };

    const temperatureThreshold = {
      last: Level.Critical,
      levels: [
        { level: Level.None, until: 10 },
        { level: Level.Alert, until: 20 },
      ],
    };

    const config = pipe(
      mappings,
      R.mapWithIndex(key => {
        switch (key) {
          case 'leaf':
            return { humidityThreshold };
          default:
            return { humidityThreshold, temperatureThreshold };
        }
      }),
    );

    return {
      comment: '',
      name: '',
      technicalId: sensor.technicalId,
      config: {
        ...config,
        transmitter: { humidityThreshold, temperatureThreshold },
      },
    };
  }

  export function getActiveDefaultValues(sensor: ActiveSensor.Impl<Type>): DeepPartial<Sinafis.UpdateParams> {
    return {
      name: sensor.name,
      zoneId: sensor.zone?.id,
      comment: sensor.comment,
      config: sensor.config,
    };
  }

  export const configSchemaFromProbe = (mappings: ExtendedMeasureProbeMapping) => {
    return pipe(
      mappings,
      R.mapWithIndex(key => {
        switch (key) {
          case 'transmitter':
            return SinafisSchema.transmitterSchema;
          case 'ground1':
          case 'ground2':
            return SinafisSchema.groundSchema;
          case 'leaf':
            return SinafisSchema.leafSchema;
        }
      }),
      z.object,
    );
  };
}
