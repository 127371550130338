import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import alertTriggersListRoute from '@modules/iot/alert-triggers/ListPage';
import createAlertTriggerRoute from '@modules/iot/alert-triggers/CreatePage';
import alertTriggerDetailRoute from '@modules/iot/alert-triggers/DetailPage';

const alertTriggersRoutes = (
  <Route path="alert-triggers" {...createRoute(alertTriggersListRoute)}>
    <Route path="new" {...createRoute(createAlertTriggerRoute)} />
    <Route path=":id" {...createRoute(alertTriggerDetailRoute)} />
  </Route>
);

export default alertTriggersRoutes;
