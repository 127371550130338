import { z } from 'zod';
import { NonEmptyString } from '@shared/schemas';
import { AlertReport } from '@modules/alert-reports/model';
import { LocalDateTime } from '@shared/modules/dates';

export namespace AlertReportSchema {
  export const filterSchema = z.object({
    name: NonEmptyString,
    categoryId: AlertReport.CategoryId.nullable(),
    subCategoryId: AlertReport.SubCategoryId.nullable(),
    typeId: AlertReport.TypeId.nullable(),
    gravity: AlertReport.Gravity.nullable(),
    period: z.nativeEnum(AlertReport.Period).nullable(),
    personalizedStartDate: LocalDateTime.nullable(),
    personalizedEndDate: LocalDateTime.nullable(),
    myReports: z.boolean().nullable(),
    hasPictures: z.boolean().nullable(),
    privateOnly: z.boolean().nullable(),
    status: z.nativeEnum(AlertReport.Status).nullable(),
  });
}
