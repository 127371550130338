import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import sensorsListRoute from '@modules/iot/sensors/list/ListPage';
import { sensoterraPendingRoute } from '@modules/iot/sensors/sensoterra/PendingDetailPage';
import zonesRoutes from '@modules/iot/zones/routes';
import { Sensor } from '@modules/iot/sensors/model';
import { sinafisPendingRoute } from '@modules/iot/sensors/sinafis/PendingDetailPage';
import sinafisDetailRoute from '@modules/iot/sensors/sinafis/DetailPage';
import sensoterraDetailRoute from '@modules/iot/sensors/sensoterra/DetailPage';
import sensorsListRedirectRoute from '@modules/iot/sensors/list/ListRedirectPage';
import { horteePendingRoute } from '@modules/iot/sensors/hortee/PendingDetailPage';
import horteeDetailRoute from '@modules/iot/sensors/hortee/DetailPage';

const sensorsListRoutes = (
  <Route path="list" {...createRoute(sensorsListRoute)}>
    <Route index {...createRoute(sensorsListRedirectRoute)} />
    {zonesRoutes}

    <Route path={`${Sensor.Type.Sensoterra}/:id`} {...createRoute(sensoterraDetailRoute)}>
      {zonesRoutes}
    </Route>

    <Route path={`${Sensor.Type.Sinafis}/:id`} {...createRoute(sinafisDetailRoute)}>
      {zonesRoutes}
    </Route>

    <Route path={`${Sensor.Type.Hortee}/:id`} {...createRoute(horteeDetailRoute)}>
      {zonesRoutes}
    </Route>

    <Route path={`pending/${Sensor.Type.Sensoterra}/:id`} {...createRoute(sensoterraPendingRoute)}>
      {zonesRoutes}
    </Route>
    <Route path={`pending/${Sensor.Type.Sinafis}/:id`} {...createRoute(sinafisPendingRoute)}>
      {zonesRoutes}
    </Route>
    <Route path={`pending/${Sensor.Type.Hortee}/:id`} {...createRoute(horteePendingRoute)}>
      {zonesRoutes}
    </Route>
  </Route>
);

export default sensorsListRoutes;
