import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import iotLayoutRoute from '@modules/iot/sensors/layout/IotLayout';
import sensorsListRoutes from '@modules/iot/sensors/list/routes';
import sensorsMapRoutes from '@modules/iot/sensors/map/routes';

const sensorsRoutes = (
  <Route path="sensors" {...createRoute(iotLayoutRoute)}>
    {sensorsMapRoutes}
    {sensorsListRoutes}
  </Route>
);

export default sensorsRoutes;
