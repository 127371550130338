import React, { FC, PropsWithChildren } from 'react';
import { AspectRatio, Card, Divider, Group, Image, Stack, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { renderNullable, renderOptional } from '@shared/utils/render';
import { IconPhoto } from '@tabler/icons-react';
import * as NEA from 'fp-ts/NonEmptyArray';
import PestStage from '@modules/alert-reports/pests/components/PestStage';
import { Pest } from '@modules/alert-reports/pests/model';
import PestDateRange from '@modules/alert-reports/pests/components/PestDateRange';
import AdviceCard from '@modules/alert-reports/pests/components/PestAdvices';

interface PestCardProps {
  pest: Pest.Description;
}

const PestCard: FC<PropsWithChildren<PestCardProps>> = ({ pest, children }) => {
  return (
    <Card
      p={20}
      shadow="0px 4px 4px 0px rgba(0, 0, 0, 0.10)"
      sx={theme => ({
        '&[data-with-border]': {
          borderColor: theme.colors.tertiary[2],
        },
      })}
    >
      <Stack spacing={17}>
        <Group noWrap>
          <AspectRatio w="100%" maw={103} ratio={1}>
            <Card radius={10} p={0}>
              {renderNullable(
                pest.image,
                ({ bound_256x256 }) => (
                  <Image src={bound_256x256} />
                ),
                () => (
                  <IconPhoto size={40} strokeWidth={1.4} />
                ),
              )}
            </Card>
          </AspectRatio>
          <Stack spacing={5}>
            <Text fw={700} fz={16} lh={1.5}>
              {pest.name}
            </Text>
            <Text component={Link} to={`/alert-reports/pests/${pest.enum}`} c="primary.5" td="underline" fz={14}>
              Plus d'information
            </Text>
          </Stack>
        </Group>
        <PestDateRange dateRange={pest.dateRange} />
        <Divider />
        {renderOptional(
          NEA.fromArray(pest.stages),
          risks => (
            <Stack>
              {risks.map(({ name, level }, index) => (
                <PestStage key={index} name={name} level={level} />
              ))}
            </Stack>
          ),
          () => (
            <Text c="gray.7" fz={14}>
              Aucun risque de prévu pour cette semaine
            </Text>
          ),
        )}

        {renderNullable(pest.advice, advice => (
          <AdviceCard advice={advice} />
        ))}
        {children}
      </Stack>
    </Card>
  );
};

export default PestCard;
