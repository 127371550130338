import { FilterQueryParser, getEnumQuery, getSearchQuery, getStringArrayQuery } from '@shared/modules/filter';
import { ActiveSensor, PendingSensor, Sensor } from '@modules/iot/sensors/model';
import { Zone } from '@modules/iot/zones/model';
import { Sinafis } from '@modules/iot/sensors/sinafis/model';
import { Threshold } from '@modules/iot/model';
import { Measures } from '@shared/modules/measures/model';

export namespace SensorUtils {
  export const sensorsFilterParser: FilterQueryParser<ActiveSensor.Filter> = query => ({
    search: getSearchQuery(query),
    zoneId: getStringArrayQuery<Zone.Id>(query, 'zoneId'),
    sensorType: getEnumQuery(query, Sensor.Type, 'sensorType'),
    sinafisProbeIdentifier: getEnumQuery(query, Sinafis.Probe.Identifier, 'sinafisProbeIdentifier'),
    alertLevel: getEnumQuery(query, Threshold.Level, 'alertLevel'),
    alertType: getEnumQuery(query, Measures.Type, 'alertType'),
  });

  export function pendingSensorLinkBuilder(sensor: PendingSensor.List.Item) {
    return `/iot/sensors/list/pending/${sensor.type}/${sensor.technicalId}`;
  }

  export function sensorLinkBuilder(sensor: Pick<Sensor, 'type' | 'id'>) {
    return `/iot/sensors/list/${sensor.type}/${sensor.id}`;
  }
}
