import React, { Dispatch, FC } from 'react';
import { Group, Select, Text } from '@mantine/core';
import SearchGeoCity from '@shared/modules/geo/SearchGeoCity';
import { Dashboard } from '@modules/dashboard/model';
import { Zone } from '@modules/iot/zones/model';
import { Geo } from '@shared/modules/geo/model';

interface LocationFilterProps {
  filter: Dashboard.Filter;
  setFilter: Dispatch<React.SetStateAction<Dashboard.Filter | null>>;
  zones: Array<Zone>;
}

const LocationFilter: FC<LocationFilterProps> = ({ filter, setFilter, zones }) => {
  const handleZoneChange = (zoneId: Zone.Id) => setFilter({ type: 'zone', zoneId });

  const handleLocationChange = (location: Geo.City) => setFilter({ type: 'location', location });

  return (
    <Group spacing={10} noWrap>
      <Select
        sx={{ flexGrow: 1 }}
        value={filter.type === 'zone' ? filter.zoneId : null}
        placeholder="Sélectionner un groupe"
        data={zones.map(zone => ({ value: zone.id, label: zone.name }))}
        onChange={handleZoneChange}
        nothingFound="Aucun groupe disponible"
      />
      <Text size={10} c="dark.1" fw={600}>
        ou
      </Text>
      <SearchGeoCity
        sx={{ flexGrow: 1 }}
        label={undefined}
        value={filter.type === 'location' ? filter.location : null}
        onChange={handleLocationChange}
      />
    </Group>
  );
};

export default LocationFilter;
