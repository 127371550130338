import z from 'zod';
import { ThresholdSchema } from '@modules/iot/schema';
import { Utils } from '@shared/utils/model';
import { HubApi } from '@modules/iot/model';
import { Zone } from '@modules/iot/zones/model';

export namespace SinafisSchema {
  export const transmitterSchema = z.object({
    height: Utils.Length,
    humidityThreshold: ThresholdSchema.humidityScaleSchema,
    temperatureThreshold: ThresholdSchema.temperatureScaleSchema,
  });

  export const groundSchema = z.object({
    depth: Utils.Length,
    humidityThreshold: ThresholdSchema.humidityScaleSchema,
    temperatureThreshold: ThresholdSchema.temperatureScaleSchema,
  });

  export const leafSchema = z.object({
    height: Utils.Length,
    humidityThreshold: ThresholdSchema.humidityScaleSchema,
  });

  const configSchema = z.object({
    transmitter: transmitterSchema,
    ground1: groundSchema.nullish(),
    ground2: groundSchema.nullish(),
    leaf: leafSchema.nullish(),
  });

  export const pendingSchema = z.object({
    technicalId: HubApi.SensorId,
    name: z.string().min(1),
    zoneId: Zone.Id,
    config: configSchema,
    comment: z.string().nullable(),
  });

  export const updateSchema = z.object({
    name: z.string().min(1),
    zoneId: Zone.Id,
    config: configSchema,
    comment: z.string().nullable(),
  });
}
