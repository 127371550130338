import { z } from 'zod';
import { emailSchema, NonEmptyString } from '@shared/schemas';
import { Passwords } from '@modules/passwords/model';

export namespace Auth {
  import ActivationToken = Passwords.ActivationToken;
  export const Token = z.string().brand('AuthToken');
  export type Token = z.infer<typeof Token>;

  export const CompanyADFSId = z.string().brand('CompanyADFSId');
  export type CompanyADFSId = z.infer<typeof CompanyADFSId>;

  export interface ActivateBySubscriptionOut {
    token: ActivationToken;
  }

  export const AuthenticateParams = z.object({
    email: emailSchema,
    password: NonEmptyString,
  });

  export type AuthenticateParams = z.infer<typeof AuthenticateParams>;
}
