import React, { createElement } from 'react';
import { Tooltip } from '@mantine/core';
import { Sensor } from '@modules/iot/sensors/model';
import { Threshold } from '@modules/iot/model';
import Pin, { PinProps } from '@shared/modules/maps/components/Pin';

interface SensorPinProps {
  type: Sensor.Type;
  tip?: string;
  level?: Threshold.Level;
}

function SensorPin<C = 'div'>({
  type,
  tip = 'Sonde',
  level = Threshold.Level.None,
  ...props
}: SensorPinProps & PinProps<C>) {
  return (
    <Tooltip label={tip} position="top" withinPortal>
      <Pin color={Threshold.levelColor[level]} size={36} {...props}>
        {createElement(Sensor.typeIcon[type], { width: 20, height: 20 })}
      </Pin>
    </Tooltip>
  );
}

export default SensorPin;
