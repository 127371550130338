import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Select } from '@mantine/core';
import { Sensoterra } from '@modules/iot/sensoterra/model';
import { useFetchTaskOption } from '@core/http/hooks';
import { SensoterraService } from '@modules/iot/sensors/sensoterra/service';
import { Utils } from '@shared/utils/model';
import * as TE from 'fp-ts/TaskEither';
import { ActiveSensor, Sensor } from '@modules/iot/sensors/model';
import { HttpError } from '@core/http';
import Parameter = Sensoterra.Parameter;
import { DateFormat, formatDate } from '@shared/modules/dates';
import { differenceInWeeks, endOfToday, startOfToday } from 'date-fns';
import { Measures } from '@shared/modules/measures/model';
import HumidityMeasure from '@shared/modules/measures/HumidityMeasure';
import { NonEmptyString } from '@shared/schemas';

interface HumidityMeasureProps {
  sensor: ActiveSensor.Impl<Sensor.Type.Sensoterra>;
  parameters: Array<Sensoterra.Parameter>;
  defaultSoilType: Sensoterra.Parameter.Key;
}

const HumidityCharts: FC<HumidityMeasureProps> = ({ sensor, parameters, defaultSoilType }) => {
  const [soilType, setSoilType] = useState<Sensoterra.Parameter.Key | null>(defaultSoilType);
  const [[startDate, endDate], setDateRange] = useState([startOfToday(), endOfToday()]);

  useEffect(() => {
    setSoilType(defaultSoilType);
  }, [defaultSoilType]);

  const filter: Measures.History.Filter = useMemo(
    () => ({
      startDate: formatDate(startDate, DateFormat.LocalDateTime),
      endDate: formatDate(endDate, DateFormat.LocalDateTime),
      unit: differenceInWeeks(endDate, startDate) < 1 ? Utils.ChronoUnit.Hours : Utils.ChronoUnit.Days,
    }),
    [startDate, endDate],
  );

  const measuresTask = useCallback(() => {
    const parse = NonEmptyString.safeParse(soilType);
    return parse.success
      ? SensoterraService.getHumidityMeasures(sensor.id, {
          ...filter,
          soilType: Sensoterra.Parameter.Key.parse(parse.data),
        })
      : TE.left(HttpError.default);
  }, [sensor.id, soilType, filter]);
  const [humidity] = useFetchTaskOption(measuresTask);

  const handleSoilChange = (key: Sensoterra.Parameter.Key | null) => setSoilType(key);

  return (
    <HumidityMeasure
      humidity={humidity}
      dateRange={[startDate, endDate]}
      setDateRange={setDateRange}
      exportParams={{ type: sensor.type, ...filter }}
    >
      <Select
        data={parameters
          .filter(({ type }) => type === Parameter.Type.Soil)
          .map(({ name, key }) => ({ value: key, label: name }))}
        label="Simuler avec un autre type de sol"
        value={soilType}
        onChange={handleSoilChange}
        placeholder="Sélectionner"
        required
      />
    </HumidityMeasure>
  );
};

export default HumidityCharts;
