import React, { FC } from 'react';
import { Card, Image, Text } from '@mantine/core';
import placeholder from '@assets/icons/alert-reports/sensors-listing-placeholder.svg';
import { NumberUtils } from '@shared/utils/number';
import formatNumber = NumberUtils.formatNumber;

const radius = formatNumber(5, { unit: 'kilometer', style: 'unit' });

const SensorsDataListPlaceholder: FC = () => {
  return (
    <Card
      px={42}
      py={27}
      maw={705}
      mx="auto"
      radius={10}
      sx={theme => ({ '&[data-with-border]': { border: `2px solid ${theme.colors.tertiary[3]}` } })}
    >
      <Text c="tertiary.4" fz={26} fw={700} lh={1.35} ta="center">
        Il n’y a aucune sonde dans un rayon de {radius} du signalement
      </Text>
      <Image src={placeholder} maw={264} mx="auto" />
    </Card>
  );
};

export default SensorsDataListPlaceholder;
