import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import Page, { PageProps } from '@layout/page/Page';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import UpdateProfileForm from '@modules/profile/components/UpdateProfileForm';
import { defineAction, useAction } from '@core/router/action';
import { ProfileService } from '@modules/profile/service';
import { Profile } from '@modules/profile/model';
import { useProfile } from '@modules/profile/loader';

const actions = {
  update: defineAction({
    type: 'update',
    payload: Profile.UpdateProfileParams,
    handler: ({ payload }) => ProfileService.updateProfile(payload),
    flashOptions: { success: () => 'Modification réalisée' },
  }),
};

const ProfilePage: FC = () => {
  const profile = useProfile();

  const { formRef, form } = useEnhancedForm<Profile.UpdateProfileParams>({
    resolver: zodResolver(Profile.UpdateProfileParams),
    defaultValues: {
      firstname: profile.firstname,
      lastname: profile.lastname,
    },
  });

  const [loading, updateProfile] = useAction(actions.update);

  const pageProps: PageProps = {
    top: 'Mon profil',
  };

  return (
    <Page {...pageProps} p={0}>
      <EnhancedForm ref={formRef} form={form} onSubmit={updateProfile} preventLeave>
        <UpdateProfileForm profile={profile} loading={loading} />
      </EnhancedForm>
    </Page>
  );
};

const profilePageRoute = defineRoute({
  component: ProfilePage,
  actions,
});

export default profilePageRoute;
