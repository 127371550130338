import React, { FC, useMemo, useState } from 'react';
import { Anchor, Box, Card, Divider, Group, Loader, Text, Tooltip } from '@mantine/core';
import { formatDate } from '@shared/modules/dates';
import { startOfToday } from 'date-fns';
import { renderNullable, renderOptional } from '@shared/utils/render';
import { PrevisionsUtils } from '@shared/modules/previsions/utils';
import { NumberUtils } from '@shared/utils/number';
import { Dashboard } from '@modules/dashboard/model';
import { useHashDisclosure } from '@core/router';
import FullDrawer from '@shared/components/drawer/FullDrawer';
import PrevisionsComponent from './Previsions';
import { Zone } from '@modules/iot/zones/model';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { PrevisionsHooks } from '@shared/modules/previsions/components/weather/hooks';
import { Weather } from '@shared/modules/weather/model';

interface PrevisionsCardProps {
  zoneId: Zone.Id;
  zones: Array<Zone>;
}

const PrevisionsCard: FC<PrevisionsCardProps> = ({ zoneId, zones }) => {
  const today = useMemo(() => formatDate(startOfToday(), 'E'), []);

  const defaultFilter = useMemo<Dashboard.Filter>(() => ({ type: 'zone', zoneId }), [zoneId]);

  const [filter, setFilter] = useState<Dashboard.Filter | null>(defaultFilter);

  const { context, weather, disease } = PrevisionsHooks.usePrevisionsAsyncData(filter);

  const [drawerOpen, drawerTriggers] = useHashDisclosure('#previsions');

  return (
    <Card
      px={13}
      py={10}
      shadow="0px 4px 14px rgba(0, 0, 0, 0.05)"
      sx={theme => ({ border: `2px solid ${theme.colors.tertiary[2]} !important` })}
    >
      <Text size={10} c="dark.1" tt="uppercase" fw={600}>
        {today}
      </Text>

      <Box mt={5} h={44}>
        <Group align="center">
          {renderOptional(
            pipe(
              O.bindTo('weather')(weather),
              O.bind('infos', ({ weather }) => PrevisionsUtils.getWeatherTypeInfos(weather.today)),
            ),
            ({ infos: { label, color, icon: Icon }, weather: { today } }) => (
              <>
                <Tooltip label={label}>
                  <Box c={color} h={44}>
                    <Icon width={44} height={44} />
                  </Box>
                </Tooltip>
                {renderNullable(today, today => (
                  <Text size={26} fw={700} c="dark.3">
                    {NumberUtils.formatTemperature(today[Weather.DayForecast.Param.AverageTemp])}
                  </Text>
                ))}
              </>
            ),
            () => (
              <Group position="center" w="100%">
                <Loader />
              </Group>
            ),
          )}

          <FullDrawer opened={drawerOpen} width={530} onClose={drawerTriggers.close}>
            <Box py={25} px={20}>
              <PrevisionsComponent
                context={context}
                weather={weather}
                disease={disease}
                setFilter={setFilter}
                zones={zones}
                vertical
              />
            </Box>
          </FullDrawer>
        </Group>
      </Box>

      <Divider my={8} color="tertiary.2" />

      <Anchor size={14} component="button" c="dark.3" fw={600} onClick={drawerTriggers.open}>
        Voir les prévisions
      </Anchor>
    </Card>
  );
};

export default PrevisionsCard;
