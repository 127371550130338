import React, { FC, PropsWithChildren, useState } from 'react';
import { renderOptional } from '@shared/utils/render';
import { flow } from 'fp-ts/function';
import * as NEA from 'fp-ts/NonEmptyArray';
import { AspectRatio, Box, Card, Image, SimpleGrid, Stack } from '@mantine/core';
import * as A from 'fp-ts/Array';
import { IconPhotoOff } from '@tabler/icons-react';
import { ImageMap } from '@shared/modules/images/model';
import GalleryModal from '@shared/components/modals/GalleryModal';
import { BoxProps } from '@mantine/core/lib/Box/Box';

interface ImagesGalleryProps {
  images: Array<ImageMap>;
}

const ImagesGallery: FC<PropsWithChildren<ImagesGalleryProps> & BoxProps> = ({ children, images, ...rest }) => {
  const [showImage, setImageIndex] = useState<number | null>(null);
  const handleCloseModal = () => setImageIndex(null);
  const handleOpenModal = (index: number) => () => setImageIndex(index);

  return (
    <>
      <Box pos="relative" {...rest}>
        {renderOptional(
          NEA.fromArray(images),
          flow(NEA.unprepend, ([head, tail]) => (
            <Stack spacing={4}>
              <AspectRatio
                ratio={376 / 239}
                onClick={handleOpenModal(0)}
                sx={{
                  ':hover': {
                    cursor: 'zoom-in',
                  },
                }}
              >
                <Image src={head.bound_512x512} styles={{ root: { borderRadius: 8 }, figure: { width: '100%' } }} />
              </AspectRatio>
              {renderOptional(NEA.fromArray(A.splitAt(4)(tail)), ([tail]) => (
                <SimpleGrid cols={4} spacing={4}>
                  {tail.map((image, index) => (
                    <AspectRatio
                      key={index}
                      ratio={91 / 61}
                      onClick={handleOpenModal(index + 1)}
                      sx={{
                        ':hover': {
                          cursor: 'zoom-in',
                        },
                      }}
                    >
                      <Image
                        src={image.cover_150x150}
                        styles={{ root: { borderRadius: 8 }, figure: { width: '100%' } }}
                      />
                    </AspectRatio>
                  ))}
                </SimpleGrid>
              ))}
            </Stack>
          )),
          () => (
            <AspectRatio ratio={376 / 239}>
              <Card bg="tertiary.2" radius={8} withBorder={false} c="tertiary.3">
                <IconPhotoOff size={80} strokeWidth={1.5} />
              </Card>
            </AspectRatio>
          ),
        )}

        {children}
      </Box>
      <GalleryModal opened={showImage} images={images ?? []} onClose={handleCloseModal} />
    </>
  );
};

export default ImagesGallery;
