import React, { FC, PropsWithChildren } from 'react';

import * as Styled from './AuthLayout.styles';
import { Title } from '@mantine/core';

import backgroundImage from '@assets/images/auth/auth-background.jpg';
import backgroundImage2x from '@assets/images/auth/auth-background@2x.jpg';

import logo from '@assets/logos/logo.svg';
import logoCompact from '@assets/logos/logo-compact.svg';

interface AuthLayoutProps {
  title?: string;
}

const AuthLayout: FC<PropsWithChildren<AuthLayoutProps>> = ({ title, children }) => {
  return (
    <Styled.AuthLayoutContainer>
      <Styled.AuthLayoutLeft>
        <Styled.AuthLayoutLeftHeader>
          <img src={logo} width={88} height={51} alt="Logo" />
        </Styled.AuthLayoutLeftHeader>

        <Styled.AuthLayoutLeftWrapper>
          <Styled.AuthLayoutLeftContent>
            <Title>{title}</Title>

            {children}
          </Styled.AuthLayoutLeftContent>
        </Styled.AuthLayoutLeftWrapper>
      </Styled.AuthLayoutLeft>

      <Styled.AuthLayoutRight>
        <Styled.AuthLayoutBackgroundContainer>
          <img src={backgroundImage} srcSet={`${backgroundImage2x} 2x`} alt="Background" />
        </Styled.AuthLayoutBackgroundContainer>

        <Styled.AuthLayoutRightContent>
          <img width={159} height={119} src={logoCompact} alt="Logo" />

          <Title size="h3" mt={30} color="white" tt="uppercase">
            Portail professionnels
          </Title>
        </Styled.AuthLayoutRightContent>
      </Styled.AuthLayoutRight>
    </Styled.AuthLayoutContainer>
  );
};

export default AuthLayout;
