import React, { FC } from 'react';
import { Weather } from '@shared/modules/weather/model';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { ordFromOrdering } from '@shared/utils/order';
import { SimpleGrid, Text } from '@mantine/core';
import { renderNullable } from '@shared/utils/render';
import Param = Weather.DayForecast.Param;

interface AnalysisListingWeatherProps {
  weather: Weather.DayForecast;
}

const paramLabelOrdering: Record<Weather.DayForecast.KnownParam, number> = {
  [Param.MinTemp]: 1,
  [Param.MinWind]: 2,
  [Param.MaxTemp]: 3,
  [Param.MaxWind]: 4,
  [Param.AverageTemp]: 5,
  [Param.AverageWind]: 6,
  [Param.MinHumidity]: 7,
  [Param.ETP]: 8,
  [Param.MaxHumidity]: 9,
  [Param.Precipitation]: 10,
  [Param.AverageDewPoint]: 11,
};

const AnalysisListingWeather: FC<AnalysisListingWeatherProps> = ({ weather }) => {
  return (
    <SimpleGrid cols={2} spacing={10} style={{ rowGap: 0 }}>
      {pipe(
        Object.values(Weather.DayForecast.KnownParam),
        A.sort(ordFromOrdering(paramLabelOrdering)),
        A.map(param => (
          <Text key={param} c="dark.5" fz={10} fw={400} lh={1.55} style={{ whiteSpace: 'nowrap' }}>
            {Weather.DayForecast.paramLabel[param]} :{' '}
            {renderNullable(
              weather.values[param],
              value => `${value} ${Weather.DayForecast.paramUnit[param]}`,
              () => '--',
            )}
          </Text>
        )),
      )}
    </SimpleGrid>
  );
};

export default AnalysisListingWeather;
