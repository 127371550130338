import { LocalDate } from '@shared/modules/dates';
import * as Eq from 'fp-ts/Eq';
import * as N from 'fp-ts/number';
import * as Show from 'fp-ts/Show';
import { MantineColor } from '@mantine/core';
import { Utils } from '@shared/utils/model';
import Percent = Utils.Percent;
import percentShow = Utils.percentShow;

export namespace Disease {
  export enum Model {
    DollarSpot = 'dollar-spot',
    ColdFusarium = 'cold-fusarium',
    LWD = 'lwd',
  }

  export const modelLabel: Record<Model, string> = {
    [Model.ColdFusarium]: 'Fusariose froide',
    [Model.DollarSpot]: 'Dollar Spot',
    [Model.LWD]: 'Humidité Foliaire',
  };

  export const modelName: Record<Model, string> = {
    [Model.ColdFusarium]: 'FF',
    [Model.DollarSpot]: 'DS',
    [Model.LWD]: 'HF',
  };

  export const modelEq: Eq.Eq<Model> = Eq.eqStrict;

  export const modelShow = {
    [Model.ColdFusarium]: percentShow,
    [Model.DollarSpot]: percentShow,
    [Model.LWD]: N.Show,
  } satisfies {
    [Model in Disease.Model]: Show.Show<Forecast.Score<Model>>;
  } as Record<Disease.Model, Show.Show<Forecast.Score>>;

  export enum Level {
    Low = 'low',
    Medium = 'medium',
    High = 'high',
  }

  export namespace Level {
    export const color: Record<Level, MantineColor> = {
      [Level.Low]: 'yellow.5',
      [Level.Medium]: 'primary.4',
      [Level.High]: 'primary',
    };

    export const nullableColor: MantineColor = 'green';

    export const text: Record<Level, string> = {
      [Level.Low]: 'Moyen',
      [Level.Medium]: 'Fort',
      [Level.High]: 'Très important',
    };

    export const nullableText = 'Faible';
  }

  export namespace Forecast {
    export type Score<Model extends Disease.Model = Disease.Model> = {
      [Model.ColdFusarium]: Percent;
      [Model.DollarSpot]: Percent;
      [Model.LWD]: number;
    }[Model];

    export interface Value<Model extends Disease.Model = Disease.Model> {
      score: Score<Model>;
      level: Level | null;
    }
  }

  export type Forecast = {
    [Model in Disease.Model]?: Forecast.Value<Model>;
  };

  export interface DayForecast {
    date: LocalDate;
    values: Forecast;
  }
}
