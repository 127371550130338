import { httpService, HttpTask } from '@core/http';
import { ActiveSensor, PendingSensor, Sensor } from '@modules/iot/sensors/model';
import { HubApi } from '@modules/iot/model';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { Measures } from '@shared/modules/measures/model';
import { SensorsMap } from '@modules/iot/sensors/map/model';
import { downloadFile } from '@shared/utils/download';

export namespace SensorsService {
  const URI = '/iot/sensors';

  export function getActiveSensors(filters: ActiveSensor.Filter): HttpTask<ActiveSensor.List> {
    return httpService.get(URI, { params: filters });
  }

  export function getPendingSensors(): HttpTask<PendingSensor.List> {
    return httpService.get(`${URI}/pending`);
  }

  export function getPendingSensorsCount(): HttpTask<number> {
    return pipe(
      httpService.get<{ count: number }>(`${URI}/pending/count`),
      TE.map(({ count }) => count),
    );
  }

  export function getActiveSensor<Type extends Sensor.Type | ActiveSensor = ActiveSensor>(
    id: Sensor.Id,
  ): HttpTask<Type extends Sensor.Type ? ActiveSensor.Impl<Type> : ActiveSensor> {
    return httpService.get(`${URI}/${id}`);
  }

  export function getPendingSensor<Type extends Sensor.Type>(id: HubApi.SensorId): HttpTask<PendingSensor<Type>> {
    return httpService.get(`${URI}/pending/${id}`);
  }

  export function getMeasures<Type extends Sensor.Type>(
    id: Sensor.Id,
    type: Type,
    filter: Measures.History.Filter,
  ): HttpTask<Measures.History<Type>> {
    return httpService.get(`${URI}/${type}/${id}/measures`, { params: filter });
  }

  export function exportMeasures(id: Sensor.Id, type: Sensor.Type, filter: Measures.History.Filter): HttpTask {
    return pipe(
      httpService.post<Blob>(
        `${URI}/${type}/${id}/measures/export`,
        undefined,
        { responseType: 'blob', params: filter },
        { raw: true },
      ),
      TE.chainIOK(downloadFile),
    );
  }

  export function getMapSensors(filters: ActiveSensor.Filter): HttpTask<SensorsMap> {
    return httpService.get(`${URI}/map`, { params: filters });
  }

  export function updateZone(params: Sensor.UpdateZoneParams): HttpTask {
    const { id, ...rest } = params;
    return httpService.post(`${URI}/${params.id}/zone`, rest);
  }

  export function deleteSensor(id: Sensor.Id): HttpTask {
    return httpService.delete(`${URI}/${id}`);
  }
}
