import React, { FC } from 'react';

import * as O from 'fp-ts/Option';
import { renderOptional } from '@shared/utils/render';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Alert, Button, Group, PasswordInput, Text } from '@mantine/core';
import { Passwords } from '@modules/passwords/model';

interface PasswordsFormProps {
  loading: boolean;
  error: O.Option<string>;
  onSubmit: (params: Passwords.PasswordsBody) => void;
}

const PasswordsForm: FC<PasswordsFormProps> = ({ loading, error, onSubmit }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Passwords.PasswordsBody>({
    resolver: zodResolver(Passwords.PasswordsBody),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {renderOptional(error, error => (
        <Alert color="red" mb={10}>
          <Text color="red" size="sm" weight={600} align="center">
            {error}
          </Text>
        </Alert>
      ))}

      <PasswordInput
        id="password"
        error={errors.password?.message}
        placeholder="Entrez votre mot de passe"
        label="Mot de passe"
        required
        {...register('password')}
      />

      <PasswordInput
        id="confirmPassword"
        mt="sm"
        error={errors.confirmPassword?.message}
        placeholder="Confirmer le mot de passe"
        label="Confirmer le mot de passe"
        required
        {...register('confirmPassword')}
      />

      <Group grow mt="xl">
        <Button type="submit" loading={loading} disabled={loading}>
          Valider
        </Button>
      </Group>
    </form>
  );
};

export default PasswordsForm;
