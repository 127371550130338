import React, { ReactNode, Suspense } from 'react';
import { Await as ReactRouterAwait } from 'react-router-dom';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import ErrorPage from '@shared/components/error-page/ErrorPage';

interface AwaitProps<R> {
  resolve: Promise<R>;
  errorElement?: ReactNode;
  fallback?: ReactNode;
  children: (data: R) => ReactNode;
  withFallbackError?: boolean;
}

function Await<R>({
  resolve,
  errorElement = <ErrorPage />,
  fallback = <DebouncedLineLoader />,
  withFallbackError = false,
  children,
}: AwaitProps<R>) {
  return (
    <Suspense fallback={fallback}>
      <ReactRouterAwait resolve={resolve} errorElement={withFallbackError ? fallback : errorElement}>
        {children}
      </ReactRouterAwait>
    </Suspense>
  );
}

export default Await;
