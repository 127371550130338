import React, { FC } from 'react';
import { Button, Group, Title } from '@mantine/core';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import FilterForm from '@modules/alert-reports/filters/components/FilterForm';
import DrawerContent from '@shared/components/drawer/DrawerContent';
import { useAction } from '@core/router/action';
import { AlertReport } from '@modules/alert-reports/model';
import { zodResolver } from '@hookform/resolvers/zod';
import { AlertReportSchema } from '@modules/alert-reports/schema';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { defineRoute, usePreserveNavigate } from '@core/router';
import { alertReportActions } from '@modules/alert-reports/filters/ListPage';

const CreatePage: FC = () => {
  const navigate = usePreserveNavigate();
  const [loading, create] = useAction(alertReportActions.create);
  const { formRef, form } = useEnhancedForm<AlertReport.Filter.SaveParams>({
    resolver: zodResolver(AlertReportSchema.filterSchema),
    defaultValues: {
      categoryId: null,
      subCategoryId: null,
      typeId: null,
      gravity: null,
      period: null,
      personalizedStartDate: null,
      personalizedEndDate: null,
      myReports: null,
      hasPictures: null,
      privateOnly: null,
      status: null,
    },
  });

  const handleClose = () => navigate('..', { replace: true, relative: 'path' });

  const handleCreateAlertReport = (params: AlertReport.Filter.SaveParams) =>
    pipe(
      create(params),
      TE.chainIOK(() => handleClose),
    );

  return (
    <DrawerContent
      top={
        <Group>
          <Title size="h3" sx={{ flex: '1 1 auto' }}>
            Créer un filtre
          </Title>
        </Group>
      }
    >
      <EnhancedForm ref={formRef} form={form} onSubmit={handleCreateAlertReport} preventLeave>
        <FilterForm />
      </EnhancedForm>
      <Group spacing={10} pt={30} position="right">
        <Button onClick={handleClose} variant="subtle" c="dark.3" color="white">
          Annuler
        </Button>
        <Button onClick={formRef.current?.handleSubmit} loading={loading}>
          Créer
        </Button>
      </Group>
    </DrawerContent>
  );
};

const createFilterRoute = defineRoute({
  component: CreatePage,
  loader: undefined,
  actions: alertReportActions,
});

export default createFilterRoute;
