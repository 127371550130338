import { HttpError, HttpTask } from '@core/http';
import { z } from 'zod';

export namespace OAuth {
  export const AccessToken = z.string().brand<'AccessToken'>();
  export type AccessToken = z.infer<typeof AccessToken>;

  export const RefreshToken = z.string().brand<'RefreshToken'>();
  export type RefreshToken = z.infer<typeof RefreshToken>;

  export interface OAuthTokens {
    access_token: AccessToken;
    expires_in: number | null;
    refresh_token: RefreshToken | null;
    token_type: string;
  }

  export type SavedOAuthTokens = Pick<OAuthTokens, 'access_token' | 'refresh_token'>;

  export interface OAuthPasswordRequest {
    grant_type: 'password';
    username: string;
    password: string;
  }

  export interface OAuthRefreshTokensRequest {
    grant_type: 'refresh_token';
    refresh_token: string;
  }

  export type OAuthRequest = OAuthPasswordRequest | OAuthRefreshTokensRequest;

  export enum OAuthErrorCode {
    InvalidRequest = 'invalid_request',
    InvalidGrant = 'invalid_grant',
    InvalidClient = 'invalid_client',
    UnsupportedGrantType = 'unsupported_grant_type',
    SlowDown = 'slow_down',
    AccessDenied = 'access_denied',
    ExpiredToken = 'expired_token',
    AuthorizationPending = 'authorization_pending',
  }

  export interface OAuthError {
    error: OAuthErrorCode;
  }

  export type OAuthHttpError = HttpError<OAuthError>;
  export type OAuthHttpTask = HttpTask<void, OAuthError>;
}
