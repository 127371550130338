import React, { FC, useMemo } from 'react';
import { Editorial } from '@shared/modules/editorial/model';
import Block = Editorial.Block;
import Template = Editorial.Block.Template;
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as NEA from 'fp-ts/NonEmptyArray';

import { ResourceUtils } from '@shared/modules/resources/utils';
import getResourceFromDetail = ResourceUtils.getResourceFromDetail;
import { sortListByOrder } from '@shared/utils/order';
import { Carousel } from '@mantine/carousel';
import { Box, Image } from '@mantine/core';
import { renderOptional } from '@shared/utils/render';
import * as O from 'fp-ts/Option';
import { EditorialHooks } from '@shared/modules/editorial/hooks';
import useMissingResourceLogger = EditorialHooks.useMissingResourceLogger;
import { PGImageVariant } from '@shared/modules/images/model';

interface GalleryRendererProps {
  block: Block<Template.Gallery>;
}

const GalleryRenderer: FC<GalleryRendererProps> = ({ block }) => {
  const images = useMemo(
    () =>
      pipe(
        sortListByOrder(block.images),
        A.filterMap(image => getResourceFromDetail(image.resource)),
        NEA.fromArray,
      ),
    [block.images],
  );

  useMissingResourceLogger(block, O.isNone(images));

  return renderOptional(images, images => (
    <Box px={35} py={10}>
      <Carousel align="start" slideSize="40%" slideGap={5} withIndicators>
        {images.map((image, index) => (
          <Carousel.Slide key={index} style={{ cursor: 'grab' }}>
            <Image
              src={image.url[PGImageVariant.Original]}
              h="100%"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            />
          </Carousel.Slide>
        ))}
      </Carousel>
    </Box>
  ));
};

export default GalleryRenderer;
