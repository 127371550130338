import { httpService, HttpTask } from '@core/http';
import { Utils } from '@shared/utils/model';
import { Sensoterra } from '@modules/iot/sensoterra/model';

export namespace SensoterraService {
  const URI = '/iot/sensoterra';

  export function getParameters(): HttpTask<Array<Sensoterra.Parameter>> {
    return httpService.get(`${URI}/parameters`);
  }

  export function getCoordinates(id: Sensoterra.Id): HttpTask<Utils.GPSCoordinates> {
    return httpService.get(`${URI}/${id}/coordinates`);
  }
}
