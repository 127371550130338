import { Measures } from '@shared/modules/measures/model';
import * as Ord from 'fp-ts/Ord';
import * as O from 'fp-ts/Option';
import { constant, flow, pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as NEA from 'fp-ts/NonEmptyArray';
import { Utils } from '@shared/utils/model';
import * as R from 'fp-ts/Record';
import * as N from 'fp-ts/number';
import { NumberUtils } from '@shared/utils/number';
import * as M from 'fp-ts/Monoid';
import { LocalDateTime } from '@shared/modules/dates';
import { Charts } from '@shared/modules/charts/model';
import { isAfter, isValid, parseISO } from 'date-fns';

export namespace MeasuresUtils {
  import RealTimeMeasure = Measures.RealTimeMeasure;
  import formatPercent = NumberUtils.formatPercent;
  import Type = Measures.Type;
  import formatTemperature = NumberUtils.formatTemperature;

  export function getMeasureFromList<Type extends Measures.Type>(
    measures: Array<RealTimeMeasure.Impl<Measures.Type>>,
    type: Type,
  ): O.Option<RealTimeMeasure.Impl<Type>> {
    return pipe(
      measures,
      A.findFirst((measure): measure is RealTimeMeasure.Impl<Type> => measure.type === type),
    );
  }
  export function getMeasureValue<Type extends Measures.Type>(
    type: Type,
    measures: Measures.Last | null,
  ): O.Option<Measures.Value<Type>> {
    return pipe(
      A.flatten(Object.values(measures ?? {})),
      A.filter<Measures.RealTimeMeasure.Impl<Measures.Type>, Measures.RealTimeMeasure.Impl<Type>>(
        (measure): measure is Measures.RealTimeMeasure.Impl<Type> => measure.type === type,
      ),
      NEA.fromArray,
      O.map(
        flow(NEA.unappend, ([tail, head]) =>
          tail.reduce((prev, acc) => Ord.min(Measures.typeOrd[type])(acc.value, prev), head.value),
        ),
      ),
    );
  }

  export const batteryLevel = (value: Utils.Percent) =>
    pipe(
      R.toEntries(Measures.batteryBreakpoint),
      A.sort(Ord.tuple(Ord.trivial, N.Ord)),
      A.reduceRight(Measures.BatteryLevel.Empty, ([level, breakpoint], acc) =>
        Ord.leq(Utils.percentOrd)(value, breakpoint) ? level : acc,
      ),
    );

  const measureValueFormatter: { [key in Type]: (value: Measures.Value<key>) => string } = {
    [Type.Battery]: formatPercent,
    [Type.Humidity]: formatPercent,
    [Type.Temperature]: formatTemperature,
    [Type.Nutrition]: N.Show.show,
    [Type.Signal]: constant(''),
  };

  export function formatMeasure(measure: Measures.RealTimeMeasure) {
    switch (measure.type) {
      case Measures.Type.Battery:
        return measureValueFormatter[measure.type](measure.value);
      case Measures.Type.Signal:
        return measureValueFormatter[measure.type](measure.value);
      case Measures.Type.Temperature:
        return measureValueFormatter[measure.type](measure.value);
      case Measures.Type.Humidity:
        return measureValueFormatter[measure.type](measure.value);
    }
  }

  export function getLastHumidityMeasure({ values }: Measures.Humidity) {
    return pipe(
      values,
      A.filterMap(({ value, at }) =>
        pipe(O.Do, O.apS('at', pipe(parseISO(at), O.fromPredicate(isValid))), O.let('value', constant(value))),
      ),
      NEA.fromArray,
      O.map(values =>
        pipe(
          values,
          NEA.reduce(NEA.last(values), (acc, curr) => (isAfter(curr.at, acc.at) ? curr : acc)),
        ),
      ),
    );
  }

  export const mergeDateMonoid: M.Monoid<Record<LocalDateTime, Charts.Line.SensorDataValues>> = R.getUnionMonoid({
    concat: (x, y) => ({ ...x, ...y }),
  });
}
