import React from 'react';
import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';

import zoneRoute from '@modules/iot/zones/components/ZonesLayout';
import createZoneRoute from '@modules/iot/zones/CreatePage';
import zoneDetailRoute from '@modules/iot/zones/DetailPage';

const zonesRoutes = (
  <Route path="zones" {...createRoute(zoneRoute)}>
    <Route path="new" {...createRoute(createZoneRoute)} />
    <Route path=":zoneId" {...createRoute(zoneDetailRoute)} />
  </Route>
);

export default zonesRoutes;
