import z from 'zod';
import { LoaderFunctionArgs, redirect, resolvePath, defer as reactRouterDefer } from 'react-router-dom';
import { pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as EI from 'fp-ts/Either';
import * as O from 'fp-ts/Option';
import * as R from 'fp-ts/Record';
import { DeferData, Loader } from './index';
import { HttpTask } from '@core/http';
import { parseParams } from '@core/router';
import { createPath } from 'history';

export function defineLoader<
  ParamsSchema extends z.ZodType = z.ZodType<unknown>,
  R = unknown,
  ID extends string = never,
>(loader: Loader<ParamsSchema, R, ID>): Loader<ParamsSchema, R, ID> {
  return loader;
}

export function loaderHandler<ParamsSchema extends z.ZodType = z.ZodType<unknown>, R = unknown>(
  loader: Loader<ParamsSchema, R>,
): (args: LoaderFunctionArgs) => Promise<R> {
  return args => {
    const program = pipe(
      O.fromNullable(loader),
      O.match(
        () => T.of({} as R),
        loader => loader.handler({ request: args.request, params: parseParams(args.params, loader.params) }),
      ),
    );

    const params = parseParams(args.params, loader.params);

    const loaderArgs = { params, request: args.request };

    return pipe(
      program,
      T.chainFirstIOK(result => () => {
        const redirectUrl = loader.redirect ? loader.redirect({ ...loaderArgs, result }) : null;

        if (redirectUrl) {
          throw redirect(createPath(resolvePath(redirectUrl)));
        }
      }),
      T.map(data => {
        if (isDeferData(data)) {
          return reactRouterDefer(
            pipe(
              data.data,
              R.map(value => (typeof value === 'function' ? value() : value)),
            ),
          ) as R;
        }

        return data;
      }),
    )();
  };
}

export function httpTaskToResponseTask<R>(task: HttpTask<R>): T.Task<R> {
  return pipe(
    task,
    T.chainIOK(res => () => {
      if (EI.isLeft(res)) {
        throw res.left.toResponse();
      }

      return res.right;
    }),
  );
}

export function defer<R extends Record<string, unknown>>(data: R): DeferData<R> {
  return {
    _tag: 'DeferData',
    data,
  };
}

export function isDeferData(value: unknown): value is DeferData<any> {
  return (value as any)._tag === 'DeferData';
}
