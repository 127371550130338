import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { useParentLoader } from '@core/router/loader';
import { iotLayoutLoader } from '@modules/iot/sensors/layout/IotLayout';
import { iotSensorsListLoader } from '@modules/iot/sensors/list/ListPage';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { SensorUtils } from '@modules/iot/sensors/utils';
import { renderOptional } from '@shared/utils/render';
import { Navigate, Outlet } from 'react-router-dom';

const SensorsListRedirectPage: FC = () => {
  const pending = useParentLoader<typeof iotSensorsListLoader>('iot-sensors-list');
  const { active } = useParentLoader<typeof iotLayoutLoader>('iot-layout');

  const redirect = pipe(
    active.zones,
    A.findFirstMap(({ id }) =>
      pipe(
        active.sensors,
        A.findFirst(({ zone }) => id === zone?.id),
      ),
    ),
    O.alt(() => A.head(active.sensors)),
    O.map(sensor => SensorUtils.sensorLinkBuilder(sensor)),
    O.alt(() =>
      pipe(
        A.head(pending),
        O.map(pending => SensorUtils.pendingSensorLinkBuilder(pending)),
      ),
    ),
  );

  return renderOptional(
    redirect,
    redirect => <Navigate to={redirect} replace />,
    () => <Outlet />,
  );
};

const sensorsListRedirectRoute = defineRoute({
  loader: undefined,
  component: SensorsListRedirectPage,
});

export default sensorsListRedirectRoute;
