import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { z } from 'zod';
import { User } from '@modules/users/model';
import { pipe } from 'fp-ts/function';
import { UsersService } from '@modules/users/service';
import * as TE from 'fp-ts/TaskEither';
import { defineAction, useAction } from '@core/router/action';
import { Link, useNavigate } from 'react-router-dom';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import DrawerContent from '@shared/components/drawer/DrawerContent';
import { ActionIcon, Anchor, Button, Group, Title, Tooltip } from '@mantine/core';
import Seo from '@shared/modules/seo/Seo';
import UserForm from '@modules/users/components/form/UserForm';
import { IconTrash } from '@tabler/icons-react';
import { useProfile } from '@modules/profile/loader';
import { modals } from '@mantine/modals';

const params = z.object({ id: User.Id });

const loader = defineLoader({
  params,
  handler: ({ params }) => httpTaskToResponseTask(UsersService.getUser(params.id)),
});

const actions = {
  update: defineAction({
    type: 'update',
    params,
    payload: User.UpdateParams,
    handler: ({ params, payload }) => UsersService.updateUser(params.id, payload),
    flashOptions: {
      success: () => 'Modification réalisée',
    },
  }),
  delete: defineAction({
    type: 'delete',
    params,
    handler: ({ params }) => UsersService.deleteUser(params.id),
    redirect: () => '..',
    flashOptions: {
      success: () => 'Suppression réalisée',
    },
  }),
};

const UserDetailPage: FC = () => {
  const profile = useProfile();

  const user = useLoader<typeof loader>();

  const navigate = useNavigate();

  const { formRef, form, handleFormSubmit } = useEnhancedForm<User.CreateParams>({
    resolver: zodResolver(User.CreateParams),
    defaultValues: {
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
    },
  });

  const [loading, update] = useAction(actions.update);
  const [deleteLoading, deleteUser] = useAction(actions.delete);

  const handleUpdateUser = (params: User.CreateParams) =>
    pipe(
      update(params),
      TE.chainIOK(() => () => navigate('/users', { replace: true })),
    );

  const canDelete = user.email !== profile.email;

  const handleDelete = () => {
    modals.openConfirmModal({
      title: <Title size="h3">Êtes vous sûr ?</Title>,
      size: 400,
      centered: true,
      children: (
        <>
          Supprimer définitivement le technicien {user.firstname} {user.lastname}.
        </>
      ),
      labels: { confirm: 'Supprimer le technicien', cancel: 'Annuler' },
      cancelProps: { variant: 'subtle', color: 'gray' },
      onConfirm: deleteUser,
    });
  };

  return (
    <DrawerContent
      top={
        <Group>
          <Title size="h3" sx={{ flex: '1 1 auto' }}>
            Modifier le profil
          </Title>

          {canDelete ? (
            <Tooltip label="Supprimer">
              <ActionIcon
                variant="light"
                c="tertiary.200"
                size={36}
                loading={deleteLoading}
                disabled={deleteLoading}
                onClick={handleDelete}
              >
                <IconTrash size={20} />
              </ActionIcon>
            </Tooltip>
          ) : null}
        </Group>
      }
      bottom={
        <Group position="right">
          <Anchor component={Link} to=".." replace size="sm" fw={600} c="dark.3">
            Annuler
          </Anchor>
          <Button loading={loading} disabled={loading} onClick={handleFormSubmit}>
            Enregistrer
          </Button>
        </Group>
      }
    >
      <Seo title="Modifier le profil" />

      <EnhancedForm ref={formRef} form={form} onSubmit={handleUpdateUser} preventLeave>
        <UserForm isEdit={true} />
      </EnhancedForm>
    </DrawerContent>
  );
};

const userDetailRoute = defineRoute({
  component: UserDetailPage,
  loader,
  actions,
});

export default userDetailRoute;
