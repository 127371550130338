import React, { FC } from 'react';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import Seo from '@shared/modules/seo/Seo';
import { Box, Card } from '@mantine/core';
import { defineAction, useAction } from '@core/router/action';
import { defineRoute, preventActionLeave, usePreserveNavigate, withKeyObserver } from '@core/router';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { sequenceS } from 'fp-ts/Apply';
import * as TE from 'fp-ts/TaskEither';
import { Outlet } from 'react-router-dom';
import { ZonesService } from '@modules/iot/zones/service';
import { SensorsService } from '@modules/iot/sensors/service';
import PendingHeader from '@modules/iot/sensors/list/components/pending/PendingHeader';
import { Sensor } from '@modules/iot/sensors/model';
import { flow } from 'fp-ts/function';
import { SensorUtils } from '@modules/iot/sensors/utils';
import { HorteeSchema } from '@modules/iot/sensors/hortee/schema';
import { HorteeService } from '@modules/iot/sensors/hortee/service';
import { Hortee } from '@modules/iot/sensors/hortee/model';
import { HorteeUtils } from '@modules/iot/sensors/hortee/utils';
import PendingForm from '@modules/iot/sensors/hortee/components/PendingForm';

const loader = defineLoader({
  id: Sensor.SensorsRouteId.HorteePending,
  params: Sensor.sensorPendingParams,
  handler: ({ params }) => {
    return httpTaskToResponseTask(
      sequenceS(TE.ApplyPar)({
        sensor: SensorsService.getPendingSensor<Sensor.Type.Hortee>(params.id),
        zones: ZonesService.getZones(),
      }),
    );
  },
});

const actions = {
  update: defineAction({
    type: 'update',
    payload: HorteeSchema.pendingSchema,
    handler: ({ payload }) => HorteeService.updatePending(payload),
    flashOptions: {
      success: () => 'Configuration réalisée',
    },
  }),
};

const PendingDetailPage: FC = () => {
  const [loading, send] = useAction(actions.update);
  const { sensor, zones } = useLoader<typeof loader>();
  const navigate = usePreserveNavigate();

  const { formRef, form } = useEnhancedForm<Hortee.PendingParams>({
    resolver: zodResolver(HorteeSchema.pendingSchema),
    defaultValues: HorteeUtils.getPendingDefaultValues(sensor),
  });

  const handleUpdate = flow(
    send,
    TE.chainIOK(
      ({ id }) =>
        () =>
          navigate(SensorUtils.sensorLinkBuilder({ type: Sensor.Type.Hortee, id }), { replace: true }),
    ),
  );

  return (
    <>
      <Box h="100%">
        <Seo title="Configuration Sinafis" />
        <PendingHeader type={sensor.type} measures={sensor.measures} />
        <Card
          p={22}
          radius={10}
          shadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
          sx={theme => ({
            '&[data-with-border]': { border: `1px solid ${theme.colors.tertiary[2]}`, borderTop: 'none' },
          })}
        >
          <EnhancedForm ref={formRef} form={form} onSubmit={handleUpdate} preventLeave>
            <PendingForm zones={zones} loading={loading} serial={sensor.serial} />
          </EnhancedForm>
        </Card>
      </Box>
      <Outlet />
    </>
  );
};

const component = withKeyObserver<typeof loader>(PendingDetailPage, 'id');

export const horteePendingRoute = defineRoute({
  component,
  actions,
  loader,
  shouldRevalidate: preventActionLeave<typeof actions>('update'),
});
