export namespace NumberUtils {
  export function formatNumber(value: number, options: Intl.NumberFormatOptions): string;
  export function formatNumber(value: number | null | undefined, options: Intl.NumberFormatOptions): string | null;
  export function formatNumber(
    value: number | null | undefined,
    { maximumFractionDigits = 0, ...options }: Intl.NumberFormatOptions = {},
  ): string | null {
    if (value != null) {
      return Intl.NumberFormat('fr-FR', { maximumFractionDigits, ...options }).format(value);
    }

    return null;
  }

  export function formatTemperature(value: number, options?: Intl.NumberFormatOptions): string;
  export function formatTemperature(
    value: number | null | undefined,
    options?: Intl.NumberFormatOptions,
  ): string | null;
  export function formatTemperature(
    value: number | null | undefined,
    options: Intl.NumberFormatOptions = {},
  ): string | null {
    const format = formatNumber(value, options);

    if (format) {
      return `${format}°`;
    }

    return null;
  }

  export function formatPercent(percent: number): string {
    return formatNumber(percent, { style: 'percent', maximumFractionDigits: 2 });
  }

  export function roundWithDigits(value: number, digits: number = 2): number {
    const decimalOffset = Math.pow(10, digits);
    return Math.round((value + Number.EPSILON) * decimalOffset) / decimalOffset;
  }

  export function formatPrice(price: number): string {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'narrowSymbol',
    }).format(price);
  }
}
