import { Threshold } from '@modules/iot/model';
import { Utils } from '@shared/utils/model';
import { Charts } from '@shared/modules/charts/model';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as NEA from 'fp-ts/NonEmptyArray';
import Percent = Utils.Percent;
import { MantineColor } from '@mantine/core';

interface RecordParams<Level extends string> {
  labels: Record<Level, string>;
  colors: Record<Level, MantineColor>;
  bg: Record<Level, MantineColor>;
}

export function mapMeasureScaleToData<Level extends string>(
  scale: Threshold.Scale<Utils.Percent, Level>,
  records: RecordParams<Level>,
): Array<Charts.Pie.Data> {
  const levels = pipe(scale.levels, A.append({ until: Percent.parse(1), level: scale.last }));

  return pipe(
    levels,
    NEA.reduce<(typeof levels)[number], Array<Charts.Pie.Data>>([], (acc, val) => [
      ...acc,
      {
        value: val.until - acc.reduce((sum, { value }) => sum + value, 0),
        name: records.labels[val.level],
        color: records.colors[val.level],
        bg: records.bg[val.level],
      },
    ]),
  );
}
