import React, { FC } from 'react';
import { AspectRatio, Box, Skeleton } from '@mantine/core';

interface DashboardPieChartPlaceholderProps {
  mah: number;
}

const DashboardPieChartPlaceholder: FC<DashboardPieChartPlaceholderProps> = ({ mah }) => {
  return (
    <Box mah={mah} h="100%" pos="relative" style={{ flex: 2, overflow: 'hidden', alignItems: 'center' }}>
      <Box h="200%" pos="relative">
        <AspectRatio
          ratio={4 / 3}
          style={{ top: '50%', transform: 'translateY(-50%)', maxWidth: ((4 * mah) / 3) * 2, margin: '0 auto' }}
        >
          <Skeleton radius="100%" />
        </AspectRatio>
      </Box>
    </Box>
  );
};

export default DashboardPieChartPlaceholder;
