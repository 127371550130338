import { Threshold } from '@modules/iot/model';
import { Utils } from '@shared/utils/model';
import { Measures } from '@shared/modules/measures/model';
import z from 'zod';
import { HorteeSchema } from '@modules/iot/sensors/hortee/schema';
import { LocalDateTime } from '@shared/modules/dates';

export namespace Hortee {
  export enum SoilType {
    Slimy = 'SLIMY',
    LoamySand = 'LOAMY_SAND',
    Clayey = 'CLAYEY',
    Sandy = 'SANDY',
  }

  export const soilTypeLabel: Record<SoilType, string> = {
    [SoilType.Slimy]: 'Limoneux',
    [SoilType.LoamySand]: 'Sablo-Limoneux',
    [SoilType.Clayey]: 'Argileux',
    [SoilType.Sandy]: 'Sableux',
  };

  export namespace Config {
    export interface Remote {
      coordinates: Utils.GPSCoordinates;
    }
  }

  export interface Config {
    soilType: SoilType;
    coordinates: Utils.GPSCoordinates;
    humidityThreshold: Threshold.HumidityScale;
    temperatureThreshold: Threshold.TemperatureScale;
  }

  export namespace Probe {
    export enum Identifier {
      Ground = 'ground',
    }
    export const identifierProbeAlerts: Record<Identifier, Array<Measures.Type>> = {
      [Identifier.Ground]: [Measures.Type.Humidity, Measures.Type.Battery, Measures.Type.Temperature],
    };
  }

  export type PendingParams = z.infer<typeof HorteeSchema.pendingSchema>;
  export type UpdateParams = z.infer<typeof HorteeSchema.updateSchema>;

  export namespace Humidity {
    export interface Filter {
      startDate: LocalDateTime;
      endDate: LocalDateTime;
      unit: Utils.ChronoUnit;
      soilType: SoilType;
    }
  }
}
