import { httpService, HttpTask } from '@core/http';
import { User } from '@modules/users/model';
import config from '@root/config';

export namespace UsersService {
  const URI = `${config.VITE_API_PREFIX}/users`;

  export function getUsers(): HttpTask<Array<User>> {
    return httpService.get(URI);
  }

  export function createUser(params: User.CreateParams): HttpTask<User> {
    return httpService.post(URI, params);
  }

  export function getUser(id: User.Id): HttpTask<User> {
    return httpService.get(`${URI}/${id}`);
  }

  export function updateUser(id: User.Id, params: User.UpdateParams): HttpTask<User> {
    return httpService.post(`${URI}/${id}`, { ...params, role: User.Role.Tech });
  }

  export function deleteUser(id: User.Id): HttpTask {
    return httpService.delete(`${URI}/${id}`);
  }

  export function triggerUserActivation(id: User.Id) {
    return httpService.post(`${URI}/${id}/activation/trigger`);
  }
}
