import React from 'react';
import { Threshold } from '@modules/iot/model';
import { Measures } from '@shared/modules/measures/model';
import { LastMeasuresUtils } from '@shared/modules/measures/last/utils';
import { FieldErrors, FieldValues } from 'react-hook-form/dist/types';
import { FieldPathByValue } from 'react-hook-form/dist/types/path/eager';
import { Control, useController, get } from 'react-hook-form';
import HumidityScaleEdit from '@shared/modules/threshold/HumidityScaleEdit';
import TemperatureScaleEdit from '@shared/modules/threshold/TemperatureScaleEdit';

interface ThresholdScaleControllerProps<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, ThresholdType>,
> {
  name: TPath;
  control: Control<TFieldValues>;
  type: LastMeasuresUtils.NumericMeasure;
}

type ThresholdType = Threshold.Scale<Measures.Value<LastMeasuresUtils.NumericMeasure>, Threshold.Level>;

function ThresholdScaleController<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, ThresholdType>,
>({ type, name, control }: ThresholdScaleControllerProps<TFieldValues, TPath>) {
  const { field, formState } = useController({
    name,
    control,
  });

  const errors: FieldErrors<ThresholdType> = get(formState.errors, name);

  //todo: transmit ref for focusing errors

  switch (type) {
    case Measures.Type.Humidity:
      return <HumidityScaleEdit onChange={field.onChange} values={field.value} errors={errors} />;
    case Measures.Type.Temperature:
      return <TemperatureScaleEdit onChange={field.onChange} values={field.value} errors={errors} />;
    default:
      return null;
  }
}

export default ThresholdScaleController;
