import z from 'zod';
import { Threshold } from '@modules/iot/model';
import { SensoterraSchema } from '@modules/iot/sensors/sensoterra/schema';
import { Measures } from '@shared/modules/measures/model';
import { LocalDateTime } from '@shared/modules/dates';
import { Utils } from '@shared/utils/model';

export namespace Sensoterra {
  export const Id = z.number().brand('SensoterraId');
  export type Id = z.infer<typeof Id>;

  export namespace Parameter {
    export const Id = z.coerce.number().brand('SensoterraParameterId');
    export type Id = z.infer<typeof Id>;

    export const Key = z.string().min(1).brand('SensoterraParameterKey');
    export type Key = z.infer<typeof Key>;

    export enum Type {
      Soil = 'SOIL',
      Depth = 'DEPTH',
    }
  }

  export interface Parameter {
    id: Parameter.Id;
    type: Parameter.Type;
    key: Parameter.Key;
    name: string;
  }

  export namespace Config {
    export interface Remote {
      id: Sensoterra.Id;
      soilType: Parameter.Key;
      depth: Parameter.Key;
    }
  }

  export interface Config {
    id: Sensoterra.Id;
    soilType: Parameter.Key;
    depth: Parameter.Key;
    humidityThreshold: Threshold.HumidityScale;
  }

  export namespace Probe {
    export enum Identifier {
      Ground = 'ground',
    }

    export const identifierProbeAlerts: Record<Identifier, Array<Measures.Type>> = {
      [Identifier.Ground]: [Measures.Type.Humidity, Measures.Type.Battery],
    };
  }

  export type PendingParams = z.infer<typeof SensoterraSchema.pendingSchema>;
  export type UpdateParams = z.infer<typeof SensoterraSchema.updateSchema>;
  export interface Parameter {
    id: Sensoterra.Parameter.Id;
    type: Sensoterra.Parameter.Type;
    key: Sensoterra.Parameter.Key;
    name: string;
  }

  export namespace Humidity {
    export interface Filter {
      startDate: LocalDateTime;
      endDate: LocalDateTime;
      unit: Utils.ChronoUnit;
      soilType: Sensoterra.Parameter.Key;
    }
  }
}
