import React, { FC } from 'react';
import { Button, Stack } from '@mantine/core';

import placeholderImage from '@assets/images/alerts-triggers-placeholder.svg';
import { Link } from 'react-router-dom';

const AlertTriggersPlaceholder: FC = () => {
  return (
    <Stack align="center">
      <img width={626} height={440} src={placeholderImage} alt="Placeholder alertes" />

      <Button component={Link} to="new">
        Créer une alerte
      </Button>
    </Stack>
  );
};

export default AlertTriggersPlaceholder;
