import React, { FC } from 'react';
import { Card, Text } from '@mantine/core';
import { LocalDateTime } from '@shared/modules/dates';

interface ReportDateCardProps {
  reportedAt: LocalDateTime;
}

const ReportDateCard: FC<ReportDateCardProps> = ({ reportedAt }) => {
  return (
    <Card withBorder={false} bg="tertiary.2" p={9} radius={8} w="fit-content">
      <Text weight={600} size={12} lh={1.55} color="dark.2">
        Date de création :{' '}
        <Text component="span" color="dark.8">
          {reportedAt}
        </Text>
      </Text>
    </Card>
  );
};

export default ReportDateCard;
