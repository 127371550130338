import React, { createElement, FC, PropsWithChildren } from 'react';
import { Card, Group, Stack, Text, ThemeIcon } from '@mantine/core';

interface ThresholdScaleCardProps {
  title: string;
  icon: FC<React.ComponentProps<'svg'> & { title?: string }>;
}

const ThresholdScaleCard: FC<PropsWithChildren<ThresholdScaleCardProps>> = ({ children, title, icon }) => {
  return (
    <Card px={14} py={10} withBorder={false} sx={theme => ({ border: `1px solid ${theme.colors.tertiary[2]}` })}>
      <Group spacing={4} pb={10} mb={20} sx={theme => ({ borderBottom: `1px solid ${theme.colors.tertiary[2]}` })}>
        <ThemeIcon size={22} bg="transparent" c="dark.1">
          {createElement(icon)}
        </ThemeIcon>
        <Text color="dark.1" size={12} weight={600}>
          {title}
        </Text>
      </Group>
      <Stack spacing={30}>{children}</Stack>
    </Card>
  );
};

export default ThresholdScaleCard;
