import { Utils } from '@shared/utils/model';
import z from 'zod';
import { MantineColor } from '@mantine/core';
import { ordFromOrdering } from '@shared/utils/order';

export namespace Threshold {
  export enum Level {
    None = 'none',
    Alert = 'alert',
    Critical = 'critical',
  }

  export const levelOrdering: Record<Level, number> = {
    [Level.None]: 0,
    [Level.Alert]: 1,
    [Level.Critical]: 3,
  };

  export const levelOrd = ordFromOrdering(levelOrdering);

  export const levelColor: Record<Level, MantineColor> = {
    [Level.None]: 'green',
    [Level.Alert]: 'orange',
    [Level.Critical]: 'red',
  };

  export const levelTitle: Record<Level, string> = {
    // Normalement ce label n'est jamais utilisé
    [Level.None]: 'None',
    [Level.Alert]: 'Moyen',
    [Level.Critical]: 'Critique',
  };

  interface Limit<Value, Level> {
    until: Value;
    level: Level;
  }

  export interface Scale<Value extends number, Level> {
    levels: Array<Limit<Value, Level>>;
    last: Level;
  }

  export type HumidityScale = Scale<Utils.Percent, Level>;
  export type TemperatureScale = Scale<Utils.Temperature, Level>;
}

export namespace HubApi {
  export const SensorId = z.coerce.number().brand('HubApiSensorId');
  export type SensorId = z.infer<typeof SensorId>;
}
