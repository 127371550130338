import React, { FC, useMemo } from 'react';
import { Disease as DiseaseModel, Disease } from '@shared/modules/disease/model';
import { Badge, Box, Card, Divider, Group, Spoiler, Stack, Text, ThemeIcon } from '@mantine/core';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as R from 'fp-ts/Record';
import background from '@assets/images/disease-background.jpg';
import { ReactComponent as BullhornIcon } from '@assets/icons/bullhorn.svg';
import { renderNullable } from '@shared/utils/render';
import * as M from 'fp-ts/Monoid';
import { DateFormat, formatDate, LocalDateTime, parseDate } from '@shared/modules/dates';
import * as Styled from './DiseasePrevisions.styles';
import { isToday } from 'date-fns';
import { Geo } from '@shared/modules/geo/model';

interface DiseasePrevisionsProps {
  main: DiseaseModel.Forecast;
  days: Array<DiseaseModel.DayForecast>;
  location: Geo.City | null;
  formatStr: string;
  date?: LocalDateTime;
  vertical?: boolean;
  withoutIcon?: boolean;
}

interface DateForecast extends Disease.Forecast.Value {
  date: Date;
}

const mergeForecastMonoid: M.Monoid<Record<Disease.Model, Array<DateForecast>>> = R.getUnionMonoid({
  concat: (x, y) => [...x, ...y],
});

const DiseasePrevisions: FC<DiseasePrevisionsProps> = ({
  main,
  days,
  location,
  date,
  vertical,
  withoutIcon,
  formatStr,
}) => {
  const forthcoming = useMemo(
    () =>
      pipe(
        days,
        A.filterMap(({ date, values }) =>
          pipe(
            parseDate(date),
            O.map(date =>
              pipe(
                values,
                R.filterMap(O.fromNullable),
                R.map(value => [{ ...value, date }]),
              ),
            ),
          ),
        ),
        M.concatAll(mergeForecastMonoid),
        R.toEntries,
      ),
    [days],
  );

  const today: Record<Disease.Model, Disease.Forecast.Value> = R.filterMap(O.fromNullable)(main);

  return (
    <Styled.DiseasePrevisionsContainer $vertical={vertical}>
      <Card
        shadow="0 4px 14px rgba(0, 0, 0, 0.1)"
        radius={8}
        p="20px 25px"
        withBorder={false}
        sx={{
          gridColumn: vertical ? 'span 2' : 'span 3',
          background: `url(${background}) no-repeat bottom center`,
          backgroundSize: 'cover',
        }}
      >
        <Group position="apart" noWrap>
          <Box>
            {renderNullable(location, location => (
              <Text color="white" size={26} weight={700} lh={1.35}>
                {location.city}, {location.postalCode}
              </Text>
            ))}

            <Text color="white" size={14} mt={2}>
              {pipe(
                parseDate(date, DateFormat.LocalDateTime),
                O.map(date => formatDate(date, 'dd LLLL yyyy')),
                O.getOrElse(() => "Aujourd'hui"),
              )}
            </Text>
          </Box>

          {!withoutIcon && (
            <ThemeIcon size={72} bg="transparent">
              <BullhornIcon />
            </ThemeIcon>
          )}
        </Group>

        <Card p={8} radius={8} bg="rgba(255, 255, 255, 0.3)" withBorder={false} w="100%" mt={25}>
          <Spoiler
            maxHeight={110}
            showLabel={
              <Badge color="tertiary.3" mt={12}>
                <Text weight={400} color="tertiary.5" size={12} lh={1}>
                  +{R.keys(today).length} indice(s)
                </Text>
              </Badge>
            }
            hideLabel={
              <Badge color="tertiary.3" mt={12}>
                <Text weight={400} color="tertiary.5" size={12} lh={1}>
                  Voir moins
                </Text>
              </Badge>
            }
          >
            <Stack spacing={6}>
              {pipe(
                R.toEntries(today),
                A.map(([type, entry]) => (
                  <Group key={type} spacing={8}>
                    <ThemeIcon
                      color={entry.level ? Disease.Level.color[entry.level] : Disease.Level.nullableColor}
                      size={22}
                      radius="50%"
                      sx={{ border: '1px solid white' }}
                    >
                      &nbsp;
                    </ThemeIcon>
                    <Text color="white" weight={700} size={12}>
                      {Disease.modelLabel[type]}
                    </Text>
                  </Group>
                )),
              )}
            </Stack>
          </Spoiler>
        </Card>
      </Card>

      {forthcoming.map(([type, forecast]) => (
        <Card
          key={type}
          px={12}
          py={14}
          shadow="0px 4px 14px rgba(0, 0, 0, 0.1)"
          sx={theme => ({ '&[data-with-border]': { border: `1px solid ${theme.colors.tertiary[2]}` } })}
        >
          <Text color="tertiary.5" weight={700} size={12} pb={5}>
            {Disease.modelLabel[type]}
          </Text>
          <Stack mt={10} spacing={10}>
            {forecast.map(({ date, level, score }, index) => (
              <Box key={index}>
                <Group position="apart" noWrap>
                  <Text tt="capitalize" color="dark.1" size={12}>
                    {isToday(date) ? "Aujourd'hui" : formatDate(date, formatStr)}
                  </Text>
                  <ThemeIcon
                    color={level ? Disease.Level.color[level] : Disease.Level.nullableColor}
                    size={25}
                    radius="50%"
                  >
                    &nbsp;
                  </ThemeIcon>
                </Group>

                {index < forecast.length - 1 ? <Divider color="tertiary.2" mt={10} mx={10} /> : null}
              </Box>
            ))}
          </Stack>
        </Card>
      ))}
    </Styled.DiseasePrevisionsContainer>
  );
};

export default DiseasePrevisions;
