import React, { FC } from 'react';
import { Dashboard } from '@modules/iot/dashboard/model';
import AlertsPieChart from '@shared/modules/charts/half-pie/AlertsPieChart';
import { BoxProps } from '@mantine/core/lib/Box/Box';

interface DashboardPieChartProps {
  alerts: Dashboard.Alerts;
  mah: number;
}

const DashboardPieChart: FC<DashboardPieChartProps & BoxProps> = ({ alerts, mah, ...rest }) => {
  const total = alerts.medium + alerts.high;

  const alertsData = [
    { value: 1 - alerts.level, bg: 'secondary', active: false },
    { value: total > 0 ? (alerts.medium * alerts.level) / total : 0, bg: 'primary.3', active: true },
    { value: total > 0 ? (alerts.high * alerts.level) / total : 0, bg: 'primary', active: true },
  ].filter(({ value }) => value !== 0);

  return <AlertsPieChart mah={mah} value={alerts.level} data={alertsData} {...rest} />;
};

export default DashboardPieChart;
