import { LocalDate } from '@shared/modules/dates';

export namespace Weather {
  export type ForecastValues = {
    [Param in DayForecast.Param]?: number;
  };

  export namespace DayForecast {
    export enum Param {
      MinTemp = 'T2M_MIN',
      MaxTemp = 'T2M_MAX',
      AverageTemp = 'T2M_MEAN',
      MinHumidity = 'RH2M_MIN',
      MaxHumidity = 'RH2M_MAX',
      AverageHumidity = 'RH2M_MEAN',
      MinWind = 'WS2M_MIN',
      MaxWind = 'WS2M_MAX',
      AverageWind = 'WS2M_MEAN',
      Weather = 'SW_ID',
      ETP = 'ETP',
      Precipitation = 'PRECIP_SUM',
      MinDewPoint = 'DEWT2M_MIN',
      MaxDewPoint = 'DEWT2M_MAX',
      AverageDewPoint = 'DEWT2M_MEAN',
    }

    export const KnownParam = {
      MinTemp: Param.MinTemp,
      MaxTemp: Param.MaxTemp,
      AverageTemp: Param.AverageTemp,
      MinHumidity: Param.MinHumidity,
      MaxHumidity: Param.MaxHumidity,
      MinWind: Param.MinWind,
      MaxWind: Param.MaxWind,
      AverageWind: Param.AverageWind,
      ETP: Param.ETP,
      Precipitation: Param.Precipitation,
      AverageDewPoint: Param.AverageDewPoint,
    } satisfies Partial<{ [key in keyof typeof Param]: (typeof Param)[key] }>;

    export type KnownParam = (typeof KnownParam)[keyof typeof KnownParam];

    export const paramLabel: Record<Param, string> = {
      [Param.MinTemp]: 'T2M min',
      [Param.MaxTemp]: 'T2M max',
      [Param.AverageTemp]: 'T2M mean',
      [Param.MinHumidity]: 'RH2M min',
      [Param.MaxHumidity]: 'RH2M max',
      [Param.AverageHumidity]: 'RH2M mean',
      [Param.MinWind]: 'WS2M min',
      [Param.MaxWind]: 'WS2M max',
      [Param.AverageWind]: 'WS2M mean',
      [Param.Weather]: 'Weather',
      [Param.ETP]: 'ETP',
      [Param.Precipitation]: 'PRECIP sum',
      [Param.MinDewPoint]: 'PR min',
      [Param.MaxDewPoint]: 'PR max',
      [Param.AverageDewPoint]: 'PR',
    };

    export const paramDescription: Record<Param, string> = {
      [Param.MinTemp]: 'Température min à 2 mètres (en Degré Celsius)',
      [Param.MaxTemp]: 'Température max à 2 mètres (en Degré Celsius)',
      [Param.AverageTemp]: 'Température moy à 2 mètres (en Degré Celsius)',
      [Param.MinHumidity]: 'Humidité relative min à 2 mètres (centième de pourcentage)',
      [Param.MaxHumidity]: 'Humidité relative max à 2 mètres (centième de pourcentage)',
      [Param.AverageHumidity]: 'Humidité relative moy à 2 mètres (centième de pourcentage)',
      [Param.MinWind]: 'Vitesse du vent min à 2 mètres (en m/s)',
      [Param.MaxWind]: 'Vitesse du vent max à 2 mètres (en m/s)',
      [Param.AverageWind]: 'Vitesse du vent moy à 2 mètres (en m/s)',
      [Param.Weather]: 'Météo',
      [Param.ETP]: 'Evapotranspiration (en Mm)',
      [Param.Precipitation]: 'Cumul des précipitations pour la journée (en Mm)',
      [Param.MinDewPoint]: 'Point de rosé min (en Degré Celsius)',
      [Param.MaxDewPoint]: 'Point de rosé max (en Degré Celsius)',
      [Param.AverageDewPoint]: 'Point de rosé (en Degré Celsius)',
    };

    export const paramOrdering: Record<Param, number> = {
      [Param.Weather]: -1,
      [Param.MinTemp]: 1,
      [Param.MaxTemp]: 2,
      [Param.AverageTemp]: 3,
      [Param.MinHumidity]: 4,
      [Param.MaxHumidity]: 5,
      [Param.AverageHumidity]: 6,
      [Param.MinWind]: 7,
      [Param.MaxWind]: 8,
      [Param.AverageWind]: 9,
      [Param.ETP]: 10,
      [Param.Precipitation]: 11,
      [Param.MinDewPoint]: 12,
      [Param.MaxDewPoint]: 13,
      [Param.AverageDewPoint]: 14,
    };

    export const paramUnit: Record<Param, string> = {
      [Param.MinTemp]: '°C',
      [Param.MaxTemp]: '°C',
      [Param.AverageTemp]: '°C',
      [Param.MinHumidity]: '',
      [Param.MaxHumidity]: '',
      [Param.AverageHumidity]: '',
      [Param.MinWind]: 'm/s',
      [Param.MaxWind]: 'm/s',
      [Param.AverageWind]: 'm/s',
      [Param.Weather]: '',
      [Param.ETP]: 'mm',
      [Param.Precipitation]: 'mm',
      [Param.MinDewPoint]: '°C',
      [Param.MaxDewPoint]: '°C',
      [Param.AverageDewPoint]: '°C',
    };
  }

  export interface DayForecast {
    date: LocalDate; // LocalDate
    values: ForecastValues;
  }
}
