import { RangeCursor, VirtualizedListProps } from '@shared/modules/range';
import React, { CSSProperties, FC, Fragment, useEffect } from 'react';
import { renderOptional } from '@shared/utils/render';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Paper, Text } from '@mantine/core';
import { useThrottledCallback } from 'use-debounce';
import { PAGE_SCROLLER_ID } from '@layout/page/Page';

interface LoadingRowProps {
  index: number;
  style: CSSProperties;
  loadRow: (index: number) => void;
}

const LoadingRow: FC<LoadingRowProps> = ({ index, style, loadRow }) => {
  useEffect(() => {
    loadRow(index);
  });

  return <div style={style} />;
};

export function VirtualizedList<T>({ header, range, children, rowHeight = 55, loadPage }: VirtualizedListProps<T>) {
  const virtualizer = useVirtualizer({
    count: range.total,
    estimateSize: () => rowHeight,
    getScrollElement: () => document.getElementById(PAGE_SCROLLER_ID),
    overscan: 10,
  });

  useEffect(() => {
    virtualizer.scrollToIndex(0);
    virtualizer.measure();
  }, [range.total, virtualizer]);

  const handleLoadRow = (index: number) => {
    if (!range.has(index)) {
      loadPage(RangeCursor.fromIndex(index, range.cursorSize).toPage());
    }
  };

  // Throttle to wait state refresh
  const throttleLoadRow = useThrottledCallback(handleLoadRow, 250);

  return (
    <Paper withBorder={false} shadow="0px 4px 10px rgba(0, 0, 0, 0.1)" radius={10}>
      {header}

      {range.total === 0 ? (
        <Text py="lg" size="sm" color="gray.8" fs="italic">
          Aucun élément à afficher
        </Text>
      ) : (
        <div style={{ position: 'relative', height: virtualizer.getTotalSize(), width: '100%' }}>
          {virtualizer.getVirtualItems().map(row => {
            const style: CSSProperties = {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              minHeight: 55,
              transform: `translateY(${row.start}px)`,
            };

            return renderOptional(
              range.get(row.index),
              item => (
                <Fragment key={row.key}>
                  {children({ index: row.index, item, style, ref: virtualizer.measureElement })}
                </Fragment>
              ),
              () => <LoadingRow key={row.key} style={style} index={row.index} loadRow={throttleLoadRow} />,
            );
          })}
        </div>
      )}
    </Paper>
  );
}
