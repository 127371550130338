import React, { createElement, FC } from 'react';
import { renderOptional } from '@shared/utils/render';
import { ThemeIcon, Tooltip } from '@mantine/core';
import { Measures } from '@shared/modules/measures/model';
import * as O from 'fp-ts/Option';

interface SignalThemeIconProps {
  signal: O.Option<Measures.RealTimeMeasure.Impl<Measures.Type.Signal>>;
}

const SignalThemeIcon: FC<SignalThemeIconProps> = ({ signal }) => {
  return renderOptional(signal, ({ value }) => (
    <Tooltip label={Measures.signalStrengthLabel[value]}>
      <ThemeIcon size={31} variant="transparent" c="tertiary.8">
        {createElement(Measures.signalStrengthIcon[value], { strokeWidth: 1, size: 31 })}
      </ThemeIcon>
    </Tooltip>
  ));
};

export default SignalThemeIcon;
