import { Passwords } from './model';
import { httpService, HttpTask } from '@core/http';
import config from '@root/config';
import { OAuth } from '@core/oauth/model';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { OAuthService } from '@core/oauth/service';
import { AxiosResponse } from 'axios/index';

export namespace PasswordsService {
  const PASSWORD_RESET_URI = `${config.VITE_API_PREFIX}/password-reset`;
  const ACTIVATION_URI = `${config.VITE_API_PREFIX}/activation`;

  export function forgotPassword(params: Passwords.ForgotPasswordParams): HttpTask {
    return httpService.post(`${PASSWORD_RESET_URI}/forgot`, params);
  }

  export function getPasswordResetDetail(token: Passwords.ResetPasswordToken): HttpTask<Passwords.PasswordsDetail> {
    return httpService.get(`${PASSWORD_RESET_URI}/${token}`);
  }

  export function resetPassword(token: Passwords.ResetPasswordToken, params: Passwords.PasswordsParams): HttpTask {
    return httpService.post(`${PASSWORD_RESET_URI}/${token}`, params);
  }

  export function getActivationDetail(token: Passwords.ActivationToken): HttpTask<Passwords.PasswordsDetail> {
    return httpService.get(`${ACTIVATION_URI}/${token}`);
  }

  export function activateAccount(
    token: Passwords.ActivationToken,
    params: Passwords.PasswordsParams,
  ): HttpTask<AxiosResponse<OAuth.OAuthTokens>> {
    return pipe(
      httpService.post<OAuth.OAuthTokens>(`${ACTIVATION_URI}/${token}`, params, {}, { raw: true }),
      TE.chainFirstTaskK(({ data }) => OAuthService.saveOAuthTokensInStorage(data)),
    );
  }
}
