import React, { FC } from 'react';
import { ActiveSensor, Sensor } from '@modules/iot/sensors/model';
import { Threshold } from '@modules/iot/model';
import * as Ord from 'fp-ts/Ord';
import { Text, ThemeIcon } from '@mantine/core';

interface ActiveSensorAlertIconProps {
  alerts: ActiveSensor.Impl<Sensor.Type>['alerts'];
}

const ActiveSensorAlertIcon: FC<ActiveSensorAlertIconProps> = ({ alerts }) => {
  const alert = alerts.reduce((acc, { level }) => Ord.max(Threshold.levelOrd)(acc, level), Threshold.Level.None);
  switch (alert) {
    case Threshold.Level.Critical:
      return (
        <ThemeIcon radius="xl" size={19} color="primary">
          <Text size={12}>!</Text>
        </ThemeIcon>
      );
    case Threshold.Level.Alert:
      return (
        <ThemeIcon radius="xl" size={19} color="complementary.1">
          <Text size={12} color="primary.4">
            !
          </Text>
        </ThemeIcon>
      );
    case Threshold.Level.None:
      return null;
  }
};

export default ActiveSensorAlertIcon;
