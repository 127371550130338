import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import Page from '@layout/page/Page';
import { defer, defineLoader, httpTaskToResponseTask } from '@core/router/loader';
import { DashboardService } from '@modules/iot/dashboard/service';
import { Box, Text } from '@mantine/core';
import { pipe } from 'fp-ts/function';
import { Threshold } from '@modules/iot/model';
import Level = Threshold.Level;
import * as T from 'fp-ts/Task';
import { z } from 'zod';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import DashboardMonitoring from '@modules/iot/dashboard/components/DashboardMonitoring';
import DashboardPark from '@modules/iot/dashboard/components/DashboardPark';

const queriesSchema = z.object({
  level: z.nativeEnum(Level).nullable().default(null),
  alertLevel: z.nativeEnum(Level).nullable().default(null),
});

export const loader = defineLoader({
  handler: ({ request }) => {
    const queries = queriesSchema.parse(parseQueriesFormUrl(request.url));
    return pipe(
      defer({
        dashboard: httpTaskToResponseTask(DashboardService.getDashboard(true, queries.alertLevel)),
        zoneAlerts: httpTaskToResponseTask(DashboardService.getAlertSensors(queries.level)),
        queries,
      }),
      T.of,
    );
  },
});

const DashboardPage: FC = () => {
  return (
    <Page top="Tableau de bord Sonde(s)">
      <Box p={20}>
        <Text color="dark.5" weight={700} pb={10}>
          Mon parc de sondes
        </Text>
        <DashboardPark />

        <Text color="dark.5" weight={700} pb={10} pt={20}>
          Suivi des alertes
        </Text>
        <DashboardMonitoring />
      </Box>
    </Page>
  );
};

const iotDashboardRoute = defineRoute({
  component: DashboardPage,
  loader,
});

export default iotDashboardRoute;
