import React, { FC } from 'react';
import { useLocation, useNavigate, useOutlet } from 'react-router-dom';
import { defineRoute } from '@core/router';
import { Flex } from '@mantine/core';
import FullDrawer from '@shared/components/drawer/FullDrawer';

const ZonesLayout: FC = () => {
  const location = useLocation();

  const outlet = useOutlet();
  const navigate = useNavigate();

  const handleClose = () => navigate({ pathname: '..', search: location.search }, { replace: true });

  return (
    <FullDrawer opened={outlet != null} onClose={handleClose} width={530}>
      <Flex direction="column" px={22} py={30} h="100%">
        {outlet}
      </Flex>
    </FullDrawer>
  );
};

const zoneRoute = defineRoute({
  component: ZonesLayout,
  loader: undefined,
});

export default zoneRoute;
