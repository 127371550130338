import React, { FC, useEffect } from 'react';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { Sensor } from '@modules/iot/sensors/model';
import { SensorsService } from '@modules/iot/sensors/service';
import { defineRoute, withKeyObserver } from '@core/router';
import { Card, Stack } from '@mantine/core';
import { sequenceS } from 'fp-ts/Apply';
import * as TE from 'fp-ts/TaskEither';
import { ZonesService } from '@modules/iot/zones/service';
import { pipe } from 'fp-ts/function';
import { defineAction, useAction } from '@core/router/action';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Outlet } from 'react-router-dom';
import ActiveSensorCommonSection from '@modules/iot/sensors/list/components/ActiveSensorCommonSection';
import { Hortee } from '@modules/iot/sensors/hortee/model';
import { HorteeSchema } from '@modules/iot/sensors/hortee/schema';
import { HorteeService } from '@modules/iot/sensors/hortee/service';
import PendingForm from '@modules/iot/sensors/hortee/components/PendingForm';
import SensorMeasure from '@shared/modules/measures/SensorMeasure';
import * as Ord from 'fp-ts/Ord';
import { Threshold } from '@modules/iot/model';
import { SensorAction } from '@modules/iot/sensors/action';
import HumidityCharts from '@modules/iot/sensors/hortee/components/HumidityCharts';
import deleteSensor = SensorAction.deleteSensor;
import exportMeasures = SensorAction.exportMeasures;

const loader = defineLoader({
  id: Sensor.SensorsRouteId.HorteeDetail,
  params: Sensor.sensorDetailParams,
  handler: ({ params }) =>
    pipe(
      sequenceS(TE.ApplyPar)({
        sensor: SensorsService.getActiveSensor<Sensor.Type.Hortee>(params.id),
        zones: ZonesService.getZones(),
      }),
      httpTaskToResponseTask,
    ),
});

const actions = {
  update: defineAction({
    params: Sensor.sensorDetailParams,
    type: 'update',
    payload: HorteeSchema.updateSchema,
    handler: ({ payload, params }) => HorteeService.updateSensor(params.id, payload),
    flashOptions: {
      success: () => 'Modification réalisée',
    },
  }),
  deleteSensor,
  exportMeasures,
};

const DetailPage: FC = () => {
  const [loading, send] = useAction(actions.update);

  const { sensor, zones } = useLoader<typeof loader>();

  const { formRef, form } = useEnhancedForm<Hortee.UpdateParams>({
    resolver: zodResolver(HorteeSchema.updateSchema),
    defaultValues: {
      name: sensor.name,
      zoneId: sensor.zone?.id,
      config: {
        soilType: sensor.config.soilType,
        coordinates: {
          latitude: sensor.location?.latitude,
          longitude: sensor.location?.longitude,
        },
        humidityThreshold: sensor.config.humidityThreshold,
        temperatureThreshold: sensor.config.temperatureThreshold,
      },
      comment: sensor.comment,
    },
  });

  // observe dnd zone change
  useEffect(() => {
    form.resetField('zoneId', { defaultValue: sensor.zone?.id });
  }, [form, sensor.zone?.id]);

  const level = sensor.alerts.reduce((acc, { level }) => Ord.max(Threshold.levelOrd)(acc, level), Threshold.Level.None);

  return (
    <>
      <Stack spacing={16}>
        <ActiveSensorCommonSection sensor={sensor} zones={zones} level={level} />
        <SensorMeasure sensor={sensor} />
        <HumidityCharts sensor={sensor} defaultSoilType={form.watch('config.soilType')} />

        <Card
          p={22}
          radius={10}
          shadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
          sx={theme => ({
            '&[data-with-border]': { border: `1px solid ${theme.colors.tertiary[2]}`, borderTop: 'none' },
          })}
        >
          <EnhancedForm ref={formRef} form={form} onSubmit={send} preventLeave>
            <PendingForm serial={sensor.serial} zones={zones} loading={loading} />
          </EnhancedForm>
        </Card>
      </Stack>
      <Outlet />
    </>
  );
};

const component = withKeyObserver<typeof loader>(DetailPage, 'id');

const horteeDetailRoute = defineRoute({
  component,
  loader,
  actions,
});

export default horteeDetailRoute;
