import React, { FC, MouseEventHandler, useState } from 'react';
import { ActionIcon, Image, Modal } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { Carousel, Embla, useAnimationOffsetEffect } from '@mantine/carousel';
import { ImageMap } from '@shared/modules/images/model';

interface GalleryModalProps {
  opened: number | null;
  onClose(): void;
  images: Array<ImageMap>;
}

const TRANSITION_DURATION = 200;

const GalleryModal: FC<GalleryModalProps> = ({ opened, onClose, images }) => {
  const [embla, setEmbla] = useState<Embla | null>(null);

  useAnimationOffsetEffect(embla, TRANSITION_DURATION);
  const preventDefaultClick: MouseEventHandler = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Modal
      opened={opened !== null}
      transitionProps={{ duration: TRANSITION_DURATION }}
      onClose={onClose}
      fullScreen
      onMouseDown={preventDefaultClick}
      withCloseButton={false}
      styles={{
        body: { padding: 0 },
        content: { border: 'none !important' },
      }}
    >
      <ActionIcon pos="absolute" top={20} right={20} bg="white" onClick={onClose} style={{ zIndex: 1 }}>
        <IconX />
      </ActionIcon>
      <Carousel getEmblaApi={setEmbla} withIndicators align="center" initialSlide={opened ?? 0}>
        {images.map((image, index) => (
          <Carousel.Slide key={index}>
            <Image
              src={image.original}
              fit="contain"
              h="100vh"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              styles={{
                image: {
                  maxHeight: '100vh',
                },
              }}
            />
          </Carousel.Slide>
        ))}
      </Carousel>
    </Modal>
  );
};

export default GalleryModal;
