import React, { ChangeEventHandler, FC, PropsWithChildren } from 'react';
import { Checkbox, Chip, Divider, Group, Radio, Select, SimpleGrid, Stack, Text } from '@mantine/core';
import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import { AlertReport } from '@modules/alert-reports/model';
import * as A from 'fp-ts/Array';
import * as Ord from 'fp-ts/Ord';
import { DatePickerInput } from '@mantine/dates';
import { DateFormat, formatDate, parseDate } from '@shared/modules/dates';
import { DatesRangeValue } from '@mantine/dates/lib/types/DatePickerValue';
import * as O from 'fp-ts/Option';
import * as NEA from 'fp-ts/NonEmptyArray';
import { endOfDay } from 'date-fns';
import { useMatches } from 'react-router-dom';

const EMPTY_VAlUE = 'empty';
interface AlertReportFiltersContentProps {
  filters: AlertReport.Filter;
  applyFilters(filters: Partial<AlertReport.Filter>): void;
  categories: Array<AlertReport.Category>;
  subcategories: Array<AlertReport.SubCategory>;
  types: Array<AlertReport.Type>;
}

const FiltersContent: FC<PropsWithChildren<AlertReportFiltersContentProps>> = ({
  filters,
  applyFilters,
  children,
  categories,
  subcategories,
  types,
}) => {
  const handleCategoryChange = (categoryId: `${AlertReport.CategoryId}` | null) =>
    applyFilters({
      categoryId: categoryId ? AlertReport.CategoryId.parse(categoryId) : null,
      subCategoryId: null,
      type: null,
      gravity: null,
    });

  const handleSubCategoryChange = (subCategoryId: `${AlertReport.SubCategoryId}` | null) =>
    applyFilters({
      subCategoryId: subCategoryId ? AlertReport.SubCategoryId.parse(subCategoryId) : null,
      type: null,
      gravity: null,
    });
  const handleTypeChange = (typeId: `${AlertReport.TypeId}` | null) =>
    applyFilters({ typeId: typeId ? AlertReport.TypeId.parse(typeId) : null });

  const handleGravityChange = (gravity: `${AlertReport.Gravity}` | null) =>
    applyFilters({ gravity: gravity ? AlertReport.Gravity.parse(gravity) : null });

  const handlePeriodChange = (period: AlertReport.Period | typeof EMPTY_VAlUE) =>
    applyFilters({
      period: period === EMPTY_VAlUE ? null : period,
      personalizedStartDate: null,
      personalizedEndDate: null,
    });

  const handleStatusChange = (status: AlertReport.Status | typeof EMPTY_VAlUE) =>
    applyFilters({ status: status === EMPTY_VAlUE ? null : status });

  const handleBooleanChange =
    (field: keyof AlertReport.Filter): ChangeEventHandler<HTMLInputElement> =>
    e =>
      applyFilters({ [field]: e.currentTarget.checked ? e.currentTarget.checked : null });

  const handleDatesChange = ([start, end]: DatesRangeValue) =>
    applyFilters({
      personalizedStartDate: formatDate(start, DateFormat.LocalDateTime),
      personalizedEndDate: end ? formatDate(endOfDay(end), DateFormat.LocalDateTime) : null,
    });

  const gravityData = pipe(
    O.fromNullable(filters.subCategoryId),
    O.chain(id =>
      pipe(
        subcategories,
        A.findFirst(sub => sub.id === id),
      ),
    ),
    O.fold(
      () => [],
      ({ maxGravity }) =>
        pipe(
          NEA.range(0, maxGravity),
          A.map(gravity => ({ value: `${gravity}`, label: `${gravity}` })),
        ),
    ),
  );

  const matches = useMatches();

  const isAnalysisFilters = matches.some(({ id }) => id.includes('analysis'));

  return (
    <Stack spacing={12}>
      <SimpleGrid cols={2}>
        <Select
          label="Catégorie"
          placeholder="Sélectionner"
          data={categories.map(({ id, label }) => ({ value: `${id}`, label }))}
          onChange={handleCategoryChange}
          value={filters.categoryId ? `${filters.categoryId}` : null}
          clearable
        />
        <Select
          label="Sous catégorie"
          placeholder="Sélectionner"
          data={subcategories.map(({ id, label }) => ({ value: `${id}`, label }))}
          onChange={handleSubCategoryChange}
          value={filters.subCategoryId ? `${filters.subCategoryId}` : null}
          clearable
          disabled={!filters.categoryId}
        />
      </SimpleGrid>
      <Select
        label="Type de signalement"
        placeholder="Sélectionner"
        data={types.map(({ id, label }) => ({ value: `${id}`, label }))}
        onChange={handleTypeChange}
        value={filters.typeId ? `${filters.typeId}` : null}
        clearable
        disabled={!filters.subCategoryId}
      />
      <Select
        label="Niveau de gravité"
        placeholder="Sélectionner"
        data={gravityData}
        onChange={handleGravityChange}
        value={filters.gravity !== null ? `${filters.gravity}` : null}
        clearable
        disabled={!filters.subCategoryId}
      />
      <Divider my={8} color="gray.3" />
      {!isAnalysisFilters && (
        <>
          <Text color="dark.5" size={12} weight={600} lh={1.55}>
            Période
          </Text>
          <Chip.Group onChange={handlePeriodChange} value={filters.period ?? EMPTY_VAlUE}>
            <Group spacing={10}>
              <Chip value={EMPTY_VAlUE}>Aucun</Chip>
              {pipe(
                R.toEntries(AlertReport.periodLabel),
                A.sort(Ord.tuple(AlertReport.periodOrd, Ord.trivial)),
                A.map(([period, label]) => (
                  <Chip key={period} value={period}>
                    {label}
                  </Chip>
                )),
              )}
            </Group>
          </Chip.Group>
          {filters.period === AlertReport.Period.Personalized && (
            <DatePickerInput
              type="range"
              placeholder="Sélectionner"
              allowSingleDateInRange
              mt={8}
              onChange={handleDatesChange}
              value={[
                parseDate(filters.personalizedStartDate, DateFormat.LocalDateTime, null),
                parseDate(filters.personalizedEndDate, DateFormat.LocalDateTime, null),
              ]}
            />
          )}
          <Divider my={8} color="gray.3" />
        </>
      )}

      <Group spacing={20}>
        {!isAnalysisFilters && (
          <Checkbox
            label={
              <Text weight={600} color="dark.5" size={14} lh={1.55}>
                Mes signalements
              </Text>
            }
            checked={!!filters.myReports}
            onChange={handleBooleanChange('myReports')}
          />
        )}
        <Checkbox
          label={
            <Text weight={600} color="dark.5" size={14} lh={1.55}>
              Avec photos
            </Text>
          }
          checked={!!filters.hasPictures}
          onChange={handleBooleanChange('hasPictures')}
        />
        <Checkbox
          label={
            <Text weight={600} color="dark.5" size={14} lh={1.55}>
              Uniquement privé
            </Text>
          }
          checked={!!filters.privateOnly}
          onChange={handleBooleanChange('privateOnly')}
        />
      </Group>
      <Divider my={8} color="gray.3" />

      <Radio.Group
        label="Statut"
        onChange={handleStatusChange}
        value={filters.status ?? EMPTY_VAlUE}
        styles={theme => ({
          label: {
            fontSize: 12,
            fontWeight: 600,
            lineHeight: 1.55,
            color: theme.colors.dark[5],
            paddingBottom: 10,
          },
        })}
      >
        <Group spacing={28}>
          <Radio value={EMPTY_VAlUE} label="Tous" />
          {pipe(
            R.toEntries(AlertReport.statusLabel),
            A.map(([status, label]) => <Radio key={status} value={status} label={label} />),
          )}
        </Group>
      </Radio.Group>
      {children}
    </Stack>
  );
};

export default FiltersContent;
