import React, { FC } from 'react';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { Button, Group, Text, TextInput } from '@mantine/core';
import { useAction } from '@core/router/action';
import { zodResolver } from '@hookform/resolvers/zod';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { z } from 'zod';
import { defineRoute, usePreserveNavigate } from '@core/router';
import { AlertReportUtils } from '@modules/alert-reports/utils';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import { useLocation } from 'react-router-dom';
import { alertReportActions } from '@modules/alert-reports/filters/ListPage';

const saveAlertSchema = z.object({
  name: z.string().min(1),
});

type SaveAlertParams = z.infer<typeof saveAlertSchema>;

const SaveAlertPage: FC = () => {
  const { search } = useLocation();
  const filter = AlertReportUtils.alertReportsFilterParser(parseQueriesFormUrl(search));

  const navigate = usePreserveNavigate();
  const [loading, create] = useAction(alertReportActions.create);
  const { formRef, form, handleFormSubmit } = useEnhancedForm<SaveAlertParams>({
    resolver: zodResolver(saveAlertSchema),
  });

  const handleClose = () => navigate('..', { replace: true });
  const handleSave = ({ name }: SaveAlertParams) =>
    pipe(
      create({ name, ...filter }),
      TE.chainIOK(() => handleClose),
    );

  return (
    <EnhancedForm ref={formRef} form={form} onSubmit={handleSave} preventLeave>
      <Text color="dark.5" weight={700} size={22} pb={20}>
        Créer un filtre
      </Text>
      <TextInput {...form.register('name')} label="Nom du filtre" placeholder="Nom du filtre" required />
      <Group spacing={10} pt={30}>
        <Button onClick={handleClose} variant="subtle" c="dark.3" color="white">
          Annuler
        </Button>
        <Button onClick={handleFormSubmit} loading={loading}>
          Créer
        </Button>
      </Group>
    </EnhancedForm>
  );
};

const saveFilterRoute = defineRoute({
  component: SaveAlertPage,
  loader: undefined,
  actions: alertReportActions,
});

export default saveFilterRoute;
