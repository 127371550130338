import React, { FC } from 'react';
import { ResourceUtils } from '@shared/modules/resources/utils';
import { Editorial } from '@shared/modules/editorial/model';
import Block = Editorial.Block;
import Template = Editorial.Block.Template;
import { renderOptional } from '@shared/utils/render';
import { Box, Image } from '@mantine/core';
import * as O from 'fp-ts/Option';
import { EditorialHooks } from '@shared/modules/editorial/hooks';
import useMissingResourceLogger = EditorialHooks.useMissingResourceLogger;
import { PGImageVariant } from '@shared/modules/images/model';

interface ImageRendererProps {
  block: Block<Template.Image>;
}

const IMAGE_WIDTH = 450;

const ImageRenderer: FC<ImageRendererProps> = ({ block }) => {
  const resource = ResourceUtils.getResourceFromDetail(block.image);

  useMissingResourceLogger(block, O.isNone(resource));

  return renderOptional(resource, ({ url }) => (
    <Box py={10}>
      <Image src={url[PGImageVariant.Original]} width={IMAGE_WIDTH} mx="auto" />
    </Box>
  ));
};

export default ImageRenderer;
