import React, { FC, ReactElement } from 'react';
import { Card, Group, Stack } from '@mantine/core';
import { CardProps } from '@mantine/core/lib/Card/Card';

interface DashboardSensorsCardProps extends CardProps {
  countContent?: ReactElement;
}

const DashboardSensorsCard: FC<DashboardSensorsCardProps> = ({ countContent, ...rest }) => {
  const { children, ...cardProps } = rest;
  return (
    <Card
      component={Group}
      display="flex"
      shadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
      withBorder={false}
      align="normal"
      spacing={18}
      p={18}
      radius={10}
      noWrap
      {...cardProps}
    >
      <Card
        component={Stack}
        display="flex"
        spacing={5}
        px={17}
        pt={24}
        pb={27}
        maw={142}
        justify="center"
        align="center"
        bg="gray.0"
        withBorder={false}
        tt="uppercase"
        fw={700}
        c="dark.3"
        ta="center"
        style={{ flex: 1 }}
      >
        {countContent}
      </Card>
      {children}
    </Card>
  );
};

export default DashboardSensorsCard;
