import styled from '@emotion/styled';

export const AuthLayoutContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100%;

  ${props => props.theme.fn.smallerThan('md')} {
    grid-template-columns: 100%;
  }
`;

export const AuthLayoutLeft = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  overflow-y: auto;
`;

export const AuthLayoutLeftHeader = styled.div`
  padding-bottom: 20px;
`;

export const AuthLayoutLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
`;

export const AuthLayoutLeftContent = styled.div`
  width: 400px;
  max-width: 100%;
`;

export const AuthLayoutRight = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  ${props => props.theme.fn.smallerThan('md')} {
    display: none;
  }
`;

export const AuthLayoutBackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    object-fit: cover;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    user-select: none;
    -webkit-user-drag: none;
  }
`;

export const AuthLayoutRightContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
