import React, { FC } from 'react';
import router from 'routes';
import { HelmetProvider } from 'react-helmet-async';
import { GlobalStyles } from '@styles/global';
import MantineTheme from '@styles/mantine';
import SentryProvider from '@shared/modules/sentry/components/SentryProvider';
import { NavigationProgress } from '@mantine/nprogress';
import { RouterProvider } from 'react-router-dom';
import ApiDownIndicator from '@core/http/components/ApiDownIndicator';
import { ModalsProvider } from '@mantine/modals';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { Center, Loader } from '@mantine/core';

const App: FC = () => {
  return (
    <SentryProvider>
      <MantineTheme>
        <GlobalStyles />

        <ApiDownIndicator />

        <NavigationProgress color="primary" size={2} />

        <ModalsProvider>
          <HelmetProvider>
            <DndProvider backend={HTML5Backend}>
              <RouterProvider
                router={router}
                fallbackElement={
                  <Center h="100vh">
                    <Loader size="xl" variant="bars" color="primary.5" />
                  </Center>
                }
              />
            </DndProvider>
          </HelmetProvider>
        </ModalsProvider>
      </MantineTheme>
    </SentryProvider>
  );
};

export default App;
