import React, { ReactNode } from 'react';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { Box, BoxProps, createPolymorphicComponent, Sx, Text } from '@mantine/core';
import styled from '@emotion/styled';
import { Link, To } from 'react-router-dom';
import { VirtualizedListChildrenProps } from '@shared/modules/range';

const VirtualizedTableHeader = createPolymorphicComponent<'div', BoxProps>(styled(Box)`
  display: grid;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid ${props => props.theme.colors.gray[2]};
`);

const VirtualizedTableRow = createPolymorphicComponent<'div', BoxProps>(styled(Box)`
  display: block;
  padding: 0 20px;
  font-size: 14px;
  color: inherit;
  text-decoration: none;

  &:not(:last-child) {
    > div {
      border-bottom: 1px solid ${props => props.theme.colors.gray[2]};
    }
  }

  &.with-hover:hover {
    background: ${props => props.theme.colors.gray[0]};
  }
`);

export const VirtualizedTableRowContent = createPolymorphicComponent<'div', BoxProps>(styled(Box)`
  display: grid;
  align-items: center;
  padding: 15px 0;
  min-height: 55px;
  font-size: 14px;
`);

interface VirtualizedTableColumn<T> {
  key: Extract<keyof T, string> | string;
  label?: ReactNode;
  width?: string;
  show?: boolean;
  headerStyle?: Sx;
  style?: Sx;
  renderer?: (item: T, index: number) => ReactNode;
}

interface VirtualizedTableRowOptions<T> {
  rowLinkBuilder?: (item: T, index: number) => To | null | undefined;
  onRowClick?: (item: T, index: number) => void;
}

export function useVirtualizedTableColumns<T>(
  columns: Array<VirtualizedTableColumn<T>>,
  { rowLinkBuilder, onRowClick }: VirtualizedTableRowOptions<T> = {},
) {
  const visibleColumns = pipe(
    columns,
    A.filter(column => column.show !== false),
  );

  const gridTemplateColumns = pipe(
    visibleColumns,
    A.reduce('', (acc, column) => `${acc} minmax(0, ${column.width ?? '1fr'})`),
  ).trim();

  const header = (
    <Box px={20}>
      <VirtualizedTableHeader sx={{ gridTemplateColumns }}>
        {visibleColumns.map(column => (
          <Text size={12} fw={600} c="dark.1" key={column.key} sx={column.headerStyle}>
            {column.label}
          </Text>
        ))}
      </VirtualizedTableHeader>
    </Box>
  );

  const row = ({ item, index, style, ref }: VirtualizedListChildrenProps<T>) => {
    const to = rowLinkBuilder ? rowLinkBuilder(item, index) : null;

    const handleClick = () => {
      if (onRowClick) {
        onRowClick(item, index);
      }
    };

    const columns = visibleColumns.map(column => (
      <Box key={column.key} sx={column.style}>
        {column.renderer
          ? column.renderer(item, index)
          : typeof (item as any)[column.key] === 'string'
          ? (item as any)[column.key]
          : null}
      </Box>
    ));

    const withHover = to || onRowClick;

    return to ? (
      <VirtualizedTableRow
        ref={ref}
        data-index={index}
        className={withHover ? 'with-hover' : undefined}
        component={Link}
        to={to}
        onClick={handleClick}
        style={style}
      >
        <VirtualizedTableRowContent sx={{ gridTemplateColumns }}>{columns}</VirtualizedTableRowContent>
      </VirtualizedTableRow>
    ) : (
      <VirtualizedTableRow
        ref={ref}
        data-index={index}
        className={withHover ? 'with-hover' : undefined}
        sx={{ gridTemplateColumns }}
        style={style}
        onClick={handleClick}
      >
        <VirtualizedTableRowContent sx={{ gridTemplateColumns }}>{columns}</VirtualizedTableRowContent>
      </VirtualizedTableRow>
    );
  };

  return { header, row };
}
