import React, { FC, useEffect, useState } from 'react';
import { defineRoute } from '@core/router';
import GoogleMaps from '@shared/modules/maps/components/GoogleMaps';
import { ActionIcon, Box, Group, Text, ThemeIcon } from '@mantine/core';
import { useNavigate, useOutlet } from 'react-router-dom';
import LightDrawer from '@shared/components/drawer/LightDrawer';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { SensorsService } from '@modules/iot/sensors/service';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import Supercluster from 'supercluster';
import { SensorsMap } from '@modules/iot/sensors/map/model';
import { Maps } from '@shared/modules/maps/model';
import { CustomPointFeature, MAX_ZOOM } from '@shared/hooks/supercluster';
import IotMapClustering from '@modules/iot/sensors/map/components/IotMapClustering';
import { SensorUtils } from '@modules/iot/sensors/utils';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import { isFilterEmpty } from '@shared/modules/filter';
import { IconExclamationCircle, IconX } from '@tabler/icons-react';

const loader = defineLoader({
  handler: ({ request }) => {
    const createCluster = (map: SensorsMap.WithLocation) => {
      const cluster = new Supercluster<CustomPointFeature<SensorsMap.Marker>>({ radius: 75, maxZoom: MAX_ZOOM });

      const points: Array<Supercluster.PointFeature<CustomPointFeature<SensorsMap.Marker>>> = map.markers.map(
        marker => ({
          type: 'Feature' as const,
          properties: { cluster: false, marker },
          geometry: {
            type: 'Point' as const,
            coordinates: [marker.location.longitude, marker.location.latitude],
          },
        }),
      );

      cluster.load(points);

      return cluster;
    };

    const filters = SensorUtils.sensorsFilterParser(parseQueriesFormUrl(request.url));

    return pipe(
      SensorsService.getMapSensors(filters),
      TE.map(map => ({
        ...map,
        markers: map.markers.filter((marker): marker is SensorsMap.Marker.WithLocation => !!marker.location),
      })),
      TE.bindTo('map'),
      TE.let('supercluster', ({ map }) => createCluster(map)),
      TE.let('filters', () => filters),
      httpTaskToResponseTask,
    );
  },
});

const IotMap: FC = () => {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const {
    map: { markers },
    supercluster,
    filters,
  } = useLoader<typeof loader>();

  const showPlaceholder = !isFilterEmpty(filters) && markers.length === 0;

  const [placeholderOpen, setPlaceholderOpen] = useState(showPlaceholder);

  useEffect(() => {
    setPlaceholderOpen(!isFilterEmpty(filters) && markers.length === 0);
  }, [filters, markers.length]);

  return (
    <Box sx={{ height: '100%' }}>
      <GoogleMaps
        options={{
          ...Maps.defaultOptions,
          zoomControl: true,
          mapTypeControlOptions: {
            position: Maps.ControlPosition.RIGHT_BOTTOM,
          },
        }}
      >
        {map => <IotMapClustering map={map} markers={markers} supercluster={supercluster} />}
      </GoogleMaps>

      {showPlaceholder ? (
        <LightDrawer opened={placeholderOpen}>
          <Group spacing={17} p={12}>
            <ThemeIcon size={22} color="primary.4" radius="100%">
              <IconExclamationCircle size={14} />
            </ThemeIcon>
            <Text size={14} color="dark.5" weight={600}>
              Aucun résultat
            </Text>
            <ActionIcon onClick={() => setPlaceholderOpen(false)}>
              <IconX />
            </ActionIcon>
          </Group>
        </LightDrawer>
      ) : (
        <LightDrawer opened={outlet != null} onClose={() => navigate('/iot/sensors', { replace: true })} width={500}>
          {outlet}
        </LightDrawer>
      )}
    </Box>
  );
};

const iotMapRoute = defineRoute({
  component: IotMap,
  loader,
});

export default iotMapRoute;
