import { PendingSensor, Sensor } from '@modules/iot/sensors/model';
import { DeepPartial } from 'react-hook-form';
import { Hortee } from '@modules/iot/sensors/hortee/model';
import { Threshold } from '@modules/iot/model';

export namespace HorteeUtils {
  import Type = Sensor.Type.Hortee;
  import Level = Threshold.Level;

  export function getPendingDefaultValues(sensor: PendingSensor<Type>): DeepPartial<Hortee.PendingParams> {
    const humidityThreshold = {
      last: Level.None,
      levels: [
        { level: Level.Critical, until: 0.33 },
        { level: Level.Alert, until: 0.66 },
      ],
    };

    const temperatureThreshold = {
      last: Level.Critical,
      levels: [
        { level: Level.None, until: 10 },
        { level: Level.Alert, until: 20 },
      ],
    };
    return {
      name: '',
      technicalId: sensor.technicalId,
      config: {
        humidityThreshold,
        temperatureThreshold,
        coordinates: {
          latitude: sensor.location?.latitude,
          longitude: sensor.location?.longitude,
        },
      },
    };
  }
}
