import { defineAction } from '@core/router/action';
import { Sensor } from '@modules/iot/sensors/model';
import { SensorsService } from '@modules/iot/sensors/service';
import { SensorSchema } from '@modules/iot/sensors/schema';

export namespace SensorAction {
  export const exportMeasures = defineAction({
    params: Sensor.sensorDetailParams,
    payload: SensorSchema.exportMeasuresSchema,
    type: 'exportMeasures',
    handler: ({ params, payload: { type, ...filter } }) => SensorsService.exportMeasures(params.id, type, filter),
  });

  export const deleteSensor = defineAction({
    type: 'deleteSensor',
    payload: Sensor.Id,
    handler: ({ payload }) => SensorsService.deleteSensor(payload),
    flashOptions: {
      success: () => 'Suppression effectuée',
    },
  });
}
