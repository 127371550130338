import Nav from '@layout/nav/Nav';
import React, { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import * as Styled from './Layout.styles';
import { DebouncedLineLoader } from './loaders/line-loader/LineLoader';
import { IconIndentDecrease } from '@tabler/icons-react';
import { useLocalStorage } from '@mantine/hooks';
import { defineRoute } from '@core/router';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { LayoutService } from '@layout/service';

export const layoutLoader = defineLoader({
  id: 'layout',
  handler: () => httpTaskToResponseTask(LayoutService.getLayoutContext()),
});

const Layout: FC = () => {
  const context = useLoader<typeof layoutLoader>();

  const [navOpen, setNavOpen] = useLocalStorage({
    key: 'nav-open',
    defaultValue: true,
    getInitialValueInEffect: false,
  });

  const handleToggleNav = () => setNavOpen(old => !old);

  return (
    <Styled.LayoutContainer>
      <Nav open={navOpen} context={context} />

      <Styled.LayoutContent>
        <Suspense fallback={<DebouncedLineLoader />}>
          <Outlet />
        </Suspense>
      </Styled.LayoutContent>

      <Styled.LayoutNavButton variant="outline" size={36} bg="white" onClick={handleToggleNav} $navOpen={navOpen}>
        <IconIndentDecrease />
      </Styled.LayoutNavButton>
    </Styled.LayoutContainer>
  );
};

const layoutRoute = defineRoute({
  component: Layout,
  loader: layoutLoader,
  // Only revalidate on form submit on iot path
  shouldRevalidate: ({ formAction }) => !!formAction?.includes('/iot'),
});

export default layoutRoute;
