import { PendingSensor, Sensor } from '@modules/iot/sensors/model';
import { Utils } from '@shared/utils/model';
import * as NEA from 'fp-ts/NonEmptyArray';
import { Measures } from '@shared/modules/measures/model';
import { Threshold } from '@modules/iot/model';
import { MantineColor } from '@mantine/core';
import { ordFromOrdering } from '@shared/utils/order';

export namespace Dashboard {
  export namespace Sensors {
    export interface Pending {
      count: number;
      items: PendingSensor.List;
    }
  }
  export interface Sensors {
    total: number;
    countByType: Record<Sensor.Type, number>;
    pending: Sensors.Pending;
  }

  export interface Alerts {
    level: Utils.Percent;
    medium: number;
    high: number;
    sensors: Array<any>;
  }

  export namespace AlertSensorsZone {
    import Identifier = Sensor.Probe.Identifier;
    import Level = Threshold.Level;
    export const identifierOrdering: Record<Identifier, number> = {
      [Identifier.Transmitter]: 0,
      [Identifier.Ground]: 1,
      [Identifier.Ground1]: 2,
      [Identifier.Ground2]: 3,
      [Identifier.Leaf]: 4,
    };

    export const identifierOrd = ordFromOrdering(identifierOrdering);

    export const identifierCaption: Record<Identifier, string> = {
      [Identifier.Transmitter]: 'Transmetteur',
      [Identifier.Ground]: 'Capteur sol',
      [Identifier.Ground1]: 'Capteur sol 1',
      [Identifier.Ground2]: 'Capteur sol 2',
      [Identifier.Leaf]: 'Capteur foliaire',
    };

    export const levelColor: Record<Level, MantineColor> = {
      [Level.None]: 'green.7',
      [Level.Alert]: 'primary.4',
      [Level.Critical]: 'primary',
    };

    export const levelBg: Record<Level, MantineColor> = {
      [Level.None]: 'secondary.1',
      [Level.Alert]: 'complementary.1',
      [Level.Critical]: 'primary.1',
    };

    export interface Measure {
      measure: Measures.RealTimeMeasure;
      alert: Threshold.Level | null;
    }
    export interface Sensor<Type extends Sensor.Type> {
      id: Sensor.Id;
      name: string;
      measures: Record<Sensor.Probe<Type>, Array<Measure>>;
      type: Type;
    }
  }

  export interface AlertSensorsZone {
    name: string;
    sensors: NEA.NonEmptyArray<AlertSensorsZone.Sensor<Sensor.Type>>;
  }
}

export interface Dashboard {
  sensors: Dashboard.Sensors;
  alerts: Dashboard.Alerts;
}
