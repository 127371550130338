import React, { FC, PropsWithChildren, ReactNode, useEffect } from 'react';

import { renderNullable } from '@shared/utils/render';
import GoogleMapsOverlay from '@shared/modules/maps/components/GoogleMapsOverlay';
import { Utils } from '@shared/utils/model';

interface LocationsMapSelectionProps {
  coordinates: Utils.GPSCoordinates | null;
  changeMarkerPosition: (value: Utils.GPSCoordinates) => void;
  map: google.maps.Map;
  children: NonNullable<ReactNode>;
  disabled?: boolean;
}

const LocationMapSelection: FC<PropsWithChildren<LocationsMapSelectionProps>> = ({
  coordinates,
  changeMarkerPosition,
  map,
  children,
  disabled = false,
}) => {
  useEffect(() => {
    const handleClick = ({ latLng }: google.maps.MapMouseEvent) => {
      if (latLng && !disabled) {
        changeMarkerPosition({ latitude: latLng.lat(), longitude: latLng.lng() });
      }
    };

    const listener = google.maps.event.addListener(map, 'click', handleClick);

    return () => {
      google.maps.event.removeListener(listener);
    };
  }, [changeMarkerPosition, disabled, map]);

  useEffect(() => {
    map.setOptions({ draggableCursor: disabled ? 'grab' : 'crosshair' });
  }, [disabled, map]);

  return renderNullable(coordinates, coordinates => (
    <GoogleMapsOverlay position={{ lat: coordinates.latitude, lng: coordinates.longitude }} map={map}>
      {children}
    </GoogleMapsOverlay>
  ));
};

export default LocationMapSelection;
