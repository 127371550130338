import React, { FC, PropsWithChildren, ReactNode } from 'react';

import * as Styled from './DrawerContent.styles';
import { Box, ScrollArea } from '@mantine/core';
import { renderNullable } from '@shared/utils/render';

interface DrawerContentProps {
  top?: ReactNode;
  bottom?: ReactNode;
}

const DrawerContent: FC<PropsWithChildren<DrawerContentProps>> = ({ top, children, bottom }) => {
  return (
    <Styled.DrawerContentContainer>
      {renderNullable(top, top => (
        <Box p={20}>{top}</Box>
      ))}

      <ScrollArea sx={{ flex: '1 1 auto' }}>
        <Box p={20}>{children}</Box>
      </ScrollArea>

      {renderNullable(bottom, bottom => (
        <Box py={15} px={20}>
          {bottom}
        </Box>
      ))}
    </Styled.DrawerContentContainer>
  );
};

export default DrawerContent;
