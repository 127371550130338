import {
  Icon,
  IconBattery1,
  IconBattery2,
  IconBattery3,
  IconBattery4,
  IconBatteryOff,
  IconWifi,
  IconWifi0,
  IconWifi1,
  IconWifi2,
} from '@tabler/icons-react';
import { Utils } from '@shared/utils/model';
import { LocalDateTime, Timestamp } from '@shared/modules/dates';
import { Sensor } from '@modules/iot/sensors/model';
import { MantineColor } from '@mantine/core';
import { Threshold } from '@modules/iot/model';
import * as Ord from 'fp-ts/Ord';
import * as Eq from 'fp-ts/Eq';
import * as S from 'fp-ts/string';
import * as N from 'fp-ts/number';
import { ordFromOrdering } from '@shared/utils/order';

export namespace Measures {
  export enum SignalStrength {
    Poor = 'poor',
    Fair = 'fair',
    Good = 'good',
    Excellent = 'excellent',
  }

  export const signalStrengthLabel: Record<SignalStrength, string> = {
    [SignalStrength.Poor]: 'Faible',
    [SignalStrength.Fair]: 'Correct',
    [SignalStrength.Good]: 'Bon',
    [SignalStrength.Excellent]: 'Excellent',
  };

  export const signalStrengthIcon: Record<SignalStrength, Icon> = {
    [SignalStrength.Poor]: IconWifi0,
    [SignalStrength.Fair]: IconWifi1,
    [SignalStrength.Good]: IconWifi2,
    [SignalStrength.Excellent]: IconWifi,
  };

  const signalStrengthOrdering: Record<SignalStrength, number> = {
    [SignalStrength.Poor]: 0,
    [SignalStrength.Fair]: 1,
    [SignalStrength.Good]: 2,
    [SignalStrength.Excellent]: 3,
  };

  const signalStrengthOrd = ordFromOrdering(signalStrengthOrdering);

  export enum BatteryLevel {
    Empty = 'empty',
    Poor = 'poor',
    Fair = 'fair',
    Good = 'good',
    Excellent = 'excellent',
  }

  export const batteryBreakpoint: Record<BatteryLevel, Utils.Percent> = {
    [BatteryLevel.Empty]: Utils.Percent.parse(0.01),
    [BatteryLevel.Poor]: Utils.Percent.parse(0.25),
    [BatteryLevel.Fair]: Utils.Percent.parse(0.5),
    [BatteryLevel.Good]: Utils.Percent.parse(0.75),
    [BatteryLevel.Excellent]: Utils.Percent.parse(1),
  };

  export const batteryIcon: Record<BatteryLevel, Icon> = {
    [BatteryLevel.Empty]: IconBatteryOff,
    [BatteryLevel.Poor]: IconBattery1,
    [BatteryLevel.Fair]: IconBattery2,
    [BatteryLevel.Good]: IconBattery3,
    [BatteryLevel.Excellent]: IconBattery4,
  };

  export const batteryLabel: Record<BatteryLevel, string> = {
    [BatteryLevel.Empty]: 'Vide',
    [BatteryLevel.Poor]: 'Faible',
    [BatteryLevel.Fair]: 'Correct',
    [BatteryLevel.Good]: 'Bon',
    [BatteryLevel.Excellent]: 'Excellent',
  };

  export enum Type {
    Temperature = 'temperature',
    Humidity = 'humidity',
    Battery = 'battery',
    Nutrition = 'nutrition',
    Signal = 'signal',
  }

  export const typeTitle: Record<Type, string> = {
    [Type.Temperature]: 'Température',
    [Type.Humidity]: 'Humidité',
    [Type.Battery]: 'Batterie',
    [Type.Nutrition]: 'Conductivité',
    [Type.Signal]: 'Signal',
  };

  export const typeOrd: { [key in Type]: Ord.Ord<Value<key>> } = {
    [Type.Temperature]: Utils.temperatureOrd,
    [Type.Humidity]: Utils.percentOrd,
    [Type.Battery]: Utils.percentOrd,
    [Type.Nutrition]: N.Ord,
    [Type.Signal]: signalStrengthOrd,
  };

  export const typeEq: Eq.Eq<Type> = S.Eq;

  export type Value<Type extends Measures.Type = Measures.Type> = {
    [Type.Temperature]: Utils.Temperature;
    [Type.Humidity]: Utils.Percent;
    [Type.Battery]: Utils.Percent;
    [Type.Nutrition]: number;
    [Type.Signal]: SignalStrength;
  }[Type];

  export namespace RealTimeMeasure {
    export interface Impl<Type extends Measures.Type> {
      at: LocalDateTime;
      value: Value<Type>;
      type: Type;
    }
  }

  export type RealTimeMeasure =
    | RealTimeMeasure.Impl<Measures.Type.Humidity>
    | RealTimeMeasure.Impl<Measures.Type.Battery>
    | RealTimeMeasure.Impl<Measures.Type.Signal>
    | RealTimeMeasure.Impl<Measures.Type.Temperature>;

  export namespace Last {
    export type Impl<Type extends Sensor.Type> = Record<Sensor.Probe<Type>, Array<RealTimeMeasure>>;
  }

  export type Last = Last.Impl<Sensor.Type.Sinafis> | Last.Impl<Sensor.Type.Hortee> | Last.Impl<Sensor.Type.Sensoterra>;

  export namespace History {
    export interface Item<Value> {
      at: Timestamp;
      value: Value;
    }

    export interface Filter {
      startDate: LocalDateTime;
      endDate: LocalDateTime;
      unit: Utils.ChronoUnit;
    }

    export const Type = {
      Battery: Measures.Type.Battery,
      Temperature: Measures.Type.Temperature,
      Humidity: Measures.Type.Humidity,
      Nutrition: Measures.Type.Nutrition,
    } satisfies Partial<{ [key in keyof typeof Measures.Type]: (typeof Measures.Type)[key] }>;

    export type Type = (typeof Type)[keyof typeof Type];

    export const typeColor: Record<Type, MantineColor> = {
      [Type.Battery]: '#7950F2',
      [Type.Temperature]: '#F06595',
      [Type.Humidity]: '#22B8CF',
      [Type.Nutrition]: '#E8933F',
    };

    export interface Measure<Type extends History.Type = History.Type> {
      type: Type;
      values: Array<Item<Measures.Value<Type>>>;
    }
  }

  export type History<Type extends Sensor.Type> = Record<Sensor.Probe<Type>, Array<History.Measure>>;

  export namespace Humidity {
    export enum Scale {
      NonUsable = 'non-usable',
      TooDry = 'too-dry',
      Ideal = 'ideal',
      TooHumid = 'too-humid',
    }

    export const scaleLabel: Record<Scale, string> = {
      [Scale.NonUsable]: 'Non utilisable',
      [Scale.TooDry]: 'Trop sec',
      [Scale.Ideal]: 'Idéal',
      [Scale.TooHumid]: 'Trop humide',
    };

    export const scaleBg: Record<Scale, MantineColor> = {
      [Scale.NonUsable]: 'complementary.4',
      [Scale.TooDry]: 'complementary.1',
      [Scale.Ideal]: 'secondary.1',
      [Scale.TooHumid]: 'indigo.0',
    };

    export const scaleColor: Record<Scale, MantineColor> = {
      [Scale.NonUsable]: 'primary',
      [Scale.TooDry]: 'primary.4',
      [Scale.Ideal]: 'green.7',
      [Scale.TooHumid]: 'blue.6',
    };
  }

  export interface Humidity {
    values: Array<History.Item<Utils.Percent>>;
    scale: Threshold.Scale<Utils.Percent, Humidity.Scale>;
  }
}
