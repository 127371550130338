import { pipe } from 'fp-ts/function';
import * as Ord from 'fp-ts/Ord';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import * as Number from 'fp-ts/number';
import { sequenceT } from 'fp-ts/Apply';

export interface Orderable {
  order: number;
}

const ordByOrder = pipe(
  Number.Ord,
  Ord.contramap((item: Orderable) => item.order),
);

export function sortListByOrder<T extends Orderable>(list: Array<T>): Array<T> {
  return pipe(list, A.sort(ordByOrder));
}

export function updateOrder<T>(list: Array<T>, source: number, destination: number): Array<T> {
  return pipe(
    sequenceT(O.Apply)(A.lookup(source)(list), A.deleteAt(source)(list)),
    O.chain(([elem, list]) => A.insertAt(destination, elem)(list)),
    O.getOrElse(() => list),
  );
}

export function saveOrderInList<T extends Orderable>(list: Array<T>): Array<T> {
  return pipe(
    list,
    A.mapWithIndex((order, item) => ({ ...item, order })),
  );
}

export function ordFromOrdering<Key extends keyof any>(ordering: Record<Key, number>): Ord.Ord<Key> {
  return Ord.fromCompare((first, second) => {
    const firstOrdering = ordering[first];
    const secondOrdering = ordering[second];
    return firstOrdering < secondOrdering ? -1 : firstOrdering > secondOrdering ? 1 : 0;
  });
}
