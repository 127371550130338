import React from 'react';
import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';

import usersListRoute from '@modules/users/ListPage';
import createUserRoute from '@modules/users/CreatePage';
import userDetailRoute from '@modules/users/DetailPage';

const usersRoutes = (
  <Route path="users" {...createRoute(usersListRoute)}>
    <Route path="new" {...createRoute(createUserRoute)} />
    <Route path=":id" {...createRoute(userDetailRoute)} />
  </Route>
);

export default usersRoutes;
