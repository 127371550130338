import React, { FC, useMemo } from 'react';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Box, Button, Group, NumberInput, Select, SimpleGrid, Stack, Text, Textarea, TextInput } from '@mantine/core';
import GoogleMaps from '@shared/modules/maps/components/GoogleMaps';
import { Controller } from 'react-hook-form';
import LocationMapSelection from '@shared/modules/maps/components/LocationMapSelection';
import SensorPin from '@shared/modules/maps/components/SensorPin';
import { Sensor } from '@modules/iot/sensors/model';
import Type = Sensor.Type;
import { Utils } from '@shared/utils/model';
import { sequenceS } from 'fp-ts/Apply';
import * as O from 'fp-ts/Option';
import { useDisclosure } from '@mantine/hooks';
import { Maps } from '@shared/modules/maps/model';
import { Zone } from '@modules/iot/zones/model';
import { Measures } from '@shared/modules/measures/model';
import ThresholdScaleCard from '@shared/modules/threshold/ThresholdScaleCard';
import { pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as TO from 'fp-ts/TaskOption';
import ThresholdScaleController from '@shared/modules/threshold/ThresholdScaleController';
import { Threshold } from '@modules/iot/model';
import { Hortee } from '@modules/iot/sensors/hortee/model';
import { getDropdownOptionsFromEnumLabels } from '@shared/utils/enum';

interface PendingFormProps {
  serial: Sensor.Serial;
  zones: Array<Zone>;
  loading: boolean;
  level?: Threshold.Level;
}

const coordinatesPrecision = 14;

const PendingForm: FC<PendingFormProps> = ({ serial, zones, loading, level = Threshold.Level.None }) => {
  const [sensorCoordinatesOpen, handlers] = useDisclosure(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
    trigger,
  } = useEnhancedFormContext<Hortee.PendingParams | Hortee.UpdateParams>();

  const coordinates = watch('config.coordinates');

  const handleCoordinatesChange = (coordinates: Utils.GPSCoordinates) => setValue('config.coordinates', coordinates);

  const location = useMemo(
    () =>
      O.toNullable(
        sequenceS(O.Apply)({
          longitude: O.fromNullable(coordinates.longitude),
          latitude: O.fromNullable(coordinates.latitude),
        }),
      ),
    [coordinates.latitude, coordinates.longitude],
  );

  const handleValidateCoordinates = sensorCoordinatesOpen
    ? pipe(
        () => trigger('config.coordinates'),
        T.map(O.guard),
        TO.chainIOK(() => handlers.close),
      )
    : handlers.open;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Stack>
        <Group position="apart">
          <Text size={18} weight={700}>
            Configuration
          </Text>
          <Button type="submit" loading={loading}>
            Enregistrer
          </Button>
        </Group>

        <SimpleGrid cols={2} pt={20}>
          <Stack>
            <Text size={14}>
              <Text weight={700} component="span">
                Numéro de la sonde :
              </Text>{' '}
              {serial}
            </Text>
            <Controller
              name="zoneId"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  error={!!errors.zoneId}
                  label="Groupe"
                  data={zones.map(({ id, name }) => ({ value: id, label: name }))}
                  placeholder="Sélectionner"
                  required
                />
              )}
            />
            <TextInput
              {...register('name')}
              error={!!errors.name}
              label="Nom de la sonde"
              placeholder="Nom de la sonde"
              required
            />
            <Controller
              control={control}
              name="config.soilType"
              render={({ field }) => (
                <Select
                  {...field}
                  label="Type de sol"
                  placeholder="Sélectionner"
                  error={!!errors.config?.soilType}
                  data={getDropdownOptionsFromEnumLabels(Hortee.soilTypeLabel)}
                  required
                />
              )}
            />
          </Stack>
          <Textarea
            {...register('comment')}
            styles={{ wrapper: { height: '100%' }, input: { height: '100%' } }}
            error={!!errors.comment}
            placeholder="Commentaire"
          />
        </SimpleGrid>
        <Stack>
          <Text size={14} weight={700}>
            Seuils d'alertes
          </Text>
          <ThresholdScaleCard title="Capteur Sol" icon={Sensor.typeIcon[Sensor.Type.Hortee]}>
            <ThresholdScaleController name="config.humidityThreshold" control={control} type={Measures.Type.Humidity} />
          </ThresholdScaleCard>
          <ThresholdScaleCard title="Capteur Sol" icon={Sensor.typeIcon[Sensor.Type.Hortee]}>
            <ThresholdScaleController
              name="config.temperatureThreshold"
              control={control}
              type={Measures.Type.Temperature}
            />
          </ThresholdScaleCard>
        </Stack>

        <Stack>
          <Text weight={700} size={14}>
            Localisation de la sonde
          </Text>
          <Group>
            <Controller
              control={control}
              name="config.coordinates.latitude"
              render={({ field }) => (
                <NumberInput
                  {...field}
                  error={!!errors.config?.coordinates?.latitude}
                  label="Latitude"
                  placeholder="Latitude"
                  precision={coordinatesPrecision}
                  disabled={!sensorCoordinatesOpen}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name="config.coordinates.longitude"
              render={({ field }) => (
                <NumberInput
                  {...field}
                  error={!!errors.config?.coordinates?.longitude}
                  label="Longitude"
                  placeholder="Longitude"
                  precision={coordinatesPrecision}
                  disabled={!sensorCoordinatesOpen}
                  required
                />
              )}
            />
          </Group>

          <Box h={312} pos="relative">
            <GoogleMaps
              options={{
                ...Maps.defaultOptions,
                zoom: 15,
                center: location ? { lat: location.latitude, lng: location.longitude } : Maps.defaultOptions.center,
              }}
            >
              {map => (
                <LocationMapSelection
                  map={map}
                  coordinates={location}
                  changeMarkerPosition={handleCoordinatesChange}
                  disabled={!sensorCoordinatesOpen}
                >
                  <SensorPin type={Type.Hortee} level={level} />
                </LocationMapSelection>
              )}
            </GoogleMaps>
            <Button pos="absolute" right={18} bottom={18} onClick={handleValidateCoordinates}>
              {sensorCoordinatesOpen ? 'Valider la position' : 'Positionner ma sonde'}
            </Button>
          </Box>
        </Stack>
      </Stack>
    </form>
  );
};

export default PendingForm;
