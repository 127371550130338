import { HttpError, HttpRemoteData, HttpTask } from '@core/http/model';
import * as RD from 'fp-ts-remote-data';
import * as EI from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import isEqual from 'lodash.isequal';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { HttpFlashNotificationOptions, showFlashFromHttpResult } from '@shared/modules/notification/utils';

function useCurrentArgs<P extends unknown[]>(...args: P) {
  const [currentArgs, setCurrentArgs] = useState(args);

  useEffect(() => {
    setCurrentArgs(old => (!isEqual(old, args) ? args : old));
  }, [args]);

  return currentArgs;
}

export function useFetchTask<P extends unknown[], R, E>(
  task: (...args: P) => HttpTask<R, E>,
  ...args: P
): [HttpRemoteData<R, E>, HttpTask<R, E>, boolean, Dispatch<SetStateAction<HttpRemoteData<R, E>>>] {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<HttpRemoteData<R, E>>(RD.pending);

  const currentArgs = useCurrentArgs(...args);

  const fetchData = useCallback(() => {
    setLoading(true);

    return pipe(
      task(...currentArgs),
      T.chainIOK(res => () => {
        setLoading(false);
        setData(RD.fromEither(res));

        return res;
      }),
    )();
  }, [currentArgs, task]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [data, fetchData, loading, setData];
}

export function useFetchTaskOption<P extends unknown[], R, E>(
  task: (...args: P) => HttpTask<R, E>,
  ...args: P
): [O.Option<R>, HttpTask<R, E>, boolean, Dispatch<SetStateAction<O.Option<R>>>] {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<O.Option<R>>(O.none);

  const currentArgs = useCurrentArgs(...args);

  const fetchData = useCallback(() => {
    setLoading(true);

    return pipe(
      task(...currentArgs),
      T.chainIOK(res => () => {
        setLoading(false);
        setData(O.fromEither(res));

        return res;
      }),
    )();
  }, [currentArgs, task]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [data, fetchData, loading, setData];
}

export function useFetchTaskList<P extends unknown[], R, E>(
  task: (...args: P) => HttpTask<Array<R>, E>,
  ...args: P
): [Array<R>, HttpTask<Array<R>, E>, boolean, Dispatch<SetStateAction<Array<R>>>] {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Array<R>>([]);

  const currentArgs = useCurrentArgs(...args);

  const fetchData = useCallback(() => {
    setLoading(true);

    return pipe(
      task(...currentArgs),
      T.chainIOK(res => () => {
        setLoading(false);
        setData(EI.getOrElseW(() => [])(res));

        return res;
      }),
    )();
  }, [currentArgs, task]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [data, fetchData, loading, setData];
}

export function useSendTask<P1 extends unknown[], P2 extends unknown[], R, E>(
  task: (...args: [...P1, ...P2]) => HttpTask<R, E>,
  flashOptions?: HttpFlashNotificationOptions,
  ...args: P1
): [boolean, (...args: P2) => HttpTask<R, E>, O.Option<HttpError<E>>, boolean] {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<O.Option<HttpError<E>>>(O.none);
  const [success, setSuccess] = useState<boolean>(false);

  const debouncedSetLoading = useDebouncedCallback(setLoading, 0);

  const currentArgs = useCurrentArgs(...args);

  const flashOptionsRef = useRef(flashOptions);

  flashOptionsRef.current = flashOptions;

  const sendRequest = useCallback(
    (...args2: P2) => {
      debouncedSetLoading(true);
      setError(O.none);
      setSuccess(false);

      return pipe(
        task(...[...currentArgs, ...args2]),
        T.chainFirstIOK(res => () => {
          showFlashFromHttpResult(flashOptionsRef.current)(res);
          debouncedSetLoading(false);
          setSuccess(EI.isRight(res));
          setError(O.fromEither(EI.swap(res)));
        }),
      );
    },
    [task, currentArgs, debouncedSetLoading],
  );

  return [loading, sendRequest, error, success];
}

export function useDeleteTask<P1 extends unknown[], P2 extends unknown[], R, E>(
  task: (...args: [...P1, ...P2]) => HttpTask<R, E>,
  returnUrl?: string,
  flashOptions?: HttpFlashNotificationOptions,
  ...args: P1
): [boolean, (...args: P2) => HttpTask<R, E>, O.Option<HttpError<E>>, boolean] {
  const navigate = useNavigate();

  const [loading, send, error, success] = useSendTask(task, flashOptions, ...args);

  const handleSend = useCallback(
    (...args2: P2) =>
      pipe(
        send(...args2),
        TE.chainFirstIOK(() => () => {
          if (returnUrl) {
            navigate(returnUrl, { replace: true, state: { ignorePrevent: true } });
          }
        }),
      ),
    [navigate, send, returnUrl],
  );

  return [loading, handleSend, error, success];
}
