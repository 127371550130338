import { constFalse, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import React, { FC } from 'react';

import { HttpStatusCode } from '@core/http';
import { defineRoute } from '@core/router';
import AuthLayout from '@layout/auth/AuthLayout';
import PasswordsForm from '@modules/passwords/components/PasswordsForm';
import Seo from '@shared/modules/seo/Seo';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { z } from 'zod';
import { defineAction, useAction } from '@core/router/action';

import { Passwords } from '@modules/passwords/model';
import { PasswordsService } from '@modules/passwords/service';
import { Text } from '@mantine/core';
import { renderOptional } from '@shared/utils/render';
import * as TE from 'fp-ts/TaskEither';

const params = z.object({ token: Passwords.ActivationToken });
const loader = defineLoader({
  params,
  handler: ({ params }) =>
    pipe(
      PasswordsService.getActivationDetail(params.token),
      TE.map(O.some),
      TE.orElse(error => (error.status === HttpStatusCode.NOT_FOUND ? TE.right(O.none) : TE.left(error))),
      httpTaskToResponseTask,
    ),
});

const actions = {
  activate: defineAction({
    type: 'activate',
    params,
    payload: Passwords.PasswordParams,
    handler: ({ params, payload }) => PasswordsService.activateAccount(params.token, payload),
    flashOptions: { error: constFalse },
    redirect: ({ result }) =>
      pipe(
        O.fromEither(result),
        O.filter(({ status }) => status === HttpStatusCode.OK),
        O.map(() => '/'),
        O.toNullable,
      ),
  }),
};

const ActivationPage: FC = () => {
  const result = useLoader<typeof loader>();
  const [loading, activate, error, success] = useAction(actions.activate);

  const handleActivateAccount = ({ password }: Passwords.PasswordsBody) => activate({ password });

  const errorMessage = pipe(
    error,
    O.map(error =>
      HttpStatusCode.CONFLICT === error.status ? 'Compte déjà activé.' : 'Une erreur technique est survenue.',
    ),
  );

  return (
    <AuthLayout title="Activer mon compte">
      <Seo title="Activation" />

      {success ? (
        <Text pt="xs">
          Votre compte est désormais activé. Vous pouvez dès à présent accéder à l'application mobile Platform Garden
          sur Android et iOS
        </Text>
      ) : (
        renderOptional(
          result,
          ({ email }) => (
            <>
              <Text pt={5} pb={25} size={14} c="gray.6">
                Votre adresse mail{' '}
                <Text component="span" fw={700}>
                  {email}
                </Text>
              </Text>
              <PasswordsForm loading={loading} error={errorMessage} onSubmit={handleActivateAccount} />
            </>
          ),
          () => <Text pt="xs">Votre lien d'activation peut avoir expiré ou est déjà utilisé</Text>,
        )
      )}
    </AuthLayout>
  );
};

const activationRoute = defineRoute({
  component: ActivationPage,
  loader,
  actions,
});

export default activationRoute;
