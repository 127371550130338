import React, { ChangeEventHandler, FC, useEffect, useMemo } from 'react';
import { ActiveSensor } from '@modules/iot/sensors/model';
import { SubmitOnEnter, useEnhancedFormContext } from '@shared/modules/form';
import { AlertTrigger } from '@modules/iot/alert-triggers/model';
import { MultiSelect, Select, Switch, Text, TextInput } from '@mantine/core';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { Controller } from 'react-hook-form';
import { AlertTriggersUtils } from '@modules/iot/alert-triggers/utils';
import ThresholdSelect from '@modules/iot/components/threshold-select/ThresholdSelect';
import { Measures } from '@shared/modules/measures/model';

interface CreateAlertTriggerFormProps {
  sensors: ActiveSensor.List;
}

const CreateAlertTriggerForm: FC<CreateAlertTriggerFormProps> = ({ sensors }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
  } = useEnhancedFormContext<AlertTrigger.CreateParams>();

  const sensorsValue = watch('sensors');
  const type = watch('type');

  const types = useMemo(() => {
    const selectedSensors = pipe(
      sensorsValue ?? [],
      A.filterMap(sensorId =>
        pipe(
          sensors.sensors,
          A.findFirst(sensor => sensor.id === sensorId),
        ),
      ),
    );

    return AlertTriggersUtils.getSensorsAvailableMeasuresType(selectedSensors);
  }, [sensors.sensors, sensorsValue]);

  useEffect(() => {
    if (type && !types.includes(type)) {
      setValue('type', null as any);
    }
  }, [setValue, type, types]);

  const handleEmailNotificationChange: ChangeEventHandler<HTMLInputElement> = e =>
    setValue('notification.byEmail', e.target.checked);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <SubmitOnEnter />

      <TextInput
        id="name"
        label="Nom de l'alerte"
        placeholder="Saisir le nom de l'alerte"
        required
        error={!!errors.name}
        {...register('name')}
      />

      <Controller
        control={control}
        name="sensors"
        render={({ field }) => (
          <MultiSelect
            mt="md"
            label="Sonde(s)"
            placeholder="Sélectionner"
            required
            data={sensors.sensors.map(sensor => ({
              value: sensor.id,
              label: sensor.name,
              group: sensor.zone?.name,
            }))}
            nothingFound="Aucune sonde disponible"
            error={!!errors.sensors}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="type"
        render={({ field }) => (
          <Select
            mt="md"
            label="Type d'alerte"
            placeholder="Sélectionner"
            required
            disabled={A.isEmpty(types)}
            data={types.map(type => ({ value: type, label: Measures.typeTitle[type] }))}
            error={!!errors.type}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="thresholdLevel"
        render={({ field }) => (
          <ThresholdSelect
            mt="md"
            label="Niveau d'alerte"
            placeholder="Sélectionner"
            required
            error={!!errors.thresholdLevel}
            {...field}
          />
        )}
      />

      <Text mt="md" size="sm" fw={500} c="dark.9">
        Gérer les notifications
      </Text>

      <Switch
        mt="sm"
        label="Activer les notifications par email"
        color="primary"
        checked={watch('notification.byEmail')}
        onChange={handleEmailNotificationChange}
        error={!!errors.notification?.byEmail}
      />
    </form>
  );
};

export default CreateAlertTriggerForm;
