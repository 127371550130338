import { Utils } from '@shared/utils/model';
import { HubApi } from '@modules/iot/model';
import z from 'zod';
import { Zone } from '@modules/iot/zones/model';
import { ThresholdSchema } from '@modules/iot/schema';
import { Hortee } from '@modules/iot/sensors/hortee/model';

export namespace HorteeSchema {
  const configSchema = z.object({
    soilType: z.nativeEnum(Hortee.SoilType),
    coordinates: Utils.GPSCoordinatesSchema,
    humidityThreshold: ThresholdSchema.humidityScaleSchema,
    temperatureThreshold: ThresholdSchema.temperatureScaleSchema,
  });

  export const pendingSchema = z.object({
    technicalId: HubApi.SensorId,
    name: z.string().min(1),
    zoneId: Zone.Id,
    config: configSchema,
    comment: z.string().nullable(),
  });

  export const updateSchema = pendingSchema.omit({ technicalId: true });
}
