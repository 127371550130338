import styled from '@emotion/styled';

export const LightDrawerContainer = styled.div`
  position: fixed;
  top: 65px;
  bottom: 0;
  right: 0;
  padding: 15px;
  overflow: hidden;
`;
