import { httpService, HttpTask } from '@core/http';
import { Profile } from '@modules/profile/model';
import config from '@root/config';

export namespace ProfileService {
  const URI = `${config.VITE_API_PREFIX}/profile`;
  export function getProfile(): HttpTask<Profile> {
    return httpService.get(URI);
  }

  export function updateProfile(profile: Profile.UpdateProfileParams): HttpTask {
    return httpService.post(URI, profile);
  }
}
