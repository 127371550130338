import { Threshold } from '@modules/iot/model';
import { Utils } from '@shared/utils/model';
import z from 'zod';
import { SinafisSchema } from '@modules/iot/sensors/sinafis/schema';
import { FC } from 'react';
import * as React from 'react';
import { ReactComponent as DropletFilledIcon } from '@assets/icons/droplet-filled.svg';
import { ReactComponent as GroundMeasureIcon } from '@assets/icons/ground-measure.svg';
import { ReactComponent as SinafisSensorIcon } from '@assets/icons/sinafis-sensor.svg';
import { Measures } from '@shared/modules/measures/model';
import { ordFromOrdering } from '@shared/utils/order';

export namespace Sinafis {
  export namespace Probe {
    export enum Identifier {
      Transmitter = 'transmitter',
      Ground1 = 'ground-1',
      Ground2 = 'ground-2',
      Leaf = 'leaf',
    }

    export const identifierProbeAlerts: Record<Identifier, Array<Measures.Type>> = {
      [Identifier.Transmitter]: [Measures.Type.Humidity, Measures.Type.Battery, Measures.Type.Temperature],
      [Identifier.Ground1]: [Measures.Type.Humidity, Measures.Type.Temperature],
      [Identifier.Ground2]: [Measures.Type.Humidity, Measures.Type.Temperature],
      [Identifier.Leaf]: [Measures.Type.Humidity],
    };

    export const identifierIcon: Record<Identifier, FC<React.ComponentProps<'svg'> & { title?: string }>> = {
      [Identifier.Transmitter]: SinafisSensorIcon,
      [Identifier.Ground1]: GroundMeasureIcon,
      [Identifier.Ground2]: GroundMeasureIcon,
      [Identifier.Leaf]: DropletFilledIcon,
    };

    export const identifierLabel: Record<Identifier, string> = {
      [Identifier.Transmitter]: 'Transmetteur',
      [Identifier.Ground1]: 'Capteur sol 1',
      [Identifier.Ground2]: 'Capteur sol 2',
      [Identifier.Leaf]: 'Capteur Foliaire',
    };

    const identifierOrdering: Record<Identifier, number> = {
      [Identifier.Transmitter]: 1,
      [Identifier.Ground1]: 2,
      [Identifier.Ground2]: 3,
      [Identifier.Leaf]: 4,
    };

    export const identifierOrd = ordFromOrdering(identifierOrdering);

    export const identifierConfig = {
      [Identifier.Transmitter]: 'transmitter',
      [Identifier.Ground1]: 'ground1',
      [Identifier.Ground2]: 'ground2',
      [Identifier.Leaf]: 'leaf',
    } satisfies Record<Identifier, Config.Identifier>;
  }
  export namespace Config {
    export interface Transmitter {
      height: Utils.Length;
      humidityThreshold: Threshold.HumidityScale;
      temperatureThreshold: Threshold.TemperatureScale;
    }

    export interface Ground {
      depth: Utils.Length;
      humidityThreshold: Threshold.HumidityScale;
      temperatureThreshold: Threshold.TemperatureScale;
    }

    export interface Leaf {
      height: Utils.Length;
      humidityThreshold: Threshold.HumidityScale;
    }
    export enum ProbeMapper {
      Probe1 = 1,
      Probe2,
      Probe3,
      Probe4,
    }
    export interface MeasureProbeMapping {
      ground1: ProbeMapper | null;
      ground2: ProbeMapper | null;
      leaf: ProbeMapper | null;
    }

    export interface ExtendedMeasureProbeMapping {
      transmitter: unknown;
      ground1: unknown;
      ground2: unknown;
      leaf: unknown;
    }
    export interface Remote {
      mappings: MeasureProbeMapping;
    }

    export type Identifier = keyof Config;

    type LengthField<Id extends Identifier> = {
      [Field in keyof NonNullable<Config[Id]>]: NonNullable<Config[Id]>[Field] extends number ? Field : never;
    }[keyof NonNullable<Config[Id]>];

    export const configLengthField: {
      [Id in Identifier]: LengthField<Id>;
    } = {
      transmitter: 'height',
      ground1: 'depth',
      ground2: 'depth',
      leaf: 'height',
    };

    export const configLengthTitle: {
      [Id in Identifier as LengthField<Id>]: string;
    } = {
      depth: 'Profondeur',
      height: 'Hauteur',
    };

    const configOrdering: Record<Identifier, number> = {
      transmitter: 1,
      ground1: 2,
      ground2: 3,
      leaf: 4,
    };

    export const configIdentifier = {
      transmitter: Probe.Identifier.Transmitter,
      ground1: Probe.Identifier.Ground1,
      ground2: Probe.Identifier.Ground2,
      leaf: Probe.Identifier.Leaf,
    } satisfies Record<Identifier, Probe.Identifier>;

    export const configOrd = ordFromOrdering(configOrdering);
  }

  export interface Config {
    transmitter: Sinafis.Config.Transmitter;
    ground1: Sinafis.Config.Ground | null;
    ground2: Sinafis.Config.Ground | null;
    leaf: Sinafis.Config.Leaf | null;
  }

  export type PendingParams = z.infer<typeof SinafisSchema.pendingSchema>;
  export type UpdateParams = z.infer<typeof SinafisSchema.updateSchema>;
}
