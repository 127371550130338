import React, { FC, useState } from 'react';

import { Chip, Group, Stack, Text } from '@mantine/core';
import {
  endOfMonth,
  endOfToday,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfToday,
  startOfYear,
  subDays,
} from 'date-fns';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as IOO from 'fp-ts/IOOption';
import * as IO from 'fp-ts/IO';
import { DatePickerInput } from '@mantine/dates';
import { DatesRangeValue } from '@mantine/dates/lib/types/DatePickerValue';

interface Preset {
  value: PresetValue;
  label: string;
  dates: IO.IO<DatesRangeValue>;
}

type PresetValue = 'custom' | 'today' | 'last-7-days' | 'this-month' | 'last-30-days' | 'this-year';

const presets: Array<Preset> = [
  {
    value: 'custom',
    label: 'Personnalisé',
    dates: () => [startOfToday(), endOfToday()],
  },
  {
    value: 'today',
    label: "Aujourd'hui",
    dates: () => [startOfToday(), endOfToday()],
  },
  {
    value: 'last-7-days',
    label: '7 derniers jours',
    dates: () => [startOfDay(subDays(new Date(), 6)), endOfToday()],
  },

  {
    value: 'this-month',
    label: 'Ce mois-ci',
    dates: () => [startOfMonth(new Date()), endOfMonth(new Date())],
  },
  {
    value: 'last-30-days',
    label: '30 derniers jours',
    dates: () => [startOfDay(subDays(new Date(), 29)), endOfToday()],
  },
  {
    value: 'this-year',
    label: 'Cette année',
    dates: () => [startOfYear(new Date()), endOfYear(new Date())],
  },
];

interface RangeFilterProps {
  dateRange: DatesRangeValue;
  onDateRangeChange: (dateRange: DatesRangeValue) => void;
}

const RangeFilter: FC<RangeFilterProps> = ({ dateRange, onDateRangeChange }) => {
  const [activatePreset, setActivePreset] = useState<PresetValue>('today');

  const handlePresetChange = (value: PresetValue) =>
    pipe(
      presets,
      A.findFirst(preset => preset.value === value),
      IOO.fromOption,
      IOO.chainIOK(preset => () => {
        setActivePreset(preset.value);
        if (preset.value !== 'custom') onDateRangeChange(preset.dates());
      }),
    )();

  return (
    <Stack>
      <Stack spacing={8} pb={20} sx={theme => ({ borderBottom: `1px solid ${theme.colors.gray[1]}` })}>
        <Text color="gray.8" size={14}>
          Sélectionner une période
        </Text>
        <Group spacing={8}>
          <Chip.Group onChange={handlePresetChange} defaultValue={activatePreset ?? ''}>
            {presets.map(({ label, value }) => (
              <Chip key={value} value={value}>
                {label}
              </Chip>
            ))}
          </Chip.Group>
        </Group>
      </Stack>

      {activatePreset === 'custom' && (
        <DatePickerInput
          type="range"
          placeholder="Sélectionner"
          defaultValue={dateRange}
          onChange={onDateRangeChange}
          valueFormat="DD/MM/YYYY"
          labelSeparator="au"
          mr="auto"
          allowSingleDateInRange
        />
      )}
    </Stack>
  );
};

export default RangeFilter;
