import { useEffect } from 'react';
import { Editorial } from '@shared/modules/editorial/model';
import Block = Editorial.Block;
import { logSentryMessage } from '@shared/modules/sentry/utils';

export namespace EditorialHooks {
  export const useMissingResourceLogger = (block: Block, isMissing: boolean) => {
    useEffect(() => {
      if (isMissing) {
        const message = `Cannot render block '${block.template}', id: ${block.id}`;
        console.warn(message);
        logSentryMessage(message, 'warning');
      }
    }, [block, isMissing]);
  };
}
