import React, { FC } from 'react';
import { defineRoute, usePreserveNavigate } from '@core/router';
import { Button, Group, Text } from '@mantine/core';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { defineAction, useAction } from '@core/router/action';
import { ZonesService } from '@modules/iot/zones/service';
import { Zone } from '@modules/iot/zones/model';
import { ZoneSchema } from '@modules/iot/zones/schema';
import SaveZoneForm from '@modules/iot/zones/components/SaveZoneForm';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import PreserveSearchLink from '@core/router/components/PreserveSearchLink';

const actions = {
  create: defineAction({
    type: 'create',
    payload: ZoneSchema.saveZoneSchema,
    handler: ({ payload }) => ZonesService.createZone(payload),
    flashOptions: {
      success: () => 'Création réalisée',
    },
  }),
};

const CreateZonePage: FC = () => {
  const navigate = usePreserveNavigate();

  const [loading, create] = useAction(actions.create);

  const { formRef, form, handleFormSubmit } = useEnhancedForm<Zone.SaveParams>({
    resolver: zodResolver(ZoneSchema.saveZoneSchema),
    defaultValues: {
      name: '',
      location: null as any,
      description: '',
    },
  });

  const handleCreate = (params: Zone.SaveParams) =>
    pipe(
      create(params),
      TE.chainFirstIOK(() => () => navigate('../..', { replace: true, relative: 'path' })),
    );

  return (
    <>
      <Text weight={700} size={22} pb={50}>
        Créer un groupe
      </Text>
      <EnhancedForm ref={formRef} form={form} onSubmit={handleCreate} preventLeave>
        <SaveZoneForm />
      </EnhancedForm>
      <Group mt="auto" ml="auto" pos="sticky" pt={20} bottom={30}>
        <Button variant="white" c="dark.3" component={PreserveSearchLink} to="../..">
          Annuler
        </Button>
        <Button onClick={handleFormSubmit} loading={loading}>
          Créer
        </Button>
      </Group>
    </>
  );
};

const createZoneRoute = defineRoute({
  component: CreateZonePage,
  loader: undefined,
  actions,
});

export default createZoneRoute;
