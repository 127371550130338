import { Dashboard } from '@modules/dashboard/model';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import { Weather } from '@shared/modules/weather/model';

export namespace PrevisionsUtils {
  export function getWeatherTypeInfos(values: Weather.ForecastValues | null): O.Option<Dashboard.Weather.TypeInfo> {
    return pipe(
      O.fromNullable(values),
      O.chain(values => {
        const swId = Number(values[Weather.DayForecast.Param.Weather]).toString(16);
        return pipe(
          Object.values(Dashboard.Weather.Type),
          A.findFirst(type => swId === type),
        );
      }),
      O.map(type => Dashboard.Weather.typeInfos[type]),
    );
  }
}
