import z from 'zod';
import { emailSchema, NonEmptyString } from '@shared/schemas';

export namespace User {
  export const Id = z.coerce.number().int().positive().brand<'UserId'>();
  export type Id = z.infer<typeof Id>;

  export enum Role {
    Admin = 'admin',
    Tech = 'tech',
  }

  export const roleLabel: Record<Role, string> = {
    [Role.Admin]: 'Admin',
    [Role.Tech]: 'Technicien',
  };

  export enum ActivationState {
    Pending = 'pending',
    Activated = 'activated',
  }

  export const activateStateLabel: Record<ActivationState, string> = {
    [ActivationState.Activated]: 'Activé',
    [ActivationState.Pending]: 'En attente',
  };

  export const CreateParams = z.object({
    email: emailSchema,
    firstname: NonEmptyString,
    lastname: NonEmptyString,
  });

  export type CreateParams = z.infer<typeof CreateParams>;

  export const UpdateParams = CreateParams.omit({ email: true });
  export type UpdateParams = z.infer<typeof UpdateParams>;
}

export interface User {
  id: User.Id;
  email: string;
  firstname: string;
  lastname: string;
  role: User.Role;
  state: User.ActivationState;
}
