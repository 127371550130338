import React, { FC } from 'react';
import { Pest } from '@modules/alert-reports/pests/model';
import * as NEA from 'fp-ts/NonEmptyArray';
import { Card, Stack } from '@mantine/core';
import PestStage from '@modules/alert-reports/pests/components/PestStage';

interface AnalysisListingPestProps {
  stages: NEA.NonEmptyArray<Pest.Description.Stage>;
}

const AnalysisListingPest: FC<AnalysisListingPestProps> = ({ stages }) => {
  return (
    <Card key="pest-stages" p={10} bg="gray.0" radius={4} withBorder={false} w="fit-content">
      <Stack spacing={5}>
        {stages.map(({ name, level }, index) => (
          <PestStage key={index} name={name} level={level} />
        ))}
      </Stack>
    </Card>
  );
};

export default AnalysisListingPest;
