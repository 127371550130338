import React, { FC, useEffect } from 'react';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { z } from 'zod';
import { Sensor } from '@modules/iot/sensors/model';
import { SensorsService } from '@modules/iot/sensors/service';
import { defineRoute, withKeyObserver } from '@core/router';
import { Card, Stack } from '@mantine/core';
import { sequenceS } from 'fp-ts/Apply';
import * as TE from 'fp-ts/TaskEither';
import { ZonesService } from '@modules/iot/zones/service';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { defineAction, useAction } from '@core/router/action';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { SinafisService } from '@modules/iot/sensors/sinafis/service';
import { SinafisSchema } from '@modules/iot/sensors/sinafis/schema';
import { Sinafis } from '@modules/iot/sensors/sinafis/model';
import SensorMeasure from '@shared/modules/measures/SensorMeasure';
import PendingForm from '@modules/iot/sensors/sinafis/components/PendingForm';
import * as R from 'fp-ts/Record';
import { SinafisUtils } from '@modules/iot/sensors/sinafis/utils';
import configSchemaFromProbe = SinafisUtils.configSchemaFromProbe;
import getActiveDefaultValues = SinafisUtils.getActiveDefaultValues;
import ActiveSensorCommonSection from '@modules/iot/sensors/list/components/ActiveSensorCommonSection';
import ExtendedMeasureProbeMapping = Sinafis.Config.ExtendedMeasureProbeMapping;
import { Threshold } from '@modules/iot/model';
import * as Ord from 'fp-ts/Ord';
import { Outlet } from 'react-router-dom';
import { SensorAction } from '@modules/iot/sensors/action';
import exportMeasures = SensorAction.exportMeasures;
import deleteSensor = SensorAction.deleteSensor;

const loader = defineLoader({
  id: Sensor.SensorsRouteId.SinafisDetail,
  params: Sensor.sensorDetailParams,
  handler: ({ params }) =>
    httpTaskToResponseTask(
      sequenceS(TE.ApplyPar)({
        sensor: SensorsService.getActiveSensor<Sensor.Type.Sinafis>(params.id),
        zones: ZonesService.getZones(),
      }),
    ),
});

const actions = {
  update: defineAction({
    params: Sensor.sensorDetailParams,
    type: 'update',
    payload: SinafisSchema.updateSchema,
    handler: ({ payload, params }) => SinafisService.updateSensor(params.id, payload),
    flashOptions: {
      success: () => 'Modification réalisée',
    },
  }),
  deleteSensor,
  exportMeasures,
};

const DetailPage: FC = () => {
  const [loading, send] = useAction(actions.update);
  const { sensor, zones } = useLoader<typeof loader>();

  const filteredConfig: Record<keyof ExtendedMeasureProbeMapping, unknown> = pipe(
    sensor.config,
    R.filterMap(O.fromNullable),
  );

  const { formRef, form } = useEnhancedForm<Sinafis.UpdateParams>({
    resolver: zodResolver(SinafisSchema.updateSchema.and(z.object({ config: configSchemaFromProbe(filteredConfig) }))),
    defaultValues: getActiveDefaultValues(sensor),
  });

  // observe dnd zone change
  useEffect(() => {
    form.resetField('zoneId', { defaultValue: sensor.zone?.id });
  }, [form, sensor.zone?.id]);

  const level = sensor.alerts.reduce((acc, { level }) => Ord.max(Threshold.levelOrd)(acc, level), Threshold.Level.None);

  return (
    <>
      <Stack spacing={16}>
        <ActiveSensorCommonSection sensor={sensor} zones={zones} level={level} />
        <SensorMeasure sensor={sensor} />
        <Card
          p={22}
          radius={10}
          shadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
          sx={theme => ({
            '&[data-with-border]': { border: `1px solid ${theme.colors.tertiary[2]}`, borderTop: 'none' },
          })}
        >
          <EnhancedForm ref={formRef} form={form} onSubmit={send} preventLeave>
            <PendingForm
              serial={sensor.serial}
              location={sensor.location}
              zones={zones}
              loading={loading}
              mappings={filteredConfig}
              level={level}
            />
          </EnhancedForm>
        </Card>
      </Stack>
      <Outlet />
    </>
  );
};

const component = withKeyObserver<typeof loader>(DetailPage, 'id');

const sinafisDetailRoute = defineRoute({
  component,
  loader,
  actions,
});

export default sinafisDetailRoute;
