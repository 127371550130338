import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import Page from '@layout/page/Page';
import { Outlet } from 'react-router-dom';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { SensorsService } from '@modules/iot/sensors/service';
import IOTLayoutHeader from '@modules/iot/sensors/layout/component/header/IOTLayoutHeader';
import { SensorUtils } from '@modules/iot/sensors/utils';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import { constant, pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';

export const iotLayoutLoader = defineLoader({
  id: 'iot-layout',
  handler: ({ request }) =>
    pipe(
      TE.Do,
      TE.let('filters', constant(SensorUtils.sensorsFilterParser(parseQueriesFormUrl(request.url)))),
      TE.bind('active', ({ filters }) => SensorsService.getActiveSensors(filters)),
      httpTaskToResponseTask,
    ),
});

const IotLayout: FC = () => {
  const { active } = useLoader<typeof iotLayoutLoader>();

  return (
    <Page top={<IOTLayoutHeader active={active} />} p={0}>
      <Outlet />
    </Page>
  );
};

const iotLayoutRoute = defineRoute({
  component: IotLayout,
  loader: iotLayoutLoader,
});

export default iotLayoutRoute;
