import { Charts } from '@shared/modules/charts/model';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { parseDate } from '@shared/modules/dates';
import * as R from 'fp-ts/Record';
import { Disease as DiseaseModel, Disease } from '@shared/modules/disease/model';

export namespace DashboardUtils {
  export function getForecastData(days: Array<DiseaseModel.DayForecast>, selection: Array<Disease.Model>) {
    return pipe(
      days,
      A.filterMap(({ date, values }) =>
        pipe(
          values,
          R.filterMap(O.fromNullable),
          R.filterMapWithIndex((type: Disease.Model, { score }) => (selection.includes(type) ? O.some(score) : O.none)),
          O.some<Omit<Charts.Line.Disease.Data, 'date'>>,
          O.bind('date', () => parseDate(date)),
        ),
      ),
    );
  }
}
