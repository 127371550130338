import { HttpRange, httpService, HttpTask } from '@core/http';
import { AlertReport } from '@modules/alert-reports/model';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { downloadFile } from '@shared/utils/download';

export namespace AlertReportsService {
  export const URI = '/portal/alert-reports';

  export function getMapAlertReports(filters: AlertReport.Filter): HttpTask<Array<AlertReport.Marker>> {
    return httpService.get(`${URI}/map`, { params: filters });
  }

  export function getAlertReport(id: AlertReport.Id): HttpTask<AlertReport> {
    return httpService.get(`${URI}/${id}`);
  }

  export function exportAlertReports(): HttpTask {
    return pipe(
      httpService.post<Blob>(`${URI}/export`, undefined, { responseType: 'blob' }, { raw: true }),
      TE.chainIOK(downloadFile),
    );
  }
  export function getCounters(): HttpTask<AlertReport.Counter> {
    return httpService.get(`${URI}/counters`);
  }

  export namespace Referential {
    const URI = `${AlertReportsService.URI}/referential`;
    export function getCategories(): HttpTask<Array<AlertReport.Category>> {
      return httpService.get(`${URI}/categories`);
    }

    export function getSubCategories(id: AlertReport.CategoryId): HttpTask<Array<AlertReport.SubCategory>> {
      return httpService.get(`${URI}/categories/${id}/subcategories`);
    }

    export function getTypes(id: AlertReport.SubCategoryId): HttpTask<Array<AlertReport.Type>> {
      return httpService.get(`${URI}/subcategories/${id}/types`);
    }
  }

  export namespace Filter {
    const URI = `${AlertReportsService.URI}/filters`;

    export function getFilters(): HttpTask<Array<AlertReport.Filter.Light>> {
      return httpService.get(URI);
    }

    export function getFilter(id: AlertReport.Filter.Id): HttpTask<AlertReport.Filter.Detail> {
      return httpService.get(`${URI}/${id}`);
    }

    export function createFilter(params: AlertReport.Filter.SaveParams): HttpTask<AlertReport.Filter.Id> {
      return httpService.post(URI, params);
    }

    export function updateFilter(
      id: AlertReport.Filter.Id,
      params: AlertReport.Filter.SaveParams,
    ): HttpTask<AlertReport.Filter.Detail> {
      return httpService.post(`${URI}/${id}`, params);
    }

    export function deleteFilter(id: AlertReport.Filter.Id): HttpTask {
      return httpService.delete(`${URI}/${id}`);
    }
  }

  export namespace Analysis {
    const URI = (id: AlertReport.Id) => `${AlertReportsService.URI}/${id}/analysis`;

    export function getRange(
      page: number,
      filter: AlertReport.Filter,
    ): HttpRange<AlertReport.Range.Item, typeof filter> {
      return httpService.getRange(`${AlertReportsService.URI}/analysis`, page, filter, 20);
    }

    export function getWeather(id: AlertReport.Id): HttpTask<AlertReport.Weather> {
      return httpService.get(`${URI(id)}/weather`);
    }

    export function getDiseases(id: AlertReport.Id): HttpTask<AlertReport.Diseases> {
      return httpService.get(`${URI(id)}/diseases`);
    }

    export function getPests(id: AlertReport.Id): HttpTask<AlertReport.Pests> {
      return httpService.get(`${URI(id)}/pest`);
    }

    export function getSensors(id: AlertReport.Id): HttpTask<Array<AlertReport.Sensor>> {
      return httpService.get(`${URI(id)}/sensors`);
    }
  }
}
