import { httpService, HttpTask } from '@core/http';
import config from '@root/config';
import { Dashboard } from '@modules/dashboard/model';

export namespace DashboardService {
  const URI = `${config.VITE_API_PREFIX}/dashboard`;

  export function getContext(filter: Dashboard.Filter | null): HttpTask<Dashboard.Context> {
    return httpService.post(`${URI}/context`, { filter }, undefined, { silent: true });
  }

  export function getWeather(context: Dashboard.Context): HttpTask<Dashboard.Weather> {
    return httpService.post(`${URI}/weather`, context, undefined, { silent: true });
  }

  export function getDisease(context: Dashboard.Context): HttpTask<Dashboard.Disease> {
    return httpService.post(`${URI}/disease`, context, undefined, { silent: true });
  }

  export function getPest(context: Dashboard.Context): HttpTask<Dashboard.Pest> {
    return httpService.post(`${URI}/pest`, context, undefined, { silent: true });
  }
}
