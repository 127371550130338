import styled from '@emotion/styled';
import { createPolymorphicComponent, rem, Text, TextProps } from '@mantine/core';
import { css } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import { styledOptions } from '@styles/utils';

export const NavContainer = styled('div', styledOptions)<{ $open: boolean }>`
  position: relative;
  flex: 0 0 ${props => (props.$open ? 264 : 70)}px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #f5f4f2 0%, #fffaf7 100%);
  border-right: 1px solid ${props => props.theme.colors.tertiary[3]};
  overflow-x: hidden;
  transition: all 0.25s ease-in-out;
`;

export const NavHeader = styled.div`
  padding: 30px 14px 15px;
  overflow: hidden;

  > div {
    overflow: hidden;
  }
`;

export const NavContentWrapper = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;

  > div {
    padding: 14px;

    > div {
      overflow-x: hidden;
    }
  }
`;

export const NavContent = styled.div`
  overflow-x: hidden;
  width: 235px;
`;

export const NavGroup = styled.div``;

export const NavGroupTitle = createPolymorphicComponent<'span', TextProps & { $hidden: boolean }>(styled(
  Text,
  styledOptions,
)<{
  $hidden: boolean;
}>`
  position: relative;
  transition: color 0.15s linear;
  margin: 15px 0 5px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 50%;
    left: 0;
    width: 41px;
    height: 1px;
    background-color: transparent;
    transition: background-color 0.15s linear;
  }

  ${props =>
    props.$hidden
      ? css`
          color: transparent;

          &:after {
            background: ${props.theme.colors.tertiary[3]};
          }
        `
      : null}
`);

export const NavEntry = styled(NavLink, styledOptions)<{ $compact: boolean }>`
  display: grid;
  grid-template-columns: 26px 1fr 20px;
  grid-column-gap: 8px;
  padding: 10px 8px;
  align-items: center;
  text-decoration: none;
  margin-bottom: 8px;
  background-color: transparent;
  border-radius: 4px;
  transition: all 0.25s linear;

  > span {
    transition: color 0.25s linear;
  }

  .mantine-ThemeIcon-root {
    background-color: ${props => props.theme.fn.rgba(props.theme.colors.tertiary[3], 0.5)};
    transition: background-color 0.25s linear;

    > svg {
      color: ${props => props.theme.colors.tertiary[5]};
      transition: color 0.25s linear;
    }
  }

  &:hover {
    background-color: #fff;

    > span {
      color: ${props => props.theme.colors.dark[9]};
    }
  }

  &.active {
    background-color: #fff;

    .mantine-ThemeIcon-root {
      background-color: ${props => props.theme.colors.complementary[4]};

      > svg {
        color: ${props => props.theme.colors.primary[5]};
      }
    }

    > span {
      color: ${props => props.theme.colors.primary[5]};
    }
  }

  ${props =>
    props.$compact &&
    css`
      background-color: transparent !important;
    `}
`;

export const NavEntryCounter = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  background-color: ${props => props.theme.colors.tertiary[5]};
  line-height: 1;
  border-radius: 100%;
  font-size: ${rem(10)};
  font-weight: 600;
  color: #fff !important;
  text-align: center;
`;
