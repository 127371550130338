import { CSSProperties } from 'react';

export namespace CssSharedStyle {
  export const lineClamp: (lines: number) => CSSProperties = lines => ({
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: lines,
    WebkitBoxOrient: 'vertical',
  });
}
