import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import iotMapRoute from '@modules/iot/sensors/map/IotMap';
import iotMapDetailRoute from '@modules/iot/sensors/map/IotMapDetail';

const sensorsMapRoutes = (
  <Route path="" {...createRoute(iotMapRoute)}>
    <Route path=":id" {...createRoute(iotMapDetailRoute)} />
  </Route>
);

export default sensorsMapRoutes;
