import z from 'zod';

export namespace Resource {
  export const Id = z.string().uuid().brand('ResourceId');
  export type Id = z.infer<typeof Id>;

  interface Crop {
    aspect?: number | undefined;
    x?: number | undefined;
    y?: number | undefined;
    width?: number | undefined;
    height?: number | undefined;
  }

  export interface Strategy extends Crop {
    type: 'server' | 'template';
    source: Id | null;
  }
  export interface Detail {
    resourceId: Id | null;
    strategy: Strategy;
    // store blob url crop preview
    cropPreview?: string;
  }

  export enum PGImageVariant {
    Original = 'original',
    Bound_256x256 = 'bound_256x256',
    Bound_512x512 = 'bound_512x512',
    Fit_300x300 = 'fit_300x300',
    Cover_150x150 = 'cover_150x150',
  }

  export type Urls = Record<PGImageVariant, string>;

  export enum Category {
    Image = 'image',
    Audio = 'audio',
    Video = 'video',
    Pdf = 'pdf',
    Other = 'other',
  }

  export interface Type {
    category: Category;
    name: string;
  }
}

export interface Resource {
  id: Resource.Id;
  url: Resource.Urls;
}
