import React, { FC } from 'react';
import { Card, Stack, Text } from '@mantine/core';
import { Weather } from '@shared/modules/weather/model';
import FullDrawer from '@shared/components/drawer/FullDrawer';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import { ordFromOrdering } from '@shared/utils/order';

interface WeatherParamDescriptionDrawerProps {
  opened: boolean;
  onClose(): void;
}

const WeatherParamDescriptionDrawer: FC<WeatherParamDescriptionDrawerProps> = ({ opened, onClose }) => {
  return (
    <FullDrawer opened={opened} onClose={onClose} width={530}>
      {/*fixme: residual scroll */}
      <Stack c="dark.5" spacing={10} px={22} py={30}>
        <Text fz={22} fw={700} lh={1.4} pb={20}>
          Légende données météo
        </Text>
        {pipe(
          Object.values(Weather.DayForecast.KnownParam),
          A.sort(ordFromOrdering(Weather.DayForecast.paramOrdering)),
          A.map(param => (
            <Card key={param} lh={1.55} radius={8} bg="tertiary.2" withBorder={false}>
              <Text fw={600} fz={14}>
                {Weather.DayForecast.paramLabel[param]}
              </Text>
              <Text fw={400} fz={12}>
                {Weather.DayForecast.paramDescription[param]}
              </Text>
            </Card>
          )),
        )}
      </Stack>
    </FullDrawer>
  );
};

export default WeatherParamDescriptionDrawer;
