import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import dashboardRoute from '@modules/alert-reports/dashboard/DashboardPage';
import pestRoutes from '@modules/alert-reports/pests/routes';
import productRoutes from '@modules/alert-reports/products/routes';
import alertReportAnalysisRoutes from '@modules/alert-reports/analysis/routes';
import alertReportMapRoutes from '@modules/alert-reports/map/routes';

const alertReportRoutes = (
  <Route path="alert-reports">
    <Route index {...createRoute(dashboardRoute)} />
    {pestRoutes}
    {productRoutes}
    {alertReportAnalysisRoutes}
    {alertReportMapRoutes}
  </Route>
);

export default alertReportRoutes;
