import React, { FC } from 'react';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { z } from 'zod';
import { AlertTrigger } from '@modules/iot/alert-triggers/model';
import { AlertTriggersService } from '@modules/iot/alert-triggers/service';
import { defineRoute } from '@core/router';
import { defineAction, useAction } from '@core/router/action';
import DrawerContent from '@shared/components/drawer/DrawerContent';
import { ActionIcon, Anchor, Button, Group, Title, Tooltip } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import Seo from '@shared/modules/seo/Seo';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import UpdateAlertTriggerForm from '@modules/iot/alert-triggers/components/update/UpdateAlertTriggerForm';
import { IconTrash } from '@tabler/icons-react';
import { modals } from '@mantine/modals';

const params = z.object({ id: AlertTrigger.Id });

const loader = defineLoader({
  params,
  handler: ({ params }) => httpTaskToResponseTask(AlertTriggersService.getAlertTrigger(params.id)),
});

const actions = {
  update: defineAction({
    type: 'update',
    params,
    payload: AlertTrigger.UpdateParams,
    handler: ({ params, payload }) => AlertTriggersService.updateAlertTrigger(params.id, payload),
    flashOptions: {
      success: () => 'Modification réalisée',
    },
  }),
  delete: defineAction({
    type: 'delete',
    params,
    handler: ({ params }) => AlertTriggersService.deleteAlertTrigger(params.id),
    redirect: () => '..',
    flashOptions: {
      success: () => 'Suppression réalisée',
    },
  }),
};

const AlertTriggerDetailPage: FC = () => {
  const alertTrigger = useLoader<typeof loader>();

  const navigate = useNavigate();

  const { formRef, form, handleFormSubmit } = useEnhancedForm<AlertTrigger.UpdateParams>({
    resolver: zodResolver(AlertTrigger.UpdateParams),
    defaultValues: {
      name: alertTrigger.name,
      type: alertTrigger.type,
      thresholdLevel: alertTrigger.thresholdLevel,
      notification: alertTrigger.notification,
    },
  });

  const [loading, update] = useAction(actions.update);
  const [deleteLoading, deleteAlertTrigger] = useAction(actions.delete);

  const handleUpdateAlertTrigger = (params: AlertTrigger.UpdateParams) =>
    pipe(
      update(params),
      TE.chainIOK(() => () => navigate('..', { replace: true })),
    );

  const handleDelete = () => {
    modals.openConfirmModal({
      title: <Title size="h3">Êtes vous sûr ?</Title>,
      size: 400,
      centered: true,
      children: <>Supprimer définitivement l'alerte {alertTrigger.name}.</>,
      labels: { confirm: "Supprimer l'alerte", cancel: 'Annuler' },
      cancelProps: { variant: 'subtle', color: 'gray' },
      onConfirm: deleteAlertTrigger,
    });
  };

  return (
    <DrawerContent
      top={
        <Group>
          <Title size="h3" sx={{ flex: '1 1 auto' }}>
            Modifier l'alerte
          </Title>{' '}
          <Tooltip label="Supprimer">
            <ActionIcon
              variant="light"
              c="tertiary.200"
              size={36}
              loading={deleteLoading}
              disabled={deleteLoading}
              onClick={handleDelete}
            >
              <IconTrash size={20} />
            </ActionIcon>
          </Tooltip>
        </Group>
      }
      bottom={
        <Group position="right">
          <Anchor component={Link} to=".." replace size="sm" fw={600} c="dark.3">
            Annuler
          </Anchor>
          <Button loading={loading} disabled={loading} onClick={handleFormSubmit}>
            Enregistrer
          </Button>
        </Group>
      }
    >
      <Seo title="Modifier l'alerte" />

      <EnhancedForm ref={formRef} form={form} onSubmit={handleUpdateAlertTrigger} preventLeave>
        <UpdateAlertTriggerForm sensor={alertTrigger.sensor} />
      </EnhancedForm>
    </DrawerContent>
  );
};

const alertTriggerDetailRoute = defineRoute({
  component: AlertTriggerDetailPage,
  loader,
  actions,
});

export default alertTriggerDetailRoute;
