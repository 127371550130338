import { useEffect, useMemo, useRef, useState } from 'react';

interface ObserverRectValue {
  top: number;
  left: number;
  width: number;
  height: number;
}
interface ObserverRect {
  client: ObserverRectValue;
  scroll: ObserverRectValue;
  offset: ObserverRectValue;
}

const defaultObserverRectValue: ObserverRectValue = {
  top: 0,
  left: 0,
  width: 0,
  height: 0,
};

const defaultState: ObserverRect = {
  client: defaultObserverRectValue,
  scroll: defaultObserverRectValue,
  offset: defaultObserverRectValue,
};

// src/mantine-hooks/src/use-resize-observer/use-resize-observer.ts
export function useEnhancedResizeObserver<T extends HTMLElement>() {
  const frameID = useRef(0);
  const ref = useRef<T>(null);
  const [rect, setRect] = useState<ObserverRect>(defaultState);

  const observer = useMemo(
    () =>
      typeof window !== 'undefined'
        ? new ResizeObserver(entries => {
            const entry = entries[0];

            if (entry) {
              cancelAnimationFrame(frameID.current);

              frameID.current = requestAnimationFrame(() => {
                if (ref.current) {
                  setRect({
                    client: {
                      top: ref.current.clientTop,
                      left: ref.current.clientLeft,
                      width: ref.current.clientWidth,
                      height: ref.current.clientHeight,
                    },
                    scroll: {
                      top: ref.current.scrollTop,
                      left: ref.current.scrollLeft,
                      width: ref.current.scrollWidth,
                      height: ref.current.scrollHeight,
                    },
                    offset: {
                      top: ref.current.offsetTop,
                      left: ref.current.offsetLeft,
                      width: ref.current.offsetWidth,
                      height: ref.current.offsetHeight,
                    },
                  });
                }
              });
            }
          })
        : null,
    [],
  );

  useEffect(() => {
    if (ref.current) {
      observer?.observe(ref.current);
    }

    return () => {
      observer?.disconnect();

      if (frameID.current) {
        cancelAnimationFrame(frameID.current);
      }
    };
    // warn: mantine use ref.current in deps
  }, [observer]);

  return [ref, rect] as const;
}

export function useEnhancedElementSize<T extends HTMLElement = any>() {
  const [ref, { client, scroll, offset }] = useEnhancedResizeObserver<T>();
  return { ref, client, scroll, offset };
}
