import React, { FC, forwardRef } from 'react';
import { SelectItem } from '@mantine/core/lib/Select/types';
import { Group, Select, Text, ThemeIcon } from '@mantine/core';
import { Threshold } from '@modules/iot/model';
import { IconAlertSmall } from '@tabler/icons-react';
import { useLoader } from '@core/router/loader';
import { loader } from '@modules/iot/dashboard/DashboardPage';
import Level = Threshold.Level;
import { useLocation, useNavigate } from 'react-router-dom';
import { stringifyQueries } from '@shared/utils/queries';

type FilterLevel = Threshold.Level | 'all';

interface ItemProps extends SelectItem {
  value: FilterLevel;
  label: string;
}

const selectData: Array<ItemProps> = [
  { value: 'all', label: 'Tout' },
  { value: Level.Alert, label: 'Niveau alerte' },
  { value: Level.Critical, label: 'Niveau critique' },
];

const ThresholdItem = forwardRef<HTMLDivElement, ItemProps>(({ value, label, ...props }, ref) => {
  return (
    <Group noWrap spacing={10} ref={ref} {...props}>
      {value === Threshold.Level.Critical || value === Threshold.Level.Alert ? (
        <ThemeIcon
          radius="xl"
          color={Threshold.levelColor[value]}
          variant="light"
          sx={{ border: '1px solid #fff' }}
          size={19}
        >
          <IconAlertSmall />
        </ThemeIcon>
      ) : null}

      <Text>{label}</Text>
    </Group>
  );
});

const DashboardFilter: FC = () => {
  const { queries } = useLoader<typeof loader>();
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (value: FilterLevel) =>
    navigate({ ...location, search: stringifyQueries({ level: value === 'all' ? null : value }) });

  return (
    <Select
      size="sm"
      data={selectData}
      itemComponent={ThresholdItem}
      onChange={handleChange}
      defaultValue={queries.level ?? 'all'}
    />
  );
};

export default DashboardFilter;
