import React, { FC } from 'react';
import { Editorial } from '@shared/modules/editorial/model';
import Block = Editorial.Block;
import Template = Editorial.Block.Template;
import { Box, Text } from '@mantine/core';
import { renderNullable, renderOptional } from '@shared/utils/render';
import { filterEmptyStringToOption } from '@shared/utils/string';
import { sequenceS } from 'fp-ts/Apply';
import * as O from 'fp-ts/Option';
import config from '@root/config';

interface AddressRendererProps {
  block: Block<Template.Address>;
}

const AddressRenderer: FC<AddressRendererProps> = ({ block }) => {
  const location = sequenceS(O.Apply)({
    lat: O.fromNullable(block.lat),
    lng: O.fromNullable(block.lng),
  });

  return (
    <Box py={10}>
      {renderOptional(filterEmptyStringToOption(block.text), text => (
        <Text fw={600} mb={14}>
          {text}
        </Text>
      ))}

      {block.showMap &&
        renderOptional(location, ({ lat, lng }) => (
          <Box
            w="100%"
            h={350}
            component="iframe"
            title="preview"
            style={{ border: 0, overflow: 'hidden' }}
            src={`https://www.google.com/maps/embed/v1/place?key=${config.VITE_GOOGLE_MAPS_KEY}&q=${lat},${lng}`}
          />
        ))}

      {renderOptional(filterEmptyStringToOption(block.address), address => (
        <Text mb={14}>{address}</Text>
      ))}

      {renderNullable(block.content, content => (
        <Box dangerouslySetInnerHTML={{ __html: content }} />
      ))}
    </Box>
  );
};

export default AddressRenderer;
