import React from 'react';
import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import forgotPasswordRoute from '@modules/passwords/ForgotPasswordPage';
import resetPasswordRoute from '@modules/passwords/ResetPasswordPage';
import activationRoute from '@modules/passwords/ActivationPage';
import subscriptionRoute from '@modules/passwords/SubscriptionPage';

const passwordsRoutes = (
  <Route>
    <Route path="password-reset">
      <Route path="forgot" {...createRoute(forgotPasswordRoute)} />
      <Route path=":token" {...createRoute(resetPasswordRoute)} />
    </Route>

    <Route path="activation/:token" {...createRoute(activationRoute)} />
    <Route path="subscription/:id/token" {...createRoute(subscriptionRoute)} />
  </Route>
);

export default passwordsRoutes;
