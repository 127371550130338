import z from 'zod';
import { Geo } from '@shared/modules/geo/model';

export namespace ZoneSchema {
  export const saveZoneSchema = z.object({
    name: z.string().min(1),
    location: Geo.CitySchema,
    description: z.string().min(1),
  });
}
