import React, { FC } from 'react';
import DashboardCaption from '@modules/dashboard/components/DashboardCaption';
import { Group } from '@mantine/core';
import { Disease } from '@root/shared/modules/disease/model';

const DiseaseCaption: FC = () => {
  return (
    <Group spacing={17}>
      <DashboardCaption color={Disease.Level.nullableColor}>{Disease.Level.nullableText}</DashboardCaption>
      <DashboardCaption color={Disease.Level.color[Disease.Level.Low]}>
        {Disease.Level.text[Disease.Level.Low]}
      </DashboardCaption>
      <DashboardCaption color={Disease.Level.color[Disease.Level.Medium]}>
        {Disease.Level.text[Disease.Level.Medium]}
      </DashboardCaption>
      <DashboardCaption color={Disease.Level.color[Disease.Level.High]}>
        {Disease.Level.text[Disease.Level.High]}
      </DashboardCaption>
    </Group>
  );
};

export default DiseaseCaption;
