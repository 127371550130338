import { MantineProvider, rem } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import React, { FC, PropsWithChildren } from 'react';
import { MantineThemeOverride } from '@mantine/core';
import 'dayjs/locale/fr';
import { IconCalendar } from '@tabler/icons-react';

const theme: MantineThemeOverride = {
  fontFamily: 'Open Sans, sans-serif',
  headings: {
    sizes: {
      h1: {
        fontSize: rem(34),
      },
    },
  },
  colors: {
    red: ['#FFF5F5', '#FFE3E3', '#FFC9C9', '#FFA8A8', '#FF8787', '#FF6B6B', '#FA5252', '#E1523D', '#E03131', '#C92A2A'],
    blue: [
      '#E2DED8',
      '#D0EBFF',
      '#A5D8FF',
      '#74C0FC',
      '#4DABF7',
      '#7B6756',
      '#228BE6',
      '#1C7ED6',
      '#1971C2',
      '#483729',
    ],
    tertiary: [
      '#F5F4F2',
      '#F5F4F2',
      '#F5F4F2',
      '#E2DED8',
      '#A39382',
      '#7B6756',
      '#483729',
      '#363636',
      '#2E2E2E',
      '#2E2E2E',
    ],
    primary: [
      '#FCE1D2',
      '#FCE1D2',
      '#F7B690',
      '#EAC448',
      '#E8933F',
      '#E1523D',
      '#E1523D',
      '#E1523D',
      '#E1523D',
      '#E1523D',
    ],
    // 0 - 4 -> S100
    // 5 - 9 -> S500
    secondary: [
      '#DDEEDB',
      '#DDEEDB',
      '#DDEEDB',
      '#DDEEDB',
      '#DDEEDB',
      '#43B02A',
      '#43B02A',
      '#43B02A',
      '#43B02A',
      '#43B02A',
    ],
    complementary: ['#FFFFFF', '#FCF6E4', '#FDF4EC', '#FAE9D9', '#FDEEEC'],
  },
  components: {
    Button: {
      defaultProps: {
        color: 'primary',
        radius: 'md',
      },
    },
    InputWrapper: {
      styles: {
        label: {
          fontWeight: 600,
          paddingBottom: 5,
        },
      },
    },
    Badge: {
      defaultProps: {
        tt: 'none',
      },
    },
    Tooltip: {
      defaultProps: {
        events: { touch: true, hover: true, focus: false },
        fz: 12,
      },
    },
    Paper: {
      defaultProps: {
        withBorder: true,
        radius: 16,
      },
    },
    Chip: {
      styles: theme => ({
        label: {
          height: 33,
          fontWeight: 600,
          padding: '0 28px',
          display: 'inline-flex',

          '&[data-checked]': {
            padding: '0 13px',
            ':not([data-disabled])': {
              background: theme.colors.primary[1],
              borderColor: theme.colors.primary[1],
            },
          },
        },
        checkIcon: {
          color: theme.colors.primary[5],
          strokeWidth: 1,
          marginRight: 10,
        },
      }),
      defaultProps: theme => ({
        // default color not working, use styles API
        color: theme.colors.primary[1],
        radius: 4,
      }),
    },
    DatePickerInput: {
      defaultProps: {
        locale: 'fr',
        valueFormat: 'DD/MM/YYYY',
        icon: <IconCalendar />,
      },
    },
    Modal: {
      styles: {
        root: {
          zIndex: 2000,
        },
      },
      defaultProps: theme => ({
        overlayProps: {
          color: theme.colors.gray[2],
          blur: 1,
        },
      }),
    },
    Radio: {
      defaultProps: {
        color: 'primary',
      },
    },
    Checkbox: {
      defaultProps: {
        color: 'primary',
      },
    },
  },
};
const MantineTheme: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MantineProvider theme={theme} withNormalizeCSS withGlobalStyles>
      <Notifications position="top-right" />

      {children}
    </MantineProvider>
  );
};

export default MantineTheme;
