import Seo from '@shared/modules/seo/Seo';
import { renderNullable } from '@shared/utils/render';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import * as Styled from './Page.styles';
import { Avatar, Box, BoxProps, Menu, Text, Title } from '@mantine/core';
import { filterEmptyStringToOption } from '@shared/utils/string';
import { useProfile } from '@modules/profile/loader';
import { Link } from 'react-router-dom';
import { UsersUtils } from '@modules/users/utils';
import { IconStarFilled } from '@tabler/icons-react';
import config from '@root/config';
import { User } from '@modules/users/model';

export const PageTopProfile: FC = () => {
  const profile = useProfile();

  return (
    <Menu trigger="hover" position="bottom-end" width={226} withinPortal offset={9}>
      <Menu.Target>
        <Styled.PageTopProfileContainer>
          <Box sx={{ position: 'relative' }}>
            <Avatar color="primary" size={36}>
              {UsersUtils.getUserInitial(profile)}
            </Avatar>

            {renderNullable(profile.admin, () => (
              <Box c="primary" sx={{ position: 'absolute', top: -10, right: -7 }}>
                <IconStarFilled size={14} />
              </Box>
            ))}
          </Box>

          <div>
            <Text fz={14} fw={600}>
              {UsersUtils.getUserName(profile)}
            </Text>
            <Text fz={12} c="gray.6">
              {profile.company.name}
            </Text>
          </div>
        </Styled.PageTopProfileContainer>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item component={Link} to="/profile">
          Modifier mon profil
        </Menu.Item>
        {profile.role === User.Role.Admin && (
          <Menu.Item component={Link} to="/users">
            Gestion des utilisateurs
          </Menu.Item>
        )}

        {renderNullable(profile.admin, () => (
          <>
            <Menu.Divider />
            <Menu.Item component="a" href={config.VITE_SUPPLIER_PORTAL_URL}>
              Retour au portail fournisseur
            </Menu.Item>
          </>
        ))}

        <Menu.Divider />
        <Menu.Item component={Link} to="/logout">
          Se déconnecter
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export const PAGE_SCROLLER_ID = 'page-scroller';

export interface PageProps {
  top?: ReactNode;
  seoTitle?: string;
}

const Page: FC<PropsWithChildren<PageProps & Omit<BoxProps, 'top'>>> = ({
  top,
  seoTitle: receivedSeoTitle,
  children,
  ...rest
}) => {
  const seoTitle = pipe(
    filterEmptyStringToOption(receivedSeoTitle),
    O.alt(() => (typeof top === 'string' ? O.some(top) : O.none)),
    O.toUndefined,
  );

  return (
    <Styled.PageContainer>
      <Seo title={seoTitle} />

      {renderNullable(top, top => (
        <Styled.PageTopContainer>
          <Styled.PageTopLeft>{typeof top === 'string' ? <Title size="h3">{top}</Title> : top}</Styled.PageTopLeft>

          <PageTopProfile />
        </Styled.PageTopContainer>
      ))}

      <Styled.PageContentWrapper id={PAGE_SCROLLER_ID}>
        <Styled.PageContent p={20} {...rest}>
          <Styled.PageChildren pb={rest.p ?? 20}>{children}</Styled.PageChildren>
        </Styled.PageContent>
      </Styled.PageContentWrapper>
    </Styled.PageContainer>
  );
};

export default Page;
