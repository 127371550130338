import React, { FC } from 'react';
import { Card, Group, Text } from '@mantine/core';
import { IconArrowNarrowDown, IconArrowNarrowUp } from '@tabler/icons-react';
import { NumberUtils } from '@shared/utils/number';
import { Weather } from '@shared/modules/weather/model';

interface WeatherTemperatureProps {
  values: Weather.ForecastValues;
}

const WeatherTemperature: FC<WeatherTemperatureProps> = ({ values }) => {
  return (
    <Card bg="tertiary.2" radius={8} withBorder={false} p={8}>
      <Group spacing={5} c="tertiary.5" fz={12} position="center">
        <IconArrowNarrowDown size={12} />

        <Text fw={700}>{NumberUtils.formatTemperature(values[Weather.DayForecast.Param.MinTemp])}</Text>

        <IconArrowNarrowUp size={12} />

        <Text fw={700}>{NumberUtils.formatTemperature(values[Weather.DayForecast.Param.MaxTemp])}</Text>
      </Group>
    </Card>
  );
};

export default WeatherTemperature;
