import React, { FC, useMemo } from 'react';
import * as Styled from './TodayWeather.styles';
import { Box, Group, SimpleGrid, Stack, Text, Tooltip } from '@mantine/core';
import { PrevisionsUtils } from '@shared/modules/previsions/utils';
import { renderNullable, renderOptional } from '@shared/utils/render';
import WeatherTemperature from '@shared/modules/previsions/components/weather/temperature/WeatherTemperature';
import { NumberUtils } from '@shared/utils/number';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { Weather } from '@shared/modules/weather/model';
import WeatherForecastParamTooltip from '@shared/modules/previsions/components/weather/WeatherForecastParamTooltip';
import { formatDate, LocalDate, parseDate } from '@shared/modules/dates';
import { Geo } from '@shared/modules/geo/model';

interface MainWeatherProps {
  values: Weather.ForecastValues | null;
  location: Geo.City | null;
  date: LocalDate | null;
}

const MainWeather: FC<MainWeatherProps> = ({ values, location, date }) => {
  const typeInfo = useMemo(() => PrevisionsUtils.getWeatherTypeInfos(values), [values]);

  return (
    <Styled.TodayWeatherContainer>
      <Stack sx={{ height: '100%' }}>
        <Group noWrap>
          <Box sx={{ flex: '1 1 auto' }}>
            {renderNullable(location, location => (
              <Text c="white" size={26} fw={700}>
                {location.city}, {location.postalCode}
              </Text>
            ))}

            <Text c="white" size={14}>
              {pipe(
                parseDate(date),
                O.map(date => formatDate(date, 'dd LLLL yyyy')),
                O.getOrElse(() => "Aujourd'hui"),
              )}
            </Text>
          </Box>

          {renderOptional(typeInfo, ({ label, icon: Icon }) => (
            <Tooltip label={label} position="bottom" offset={-10}>
              <Box>
                <Icon width={72} height={72} color="white" />
              </Box>
            </Tooltip>
          ))}
        </Group>

        <Box sx={{ flex: '1 1 auto' }} />

        <SimpleGrid cols={2}>
          <Box>
            {renderNullable(values, values => (
              <Text size={80} c="white">
                {NumberUtils.formatTemperature(values[Weather.DayForecast.Param.AverageTemp])}
              </Text>
            ))}
            {renderNullable(values, values => (
              <Box w={100}>
                <WeatherTemperature values={values} />
              </Box>
            ))}
          </Box>

          <Stack justify="flex-end" align="flex-end">
            <Styled.TodayWeatherInfos>
              <Box>
                <Text>
                  Point de rosée
                  {renderNullable(values, values => (
                    <Text component="span">
                      {NumberUtils.formatTemperature(values[Weather.DayForecast.Param.AverageDewPoint])}
                    </Text>
                  ))}
                </Text>
              </Box>

              <Box>
                <Text>
                  Précipitation
                  {renderNullable(values, values => (
                    <Text component="span">
                      {NumberUtils.formatNumber(values[Weather.DayForecast.Param.Precipitation], {
                        style: 'unit',
                        unit: 'millimeter',
                        maximumFractionDigits: 1,
                      })}
                    </Text>
                  ))}
                </Text>
              </Box>

              <Text>
                ETP cumulée
                {renderNullable(values, values => (
                  <Text component="span">
                    {NumberUtils.formatNumber(values[Weather.DayForecast.Param.ETP], {
                      style: 'unit',
                      unit: 'millimeter',
                      maximumFractionDigits: 1,
                    })}
                  </Text>
                ))}
              </Text>
              {renderNullable(values, values => (
                <WeatherForecastParamTooltip values={values}>
                  <Text td="underline" pt={3} style={{ cursor: 'default' }}>
                    Voir tout
                  </Text>
                </WeatherForecastParamTooltip>
              ))}
            </Styled.TodayWeatherInfos>
          </Stack>
        </SimpleGrid>
      </Stack>
    </Styled.TodayWeatherContainer>
  );
};

export default MainWeather;
