import styled from '@emotion/styled';
import { Box, BoxProps, createPolymorphicComponent } from '@mantine/core';

export const PageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PAGE_TOP_HEIGHT = 65;

export const PageTopContainer = styled.div`
  display: grid;
  flex: 0 0 auto;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
  align-items: center;
  height: ${PAGE_TOP_HEIGHT}px;
  padding: 5px 15px;
  background: ${props => props.theme.white};
  border-bottom: 1px solid ${props => props.theme.colors.gray[1]};
`;

export const PageTopLeft = styled.div`
  padding-left: 30px;
`;

export const PageTopProfileContainer = styled.div`
  display: grid;
  max-width: 200px;
  grid-template-columns: 36px 1fr;
  align-items: center;
  grid-gap: 14px;
  cursor: pointer;

  > div:last-child {
    overflow: hidden;

    > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const PageContentWrapper = styled.div`
  flex: 1 1 auto;
  width: 100%;
  overflow-y: auto;
`;

export const PageContent = createPolymorphicComponent<'div', BoxProps>(styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0 auto;
`);

export const PageChildren = createPolymorphicComponent<'div', BoxProps>(styled(Box)`
  flex: 1 1 auto;
`);
