import React, { FC } from 'react';
import { ActiveSensor } from '@modules/iot/sensors/model';
import { Badge, Group, Indicator, SegmentedControl, Title } from '@mantine/core';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useLayoutContext } from '@layout/hooks';
import SensorsFilters from '@modules/iot/sensors/layout/component/header/filters/SensorsFilters';

interface IOTLayoutHeaderProps {
  active: ActiveSensor.List;
}

const IOTLayoutHeader: FC<IOTLayoutHeaderProps> = ({ active }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const listMatch = useMatch('/iot/sensors/list/*');

  const { pendingSensorCount } = useLayoutContext();

  const handleSegmentedControlChange = (value: string) => {
    navigate({
      pathname: value === 'map' ? '/iot/sensors' : '/iot/sensors/list',
      search: location.search,
    });
  };

  return (
    <Group align="center">
      <Title size="h3">Parc de sondes</Title>

      <Badge color="primary" bg="complementary.4">
        {active.sensors.length === 0 ? 'Aucune' : active.sensors.length} sonde{active.sensors.length > 1 ? 's' : ''}
      </Badge>

      <Indicator withBorder size={13} color="tertiary.5" disabled={pendingSensorCount === 0}>
        <SegmentedControl
          bg="tertiary.2"
          value={listMatch ? 'list' : 'map'}
          data={[
            { value: 'map', label: 'Carte' },
            { value: 'list', label: 'Liste' },
          ]}
          styles={theme => ({
            label: {
              fontSize: 12,

              '&[data-active]': {
                color: theme.colors.primary[5],
                fontWeight: 700,
              },
            },
          })}
          onChange={handleSegmentedControlChange}
        />
      </Indicator>

      <SensorsFilters zones={active.zones} />
    </Group>
  );
};

export default IOTLayoutHeader;
