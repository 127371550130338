import z from 'zod';
import { Sensor } from '@modules/iot/sensors/model';
import { Threshold } from '@modules/iot/model';
import { NonEmptyString } from '@shared/schemas';
import { Measures } from '@shared/modules/measures/model';
import { Sinafis } from '@modules/iot/sensors/sinafis/model';
import { Sensoterra } from '@modules/iot/sensoterra/model';
import { Hortee } from '@modules/iot/sensors/hortee/model';
import { flow } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import * as Ord from 'fp-ts/Ord';
import * as A from 'fp-ts/Array';

export namespace AlertTrigger {
  export const Id = z.string().uuid().brand('AlertTriggerId');
  export type Id = z.infer<typeof Id>;

  export enum State {
    Enabled = 'enabled',
    Disabled = 'disabled',
  }

  export const Notification = z.object({
    byPush: z.boolean(),
    byEmail: z.boolean(),
  });

  export type Notification = z.infer<typeof Notification>;

  export const CreateParams = z.object({
    name: NonEmptyString,
    sensors: z.array(Sensor.Id).nonempty(),
    type: z.nativeEnum(Measures.Type),
    thresholdLevel: z.nativeEnum(Threshold.Level),
    notification: Notification,
  });

  export type CreateParams = z.infer<typeof CreateParams>;

  export const UpdateParams = CreateParams.omit({ sensors: true });
  export type UpdateParams = z.infer<typeof UpdateParams>;

  const reduceMeasureTypeAlerts = flow(
    R.reduce(Ord.trivial)<Array<Measures.Type>, Array<Measures.Type>>([], (acc, b) => [...acc, ...b]),
    A.uniq(Measures.typeEq),
  );

  export const alertMeasureTypeBySensorType: Record<Sensor.Type, Array<Measures.Type>> = {
    [Sensor.Type.Hortee]: reduceMeasureTypeAlerts(Hortee.Probe.identifierProbeAlerts),
    [Sensor.Type.Sinafis]: reduceMeasureTypeAlerts(Sinafis.Probe.identifierProbeAlerts),
    [Sensor.Type.Sensoterra]: reduceMeasureTypeAlerts(Sensoterra.Probe.identifierProbeAlerts),
  };

  export const alertMeasureTypeFromIdentifier: {
    [Type in Sensor.Type]: Record<Sensor.Probe<Type>, Array<Measures.Type>>;
  } = {
    [Sensor.Type.Sinafis]: Sinafis.Probe.identifierProbeAlerts,
    [Sensor.Type.Sensoterra]: Sensoterra.Probe.identifierProbeAlerts,
    [Sensor.Type.Hortee]: Hortee.Probe.identifierProbeAlerts,
  };
}

export interface AlertTrigger {
  id: AlertTrigger.Id;
  name: string;
  sensor: Sensor;
  type: Measures.Type;
  thresholdLevel: Threshold.Level;
  state: AlertTrigger.State;
  notification: AlertTrigger.Notification;
}
