import { ThresholdSchema } from '@modules/iot/schema';
import { Utils } from '@shared/utils/model';
import { HubApi } from '@modules/iot/model';
import z from 'zod';
import { Sensoterra } from '@modules/iot/sensoterra/model';
import { Zone } from '@modules/iot/zones/model';

export namespace SensoterraSchema {
  const configSchema = z.object({
    soilType: Sensoterra.Parameter.Key,
    humidityThreshold: ThresholdSchema.humidityScaleSchema,
    coordinates: Utils.GPSCoordinatesSchema,
  });

  export const pendingSchema = z.object({
    technicalId: HubApi.SensorId,
    name: z.string().min(1),
    zoneId: Zone.Id,
    config: configSchema,
    comment: z.string().nullable(),
  });

  export const updateSchema = z.object({
    name: z.string().min(1),
    zoneId: Zone.Id,
    config: configSchema,
    comment: z.string().nullable(),
  });
}
