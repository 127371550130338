import { Measures } from '@shared/modules/measures/model';
import { Utils } from '@shared/utils/model';
import { NumberUtils } from '@shared/utils/number';
import { LastMeasuresUtils } from '@shared/modules/measures/last/utils';

export namespace ThresholdUtils {
  import Percent = Utils.Percent;
  import roundWithDigits = NumberUtils.roundWithDigits;
  import NumericMeasure = LastMeasuresUtils.NumericMeasure;

  function percentFormatter(value: Percent): string {
    return `${roundWithDigits(value * 100, 0)}`;
  }

  export function numericFormatter<Type extends NumericMeasure>(
    value: Measures.Value<Type>,
    type: Type & Measures.Type,
  ): string {
    switch (type) {
      case Measures.Type.Battery:
        return percentFormatter(value as Measures.Value<typeof type>);
      case Measures.Type.Humidity:
        return percentFormatter(value as Measures.Value<typeof type>);
      case Measures.Type.Temperature:
        return `${value}`;
      default:
        return '';
    }
  }

  function percentParser(value: Percent): string {
    return `${value / 100}`;
  }

  export function numericParser<Type extends NumericMeasure>(
    value: Measures.Value<Type>,
    type: Type & Measures.Type,
  ): string {
    switch (type) {
      case Measures.Type.Battery:
        return percentParser(value as Measures.Value<typeof type>);
      case Measures.Type.Humidity:
        return percentParser(value as Measures.Value<typeof type>);
      case Measures.Type.Temperature:
        return `${value}`;
      default:
        return '';
    }
  }
}
