import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import alertReportMapRoute from '@modules/alert-reports/map/AlertReportMap';
import alertReportMapDetailRoute from '@modules/alert-reports/map/AlertReportMapDetail';
import getFiltersRoutes from '@modules/alert-reports/filters/routes';

const alertReportMapRoutes = (
  <Route path="map" {...createRoute(alertReportMapRoute)}>
    <Route path=":id" {...createRoute(alertReportMapDetailRoute)} />
    {getFiltersRoutes('map')}
  </Route>
);

export default alertReportMapRoutes;
