import z from 'zod';
import { Utils } from '@shared/utils/model';
import { NonEmptyString } from '@shared/schemas';

export namespace Geo {
  export namespace City {
    export const INSEECode = z.string().brand('GeoCityINSEECode');
    export type INSEECode = z.infer<typeof INSEECode>;
  }

  export const CitySchema = z.object({
    code: City.INSEECode,
    city: NonEmptyString,
    postalCode: Utils.PostalCode,
  });

  export type City = z.infer<typeof CitySchema>;
}
