import { ParsedQuery } from 'query-string';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { getIntQuery } from '@shared/modules/filter';

export function getPageQuery(query: ParsedQuery): number {
  return pipe(
    O.fromNullable(getIntQuery(query, 'page')),
    O.filter(page => page > 0),
    O.getOrElse(() => 1),
  );
}
