import React, { FC } from 'react';
import {
  Accordion,
  AccordionControlProps,
  ActionIcon,
  Box,
  Flex,
  Group,
  Indicator,
  MantineColor,
  ScrollArea,
  Stack,
  Text,
  ThemeIcon,
} from '@mantine/core';
import { IconDotsVertical, IconSeeding } from '@tabler/icons-react';
import { Zone } from '@modules/iot/zones/model';
import { ActiveSensor, Sensor } from '@modules/iot/sensors/model';
import { ACCORDION_ITEM_PANEL } from '@modules/iot/sensors/list/ListPage';
import ActiveSensorItem from '@modules/iot/sensors/list/components/ActiveSensorItem';
import { DropTargetHookSpec, useDrop } from 'react-dnd';
import { TargetType } from 'dnd-core';
import * as Ord from 'fp-ts/Ord';
import { Threshold } from '@modules/iot/model';
import Level = Threshold.Level;
import PreserveSearchLink from '@core/router/components/PreserveSearchLink';

interface ActiveSensorZoneProps {
  accept: TargetType;
  zone: Zone;
  sensors: Array<ActiveSensor>;
  onDrop: DropTargetHookSpec<ActiveSensor, unknown, unknown>['drop'];
  isOpen: boolean;
}

const levelBackgroundColor: Record<Level, MantineColor> = {
  [Level.None]: 'green',
  [Level.Alert]: 'complementary.1',
  [Level.Critical]: 'primary',
};

const levelTextColor: Record<Level, MantineColor> = {
  [Level.None]: 'white',
  [Level.Alert]: 'primary.4',
  [Level.Critical]: 'white',
};

interface ActionItemProps {
  isOpen: boolean;
  zone: Zone;
}
const AccordionControl: FC<ActionItemProps & AccordionControlProps> = ({ isOpen, zone, ...rest }) => {
  return isOpen ? (
    <Flex pos="relative" align="center" sx={{ ':hover > a': { opacity: 1 } }}>
      <Accordion.Control {...rest} chevron={<></>} pr={30} />
      <ActionIcon
        component={PreserveSearchLink}
        to={`zones/${zone.id}`}
        relative="path"
        size="sm"
        h="fit-content"
        pos="absolute"
        color="primary"
        right={0}
        mr={10}
        opacity={0}
        sx={{ transition: 'opacity ease-in-out 300ms' }}
      >
        <IconDotsVertical size="1rem" />
      </ActionIcon>
    </Flex>
  ) : (
    <Accordion.Control {...rest} />
  );
};

const ActiveSensorZone: FC<ActiveSensorZoneProps> = ({ accept, zone, sensors, onDrop, isOpen }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const zoneAlert = sensors
    .flatMap<ActiveSensor.Alert<Sensor.Type>>(({ alerts }) => alerts)
    .reduce((acc, { level }) => Ord.max(Threshold.levelOrd)(acc, level), Level.None);

  return (
    <Indicator
      ref={drop}
      color={levelBackgroundColor[zoneAlert]}
      label={<Text color={levelTextColor[zoneAlert]}>!</Text>}
      size={19}
      disabled={zoneAlert === Threshold.Level.None}
    >
      <Accordion.Item
        value={zone.id}
        sx={theme =>
          isOver && canDrop
            ? {
                border: `2px dashed ${theme.colors.dark[1]}`,
                '&[data-active]': {
                  border: `2px dashed ${theme.colors.dark[1]}`,
                },
              }
            : {}
        }
      >
        <AccordionControl isOpen={isOpen || sensors.length === 0} zone={zone}>
          <Group spacing={10} noWrap>
            <ThemeIcon c="primary.4" variant="transparent">
              <IconSeeding strokeWidth={1.4} />
            </ThemeIcon>
            <Box>
              <Text color="primary.4" weight={700}>
                {zone.name}
              </Text>

              <Text color="primary.4" size={10}>
                {sensors.length > 0 ? sensors.length : 'Aucune'} sonde{sensors.length > 1 && 's'}
              </Text>
            </Box>
          </Group>
        </AccordionControl>
        {sensors.length > 0 ? (
          <Accordion.Panel>
            <ScrollArea styles={{ viewport: { maxHeight: ACCORDION_ITEM_PANEL } }}>
              <Stack spacing="xs">
                {sensors.map(sensor => (
                  <ActiveSensorItem key={sensor.id} sensor={sensor} />
                ))}
              </Stack>
            </ScrollArea>
          </Accordion.Panel>
        ) : null}
      </Accordion.Item>
    </Indicator>
  );
};

export default ActiveSensorZone;
