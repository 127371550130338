import React, { createElement, FC } from 'react';
import { useMatch } from 'react-router-dom';
import { Box, Burger, Card, Group, Sx, Text, ThemeIcon } from '@mantine/core';
import { ActiveSensor, Sensor } from '@modules/iot/sensors/model';
import { useDrag } from 'react-dnd';
import { CssSharedStyle } from '@styles/shared/styles';
import ActiveSensorAlertIcon from '@modules/iot/sensors/list/components/ActiveSensorAlertIcon';
import { SensorUtils } from '@modules/iot/sensors/utils';
import PreserveSearchLink from '@core/router/components/PreserveSearchLink';

interface ActiveSensorItemProps {
  sensor: ActiveSensor;
}

const ActiveSensorItem: FC<ActiveSensorItemProps> = ({ sensor }) => {
  const [{ isDragging }, dragSource] = useDrag(
    () => ({
      type: sensor.id,
      item: sensor,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [sensor],
  );

  const cardStyle: Sx = isDragging ? { opacity: 0.4, zIndex: 200 } : { ':hover': { zIndex: 200 } };

  const link = SensorUtils.sensorLinkBuilder(sensor);

  const isActive = !!useMatch(`${link}/*`);

  return (
    <Card
      ref={dragSource}
      component={PreserveSearchLink}
      to={link}
      radius={4}
      withBorder={false}
      px={12}
      py={8}
      sx={cardStyle}
    >
      <Group position="apart" noWrap>
        <Group spacing={10} noWrap>
          <ThemeIcon variant="transparent" size={22} c={isActive ? 'primary' : undefined}>
            {createElement(Sensor.typeIcon[sensor.type])}
          </ThemeIcon>
          <Box>
            <Text
              c={isActive ? 'primary' : 'dark.2'}
              size={14}
              weight={600}
              maw={138}
              style={CssSharedStyle.lineClamp(1)}
            >
              {sensor.name}
            </Text>
            <Text c="dark.2" size={8} weight={600}>
              {sensor.serial}
            </Text>
          </Box>
        </Group>
        <Group spacing={8} noWrap>
          <ActiveSensorAlertIcon alerts={sensor.alerts} />
          <Burger opened={false} size="xs" />
        </Group>
      </Group>
    </Card>
  );
};

export default ActiveSensorItem;
