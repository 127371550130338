import z from 'zod';
import { Utils } from '@shared/utils/model';
import { LocalDateTime, Year } from '@shared/modules/dates';
import { StringifiableRecord } from 'query-string';
import { NonEmptyString } from '@shared/schemas';
import { ImageMap, PGImageDetail } from '@shared/modules/images/model';
import * as NEA from 'fp-ts/NonEmptyArray';
import { ordFromOrdering } from '@shared/utils/order';
import { AlertReportSchema } from '@modules/alert-reports/schema';
import { Weather as WeatherModel } from '@shared/modules/weather/model';
import { Disease } from '@shared/modules/disease/model';
import { Pest } from '@modules/alert-reports/pests/model';
import { ActiveSensor, Sensor as SensorModel } from '@modules/iot/sensors/model';
import { HubApi } from '@modules/iot/model';
import { Measures } from '@shared/modules/measures/model';
import { Geo } from '@shared/modules/geo/model';
import PostalCode = Utils.PostalCode;
import GPSCoordinates = Utils.GPSCoordinates;
import { Profile } from '@modules/profile/model';

export namespace AlertReport {
  import GPSCoordinates = Utils.GPSCoordinates;
  export const Id = z.coerce.number().brand('AlertReportId');
  export type Id = z.infer<typeof Id>;

  export const CategoryId = z.coerce.number().brand('AlertReportCategoryId');
  export type CategoryId = z.infer<typeof CategoryId>;

  export const SubCategoryId = z.coerce.number().brand('AlertReportSubCategoryId');
  export type SubCategoryId = z.infer<typeof SubCategoryId>;

  export const TypeId = z.coerce.number().brand('AlertReportTypeId');
  export type TypeId = z.infer<typeof TypeId>;

  export const Gravity = z.coerce.number().brand('AlertReportGravity');
  export type Gravity = z.infer<typeof Gravity>;

  export const Color = z.string().brand('AlertReportColor');
  export type Color = z.infer<typeof Color>;

  export enum Period {
    CurrentMonth = 'current-month',
    CurrentTrimester = 'current-trimester',
    CurrentSemester = 'current-semester',
    Personalized = 'personalized',
  }

  export const periodLabel: Record<Period, string> = {
    [Period.CurrentMonth]: 'Mois en cours',
    [Period.CurrentTrimester]: 'Trimestre en cours',
    [Period.CurrentSemester]: 'Semestre en cours',
    [Period.Personalized]: 'Personnaliser',
  };

  const periodOrdering: Record<Period, number> = {
    [Period.CurrentMonth]: 0,
    [Period.CurrentTrimester]: 1,
    [Period.CurrentSemester]: 2,
    [Period.Personalized]: 3,
  };

  export const periodOrd = ordFromOrdering(periodOrdering);

  export enum Status {
    Active = 'active',
    Inactive = 'inactive',
  }

  export const statusLabel: Record<Status, string> = {
    [Status.Active]: 'Actif',
    [Status.Inactive]: 'Inactif',
  };

  export interface Marker {
    id: Id;
    location: GPSCoordinates;
    category: AlertReport.Category;
    subCategory: AlertReport.SubCategory;
    type: AlertReport.Type;
  }

  export namespace Filter {
    export const Id = z.string().uuid().brand('AlertReportFilterId');
    export type Id = z.infer<typeof Id>;
    export interface Light {
      id: Id;
      name: string;
    }

    export interface Detail extends Filter {
      id: Id;
      name: string;
    }

    export type SaveParams = z.infer<typeof AlertReportSchema.filterSchema>;

    export enum RouterId {
      Listing = 'listing-filter',
      Update = 'update-filter',
      New = 'new-filter',
      Save = 'save-filter',
    }
  }

  export interface Filter extends StringifiableRecord {
    categoryId: CategoryId | null;
    subCategoryId: SubCategoryId | null;
    typeId: TypeId | null;
    gravity: Gravity | null;
    period: Period | null;
    personalizedStartDate: LocalDateTime | null;
    personalizedEndDate: LocalDateTime | null;
    year: Year | null;
    myReports: boolean | null;
    hasPictures: boolean | null;
    privateOnly: boolean | null;
    status: Status | null;
    filterId: Filter.Id | null;
  }

  export const defaultFilter: Filter = {
    categoryId: null,
    subCategoryId: null,
    typeId: null,
    gravity: null,
    period: null,
    personalizedStartDate: null,
    personalizedEndDate: null,
    year: null,
    myReports: null,
    hasPictures: null,
    privateOnly: null,
    status: Status.Active,
    filterId: null,
  };

  export interface Category {
    id: CategoryId;
    label: NonEmptyString;
    color: Color;
    images: ImageMap;
  }

  export interface SubCategory {
    id: SubCategoryId;
    categoryId: CategoryId;
    label: NonEmptyString;
    images: ImageMap;
    maxGravity: Gravity;
  }

  export interface Type {
    id: TypeId;
    subCategoryId: SubCategoryId;
    label: NonEmptyString;
    images: ImageMap;
  }

  export interface Counter {
    globalThisYear: number;
    companyThisYear: number;
  }

  export enum GrassType {
    Start = 'START',
    Fairway = 'FAIRWAY',
    Rough = 'ROUGH',
    Green = 'GREEN',
    AroundGreen = 'AROUND_GREEN',
    Other = 'OTHER',
  }

  export namespace Vote {
    export enum State {
      Like = 'LIKE',
      Dislike = 'DISLIKE',
      None = 'NONE',
    }
  }

  export interface Vote {
    likesCount: number;
    dislikesCount: number;
    mine: Vote.State | null;
  }

  export interface Weather {
    reportDay: WeatherModel.DayForecast | null;
    previousDays: Array<WeatherModel.DayForecast>;
    location: Geo.City | null;
  }

  export interface Diseases {
    reportDay: Disease.Forecast;
    previousDays: Array<Disease.DayForecast>;
    location: Geo.City | null;
  }

  export interface Pests {
    reportWeek: Pest.Description;
    previousWeek: Pest.Description;
  }

  export type Sensor<Type extends SensorModel.Type = SensorModel.Type> = {
    id: SensorModel.Id;
    technicalId: HubApi.SensorId;
    type: Type;
    serial: SensorModel.Serial;
    name: NonEmptyString;
    zone: NonEmptyString | null;
    config: ActiveSensor.Config<Type> | null;
    measures: Measures.History<Type> | null;
    canAccessDetail: boolean;
  };

  export namespace Range {
    export interface Item {
      id: AlertReport.Id;
      category: NonEmptyString;
      subCategory: NonEmptyString;
      type: NonEmptyString;
      reportedAt: LocalDateTime;
      weather: WeatherModel.DayForecast | null;
      diseases: Disease.Forecast | null;
      pest: Pest.Description | null;
      sensorCount: number;
    }
  }
}

export interface AlertReport {
  id: AlertReport.Id;
  companyId: Profile.Company.Id;
  department: NonEmptyString | null;
  category: AlertReport.Category;
  subCategory: AlertReport.SubCategory;
  type: AlertReport.Type;
  reportedAt: LocalDateTime;
  coordinates: GPSCoordinates;
  comment: NonEmptyString | null;
  internalComment: NonEmptyString | null;
  grassType: AlertReport.GrassType | null;
  companyReportTotal: number;
  images: NEA.NonEmptyArray<PGImageDetail> | null;
  gravity: AlertReport.Gravity | null;
  vote: AlertReport.Vote;
  isPrivate: boolean;
  pest: Pest.Name | null;
  diseaseModels: NEA.NonEmptyArray<Disease.Model> | null;
  insee: Geo.City.INSEECode | null;
  city: NonEmptyString | null;
  postalCode: PostalCode | null;
  canEdit: boolean;
}
