import { z } from 'zod';
import { NonEmptyString } from '@shared/schemas';
import { User } from '@modules/users/model';
import { Utils } from '@shared/utils/model';

export namespace Profile {
  export const UpdateProfileParams = z.object({
    firstname: NonEmptyString,
    lastname: NonEmptyString,
  });

  export type UpdateProfileParams = z.infer<typeof UpdateProfileParams>;

  export interface Rights {
    createTech: boolean;
  }

  export interface Admin {
    authedAs: string;
  }

  export namespace Company {
    export const Id = z.number().int().positive().brand<'CompanyId'>();
    export type Id = z.infer<typeof Id>;
  }

  export interface Company {
    id: Company.Id;
    name: string;
    postalCode: Utils.PostalCode | null;
  }
}

export interface Profile<Role extends User.Role = User.Role> {
  email: string;
  firstname: string;
  lastname: string;
  role: Role;
  company: Profile.Company;
  rights: Profile.Rights;
  admin: Profile.Admin | null;
}
