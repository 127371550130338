import React, { FC } from 'react';
import { useEnhancedFormContext } from '@shared/modules/form';
import { Avatar, Badge, Box, Button, Group, Stack, Text, TextInput } from '@mantine/core';
import { Profile } from '@modules/profile/model';
import { UsersUtils } from '@modules/users/utils';

import * as Styled from './UpdateProfileForm.styles';
import { User } from '@modules/users/model';

interface UpdateProfileFormProps {
  profile: Profile;
  loading: boolean;
}

const UpdateProfileForm: FC<UpdateProfileFormProps> = ({ profile, loading }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useEnhancedFormContext<Profile.UpdateProfileParams>();

  return (
    <Styled.UpdateProfileFormContainer>
      <form onSubmit={handleSubmit} noValidate>
        <Box p={50} m="0 auto" sx={{ maxWidth: 415 }}>
          <Stack align="center" spacing="xs">
            <Avatar color="primary" size={87}>
              {UsersUtils.getUserInitial(profile)}
            </Avatar>

            <Text size="sm" c="gray.8">
              {profile.email}
            </Text>

            <Badge color="tertiary">{User.roleLabel[profile.role]}</Badge>
          </Stack>

          <Box mt={40}>
            <TextInput
              id="lastname"
              label="Nom"
              placeholder="Nom"
              required
              error={!!errors.lastname}
              {...register('lastname')}
            />

            <TextInput
              mt="md"
              id="firstname"
              label="Prénom"
              placeholder="Prénom"
              error={!!errors.firstname}
              required
              {...register('firstname')}
            />
          </Box>

          <Group position="center" mt={40}>
            <Button type="submit" loading={loading} disabled={loading}>
              Enregister
            </Button>
          </Group>
        </Box>
      </form>
    </Styled.UpdateProfileFormContainer>
  );
};

export default UpdateProfileForm;
