import React, { FC } from 'react';
import { defineRoute, preventActionLeave, useHashDisclosure, usePreserveNavigate } from '@core/router';
import DrawerContent from '@shared/components/drawer/DrawerContent';
import { ActionIcon, Button, Group, Title } from '@mantine/core';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import FilterForm from '@modules/alert-reports/filters/components/FilterForm';
import { defineAction, useAction } from '@core/router/action';
import { AlertReport } from '@modules/alert-reports/model';
import { zodResolver } from '@hookform/resolvers/zod';
import { AlertReportSchema } from '@modules/alert-reports/schema';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { AlertReportsService } from '@modules/alert-reports/service';
import { z } from 'zod';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { IconTrash } from '@tabler/icons-react';
import DeleteModal from '@shared/components/modals/DeleteModal';

const params = z.object({ id: AlertReport.Filter.Id });

const loader = defineLoader({
  params,
  handler: ({ params }) => httpTaskToResponseTask(AlertReportsService.Filter.getFilter(params.id)),
});

const actions = {
  update: defineAction({
    type: 'update',
    params,
    payload: AlertReportSchema.filterSchema,
    handler: ({ payload, params }) => AlertReportsService.Filter.updateFilter(params.id, payload),
    flashOptions: {
      success: () => 'Modification réalisée',
    },
  }),
  deleteFilter: defineAction({
    type: 'delete',
    params,
    handler: ({ params }) => AlertReportsService.Filter.deleteFilter(params.id),
    flashOptions: {
      success: () => 'Suppression réalisée',
    },
  }),
};

const FilterDetailPage: FC = () => {
  const navigate = usePreserveNavigate();
  const [deleteOpened, deleteHandlers] = useHashDisclosure('#delete');
  const filter = useLoader<typeof loader>();
  const [updateLoading, update] = useAction(actions.update);
  const [deleteLoading, deleteFilter] = useAction(actions.deleteFilter);
  const { formRef, form, handleFormSubmit } = useEnhancedForm<AlertReport.Filter.SaveParams>({
    resolver: zodResolver(AlertReportSchema.filterSchema),
    defaultValues: filter,
  });

  const handleClose = () => navigate({ pathname: '..', hash: undefined }, { replace: true, relative: 'path' });

  const handleUpdate = (params: AlertReport.Filter.SaveParams) =>
    pipe(
      update(params),
      TE.chainIOK(() => handleClose),
    );

  const handleDelete = () =>
    pipe(
      deleteFilter(),
      TE.chainIOK(() => handleClose),
    )();

  return (
    <>
      <DrawerContent
        top={
          <Group>
            <Title size="h3" sx={{ flex: '1 1 auto' }}>
              Modifier un filtre
            </Title>
            <ActionIcon onClick={deleteHandlers.open}>
              <IconTrash />
            </ActionIcon>
          </Group>
        }
      >
        <EnhancedForm ref={formRef} form={form} onSubmit={handleUpdate} preventLeave>
          <FilterForm />
        </EnhancedForm>
        <Group spacing={10} pt={30} position="right">
          <Button onClick={handleClose} variant="subtle" c="dark.3" color="white">
            Annuler
          </Button>
          <Button onClick={handleFormSubmit} loading={updateLoading}>
            Enregistrer
          </Button>
        </Group>
      </DrawerContent>
      <DeleteModal
        opened={deleteOpened}
        handleClose={deleteHandlers.close}
        onDelete={handleDelete}
        loading={deleteLoading}
      />
    </>
  );
};

const filterDetailRoute = defineRoute({
  component: FilterDetailPage,
  loader,
  actions,
  shouldRevalidate: preventActionLeave<typeof actions>('delete'),
});

export default filterDetailRoute;
