import z from 'zod';
import { Utils } from '@shared/utils/model';
import { HubApi, Threshold } from '@modules/iot/model';
import { LocalDateTime } from '@shared/modules/dates';
import { Sinafis } from '@modules/iot/sensors/sinafis/model';
import { Hortee } from '@modules/iot/sensors/hortee/model';
import * as NEA from 'fp-ts/NonEmptyArray';
import { ReactComponent as HorteeSensorIcon } from '@assets/icons/hortee-sensor.svg';
import { ReactComponent as SensoterraSensorIcon } from '@assets/icons/sensoterra-sensor.svg';
import { ReactComponent as SinafisSensorIcon } from '@assets/icons/sinafis-sensor.svg';
import { FC } from 'react';
import { Sensoterra } from '@modules/iot/sensoterra/model';
import * as React from 'react';
import { Zone } from '@modules/iot/zones/model';
import { StringifiableRecord } from 'query-string';
import sinafisLogo from '@assets/logos/sinafis.svg';
import horteeLogo from '@assets/logos/hortee.svg';
import sensoterraLogo from '@assets/logos/sensoterra.svg';
import { Measures } from '@shared/modules/measures/model';
import { MantineColor } from '@mantine/core';
import { SensorSchema } from '@modules/iot/sensors/schema';

export namespace Sensor {
  export const Id = z.string().uuid().brand('SensorId');
  export type Id = z.infer<typeof Id>;

  export const Serial = z.string().uuid().brand('SensorSerial');
  export type Serial = z.infer<typeof Serial>;

  export enum SensorsRouteId {
    SensoterraDetail = 'sensoterra-detail',
    SensoterraPending = 'sensoterra-pending',
    SinafisDetail = 'sinafis-detail',
    SinafisPending = 'sinafis-pending',
    HorteeDetail = 'hortee-detail',
    HorteePending = 'hortee-pending',
  }

  export const sensorPendingParams = z.object({ id: HubApi.SensorId });
  export const sensorDetailParams = z.object({ id: Sensor.Id });

  export enum Type {
    Sensoterra = 'sensoterra',
    Hortee = 'hortee',
    Sinafis = 'sinafis',
  }

  export const typeTitle: Record<Type, string> = {
    [Type.Sensoterra]: 'Sensoterra',
    [Type.Hortee]: 'Hortee',
    [Type.Sinafis]: 'Sinafis',
  };

  export const typeLogo: Record<Type, string> = {
    [Type.Sensoterra]: sensoterraLogo,
    [Type.Hortee]: horteeLogo,
    [Type.Sinafis]: sinafisLogo,
  };

  export const typeIcon: Record<Type, FC<React.ComponentProps<'svg'> & { title?: string }>> = {
    [Type.Sensoterra]: SensoterraSensorIcon,
    [Type.Hortee]: HorteeSensorIcon,
    [Type.Sinafis]: SinafisSensorIcon,
  };

  export namespace Probe {
    export const Identifier = {
      ...Sensoterra.Probe.Identifier,
      ...Hortee.Probe.Identifier,
      ...Sinafis.Probe.Identifier,
    };

    export type Identifier = (typeof Identifier)[keyof typeof Identifier];

    export const identifierLabel: Record<Identifier, string> = {
      [Identifier.Ground]: 'Sol',
      [Identifier.Ground1]: 'Sol 1',
      [Identifier.Ground2]: 'Sol 2',
      [Identifier.Transmitter]: 'Transmetteur',
      [Identifier.Leaf]: 'Foliaire',
    };

    export const identifierColor: Record<Identifier, MantineColor> = {
      [Identifier.Ground]: '#afafaf',
      [Identifier.Ground1]: '#afafaf',
      [Identifier.Ground2]: '#afafaf',
      [Identifier.Transmitter]: '#333333',
      [Identifier.Leaf]: '#ffffff',
    };
  }

  export type Probe<Type extends Sensor.Type> = {
    [Sensor.Type.Sensoterra]: Sensoterra.Probe.Identifier;
    [Sensor.Type.Hortee]: Hortee.Probe.Identifier;
    [Sensor.Type.Sinafis]: Sinafis.Probe.Identifier;
  }[Type];

  export type UpdateZoneParams = z.infer<typeof SensorSchema.updateZoneSchema>;
  export type ExportSensorParams = z.infer<typeof SensorSchema.exportMeasuresSchema>;
}

export namespace ActiveSensor {
  export type Config<Type extends Sensor.Type> = {
    [Sensor.Type.Sensoterra]: Sensoterra.Config;
    [Sensor.Type.Hortee]: Hortee.Config;
    [Sensor.Type.Sinafis]: Sinafis.Config;
  }[Type];

  export interface Alert<Type extends Sensor.Type> {
    probe: Sensor.Probe<Type>;
    type: Measures.Type;
    level: Threshold.Level;
    since: LocalDateTime;
  }

  export interface Filter extends StringifiableRecord {
    search: string | null;
    zoneId: NEA.NonEmptyArray<Zone.Id> | null;
    sensorType: Sensor.Type | null;
    sinafisProbeIdentifier: Sinafis.Probe.Identifier | null;
    alertLevel: Threshold.Level | null;
    alertType: Measures.Type | null;
  }

  export const defaultFilter: Filter = {
    search: null,
    zoneId: null,
    sensorType: null,
    sinafisProbeIdentifier: null,
    alertLevel: null,
    alertType: null,
  };

  export interface List {
    zones: Array<Zone>;
    sensors: Array<ActiveSensor>;
  }

  export interface Impl<Type extends Sensor.Type> extends Sensor<Type> {
    serial: Sensor.Serial;
    zone: Zone | null;
    location: Utils.GPSCoordinates;
    config: ActiveSensor.Config<Type>;
    measures: Measures.Last.Impl<Type> | null;
    alerts: Array<ActiveSensor.Alert<Type>>;
    comment: string | null;
  }
}

export interface Sensor<Type extends Sensor.Type = Sensor.Type> {
  id: Sensor.Id;
  type: Type;
  name: string;
}

export type ActiveSensor =
  | ActiveSensor.Impl<Sensor.Type.Hortee>
  | ActiveSensor.Impl<Sensor.Type.Sinafis>
  | ActiveSensor.Impl<Sensor.Type.Sensoterra>;

export namespace PendingSensor {
  export type RemoteConfig<Type extends Sensor.Type> = {
    [Sensor.Type.Sensoterra]: Sensoterra.Config.Remote;
    [Sensor.Type.Hortee]: Hortee.Config.Remote;
    [Sensor.Type.Sinafis]: Sinafis.Config.Remote;
  }[Type];

  export namespace List {
    export interface Item {
      technicalId: HubApi.SensorId;
      type: Sensor.Type;
      serial: Sensor.Serial;
    }
  }

  export type List = Array<List.Item>;

  export interface RegisterSensorOut {
    id: Sensor.Id;
  }
}

export interface PendingSensor<Type extends Sensor.Type = Sensor.Type> {
  technicalId: HubApi.SensorId;
  type: Type;
  serial: Sensor.Serial;
  location: Utils.GPSCoordinates | null;
  config: PendingSensor.RemoteConfig<Type>;
  measures: Measures.Last.Impl<Type> | null;
}
