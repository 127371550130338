import React, { FC, useEffect } from 'react';
import { HttpError, HttpStatusCode } from '@core/http';

import * as Styled from './ErrorPage.styles';
import { isRouteErrorResponse, Link, useRouteError } from 'react-router-dom';
import { Button, Text, Title } from '@mantine/core';
import * as Sentry from '@sentry/react';
import { getErrorTitle } from '@shared/components/error-page/utils';
import config from '@root/config';

interface DefaultErrorContentProps {
  title: string;
}
const DefaultErrorContent: FC<DefaultErrorContentProps> = ({ title }) => {
  return (
    <Styled.ErrorPageContainer>
      <Title>{title}</Title>

      <Text p="30px 0">Nous vous invitons à revenir à l'accueil</Text>

      <Button component={Link} to="/">
        Revenir à l'accueil
      </Button>
    </Styled.ErrorPageContainer>
  );
};

const UnauthorizedErrorContent: FC = () => {
  return (
    <Styled.ErrorPageContainer>
      <Title>Vous n'êtes pas autorisés à accéder à cette section</Title>

      <Text p="30px 0">Nous vous invitons à vous authentifier de nouveau</Text>

      <Button component={Link} to={config.VITE_SUPPLIER_PORTAL_URL}>
        Retourner à la plateforme
      </Button>
    </Styled.ErrorPageContainer>
  );
};

interface InternalErrorContentProps {
  status: number;
}
const InternalErrorContent: FC<InternalErrorContentProps> = ({ status }) => {
  switch (status) {
    case HttpStatusCode.UNAUTHORIZED:
      return <UnauthorizedErrorContent />;
    default:
      return <DefaultErrorContent title={getErrorTitle(status)} />;
  }
};

interface ErrorPageProps {
  error?: HttpError;
}

const ErrorPage: FC<ErrorPageProps> = ({ error = HttpError.notFound }) => {
  const internalError = useRouteError();

  useEffect(() => {
    if (internalError) {
      if (!isRouteErrorResponse(internalError)) {
        Sentry.captureException(internalError, {
          level: 'error',
        });
      } else if (
        ![HttpStatusCode.NOT_FOUND, HttpStatusCode.UNAUTHORIZED, HttpStatusCode.FORBIDDEN].includes(
          internalError.status,
        )
      ) {
        Sentry.captureMessage(`[ErrorPage] capture response with ${internalError.status} code`, {
          level: 'error',
          extra: { response: internalError },
        });
      }
    }
  }, [internalError]);

  return isRouteErrorResponse(internalError) ? (
    <InternalErrorContent status={internalError.status} />
  ) : internalError ? (
    <DefaultErrorContent title="Erreur technique interne" />
  ) : (
    <DefaultErrorContent title={getErrorTitle(error.status)} />
  );
};

export default ErrorPage;
