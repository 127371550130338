import z from 'zod';
import { NonEmptyString } from '@shared/schemas';
import { ImageMap } from '@shared/modules/images/model';
import { Editorial } from '@shared/modules/editorial/model';
import { Utils } from '@shared/utils/model';

export namespace Product {
  import GPSCoordinates = Utils.GPSCoordinates;
  export const Id = z.coerce.number().brand('ProductId');
  export type Id = z.infer<typeof Id>;

  export interface Reseller {
    id: number;
    label: NonEmptyString;
    catalog: string;
    packages: Array<Package>;
  }

  export interface RetailPoint {
    id: number;
    label: NonEmptyString;
    reseller: NonEmptyString;
    address: NonEmptyString;
    location: GPSCoordinates;
    distanceInKilometers: number | null;
    packages: Array<Package>;
  }

  export interface Package {
    id: number;
    label: NonEmptyString;
    oldPrice: number | null;
    price: number | null;
  }
}

export interface Product {
  id: Product.Id;
  label: NonEmptyString;
  brand: NonEmptyString;
  image: ImageMap | null;
  secondaryImages: Array<ImageMap>;
  content: Editorial;
  resellers: Array<Product.Reseller>;
  retailPoints: Array<Product.RetailPoint>;
}
