import { PendingSensor, Sensor } from '@modules/iot/sensors/model';
import { DeepPartial } from 'react-hook-form';
import { Threshold } from '@modules/iot/model';
import { Sensoterra } from '@modules/iot/sensoterra/model';

export namespace SensoterraUtils {
  import Type = Sensor.Type.Sensoterra;
  import Level = Threshold.Level;

  export function getPendingDefaultValues(sensor: PendingSensor<Type>): DeepPartial<Sensoterra.PendingParams> {
    const humidityThreshold = {
      last: Level.None,
      levels: [
        { level: Level.Critical, until: 0.33 },
        { level: Level.Alert, until: 0.66 },
      ],
    };

    return {
      name: '',
      technicalId: sensor.technicalId,
      config: {
        coordinates: {
          latitude: sensor.location?.latitude,
          longitude: sensor.location?.longitude,
        },
        soilType: sensor.config.soilType,
        humidityThreshold,
      },
    };
  }
}
