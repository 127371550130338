import { z } from 'zod';
import { Zone } from '@modules/iot/zones/model';
import { Sensor } from '@modules/iot/sensors/model';
import { LocalDateTime } from '@shared/modules/dates';
import { Utils } from '@shared/utils/model';

export namespace SensorSchema {
  export const exportMeasuresSchema = z.object({
    type: z.nativeEnum(Sensor.Type),
    startDate: LocalDateTime,
    endDate: LocalDateTime,
    unit: z.nativeEnum(Utils.ChronoUnit),
  });

  export const updateZoneSchema = z.object({
    id: Sensor.Id,
    zoneId: Zone.Id,
  });
}
