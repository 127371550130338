import React, { FC } from 'react';
import { renderNullable } from '@shared/utils/render';
import { Card, Group, Stack, Text } from '@mantine/core';
import { AlertReport } from '@modules/alert-reports/model';
import ImagesGallery from '@modules/alert-reports/components/ImagesGallery';

interface AlertReportGalleryCardProps {
  alertReport: AlertReport;
}

const AlertReportGalleryCard: FC<AlertReportGalleryCardProps> = ({ alertReport }) => {
  const images = alertReport.images?.map(({ variants }) => variants) ?? [];

  return (
    <Stack>
      <Text color="primary.4" weight={600} size={12} lh={1.55}>
        {alertReport.category.label} / {alertReport.subCategory.label}
      </Text>
      <ImagesGallery images={images}>
        {renderNullable(alertReport.gravity, gravity => (
          <Card
            pos="absolute"
            top={9}
            right={8}
            bg="rgba(255, 255, 255, 0.8)"
            radius={8}
            p={20}
            pt={8}
            pb={12}
            withBorder={false}
          >
            <Text color="gray.6" size={10} weight={600} lh={1.55}>
              Gravité
            </Text>
            <Text color="gray.6" size={16} weight={600} lh={1.55}>
              <Text component="span" color="primary" weight={700} lh={1.3} size={34}>
                {gravity}
              </Text>
              /{alertReport.subCategory.maxGravity}
            </Text>
          </Card>
        ))}
      </ImagesGallery>
      <Group position="apart" fz={13} c="gray.8" fw={600}>
        <Text>Cette entreprise a effectué {alertReport.companyReportTotal} signalements</Text>
        {renderNullable(alertReport.department, department => (
          <Text>Département {department}</Text>
        ))}
      </Group>
    </Stack>
  );
};

export default AlertReportGalleryCard;
