import React, { ChangeEventHandler, FC } from 'react';
import { SubmitOnEnter, useEnhancedFormContext } from '@shared/modules/form';
import { AlertTrigger } from '@modules/iot/alert-triggers/model';
import { Select, Switch, Text, TextInput } from '@mantine/core';
import { Controller } from 'react-hook-form';
import ThresholdSelect from '@modules/iot/components/threshold-select/ThresholdSelect';
import { Sensor } from '@modules/iot/sensors/model';
import { Measures } from '@shared/modules/measures/model';

interface UpdateAlertTriggerForm {
  sensor: Sensor;
}

const UpdateAlertTriggerForm: FC<UpdateAlertTriggerForm> = ({ sensor }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
  } = useEnhancedFormContext<AlertTrigger.UpdateParams>();

  const handleEmailNotificationChange: ChangeEventHandler<HTMLInputElement> = e =>
    setValue('notification.byEmail', e.target.checked);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <SubmitOnEnter />

      <TextInput
        id="name"
        label="Nom de l'alerte"
        placeholder="Saisir le nom de l'alerte"
        required
        error={!!errors.name}
        {...register('name')}
      />

      <Controller
        control={control}
        name="type"
        render={({ field }) => (
          <Select
            mt="md"
            label="Type d'alerte"
            placeholder="Sélectionner"
            required
            data={AlertTrigger.alertMeasureTypeBySensorType[sensor.type].map(type => ({
              value: type,
              label: Measures.typeTitle[type],
            }))}
            error={!!errors.type}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="thresholdLevel"
        render={({ field }) => (
          <ThresholdSelect
            mt="md"
            label="Niveau d'alerte"
            placeholder="Sélectionner"
            required
            error={!!errors.thresholdLevel}
            {...field}
          />
        )}
      />

      <Text mt="md" size="sm" fw={500} c="dark.9">
        Gérer les notifications
      </Text>

      <Switch
        mt="sm"
        label="Activer les notifications par email"
        color="primary"
        checked={watch('notification.byEmail')}
        onChange={handleEmailNotificationChange}
        error={!!errors.notification?.byEmail}
      />
    </form>
  );
};

export default UpdateAlertTriggerForm;
