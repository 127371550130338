import { httpService, HttpTask } from '@core/http';
import { Zone } from '@modules/iot/zones/model';

export namespace ZonesService {
  const URI = '/iot/zones';

  export function getZones(): HttpTask<Array<Zone>> {
    return httpService.get(URI);
  }

  export function createZone(params: Zone.SaveParams): HttpTask<Zone> {
    return httpService.post(URI, params);
  }

  export function getZone(id: Zone.Id): HttpTask<Zone> {
    return httpService.get(`${URI}/${id}`);
  }

  export function updateZone(id: Zone.Id, params: Zone.SaveParams): HttpTask<Zone> {
    return httpService.post(`${URI}/${id}`, params);
  }

  export function deleteZone(id: Zone.Id): HttpTask {
    return httpService.delete(`${URI}/${id}`);
  }
}
