import { Sensor } from '@modules/iot/sensors/model';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as S from 'fp-ts/string';
import * as Eq from 'fp-ts/Eq';
import * as O from 'fp-ts/Option';
import * as NEA from 'fp-ts/NonEmptyArray';
import { AlertTrigger } from '@modules/iot/alert-triggers/model';
import { Measures } from '@shared/modules/measures/model';

export namespace AlertTriggersUtils {
  export function getSensorsAvailableMeasuresType(sensors: Array<Sensor>): Array<Measures.Type> {
    const sensorTypeEq = pipe(
      S.Eq,
      Eq.contramap((type: Sensor.Type) => type),
    );

    const measureTypeEq = pipe(
      S.Eq,
      Eq.contramap((type: Measures.Type) => type),
    );

    return pipe(
      sensors,
      A.map(sensor => sensor.type),
      A.uniq(sensorTypeEq),
      NEA.fromArray,
      O.fold(
        () => [],
        types =>
          pipe(
            types,
            NEA.reduce(Object.values(Measures.Type), (acc, curr) =>
              A.intersection(measureTypeEq)(acc, AlertTrigger.alertMeasureTypeBySensorType[curr]),
            ),
          ),
      ),
    );
  }
}
