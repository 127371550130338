import React, { createElement, FC } from 'react';
import { Accordion, Box, Card, Group, Indicator, ScrollArea, Stack, Text, ThemeIcon } from '@mantine/core';
import { IconPencil } from '@tabler/icons-react';
import { useMatch } from 'react-router-dom';
import { ActiveSensor, PendingSensor, Sensor } from '@modules/iot/sensors/model';
import { ACCORDION_ITEM_PANEL } from '../../ListPage';
import { IndicatorProps } from '@mantine/core/lib/Indicator/Indicator';
import { CssSharedStyle } from '@styles/shared/styles';
import { SensorUtils } from '@modules/iot/sensors/utils';
import PreserveSearchLink from '@core/router/components/PreserveSearchLink';
import { DropTargetHookSpec, useDrop } from 'react-dnd';
import { TargetType } from 'dnd-core';

interface PendingSensorItemProps {
  sensor: PendingSensor.List.Item;
}

const PendingSensorItem: FC<PendingSensorItemProps> = ({ sensor }) => {
  const { type, serial } = sensor;

  const link = SensorUtils.pendingSensorLinkBuilder(sensor);

  const isActive = !!useMatch(`${link}/*`);

  return (
    <Card component={PreserveSearchLink} to={link} radius={4} withBorder={false} px={12} py={8}>
      <Group spacing={10} noWrap>
        <ThemeIcon variant="transparent" size={22} c={isActive ? 'primary' : undefined}>
          {createElement(Sensor.typeIcon[type])}
        </ThemeIcon>
        <Box>
          <Text c={isActive ? 'primary' : 'dark.2'} size={14} weight={600} style={CssSharedStyle.lineClamp(2)}>
            Sonde {Sensor.typeTitle[type]}
          </Text>
          <Text c="dark.2" size={8} weight={600}>
            {serial}
          </Text>
        </Box>
      </Group>
    </Card>
  );
};

interface PendingSensorsProps {
  sensors: PendingSensor.List;
  onDrop: DropTargetHookSpec<ActiveSensor, unknown, unknown>['drop'];
  accept: TargetType;
}

const PendingSensors: FC<PendingSensorsProps & Omit<Partial<IndicatorProps>, 'onDrop'>> = ({
  sensors,
  accept,
  onDrop,
  ...props
}) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <Indicator ref={drop} label={sensors.length} size={19} {...props}>
      <Accordion.Item
        value="pending"
        sx={theme => ({
          background: theme.colors.complementary[2],
          border: isOver && canDrop ? `2px dashed ${theme.colors.dark[1]}` : undefined,

          '[data-active]': {
            background: theme.colors.complementary[2],
          },
          '&[data-active]': {
            borderColor: theme.colors.complementary[2],
          },
          ':hover': {
            background: `${theme.colors.complementary[3]}`,
          },
        })}
      >
        <Accordion.Control>
          <Group spacing={10}>
            <ThemeIcon c="primary.4" variant="transparent">
              <IconPencil strokeWidth={1.4} />
            </ThemeIcon>
            <Box>
              <Text color="primary.4" weight={700} size={14}>
                À configurer
              </Text>
              <Text color="primary.4" size={10}>
                {sensors.length} sonde{sensors.length > 1 && 's'}
              </Text>
            </Box>
          </Group>
        </Accordion.Control>
        <Accordion.Panel sx={theme => ({ background: theme.colors.complementary[2] })}>
          <ScrollArea styles={{ viewport: { maxHeight: ACCORDION_ITEM_PANEL } }}>
            <Stack spacing="xs">
              {sensors.map(sensor => (
                <PendingSensorItem key={sensor.technicalId} sensor={sensor} />
              ))}
            </Stack>
          </ScrollArea>
        </Accordion.Panel>
      </Accordion.Item>
    </Indicator>
  );
};

export default PendingSensors;
