export namespace Maps {
  export const defaultOptions: google.maps.MapOptions = {
    center: { lat: 46.9839695, lng: 1.7773997 },
    zoom: 6.6,
    streetViewControl: false,
    fullscreenControl: true,
    mapTypeControl: true,
    mapTypeControlOptions: {
      position: Maps.ControlPosition.BOTTOM_LEFT,
    },
    clickableIcons: false,
    draggableCursor: 'grab',
    draggingCursor: 'grabbing',
    zoomControlOptions: {
      position: 5.0,
    },
    styles: [
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
    ],
  };

  // from google.maps.ControlPosition
  export enum ControlPosition {
    /**
     * Elements are positioned in the center of the bottom row.
     */
    BOTTOM_CENTER = 0.0,
    /**
     * Elements are positioned in the bottom left and flow towards the middle.
     * Elements are positioned to the right of the Google logo.
     */
    BOTTOM_LEFT = 1.0,
    /**
     * Elements are positioned in the bottom right and flow towards the middle.
     * Elements are positioned to the left of the copyrights.
     */
    BOTTOM_RIGHT = 2.0,
    /**
     * Elements are positioned on the left, above bottom-left elements, and flow
     * upwards.
     */
    LEFT_BOTTOM = 3.0,
    /**
     * Elements are positioned in the center of the left side.
     */
    LEFT_CENTER = 4.0,
    /**
     * Elements are positioned on the left, below top-left elements, and flow
     * downwards.
     */
    LEFT_TOP = 5.0,
    /**
     * Elements are positioned on the right, above bottom-right elements, and
     * flow upwards.
     */
    RIGHT_BOTTOM = 6.0,
    /**
     * Elements are positioned in the center of the right side.
     */
    RIGHT_CENTER = 7.0,
    /**
     * Elements are positioned on the right, below top-right elements, and flow
     * downwards.
     */
    RIGHT_TOP = 8.0,
    /**
     * Elements are positioned in the center of the top row.
     */
    TOP_CENTER = 9.0,
    /**
     * Elements are positioned in the top left and flow towards the middle.
     */
    TOP_LEFT = 10.0,
    /**
     * Elements are positioned in the top right and flow towards the middle.
     */
    TOP_RIGHT = 11.0,
  }
}
