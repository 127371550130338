import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import getFiltersRoutes from '@modules/alert-reports/filters/routes';
import alertReportAnalysesRoute from '@modules/alert-reports/analysis/ListPage';
import alertReportAnalysisRoute from '@modules/alert-reports/analysis/DetailPage';

const alertReportAnalysisRoutes = (
  <>
    <Route path="analysis" {...createRoute(alertReportAnalysesRoute)}>
      {getFiltersRoutes('analysis')}
    </Route>
    <Route path="analysis/:id" {...createRoute(alertReportAnalysisRoute)} />
  </>
);

export default alertReportAnalysisRoutes;
