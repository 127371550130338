import { useFetchTaskOption } from '@core/http/hooks';
import { DashboardService } from '@modules/dashboard/service';
import { useCallback } from 'react';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { HttpError } from '@core/http';
import { Dashboard } from '@modules/dashboard/model';

export namespace DashboardHooks {
  export function useDashboardAsyncData(filter: Dashboard.Filter | null) {
    const [context] = useFetchTaskOption(DashboardService.getContext, filter);

    const getWeather = useCallback(
      () =>
        pipe(
          context,
          TE.fromOption(() => HttpError.notFound),
          TE.chain(DashboardService.getWeather),
        ),
      [context],
    );

    const getDisease = useCallback(
      () =>
        pipe(
          context,
          TE.fromOption(() => HttpError.notFound),
          TE.chain(DashboardService.getDisease),
        ),
      [context],
    );

    const getPest = useCallback(
      () =>
        pipe(
          context,
          TE.fromOption(() => HttpError.notFound),
          TE.chain(DashboardService.getPest),
        ),
      [context],
    );

    const [weather] = useFetchTaskOption(getWeather);
    const [disease] = useFetchTaskOption(getDisease);
    const [pest] = useFetchTaskOption(getPest);

    return {
      context,
      weather,
      disease,
      pest,
    };
  }
}
