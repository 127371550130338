import React, { createElement, FC } from 'react';
import { renderOptional } from '@shared/utils/render';
import { ThemeIcon, Tooltip } from '@mantine/core';
import { Measures } from '@shared/modules/measures/model';
import * as O from 'fp-ts/Option';
import { MeasuresUtils } from '@shared/modules/measures/utils';
import batteryLevel = MeasuresUtils.batteryLevel;

interface BatteryThemeIconProps {
  battery: O.Option<Measures.RealTimeMeasure.Impl<Measures.Type.Battery>>;
}

const BatteryThemeIcon: FC<BatteryThemeIconProps> = ({ battery }) => {
  return renderOptional(battery, ({ value }) => (
    <Tooltip label={Measures.batteryLabel[batteryLevel(value)]}>
      <ThemeIcon size={31} variant="transparent" c={value < 0.01 ? 'dark.1' : 'tertiary.8'}>
        {createElement(Measures.batteryIcon[batteryLevel(value)], { strokeWidth: 1, size: 31 })}
      </ThemeIcon>
    </Tooltip>
  ));
};

export default BatteryThemeIcon;
