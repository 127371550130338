import React, { FC } from 'react';
import { Editorial } from '@shared/modules/editorial/model';
import { Title as MantineTitle } from '@mantine/core';
import Block = Editorial.Block;
import Template = Block.Template;

interface TitleRendererProps {
  block: Block<Template.Title>;
}

const TitleRenderer: FC<TitleRendererProps> = ({ block }) => {
  return (
    <MantineTitle order={Block.Title.levelOrder[block.level]} c="primary" mb={14}>
      {block.content}
    </MantineTitle>
  );
};

export default TitleRenderer;
