import styled from '@emotion/styled';

export const HalfPieChartPointer = styled.span`
  position: absolute;
  transform-origin: bottom center;
  bottom: 50%;
  left: 50%;

  width: 2px;
  height: calc(100% / 4);

  background: ${props => props.theme.colors.dark[4]};

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 12px;
    height: 12px;
    background: inherit;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
`;
