import React, { FC, useMemo, useState } from 'react';
import { defineRoute, useHashDisclosure, usePreserveNavigate } from '@core/router';
import { z } from 'zod';
import { AlertReport } from '@modules/alert-reports/model';
import { defer, defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { pipe } from 'fp-ts/function';
import { AlertReportsService } from '@modules/alert-reports/service';
import Page from '@layout/page/Page';
import { ActionIcon, Group, Select, SimpleGrid, Stack, Text } from '@mantine/core';
import WeatherPrevisions from '@shared/modules/previsions/components/weather/WeatherPrevisions';
import { sequenceS } from 'fp-ts/Apply';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import * as O from 'fp-ts/Option';
import * as TO from 'fp-ts/TaskOption';
import * as R from 'fp-ts/Record';
import * as A from 'fp-ts/Array';
import * as M from 'fp-ts/Monoid';
import * as S from 'fp-ts/string';
import * as E from 'fp-ts/Either';
import * as NEA from 'fp-ts/NonEmptyArray';
import Await from '@core/router/components/Await';
import DiseasePrevisions from '@shared/modules/previsions/components/disease/DiseasePrevisions';
import WeatherPlaceholder from '@shared/modules/previsions/components/weather/WeatherPlaceholder';
import DiseasePlaceholder from '@shared/modules/previsions/components/disease/DiseasePlaceholder';
import DiseaseCaption from '@shared/modules/previsions/components/disease/DiseaseCaption';
import ReportDateCard from '@modules/alert-reports/components/ReportDateCard';
import AlertReportGalleryCard from '@modules/alert-reports/components/AlertReportGalleryCard';
import CommentsCards from '@modules/alert-reports/components/CommentsCards';
import SensorsDataList from '@modules/alert-reports/analysis/components/sensors/SensorsDataList';
import { AlertReportUtils } from '@modules/alert-reports/utils';
import { parseQueriesFormUrl, stringifyQueries } from '@shared/utils/queries';
import { SensorsService } from '@modules/iot/sensors/service';
import { renderOptional } from '@shared/utils/render';
import SensorLineChart from '@shared/modules/charts/line/SensorLineChart';
import { DateFormat, formatDate, parseDate } from '@shared/modules/dates';
import { parseISO, subDays } from 'date-fns';
import { Measures } from '@shared/modules/measures/model';
import { Sensor } from '@modules/iot/sensors/model';
import { MeasuresUtils } from '@shared/modules/measures/utils';
import { Utils } from '@shared/utils/model';
import SensorMeasureSelection from '@shared/modules/measures/SensorMeasureSelection';
import * as IOO from 'fp-ts/IOOption';
import SensorsDataListPlaceholder from '@modules/alert-reports/analysis/components/sensors/SensorsDataListPlaceholder';
import PestCard from '@modules/alert-reports/dashboard/components/PestCard';
import { IconHelp } from '@tabler/icons-react';
import WeatherParamDescriptionDrawer from '@shared/modules/previsions/components/weather/WeatherParamDescriptionDrawer';
import DiseasesDataPlaceholder from '@modules/alert-reports/analysis/components/DiseasesDataPlaceholder';
import DiseaseChartCard from '@modules/alert-reports/components/DiseaseChartCard';

const params = z.object({ id: AlertReport.Id });

const loader = defineLoader({
  params,
  handler: ({ params, request }) => {
    const { sensorId, type } = AlertReportUtils.alertReportFilterParser(parseQueriesFormUrl(request.url));
    return httpTaskToResponseTask(
      pipe(
        AlertReportsService.getAlertReport(params.id),
        TE.map(alertReport => {
          const sensors = httpTaskToResponseTask(AlertReportsService.Analysis.getSensors(params.id))();

          const filter: O.Option<Measures.History.Filter> = pipe(
            parseDate(alertReport.reportedAt, DateFormat.LocalDateTime),
            O.map(endDate => ({
              startDate: formatDate(subDays(endDate, 8), DateFormat.LocalDateTime),
              endDate: alertReport.reportedAt,
              unit: Utils.ChronoUnit.Days,
            })),
          );

          const sensor = pipe(
            TO.fromOption(sequenceS(O.Apply)({ id: O.fromNullable(sensorId), type: O.fromNullable(type) })),
            TO.altW(() => pipe(() => sensors, T.map(A.head))),
          );

          const measures = pipe(
            sensor,
            TO.bind('filter', () => T.of(filter)),
            TO.chainTaskK(sensor =>
              httpTaskToResponseTask(SensorsService.getMeasures(sensor.id, sensor.type, sensor.filter)),
            ),
          );

          const diseases: TO.TaskOption<E.Either<AlertReport.Diseases, AlertReport.Pests>> = pipe(
            TO.fromNullable(alertReport.diseaseModels),
            TO.chainTaskK(() => httpTaskToResponseTask(AlertReportsService.Analysis.getDiseases(params.id))),
            TO.map(E.left),
            TO.altW(() =>
              pipe(
                TO.fromNullable(alertReport.pest),
                TO.chainTaskK(() => httpTaskToResponseTask(AlertReportsService.Analysis.getPests(params.id))),
                TO.map(E.right),
              ),
            ),
          );

          return defer({
            alertReport,
            weather: httpTaskToResponseTask(AlertReportsService.Analysis.getWeather(params.id)),
            sensor,
            sensors,
            measures,
            diseases,
          });
        }),
      ),
    );
  },
});

const defaultSelection = Object.values(Sensor.Probe.Identifier).flatMap(identifier =>
  Object.values(Measures.Type).map(type => `${identifier}.${type}` as const),
);

const DetailPage: FC = () => {
  const [helpWeatherOpen, { open: openHelpWeather, close: closeHelpWeather }] = useHashDisclosure('#help-weather');

  const navigate = usePreserveNavigate();
  const { alertReport, weather, diseases, sensors, measures, sensor } = useLoader<typeof loader>();
  const [selection, setSelection] = useState(defaultSelection);

  const handleSensorChange = (sensors: Array<AlertReport.Sensor>) => (id: Sensor.Id) =>
    pipe(
      sensors,
      A.findFirst(sensor => sensor.id === id),
      IOO.fromOption,
      IOO.chainIOK(
        ({ id, type }) =>
          () =>
            navigate({ search: stringifyQueries({ sensorId: id, type }) }, { preventScrollReset: true }),
      ),
    )();

  const getData = (measures: Measures.History<Sensor.Type>) =>
    pipe(
      R.toEntries(measures),
      A.chain(([identifier, values]) =>
        pipe(
          values,
          A.filter(({ type }) => selection.some(key => key === `${identifier}.${type}`)),
          A.map(({ values, type }) =>
            values.reduce((acc, curr) => ({ ...acc, [curr.at]: { [`${identifier}.${type}`]: curr.value } }), {}),
          ),
        ),
      ),
      M.concatAll(MeasuresUtils.mergeDateMonoid),
      R.collect(S.Ord)((at, values) => ({ date: parseISO(at), ...values })),
    );

  const dateFormatter = (date: Date) => formatDate(date, 'dd/MM');

  return (
    <>
      <Page top="Analyse de mes signalements">
        <Group position="apart">
          <Text fz={22} fw={700} c="primary">
            {alertReport.type.label}
          </Text>
          <ReportDateCard reportedAt={alertReport.reportedAt} />
        </Group>
        <Stack spacing={32}>
          <Group>
            <AlertReportGalleryCard alertReport={alertReport} />
            <CommentsCards alertReport={alertReport} mt={32} style={{ flexGrow: 2 }} />
          </Group>
          <Stack>
            <Group position="apart">
              <Text size={18} weight={700}>
                Données météo une semaine avant le signalement
              </Text>
              <Group spacing={4}>
                <Text
                  component="label"
                  fz={10}
                  fw={600}
                  lh={1.55}
                  c="primary.4"
                  htmlFor="help-weather"
                  style={{ cursor: 'pointer' }}
                >
                  Légende des données météo
                </Text>
                <ActionIcon id="help-weather" onClick={openHelpWeather} size={20} c="primary.4">
                  <IconHelp strokeWidth={1.5} />
                </ActionIcon>
              </Group>
            </Group>
            <Await resolve={weather} fallback={<WeatherPlaceholder />} withFallbackError>
              {weather => (
                <WeatherPrevisions
                  location={weather.location}
                  main={weather.reportDay?.values ?? null}
                  days={weather.previousDays}
                  date={weather.reportDay?.date}
                />
              )}
            </Await>
          </Stack>
          <Await resolve={diseases} fallback={<DiseasePlaceholder />} withFallbackError>
            {diseases =>
              renderOptional(
                diseases,
                E.match(
                  ({ previousDays, reportDay, location }) => (
                    <Stack spacing={8}>
                      <Group position="apart">
                        <Text color="dark.5" weight={700} size={18} lh={1.45}>
                          Données épidémiosurveillance une semaine avant le signalement
                        </Text>

                        <DiseaseCaption />
                      </Group>
                      {renderOptional(
                        NEA.fromArray(previousDays),
                        previousDays => (
                          <Stack>
                            <DiseasePrevisions
                              location={location}
                              main={reportDay}
                              days={previousDays}
                              date={alertReport.reportedAt}
                              formatStr={DateFormat.LocalDate}
                              withoutIcon
                            />
                            <DiseaseChartCard days={previousDays} />
                          </Stack>
                        ),
                        () => (
                          <DiseasesDataPlaceholder>
                            Aucune donnée existante une semaine avant le signalement
                          </DiseasesDataPlaceholder>
                        ),
                      )}
                    </Stack>
                  ),
                  ({ previousWeek, reportWeek }) => (
                    <Stack spacing={20}>
                      <Text color="dark.5" weight={700} size={18} lh={1.45}>
                        Suivi des risques par bioagresseur
                      </Text>
                      <SimpleGrid cols={2} spacing={30} c="primary" fz={18} fw={700} lh={1.45} style={{ rowGap: 20 }}>
                        <PestCard pest={reportWeek} />
                        <PestCard pest={previousWeek} />
                        <Text style={{ justifySelf: 'center' }}>Semaine du signalement</Text>
                        <Text style={{ justifySelf: 'center' }}>Semaine précédent le signalement</Text>
                      </SimpleGrid>
                    </Stack>
                  ),
                ),
                () => (
                  <Stack spacing={18}>
                    <Text color="dark.5" weight={700} size={18} lh={1.45}>
                      Données épidémiosurveillance une semaine avant le signalement
                    </Text>
                    <DiseasesDataPlaceholder>
                      Nous ne disposons pas de modèle de prévision ou de vigilance pour ce bioagresseur
                    </DiseasesDataPlaceholder>
                  </Stack>
                ),
              )
            }
          </Await>

          <Stack spacing={18}>
            <Text color="dark.5" weight={700} size={18} lh={1.45}>
              Données de sondes
            </Text>
            <Await resolve={sensors}>
              {sensors =>
                renderOptional(
                  NEA.fromArray(sensors),
                  sensors => <SensorsDataList sensors={sensors} />,
                  () => <SensorsDataListPlaceholder />,
                )
              }
            </Await>
          </Stack>

          <Await resolve={useMemo(() => Promise.all([sensor, sensors, measures]), [measures, sensor, sensors])}>
            {([sensor, sensors, measures]) =>
              renderOptional(sequenceS(O.Apply)({ measures, sensor }), ({ measures, sensor }) => (
                <Stack spacing={18}>
                  <Text color="dark.5" weight={700} size={18} lh={1.45}>
                    Représentation des données de sondes une semaine avant le signalement
                  </Text>
                  <Stack>
                    <Group>
                      <Select
                        label="Sélectionner une sonde"
                        placeholder="Sélectionner une sonde"
                        data={sensors.map(({ id, serial }) => ({ value: id, label: serial }))}
                        onChange={handleSensorChange(sensors)}
                        value={sensor.id}
                      />
                    </Group>
                    <SensorLineChart data={getData(measures)} dateFormatter={dateFormatter} />
                    <SensorMeasureSelection measures={measures} selection={selection} setSelection={setSelection} />
                  </Stack>
                </Stack>
              ))
            }
          </Await>
        </Stack>
      </Page>
      <WeatherParamDescriptionDrawer opened={helpWeatherOpen} onClose={closeHelpWeather} />
    </>
  );
};

const alertReportAnalysisRoute = defineRoute({
  component: DetailPage,
  loader,
});

export default alertReportAnalysisRoute;
