import React, { FC } from 'react';
import PrevisionsCard from '@shared/modules/previsions/components/PrevisionsCard';
import { Group, Stack, Text } from '@mantine/core';
import { IconMapPin } from '@tabler/icons-react';
import { Zone } from '@modules/iot/zones/model';

interface ActiveSensorWeatherProps {
  zone: Zone;
  zones: Array<Zone>;
}

const ActiveSensorWeather: FC<ActiveSensorWeatherProps> = ({ zone, zones }) => {
  return (
    <Group align="start" spacing={16}>
      <PrevisionsCard zoneId={zone.id} zones={zones} />
      <Stack spacing={8}>
        <Text color="dark.5" size={14} weight={400}>
          {zone.description}
        </Text>
        <Text size={14} weight={400} color="dark.1">
          <Group spacing={4}>
            <IconMapPin size={14} />
            {zone.location.postalCode}, {zone.location.city}
          </Group>
        </Text>
      </Stack>
    </Group>
  );
};

export default ActiveSensorWeather;
