import React, {
  createContext,
  FormEventHandler,
  PropsWithChildren,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { UseFormReturn, UseFormSetValue } from 'react-hook-form';
import * as IO from 'fp-ts/IO';
import * as TE from 'fp-ts/TaskEither';
import { constVoid, pipe } from 'fp-ts/function';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';
import { FormPreventLeaveModal } from '@shared/modules/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

export interface EnhancedFormContextValue<Values extends FieldValues>
  extends Omit<UseFormReturn<Values>, 'handleSubmit'> {
  handleSubmit: FormEventHandler<HTMLFormElement>;
}

export const EnhancedFormContext = createContext<EnhancedFormContextValue<any>>(undefined as any);

export interface EnhancedFormInnerProps<Values extends FieldValues> {
  form: UseFormReturn<Values>;
  preventLeave?: boolean;
  onSubmit: (values: Values) => TE.TaskEither<unknown, unknown>;
}

export type EnhancedFormExposedMethods = {
  handleSubmit: IO.IO<void>;
};

function EnhanceFormInner<Values extends FieldValues>(
  { form, preventLeave, onSubmit, children }: PropsWithChildren<EnhancedFormInnerProps<Values>>,
  ref: React.ForwardedRef<EnhancedFormExposedMethods>,
) {
  const [isDirty, setDirty] = useState<boolean>(false);

  useEffect(() => {
    setDirty(form.formState.isDirty);
  }, [form.formState.isDirty]);

  const blocker = useBlocker(!!preventLeave && isDirty);

  const handleSubmit = (values: Values) => {
    setDirty(false);

    pipe(
      onSubmit(values),
      TE.chainFirstIOK(() => () => form.reset(undefined, { keepValues: true })),
      TE.orElseFirstIOK(() => () => setDirty(true)),
    )();
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: form.handleSubmit(handleSubmit),
  }));

  const setValue = useCallback<UseFormSetValue<Values>>(
    (name, value, options = {}) =>
      form.setValue(name, value, {
        ...options,
        shouldDirty: true,
        shouldValidate: form.formState.isSubmitted,
      }),
    [form],
  );

  const ctx: EnhancedFormContextValue<Values> = {
    ...form,
    setValue,
    handleSubmit: form.handleSubmit(handleSubmit),
  };

  return (
    <>
      {preventLeave ? (
        <FormPreventLeaveModal
          open={blocker.state === 'blocked'}
          onLeave={blocker.proceed ?? constVoid}
          onClose={blocker.reset ?? constVoid}
        />
      ) : null}

      <EnhancedFormContext.Provider value={ctx}>{children}</EnhancedFormContext.Provider>
    </>
  );
}

// Redecalare forwardRef
declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

const EnhancedForm = React.forwardRef(EnhanceFormInner);

export default EnhancedForm;
