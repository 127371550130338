import React, { FC } from 'react';
import { Disease } from '@shared/modules/disease/model';
import { Pest } from '@modules/alert-reports/pests/model';
import { flow, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as R from 'fp-ts/Record';
import * as NEA from 'fp-ts/NonEmptyArray';
import { Text } from '@mantine/core';
import AnalysisListingDiseases from '@modules/alert-reports/analysis/components/list/AnalysisListingDiseases';
import AnalysisListingPest from '@modules/alert-reports/analysis/components/list/AnalysisListingPest';

interface AnalysisListingScoringProps {
  diseases: Disease.Forecast | null;
  pest: Pest.Description | null;
}

const AnalysisListingScoring: FC<AnalysisListingScoringProps> = ({ diseases: diseasesParam, pest }) => {
  const diseases = pipe(
    O.fromNullable(diseasesParam),
    O.filterMap(flow(R.filterMap(O.fromNullable), R.toEntries<Disease.Model, Disease.Forecast.Value>, NEA.fromArray)),
  );

  const stages = pipe(
    O.fromNullable(pest),
    O.filterMap(({ stages }) => NEA.fromArray(stages)),
  );

  return pipe(
    diseases,
    O.map(diseases => <AnalysisListingDiseases key="listing-diseases" diseases={diseases} />),
    O.alt(() =>
      pipe(
        stages,
        O.map(stages => <AnalysisListingPest key="listing-pest" stages={stages} />),
      ),
    ),
    O.getOrElse(() => (
      <Text c="dark.1" fz={14} fw={400} lh={1.55}>
        Aucun
      </Text>
    )),
  );
};

export default AnalysisListingScoring;
