import React, { FC, useEffect } from 'react';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { Sensor } from '@modules/iot/sensors/model';
import { SensorsService } from '@modules/iot/sensors/service';
import { defineRoute, withKeyObserver } from '@core/router';
import { Card, Stack } from '@mantine/core';
import HumidityCharts from '@modules/iot/sensors/sensoterra/components/HumidityCharts';
import { sequenceS } from 'fp-ts/Apply';
import * as TE from 'fp-ts/TaskEither';
import * as A from 'fp-ts/Array';
import { SensoterraService as IOTSensoterraService } from '@modules/iot/sensoterra/service';
import { SensoterraService } from '@modules/iot/sensors/sensoterra/service';
import { ZonesService } from '@modules/iot/zones/service';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import PendingForm from '@modules/iot/sensors/sensoterra/components/PendingForm';
import { defineAction, useAction } from '@core/router/action';
import { SensoterraSchema } from '@modules/iot/sensors/sensoterra/schema';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { Sensoterra } from '@modules/iot/sensoterra/model';
import { zodResolver } from '@hookform/resolvers/zod';
import { Outlet } from 'react-router-dom';
import ActiveSensorCommonSection from '@modules/iot/sensors/list/components/ActiveSensorCommonSection';
import { Threshold } from '@modules/iot/model';
import * as Ord from 'fp-ts/Ord';
import { SensorAction } from '@modules/iot/sensors/action';
import exportMeasures = SensorAction.exportMeasures;
import deleteSensor = SensorAction.deleteSensor;

const loader = defineLoader({
  id: Sensor.SensorsRouteId.SensoterraDetail,
  params: Sensor.sensorDetailParams,
  handler: ({ params }) =>
    pipe(
      sequenceS(TE.ApplyPar)({
        sensor: SensorsService.getActiveSensor<Sensor.Type.Sensoterra>(params.id),
        parameters: IOTSensoterraService.getParameters(),
        zones: ZonesService.getZones(),
      }),
      httpTaskToResponseTask,
    ),
});
const actions = {
  update: defineAction({
    params: Sensor.sensorDetailParams,
    type: 'update',
    payload: SensoterraSchema.updateSchema,
    handler: ({ payload, params }) => SensoterraService.updateSensor(params.id, payload),
    flashOptions: {
      success: () => 'Modification réalisée',
    },
  }),
  deleteSensor,
  exportMeasures,
};

const DetailPage: FC = () => {
  const [loading, send] = useAction(actions.update);

  const { sensor, parameters, zones } = useLoader<typeof loader>();

  const { formRef, form } = useEnhancedForm<Sensoterra.UpdateParams>({
    resolver: zodResolver(SensoterraSchema.updateSchema),
    defaultValues: {
      name: sensor.name,
      zoneId: sensor.zone?.id,
      config: {
        coordinates: {
          latitude: sensor.location?.latitude,
          longitude: sensor.location?.longitude,
        },
        soilType: sensor.config.soilType ?? '',
        humidityThreshold: sensor.config.humidityThreshold,
      },
      comment: sensor.comment,
    },
  });

  // observe dnd zone change
  useEffect(() => {
    form.resetField('zoneId', { defaultValue: sensor.zone?.id });
  }, [form, sensor.zone?.id]);

  const formatDistance = (key: string) =>
    pipe(
      parameters,
      A.findFirstMap(parameter => (parameter.key === key ? O.some(parameter.name) : O.none)),
      O.toNullable,
    );

  const level = sensor.alerts.reduce((acc, { level }) => Ord.max(Threshold.levelOrd)(acc, level), Threshold.Level.None);

  return (
    <>
      <Stack spacing={16}>
        <ActiveSensorCommonSection sensor={sensor} zones={zones} formatDistance={formatDistance} level={level} />
        <HumidityCharts sensor={sensor} parameters={parameters} defaultSoilType={form.watch('config.soilType')} />

        <Card
          p={22}
          radius={10}
          shadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
          sx={theme => ({
            '&[data-with-border]': { border: `1px solid ${theme.colors.tertiary[2]}`, borderTop: 'none' },
          })}
        >
          <EnhancedForm ref={formRef} form={form} onSubmit={send} preventLeave>
            <PendingForm
              serial={sensor.serial}
              parameters={parameters}
              zones={zones}
              loading={loading}
              depth={sensor.config.depth}
              level={level}
            />
          </EnhancedForm>
        </Card>
      </Stack>
      <Outlet />
    </>
  );
};

const component = withKeyObserver<typeof loader>(DetailPage, 'id');

const sensoterraDetailRoute = defineRoute({
  component,
  loader,
  actions,
});

export default sensoterraDetailRoute;
