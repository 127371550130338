import { httpService, HttpTask } from '@core/http';
import { Sinafis } from '@modules/iot/sensors/sinafis/model';
import { PendingSensor, Sensor } from '@modules/iot/sensors/model';

export namespace SinafisService {
  const URI = '/iot/sensors/sinafis';

  export function updatePending(params: Sinafis.PendingParams): HttpTask<PendingSensor.RegisterSensorOut> {
    return httpService.post(URI, params);
  }

  export function updateSensor(id: Sensor.Id, params: Sinafis.UpdateParams): HttpTask {
    return httpService.post(`${URI}/${id}/config`, params);
  }
}
