import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import React from 'react';
import filterDetailRoute from '@modules/alert-reports/filters/DetailPage';
import createFilterRoute from '@modules/alert-reports/filters/CreatePage';
import saveFilterRoute from '@modules/alert-reports/filters/SavePage';
import filtersListRoute from '@modules/alert-reports/filters/ListPage';
import { AlertReport } from '@modules/alert-reports/model';

const getFiltersRoutes = (prefix: string) => (
  <Route path="filters" {...createRoute(filtersListRoute)} id={`${prefix}/${AlertReport.Filter.RouterId.Listing}`}>
    <Route path="new" {...createRoute(createFilterRoute)} id={`${prefix}/${AlertReport.Filter.RouterId.New}`} />
    <Route path=":id" {...createRoute(filterDetailRoute)} id={`${prefix}/${AlertReport.Filter.RouterId.Update}`} />
    <Route path="save" {...createRoute(saveFilterRoute)} id={`${prefix}/${AlertReport.Filter.RouterId.Save}`} />
  </Route>
);

export default getFiltersRoutes;
