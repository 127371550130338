import { FilterQueryParser, getBooleanQuery, getEnumQuery, getIntQuery, getStringQuery } from '@shared/modules/filter';
import { AlertReport } from '@modules/alert-reports/model';
import { LocalDateTime, Year } from '@shared/modules/dates';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { HttpTask } from '@core/http';
import * as TE from 'fp-ts/TaskEither';
import { AlertReportsService } from '@modules/alert-reports/service';
import * as A from 'fp-ts/Array';
import { ImageMap, PGImageVariant } from '@shared/modules/images/model';
import { Sensor } from '@modules/iot/sensors/model';

export namespace AlertReportUtils {
  export const alertReportsFilterParser: FilterQueryParser<AlertReport.Filter> = query => ({
    categoryId: getIntQuery<AlertReport.CategoryId>(query, 'categoryId'),
    subCategoryId: getIntQuery<AlertReport.SubCategoryId>(query, 'subCategoryId'),
    typeId: getIntQuery<AlertReport.TypeId>(query, 'typeId'),
    gravity: getIntQuery<AlertReport.Gravity>(query, 'gravity'),
    period: getEnumQuery(query, AlertReport.Period, 'period'),
    personalizedStartDate: getStringQuery<LocalDateTime>(query, 'personalizedStartDate'),
    personalizedEndDate: getStringQuery<LocalDateTime>(query, 'personalizedEndDate'),
    year: getStringQuery<Year>(query, 'year'),
    myReports: getBooleanQuery(query, 'myReports'),
    hasPictures: getBooleanQuery(query, 'hasPictures'),
    privateOnly: getBooleanQuery(query, 'privateOnly'),
    status: getEnumQuery(query, AlertReport.Status, 'status'),
    filterId: getStringQuery<AlertReport.Filter.Id>(query, 'filterId'),
  });

  export const alertReportFilterParser: FilterQueryParser<{
    sensorId: Sensor.Id | null;
    type: Sensor.Type | null;
  }> = query => ({
    sensorId: getStringQuery<Sensor.Id>(query, 'sensorId'),
    type: getStringQuery<Sensor.Type>(query, 'type'),
  });

  export const getNullableSubCategories = (id: AlertReport.CategoryId | null) =>
    pipe(
      O.fromNullable(id),
      O.fold(
        (): HttpTask<Array<AlertReport.SubCategory>> => TE.of([]),
        id => AlertReportsService.Referential.getSubCategories(id),
      ),
    );

  export const getNullableTypes = (id: AlertReport.SubCategoryId | null) =>
    pipe(
      O.fromNullable(id),
      O.fold(
        (): HttpTask<Array<AlertReport.Type>> => TE.of([]),
        id => AlertReportsService.Referential.getTypes(id),
      ),
    );

  export function getFallbackImage(variant: PGImageVariant, images: Array<ImageMap>) {
    return pipe(
      images,
      A.findFirstMap(map => O.fromNullable(map[variant])),
    );
  }
}
