import React, { ComponentType, createElement, FC, lazy } from 'react';
import { Params, RouteObject, ShouldRevalidateFunction, useParams } from 'react-router-dom';
import {
  ComponentRouteExport,
  DefaultRouteExport,
  DefaultRouteExportWithoutProps,
  RouteExport,
  RouteExportProps,
} from '@core/router/model';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import { actionHandler, Actions } from '@core/router/action';
import z from 'zod';
import { Loader, loaderHandler } from '@core/router/loader';
import * as S from 'fp-ts/string';

export function safeLazy<T extends ComponentType<any>>(factory: () => Promise<{ default: T }>) {
  return lazy<T>(() =>
    factory().catch(() => {
      window.location.reload();

      return new Promise(resolve => {
        setTimeout(resolve, 3000);
      });
    }),
  );
}

export function defineRoute<L extends Loader<any, any, any>, A extends Actions, RE extends DefaultRouteExport<L, A>>(
  route: RE,
): RE;
export function defineRoute<L extends Loader<any, any, any>, A extends Actions, RE extends ComponentRouteExport<L, A>>(
  route: RE,
): RE;

export function defineRoute<RE extends RouteExport<any, any>>(route: RE): RE {
  return route;
}

export function createRoute<L extends Loader<any, any, any>, A extends Actions, RE extends DefaultRouteExport<L, A>>(
  routeExport: DefaultRouteExportWithoutProps<RE>,
): Pick<RouteObject, 'element' | 'loader' | 'action' | 'errorElement' | 'shouldRevalidate'>;
export function createRoute<
  L extends Loader<any, any, any>,
  A extends Actions,
  RE extends ComponentRouteExport<L, A, any, any>,
>(
  routeExport: RE,
  props: RouteExportProps<RE>,
): Pick<RouteObject, 'element' | 'loader' | 'action' | 'errorElement' | 'shouldRevalidate'>;

export function createRoute<L extends Loader, A extends Actions, RE extends RouteExport<L, A>>(
  routeExport: RE,
  props?: RouteExportProps<RE>,
): Pick<RouteObject, 'element' | 'loader' | 'action' | 'errorElement' | 'shouldRevalidate' | 'id'> {
  const element =
    'component' in routeExport && routeExport.component ? createElement(routeExport.component, props) : undefined;

  const { actions, loader, shouldRevalidate } = routeExport;

  return {
    id: loader ? loader.id : undefined,
    element,
    action: actions ? actionHandler(actions) : undefined,
    loader: loader ? loaderHandler(loader) : undefined,
    errorElement: <ErrorPage />,
    shouldRevalidate,
  };
}

export function parseParams<ParamsSchema extends z.ZodType = z.ZodType<unknown>>(
  params: Params,
  paramsSchema?: z.ZodType,
): z.infer<ParamsSchema> {
  if (paramsSchema) {
    try {
      return paramsSchema.parse(params);
    } catch (e) {
      console.error('[loader] failed to parse params', e);

      throw new Response('[loader] failed to parse params', { status: 404 });
    }
  }

  return params;
}

export function preventActionLeave<A extends Actions>(
  type: A[keyof A]['type'] | Array<A[keyof A]['type']>,
): ShouldRevalidateFunction {
  const types: Array<string> = Array.isArray(type) ? type : [type];
  return ({ formData, defaultShouldRevalidate }) => {
    const _type = formData?.get('_type');

    if (_type && S.isString(_type)) return defaultShouldRevalidate && !types.includes(_type);

    return defaultShouldRevalidate;
  };
}

export function withKeyObserver<
  L extends Loader<any, any, any>,
  Props = {},
  Params extends Record<string, string> = L extends Loader<infer ParamsSchema, any, any>
    ? z.infer<ParamsSchema>
    : never,
>(Component: FC<Props>, key: keyof Params): FC<Props> {
  return (props: Props) => {
    const params = useParams<Params>();

    return <Component key={params[key]} {...props} />;
  };
}
