import React, { FC } from 'react';

import placeholderImage from '@assets/images/sensors-placeholder.svg';
import { Stack, Text } from '@mantine/core';

const SensorsPlaceholder: FC = () => {
  return (
    <Stack p={50} align="center">
      <img src={placeholderImage} alt="Placeholder" />

      <Text pt={20} fw={700} fz={26} c="tertiary.4" ta="center">
        Aucune sonde
      </Text>
    </Stack>
  );
};

export default SensorsPlaceholder;
