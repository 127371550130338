import React, { FC } from 'react';
import { Box, Divider, Group, Skeleton } from '@mantine/core';

const DashboardAlertsTablePlaceholder: FC = () => {
  return (
    <Box pt={50}>
      <Skeleton height={8} width={50} />
      <Skeleton height={8} width={90} my={8} />
      <Skeleton />
      <Group spacing={6} noWrap>
        <Skeleton height={36} radius={4} />
        <Skeleton width={36} height={36} radius={4} />
      </Group>
      <Group mt={6} spacing={6} noWrap>
        <Skeleton height={36} radius={4} />
        <Skeleton width={36} height={36} radius={4} />
      </Group>
      <Divider my={20} color="gray.3" />
      <Group spacing={6} noWrap>
        <Skeleton height={36} radius={4} />
        <Skeleton width={36} height={36} radius={4} />
      </Group>
      <Group mt={6} spacing={6} noWrap>
        <Skeleton height={36} radius={4} />
        <Skeleton width={36} height={36} radius={4} />
      </Group>
    </Box>
  );
};

export default DashboardAlertsTablePlaceholder;
