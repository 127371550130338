import React, { FC } from 'react';
import { Editorial } from '@shared/modules/editorial/model';
import Block = Editorial.Block;
import { AspectRatio, Box } from '@mantine/core';
import Template = Editorial.Block.Template;

interface YoutubeRendererProps {
  block: Block<Template.Youtube>;
}

const YoutubeRenderer: FC<YoutubeRendererProps> = ({ block }) => {
  return (
    <Box py={10}>
      <AspectRatio ratio={16 / 9}>
        <iframe
          title="YouTube"
          src={`https://www.youtube.com/embed/${block.youtubeId}`}
          allow="encrypted-media"
          style={{ border: 0 }}
        />
      </AspectRatio>
    </Box>
  );
};

export default YoutubeRenderer;
