import React, { FC } from 'react';
import { Anchor, Text } from '@mantine/core';
import { Editorial } from '@shared/modules/editorial/model';
import Block = Editorial.Block;
import Template = Editorial.Block.Template;
import { IconArrowRight } from '@tabler/icons-react';

interface LinkRendererProps {
  block: Block<Template.Link>;
}

const LinkRenderer: FC<LinkRendererProps> = ({ block }) => {
  return (
    <Anchor
      display="flex"
      p={10}
      c="primary"
      underline={false}
      href={block.url}
      target="_blank"
      rel="noopener noreferrer"
      sx={theme => ({
        transition: 'color 0.15s linear',
        '&:hover': {
          color: theme.fn.darken(theme.colors.primary[5], 0.1),
        },
      })}
    >
      <IconArrowRight />
      <Text ml={3} style={{ wordBreak: 'break-all' }}>
        {block.content}
      </Text>
    </Anchor>
  );
};

export default LinkRenderer;
