import z from 'zod';
import { ZoneSchema } from '@modules/iot/zones/schema';
import { Geo } from '@shared/modules/geo/model';

export namespace Zone {
  export const Id = z.string().uuid().brand('ZoneId');
  export type Id = z.infer<typeof Id>;

  export type SaveParams = z.infer<typeof ZoneSchema.saveZoneSchema>;
}

export interface Zone {
  id: Zone.Id;
  name: string;
  location: Geo.City;
  description: string;
}
