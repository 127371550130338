import React, { FC } from 'react';
import ActiveSensorHeader from '@modules/iot/sensors/components/ActiveSensorHeader';
import { Measures } from '@shared/modules/measures/model';
import { Stack } from '@mantine/core';
import { renderNullable } from '@shared/utils/render';
import ActiveSensorWeather from '@modules/iot/sensors/list/components/ActiveSensorWeather';
import ActiveSensorSignalInfo from '@modules/iot/sensors/list/components/ActiveSensorSignalInfo';
import DetailContent from '@modules/iot/sensors/list/components/DetailContent';
import { MeasuresUtils } from '@shared/modules/measures/utils';
import getMeasureFromList = MeasuresUtils.getMeasureFromList;
import { ActiveSensor } from '@modules/iot/sensors/model';
import { Zone } from '@modules/iot/zones/model';
import * as A from 'fp-ts/Array';
import { Threshold } from '@modules/iot/model';

interface ActiveSensorCommonSectionProps {
  sensor: ActiveSensor;
  zones: Array<Zone>;
  level: Threshold.Level;
  formatDistance?(distance: string): string | null;
}

const ActiveSensorCommonSection: FC<ActiveSensorCommonSectionProps> = ({ sensor, zones, formatDistance, level }) => {
  const measures = A.flatten(Object.values(sensor.measures ?? {}));
  const signal = getMeasureFromList(measures, Measures.Type.Signal);

  return (
    <>
      <ActiveSensorHeader
        sensor={sensor}
        battery={getMeasureFromList(measures, Measures.Type.Battery)}
        signal={signal}
      />
      <Stack pb={16} sx={theme => ({ borderBottom: `1px solid ${theme.colors.gray[3]}` })}>
        {renderNullable(sensor.zone, zone => (
          <ActiveSensorWeather zone={zone} zones={zones} />
        ))}
        <ActiveSensorSignalInfo signal={signal} />
      </Stack>

      <DetailContent sensor={sensor} formatDistance={formatDistance} level={level} />
    </>
  );
};

export default ActiveSensorCommonSection;
