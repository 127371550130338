import React, { FC } from 'react';
import MainWeather from '@shared/modules/previsions/components/weather/today/MainWeather';

import * as Styled from './WeatherPrevisions.styles';
import DayWeather from '@shared/modules/previsions/components/weather/day/DayWeather';
import { Weather as WeatherModel } from '@shared/modules/weather/model';
import { LocalDate } from '@shared/modules/dates';
import { Geo } from '@shared/modules/geo/model';

interface WeatherPrevisionsProps {
  main: WeatherModel.ForecastValues | null;
  days: Array<WeatherModel.DayForecast>;
  location: Geo.City | null;
  date?: LocalDate;
  vertical?: boolean;
}

const WeatherPrevisions: FC<WeatherPrevisionsProps> = ({ main, days, location, vertical = false, date = null }) => {
  return (
    <Styled.WeatherPrevisionsContainer $vertical={vertical}>
      <MainWeather values={main} location={location} date={date} />

      {days.map(day => (
        <DayWeather key={day.date} day={day} />
      ))}
    </Styled.WeatherPrevisionsContainer>
  );
};

export default WeatherPrevisions;
