import { z } from 'zod';

export enum DateFormat {
  LocalDate = 'dd/MM/yyyy',
  LocalDateTime = 'dd/MM/yyyy HH:mm',
  LocalTime = 'HH:mm',
  Year = 'yyyy',
}

export const LocalDate = z.string().brand<'LocalDate'>();
export type LocalDate = z.infer<typeof LocalDate>;

export const LocalDateTime = z.string().brand<'LocalDateTime'>();
export type LocalDateTime = z.infer<typeof LocalDateTime>;

export const LocalTime = z.string().brand<'LocalTime'>();
export type LocalTime = z.infer<typeof LocalTime>;

export const Year = z.string().brand('Year');
export type Year = z.infer<typeof Year>;

export type DateTypes<Format extends DateFormat> = {
  [DateFormat.LocalDate]: LocalDate;
  [DateFormat.LocalDateTime]: LocalDateTime;
  [DateFormat.LocalTime]: LocalTime;
  [DateFormat.Year]: Year;
}[Format];

export const Timestamp = z.string().brand<'Timestamp'>();
export type Timestamp = z.infer<typeof Timestamp>;
