import React, { FC } from 'react';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { z } from 'zod';
import { Zone } from '@modules/iot/zones/model';
import { ZonesService } from '@modules/iot/zones/service';
import { defineRoute, preventActionLeave, usePreserveNavigate } from '@core/router';
import { defineAction, useAction } from '@core/router/action';
import { ZoneSchema } from '@modules/iot/zones/schema';
import { EnhancedForm, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { ActionIcon, Button, Group, Title, Tooltip } from '@mantine/core';
import SaveZoneForm from '@modules/iot/zones/components/SaveZoneForm';
import { IconTrash } from '@tabler/icons-react';
import { modals } from '@mantine/modals';
import PreserveSearchLink from '@core/router/components/PreserveSearchLink';

const params = z.object({ zoneId: Zone.Id });

const loader = defineLoader({
  params,
  handler: ({ params }) => httpTaskToResponseTask(ZonesService.getZone(params.zoneId)),
});

const actions = {
  update: defineAction({
    type: 'update',
    params,
    payload: ZoneSchema.saveZoneSchema,
    handler: ({ params, payload }) => ZonesService.updateZone(params.zoneId, payload),
    flashOptions: {
      success: () => 'Modification réalisée',
    },
  }),
  delete: defineAction({
    type: 'delete',
    params,
    handler: ({ params }) => ZonesService.deleteZone(params.zoneId),
    flashOptions: {
      success: () => 'Suppression réalisée',
    },
  }),
};

const ZoneDetailPage: FC = () => {
  const zone = useLoader<typeof loader>();

  const navigate = usePreserveNavigate();

  const [loading, update] = useAction(actions.update);
  const [deleteLoading, deleteZone] = useAction(actions.delete);

  const { formRef, form, handleFormSubmit } = useEnhancedForm<Zone.SaveParams>({
    resolver: zodResolver(ZoneSchema.saveZoneSchema),
    defaultValues: {
      name: zone.name,
      location: zone.location,
      description: zone.description,
    },
  });

  const handleUpdate = (params: Zone.SaveParams) =>
    pipe(
      update(params),
      TE.chainFirstIOK(() => () => navigate('../..', { replace: true, relative: 'path' })),
    );

  const onConfirmDelete = () =>
    pipe(
      deleteZone(),
      TE.chainFirstIOK(() => () => navigate('../..', { replace: true, relative: 'path' })),
    )();

  const handleDelete = () => {
    modals.openConfirmModal({
      title: <Title size="h3">Êtes vous sûr ?</Title>,
      size: 400,
      centered: true,
      children: <>Supprimer définitivement le groupe {zone.name}.</>,
      labels: { confirm: 'Supprimer le groupe', cancel: 'Annuler' },
      cancelProps: { variant: 'subtle', color: 'gray' },
      onConfirm: onConfirmDelete,
    });
  };

  return (
    <>
      <Group pb={50}>
        <Title size="h3" sx={{ flexGrow: 1 }}>
          Modifier le groupe
        </Title>

        <Tooltip label="Supprimer">
          <ActionIcon
            variant="light"
            c="tertiary.200"
            size={36}
            loading={deleteLoading}
            disabled={deleteLoading}
            onClick={handleDelete}
          >
            <IconTrash size={20} />
          </ActionIcon>
        </Tooltip>
      </Group>
      <EnhancedForm ref={formRef} form={form} onSubmit={handleUpdate} preventLeave>
        <SaveZoneForm />
      </EnhancedForm>
      <Group mt="auto" ml="auto" pos="sticky" pt={20} bottom={30}>
        <Button variant="white" c="dark.3" component={PreserveSearchLink} to="../..">
          Annuler
        </Button>
        <Button onClick={handleFormSubmit} loading={loading}>
          Enregistrer
        </Button>
      </Group>
    </>
  );
};

const zoneDetailRoute = defineRoute({
  component: ZoneDetailPage,
  loader,
  actions,
  shouldRevalidate: preventActionLeave<typeof actions>(['update', 'delete']),
});

export default zoneDetailRoute;
