import { httpService, HttpTask } from '@core/http';
import { PendingSensor, Sensor } from '@modules/iot/sensors/model';
import { Hortee } from '@modules/iot/sensors/hortee/model';
import { Measures } from '@shared/modules/measures/model';

export namespace HorteeService {
  const URI = '/iot/sensors/hortee';

  export function updatePending(params: Hortee.PendingParams): HttpTask<PendingSensor.RegisterSensorOut> {
    return httpService.post(URI, params);
  }

  export function updateSensor(id: Sensor.Id, params: Hortee.UpdateParams): HttpTask {
    return httpService.post(`${URI}/${id}/config`, params);
  }

  export function getHumidityMeasures(id: Sensor.Id, filter: Hortee.Humidity.Filter): HttpTask<Measures.Humidity> {
    return httpService.get(`${URI}/${id}/measures/humidity`, { params: filter });
  }
}
